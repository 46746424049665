import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useMyFiles, useClickOutside } from 'hooks';
import {
  Badge, DownloadButton, IframePreview, Preloader, Slider, VideoPlayer,
} from 'ui-kit';
import { COLORS } from 'constants/styles';
import {
  getPreview, getRenderTypeByFileType, getToken, notify,
} from 'utils/helpers';
import {
  HTML_PREVIEW_TYPES, RENDER_STATUS, RENDER_TYPES_FOR_API, SPECIFIC_PREVIEW_TYPES,
} from 'constants/rendering';
import { useTranslation } from 'react-i18next';
import { videoService } from 'api';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import {
  Body, Card, Header, Image,
  RootStyle, Row, Title,
} from './Preview.styled';

const BUTTON_DIMENSION = 24;
const FONT_WEIGHT = 'normal';

/**
 *
 * @param file {object}
 * @returns {JSX.Element}
 * @constructor
 */

export const Preview = ({ file }) => {
  const { t } = useTranslation('common');
  const { setSelectedPreviewFileId } = useMyFiles();

  const ref = useRef(null);
  const {
    id,
    type,
    duration,
    animation_ratio:
    animationRatio,
    status,
    title,
    video_file_id: videoFileId,
    preview_file_id: previewFileId,
  } = file;
  const [frames, setFrames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fileDuration = duration ? `${duration}sec` : '-';
  const renderTypeLabel = getRenderTypeByFileType(type)?.label || '-';

  // can be an object with id or number
  const previewId = previewFileId?.id || previewFileId;
  // check if html5 or zip render type
  const fileUrlId = SPECIFIC_PREVIEW_TYPES.includes(type) ? previewId : videoFileId;
  // get url by file url id
  const fileUrl = getPreview(fileUrlId, status);

  const isVideo = type === RENDER_TYPES_FOR_API.mp4 && status === RENDER_STATUS.SUCCESS;
  const isKeyFrames = type === RENDER_TYPES_FOR_API.keyframesZip && status === RENDER_STATUS.SUCCESS;
  const isHtml = HTML_PREVIEW_TYPES.includes(type) && status === RENDER_STATUS.SUCCESS;

  useClickOutside({
    ref,
    callback: () => setSelectedPreviewFileId(null),
  });

  const getPreviewFile = async () => {
    try {
      setIsLoading(true);
      const { data } = await videoService.getVideoPreview(id);
      setFrames(data.frames);
    } catch (e) {
      notify.error(t('An issue with getting preview'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadFrame = useCallback(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getPreviewFile();
    return () => {
      setFrames([]);
    };
  }, []);

  const iframeLink = `${process.env.REACT_APP_API_URL}/api/videos/${id}/preview?html=1&token=${getToken()}`;

  return (
    <RootStyle>
      <Card ref={ref} id="container">
        <Header>
          <Title>{file.title}</Title>
          <Row>
            <Badge label={animationRatio} bg={COLORS.MAIN} fontWeight={FONT_WEIGHT} />
            <Badge label={fileDuration} bg={COLORS.MAIN} fontWeight={FONT_WEIGHT} />
            <Badge label={renderTypeLabel} bg={COLORS.GREEN} fontWeight={FONT_WEIGHT} />
            <DownloadButton
              status={status}
              title={title}
              videoFileId={videoFileId}
              type={type}
              size={BUTTON_DIMENSION}
            />
          </Row>
        </Header>
        <Preloader
          type={PRELOADER_TYPES.CONTAINER}
          size={PRELOADER_SIZES.SMALL}
          isLoading={isHtml && isLoading}
          minDuration={800}
          isDestroyChildren
          height="400px"
          width="400px"
          preloaderItemProps={{
            backgroundColor: 'transparent',
          }}
        >
          <Body>
            {isKeyFrames && (<Slider slides={frames} />)}
            {isVideo && (<VideoPlayer src={fileUrl} />)}
            {isHtml && (<IframePreview link={iframeLink} onLoad={handleLoadFrame} />)}
            {!isKeyFrames && !isVideo && !isHtml && (
              <Image
                src={fileUrl}
                alt="Preview"
              />
            )}
          </Body>
        </Preloader>
      </Card>
    </RootStyle>
  );
};
