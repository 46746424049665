import { handleActions } from 'redux-actions';
import {
  assocPath,
  compose, objOf, prop, propOr,
} from 'ramda';
import {
  mergeByProp, mergeIn, mergeDeep, mergeDeepByProp,
} from 'utils/helpers/ramdaStateHelpers';
import animationTypes from 'state/animation/types';
import types from './types';

const IMAGE_REFS = 'imageRefs';

const initialState = {
  loop: true,
  autoplay: false,
  version: '',
  rendererSettings: { progressiveLoad: false },
  meta: {
    duration: 15,
  },
  convertAudio: {

  },
  audio: {
    fade: true,
    mute: false,
    playerState: 0,
    percentOfPlayer: 0,
  },
  projectName: 'Untitled project',
  isReady: false,
  customLength: null,
};

const reducer = handleActions({
  [types.SET_IMAGE_CROPPER_DATA]: mergeDeep(compose(objOf('imagesLayers'), propOr({}, 'payload'))),
  [types.SET_IMAGE_DATA]: mergeDeep(compose(objOf(IMAGE_REFS), prop('payload'))),
  [types.RESET_IMAGES_DATA]: mergeIn(() => ({ imageRefs: null, prevVersionData: null })),
  [types.SET_IMAGE_ZOOM]: mergeDeep(({ payload: [refId, zoom] }) => ({
    imageRefs: {
      [refId]: {
        options: {
          zoom,
        },
      },
    },
  })),
  [types.SET_BACKGROUND_IMAGE_TO_DEFAULT]: mergeDeep(({ payload: { refId, resetOptions } }) => ({
    imageRefs: {
      [refId]: {
        options: resetOptions,
      },
    },
  })),
  [types.RESET_VERSION_BACKGROUND]: mergeIn(({ payload: { refId, versionBackground } }) => {
    const resetVersionBackground = versionBackground;
    // eslint-disable-next-line guard-for-in
    for (const version in resetVersionBackground) {
      delete resetVersionBackground[version][refId];
    }
    return ({
      versionBackground: resetVersionBackground,
    });
  }),
  [types.SET_VIDEO_ID]: mergeDeep(({ payload: { refId, videoId } }) => ({
    imageRefs: {
      [refId]: {
        videoId,
      },
    },
  })),
  [types.SET_HAS_AUDIO_STATUS]: mergeDeep(({ payload: { refId, hasAudio } }) => ({
    imageRefs: {
      [refId]: {
        hasAudio,
      },
    },
  })),
  [types.SAVE_TRIMMED_VIDEO]: mergeDeep(({ payload: { refId, video } }) => ({
    imageRefs: {
      [refId]: {
        video,
      },
    },
  })),
  [types.SET_IMAGE_TEMP_FILE_ID]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { imageName, tempId } }) => ({
      [imageName]: {
        options: {
          tempId,
        },
      },
    })),
  [types.SET_IMAGE_VIDEO_TEMP_ID]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { refId, tempId } }) => ({
      [refId]: {
        originalVideoTempId: tempId,
      },
    })),
  [types.SET_IMAGE_VIDEO_FILE_ID]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { refId, fileId } }) => ({
      [refId]: {
        originalVideoFileId: fileId,
      },
    })),
  [types.SET_ORIGINAL_IMAGE_ID]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { refId, fileId } }) => ({
      [refId]: {
        originalImageId: fileId,
      },
    })),
  [types.SET_CONVERT_AUDIO]: mergeByProp('convertAudio',
    ({ payload: { isPreparing, data } }) => ({ isPreparing, data })),
  [types.ADAPTATION_IMAGE_TO_DRAFT]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { imageName } }) => ({
      [imageName]: {
        isPreparing: true,
      },
    })),
  [types.SET_PREPARING_TO_GIF_STATUS]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { imageName, status } }) => ({
      [imageName]: {
        isPreparingGif: status,
      },
    })),
  [types.SET_IMAGE_READY]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { imageName, status } }) => ({
      [imageName]: {
        isReady: status,
      },
    })),
  [types.SET_IMAGE_REF_URL]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { imageRef, value } }) => ({
      [imageRef]: {
        tempValue: value,
      },
    })),
  [types.SET_SAVING_DRAFT_FILE_STATUS]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { imageName, status } }) => ({
      [imageName]: {
        isSaving: status,
      },
    })),
  [types.ADAPTATION_IMAGE_TO_DRAFT_SUCCESS]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { imageName } }) => ({
      [imageName]: {
        isPreparing: false,
      },
    })),
  [types.SET_LOOP]: mergeIn(compose(prop('payload'), objOf('loop'))),
  [types.SET_AUTOPLAY]: mergeIn(compose(prop('payload'), objOf('autoplay'))),
  [types.SET_IS_READY]: mergeIn(compose(objOf('isReady'), prop('payload'))),
  [types.SET_PREV_VERSION_DATA]: mergeIn(compose(objOf('prevVersionData'), prop('payload'))),
  [types.SET_RENDERER_SETTINGS]: mergeByProp('rendererSettings', prop('payload')),
  [types.SET_VERSION]: mergeIn(compose(prop('payload'))),
  [types.SET_TEXT_LAYERS]: mergeIn(compose(objOf('textLayers'), prop('payload'))),
  [types.SET_TEXT_GROUPS]: mergeIn(compose(objOf('textGroups'), prop('payload'))),
  [types.SET_PROJECT_NAME]: mergeDeep(compose(objOf('projectName'), prop('payload'))),
  [types.SET_EDITOR_COLORS]: mergeIn(compose(objOf('colors'), prop('payload'))),
  [types.CHANGE_LAST_EDIT_FRAME]: mergeIn(compose(objOf('lastEditFrame'), prop('payload'))),
  [types.SAVE_EDIT_FRAMES]: mergeIn(compose(objOf('editFrames'), prop('payload'))),
  [types.SET_AUDIO_DURATION]: mergeByProp('audio', compose(objOf('duration'), prop('payload'))),
  [types.SET_AUDIO_FADE]: mergeByProp('audio', compose(objOf('fade'), prop('payload'))),
  [types.SET_AUDIO_MUTE]: mergeByProp('audio', compose(objOf('mute'), prop('payload'))),
  [types.SET_PLAYER_STATE]: mergeByProp('audio', compose(objOf('playerState'), prop('payload'))),
  [types.SET_PERCENT_OF_PLAYER]: mergeByProp('audio', compose(objOf('percentOfPlayer'), prop('payload'))),
  [types.SET_AUDIO_FETCH_STATUS]: mergeByProp('audio', compose(objOf('isFetching'), prop('payload'))),
  [types.SET_AUDIO_FILE_INFO]: mergeByProp('audio', compose(objOf('audioFile'), prop('payload'))),
  [types.SAVE_FONTS]: (state, { payload }) => ({
    ...state,
    fonts: [...payload],
  }),
  [types.SET_DESCRIPTION]: mergeIn(compose(objOf('description'), propOr('', 'payload'))),
  [types.SET_TEXT_FIELD_VALUE]: mergeDeep(compose(objOf('textLayers'),
    ({ name, value }) => ({ [name]: { value } }),
    prop('payload'))),
  [types.SET_TEXT_SCALE]: mergeDeep(compose(objOf('textLayers'),
    ({ name, value }) => ({ [name]: { selectedScale: value } }),
    prop('payload'))),
  [types.SET_FONT_FAMILY]: mergeDeep(compose(objOf('textLayers'),
    ({ name, value }) => ({ [name]: { selectedFont: value } }),
    prop('payload'))),
  [types.SET_IMAGE_CONTROL]: mergeDeep(compose(objOf('imagesControls'),
    ({
      name, value, layerName, templateLayerIndex,
    }) => ({
      [layerName]: { [name]: { selectedValue: value, templateLayerIndex, switcherLayerIndex: name } },
    }),
    prop('payload'))),
  [types.SET_INITIAL_SWITCHER_STATE]: mergeDeep(compose(objOf('imagesControls'),
    ({ initialSwitchers, layerName }) => ({
      [layerName]: initialSwitchers,
    }),
    prop('payload'))),
  [types.SET_LAYER_TYPE]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { refId, layerType } }) => ({
      [refId]: {
        layerType,
      },
    })),
  [types.SET_IMAGE_FILE_TYPE]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { refId, fileType, isPreparingGif = false } }) => ({
      [refId]: {
        fileType,
        isPreparingGif,
      },
    })),
  [types.SET_VERSION_BACKGROUND]: mergeDeepByProp('versionBackground',
    ({ payload: { prevVersion, bcgrImages } }) => ({
      [prevVersion]: bcgrImages,
    })),
  [types.SET_PREV_VERSION]: mergeIn(compose(objOf('prevVersion'), prop('payload'))),
  [types.SET_TEXT_VARIABLE]: mergeDeep(compose(objOf('textLayers'),
    ({ name, value }) => ({ [name]: { selectedVariable: value } }),
    prop('payload'))),
  [types.SET_TEXT_STATUS]: mergeDeep(compose(objOf('textLayers'),
    ({ name, value }) => ({ [name]: { isDisabled: value } }),
    prop('payload'))),
  [types.SET_TEXT_COLOR]: mergeDeep(compose(objOf('textLayers'),
    ({ name, value: { index, isGlobalColor } }) => ({
      [name]: {
        selectedColor: index,
        isGlobalColor,
      },
    }),
    prop('payload'))),
  [types.SET_TEXT_GROUP_VARIABLE]: mergeDeep(({ payload: { name, value, index } },
    { textGroups }) => ({
    textGroups: [...assocPath([Number(name), 'switchersNormalized', index, 'value'], value, textGroups)],
  })),
  [animationTypes.SET_LENGTH]: mergeByProp('meta', ({ payload: duration }) => ({ duration })),
  [types.EDITOR_HARD_RESET]: ({
    version, meta, imageEditFrames, projectName,
  }, { payload = {} }) => ({
    ...initialState, imageEditFrames, version, meta, projectName, ...payload,
  }),
  [types.SET_CUSTOM_LENGTH]: (state, { payload }) => ({
    ...state,
    customLength: Math.round(payload * 100) / 100,
  }),
  [types.SET_AUDIO_POINTS]: (state, { payload }) => ({
    ...state,
    audio: {
      ...state.audio,
      startPoint: payload.startPoint,
      endPoint: payload.endPoint,
    },
  }),
  [types.SET_CONTROLS]: (state, { payload }) => ({
    ...state,
    controls: payload,
  }),
  [types.SET_VIDEO_TYPE_SYNCHRONIZATION]: mergeDeepByProp(IMAGE_REFS,
    ({ payload: { refId, syncToSlide } }) => ({
      [refId]: {
        options: {
          syncToSlide,
        },
      },
    })),
  [types.SAVE_IMAGE_EDIT_FRAMES]: (state, { payload }) => ({
    ...state,
    imageEditFrames: {
      ...payload,
    },
  }),
  [types.SET_VISIBLE_TEXT_GROUPS]: (state, { payload }) => ({
    ...state,
    visibleTextGroups: [...payload],
  }),
}, initialState);

export default reducer;
