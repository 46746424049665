import {
  fork, put, take,
} from 'redux-saga/effects';

import sagasManager from 'utils/sagasManager';
import { draftsFilesStorage } from 'utils/helpers/filesHelper';
import { identifyMixPanelUser } from 'utils/mixpanel';
import * as authActions from './actions';
import authTypes from './types';

function* logoutFork() {
  while (true) {
    yield take([authTypes.USER_LOGOUT, authTypes.GET_CURRENT_USER_ERROR]);
    yield put(authActions.setAuthenticated(false));
    localStorage.clear();
    draftsFilesStorage.resetAll();
  }
}

function* currentUserFork() {
  while (true) {
    const { payload: { data } } = yield take(authTypes.GET_CURRENT_USER_SUCCESS);
    localStorage.setItem('salt', data.salt);
    yield put(authActions.setUser(data));
    identifyMixPanelUser(data.user.id);
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(logoutFork);
  yield fork(currentUserFork);
});
