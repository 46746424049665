import React from 'react';
import { PageError } from 'pages';
import ERRORS, { ERROR_CODES } from 'constants/errors';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { Preloader } from '../../ui-kit/Preloader';

/**
 *
 * @param isError {boolean}
 * @param errorCode {number}
 * @param isLoading {boolean}
 * @param children {React.ReactNode}
 * @returns {JSX.Element}
 * @constructor
 */

export const StateWrapper = ({
  isError = false, errorCode = ERROR_CODES.NOT_FOUND, isLoading = false, children,
}) => {
  if (isError) return <PageError pageError={ERRORS[errorCode]} />;
  if (isLoading) {
    return (
      <Preloader
        type={PRELOADER_TYPES.OVERLAY}
        size={PRELOADER_SIZES.LARGE}
        isLoading={isLoading}
        minDuration={800}
        isDestroyChildren={false}
      />
    );
  }
  return <>{children}</>;
};
