import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useDispatch, useSelector } from 'react-redux';
import { equals, last, pathOr } from 'ramda';
import { Icon, Radio } from 'ui-kit';
import {
  getEditFramesList, selectTextGroups, getTextLayers, getLastEditFrame, selectVisibleTextGroups,
} from 'state/editor/selectors';
import { goToEditFrameByIndex } from 'state/editor/actions';
import { useEditor, usePrevValue } from 'hooks';
import { DEFAULT_INDEX } from 'constants/common';
import { getSelectedDraft } from 'state/drafts/selectors';
import { getSelectAnimationName } from 'state/animations/selectors';
import { MainControls, LabelWrapper, Row } from './TextTab.styled';
import { TextSwitchers } from './TextSwitchers';
import { MobileTextSwitchers } from './MobileTextSwitchers';
import { TextField } from '../TextField';
import { COLORS } from '../../../../constants/styles';

const MOBILE_BREAKPOINT = 922;
const NONEXISTENT_FRAME = '0';
const MAX_LABELS_LENGTH = 7;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const TextTab = () => {
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const textGroups = useSelector(selectTextGroups);
  const visibleTextGroups = useSelector(selectVisibleTextGroups);
  const textLayers = useSelector(getTextLayers);
  const editFramesList = useSelector(getEditFramesList);
  const lastEditFrame = useSelector(getLastEditFrame);

  const selectedDraft = useSelector(getSelectedDraft);
  const selectedAnimation = useSelector(getSelectAnimationName);

  const lastTextEditFrame = useRef(null);
  const isChangingByClick = useRef(null);

  const prevVisibleTextGroups = usePrevValue(visibleTextGroups);

  const { isPaused } = useEditor();

  const isMobile = windowWidth < MOBILE_BREAKPOINT;

  const [activeTab, setActiveTab] = useState(0);
  const [activeMobileTabId, setActiveMobileTabId] = useState(0);

  const showField = (tabId) => !isMobile || (activeMobileTabId === tabId);

  const handleChangeMobileTab = (tabId) => () => setActiveMobileTabId(tabId);

  const layersListFromGroups = useMemo(() => {
    return pathOr([], [activeTab, 'textLayersList'], textGroups);
  }, [textGroups, activeTab]);

  const layersListFromGroupsByIndex = useCallback((index) => {
    return pathOr([], [index, 'textLayersList'], textGroups);
  }, [textGroups, activeTab]);

  const getEditFrame = useCallback((groupIndex) => {
    // get text layer index from groups
    const textLayerIndex = layersListFromGroupsByIndex(groupIndex)[0];
    // get edit frame from text layers
    const textEditFrame = textLayers[textLayerIndex]?.editFrame;
    return editFramesList[textEditFrame];
  }, [editFramesList, textLayers]);

  const handleGoToGroupEditFrame = useCallback(({ group }) => () => {
    const layersList = layersListFromGroupsByIndex(group); // get layers
    const textEditFrame = last(layersList); // get text edit frame from layers list

    // check existing frame
    const isExistedNewFrame = getEditFrame(group) !== NONEXISTENT_FRAME
        && textEditFrame !== lastTextEditFrame.current;

    if (isExistedNewFrame || !isPaused) {
      // get index of edit frame (index in the frame list)
      const indexEditFrame = textLayers[textEditFrame]?.editFrame;
      // dispatch action for changing frame
      dispatch(goToEditFrameByIndex(indexEditFrame));
      setActiveTab(parseInt(group, 10));
      setActiveMobileTabId(0); // set default mobile tab
      lastTextEditFrame.current = textEditFrame; // set last layer for prevent useless actions
      isChangingByClick.current = true;
    }
  }, [layersListFromGroupsByIndex, isPaused, textLayers]);

  useEffect(() => {
    const indexEditFrame = editFramesList.findIndex((frame) => Number(frame) === lastEditFrame);
    if (indexEditFrame !== DEFAULT_INDEX && !isChangingByClick.current) {
      const textLayerIndex = Object
        .keys(textLayers)
        .find((key) => textLayers[key].editFrame === indexEditFrame);
      const groupIndex = textGroups
        .findIndex((group) => group.textLayersList.includes(Number(textLayerIndex)));
      if (groupIndex !== DEFAULT_INDEX) {
        setActiveTab(groupIndex);
        lastTextEditFrame.current = textLayerIndex;
      }
    }
    isChangingByClick.current = false;
  }, [lastEditFrame]);

  useEffect(() => {
    if (!equals(prevVisibleTextGroups, visibleTextGroups)) {
      setActiveTab(visibleTextGroups[0]?.idx);
    }
  }, [selectedDraft, selectedAnimation, visibleTextGroups]);

  const generateLabel = useCallback((arePresets, isCTA, label) => {
    if (arePresets) {
      return <Icon name="gear" color={COLORS.WHITE} fill={COLORS.WHITE} />;
    }
    if (isCTA) {
      return 'CTA';
    }
    return label;
  }, []);

  return visibleTextGroups.length > 0 ? (
    <>
      <MainControls>
        <Row>
          {visibleTextGroups.map(({
            label, idx, name, arePresets, isCTA,
          }) => (
            <LabelWrapper
              key={name}
              hasManyLabels={visibleTextGroups.length > MAX_LABELS_LENGTH}
            >
              <Radio
                name="text_groups"
                value={idx}
                id={name}
                isDisabled={getEditFrame(idx) === NONEXISTENT_FRAME}
                isChecked={activeTab === idx}
                label={generateLabel(arePresets, isCTA, label)}
                onChange={handleGoToGroupEditFrame({ group: idx })}
                {...(arePresets ? { backgroundColor: COLORS.BLACK } : {})}
              />
            </LabelWrapper>
          ))}
        </Row>
      </MainControls>
      <TextSwitchers activeGroup={activeTab} />
      {isMobile && (
      <MobileTextSwitchers
        list={layersListFromGroups}
        activeTab={activeMobileTabId}
        onChangeTab={handleChangeMobileTab}
      />
      )}
      <div onClick={handleGoToGroupEditFrame({ group: activeTab })} role="presentation">
        {layersListFromGroups.map((id, index) => showField(index) && (
          <TextField
            id={id}
            key={id}
          />
        ))}
      </div>
    </>
  ) : (<></>);
};
