export const ACCOUNT = '/account';
export const AUTH = '/auth';

export const ROUTES = {
  ROOT: '/',
  SHARED_LINKS: '/shared-links',
  MY_FILES: '/my-files',
  DISCUS_VIDEOS: '/discus-videos',
  FULL_VIEW: '/full-view',
  ACCOUNT: {
    ROOT: ACCOUNT,
    OVERVIEW: `${ACCOUNT}/overview`,
    DESIGN_PRESETS: `${ACCOUNT}/design-presets`,
    CHANGELOG: `${ACCOUNT}/changelog`,
    BILLING_INFORMATION: `${ACCOUNT}/billing-information`,
    INTEGRATIONS: `${ACCOUNT}/integrations`,
  },
  AUTH: {
    LOGIN: `${AUTH}/login`,
    SIGN_UP: `${AUTH}/sign-up`,
    SIGN_UP_SUCCESS: `${AUTH}/sign-up-success`,
    SIGN_UP_CONFIRM: `${AUTH}/confirm-register`,
    SIGN_UP_CONFIRM_SUCCESS: `${AUTH}/confirm-success`,
    FORGOT_PASSWORD: `${AUTH}/forgot-password`,
    FORGOT_PASSWORD_SUCCESS: `${AUTH}/forgot-password-success`,
    RESET_PASSWORD: `${AUTH}/reset-password`,
    RESET_PASSWORD_SUCCESS: `${AUTH}/reset-password-success`,
  },
};
