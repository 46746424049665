import { getLayerIndexByName } from './core';

const TUMPLATE_PRESETS = 'Tumplate presets';
const PRESETS_DATA_PATH = ['ef', 0, 'nm'];

const reg = new RegExp(/^Aspect \d/);
const checkRegTitle = new RegExp('aspectName =(.*);');

/**
 *
 * @param item {object}
 * @returns {string|*}
 */
const makeTitle = (item) => {
  const data = item.v.k;
  const str = item.v?.x;
  if (str) {
    const matchedString = str.match(checkRegTitle);
    return matchedString[1].trim().replace(/'/g, '');
  }
  return `${data[0]}:${data[1]}`;
};

/**
 *
 * @param layer {object}
 * @returns {*[]}
 */

const getRatiosList = (layer) => {
  // we always have this keys
  const dataList = layer.ef[0].ef;
  let listOfRatios = [];
  for (const item of dataList) {
    const isWorkingRatio = reg.test(item.nm) && item.v.k[0] && item.v.k[1] && item.v.k[2];
    if (isWorkingRatio) {
      const [width, height] = item.v.k;
      const ratio = {
        width,
        height,
        title: makeTitle(item),
      };
      listOfRatios = [...listOfRatios, ratio];
    }
  }
  return listOfRatios;
};

/**
 *
 * @param anim {object}
 * @returns {null|*[]}
 */

export const getRatios = (anim) => {
  if (anim) {
    const indexLayer = getLayerIndexByName({
      animationLayers: anim.layers,
      path: PRESETS_DATA_PATH,
      name: TUMPLATE_PRESETS,
    });
    if (indexLayer !== -1) {
      return getRatiosList(anim.layers[indexLayer]);
    }
    return [];
  }
  return [];
};

/**
 *
 * @param anim {object}
 * @returns {null|*}
 */

export const getCurrentRatioTitleByDimensions = ({ anim }) => {
  const list = getRatios(anim);
  const neededRatio = list.find((item) => item.width === anim.w && item.height === anim.h);
  if (neededRatio) return neededRatio.title;
  return null;
};

/**
 *
 * @param anim {object}
 * @param ratio {string}
 * @returns {null|*}
 */

export const getDimensionsBuCurrentRatio = ({ anim, ratio }) => {
  const list = getRatios(anim);
  const neededRatio = list.find((item) => item.title.toLowerCase() === ratio.toLowerCase());
  if (neededRatio) return neededRatio;
  return null;
};
