/**
 *
 * @param username {string | undefined}
 * @param firstName {string | undefined}
 * @param lastName {string | undefined}
 * @returns {string|*}
 */
export const getUserName = ({ username, firstName, lastName }) => {
  if (username) return username;
  return `${firstName ?? ''} ${lastName ?? ''}`;
};
