import {
  compose, view, filter, map, defaultTo, propEq, find, has, pathOr, lensProp,
} from 'ramda';

export const assets = lensProp('assets');

export const assetsWithRefs = compose(
  (ats) => {
    const assetsLayers = filter(has('layers'), ats);
    return map((l) => ({
      ...l,
      ref: find(propEq('id', pathOr(null, ['layers', 0, 'refId'], l)), ats),
    }))(assetsLayers);
  },
  defaultTo([]),
  view(assets),
);
