import { mbToBytes } from 'utils/helpers/filesHelper';

export const IMAGE_MAX_SIZE = 1000;
export const IMAGE_MAX_SIZE_BYTES = mbToBytes(IMAGE_MAX_SIZE);

export const LOGO_MAX_SIZE = 5;
export const LOGO_MAX_SIZE_BYTES = mbToBytes(LOGO_MAX_SIZE);

export const AUDIO_MAX_SIZE = 30;
export const AUDIO_MAX_SIZE_BYTES = mbToBytes(AUDIO_MAX_SIZE);

export const IMAGES_MAX_DIMENSION = {
  WIDTH: 1920,
  HEIGHT: 1920,
};
