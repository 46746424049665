import React from 'react';
import { useTranslation } from 'react-i18next';
import { transparentLogo } from 'assets/images';
import {
  Header, RootStyle, StyledLink, Title, Container,
} from './AuthLayout.styled';

/**
 *
 * @param title {string | React.ReactNode}
 * @param containerWidth {number | string}
 * @param children {React.ReactNode}
 * @returns {JSX.Element}
 * @constructor
 */

export const AuthLayout = ({ title, containerWidth = 435, children }) => {
  const { t } = useTranslation(['account', 'common', 'forms']);
  return (
    <RootStyle>
      <Container width={containerWidth}>
        <Header>
          <StyledLink to="/" title={t('Go to Home page')}>
            <img src={transparentLogo} alt="Tumplate" />
          </StyledLink>
          <Title>
            {title}
          </Title>
        </Header>
        {children}
      </Container>
    </RootStyle>
  );
};
