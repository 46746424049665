import { handleActions } from 'redux-actions';
import {
  compose, objOf, path, concat, reject, propEq, uniq,
} from 'ramda';
import { mergeDeep, mergeDeepByProp, mergeIn } from 'utils/helpers/ramdaStateHelpers';
import { NONE_DRAFT } from 'constants/drafts';
import types from './types';

const reducer = handleActions({
  [types.SET_VIDEO_DRAFTS]: mergeIn(({ payload: { data: { data } } }) => ({
    data,
  })),
  [types.UPDATE_VIDEO_DRAFTS]: mergeIn((_, { data = [], topDrafts: { data: topDrafts } }) => ({
    data: uniq(concat([NONE_DRAFT, ...topDrafts], data)),
  })),
  [types.SET_SELECTED_DRAFT]: mergeIn(compose(objOf('selectedDraft'), path(['payload']))),
  [types.DELETE_VIDEO_DRAFT]: mergeIn(({ payload: id }, { data }) => ({
    data: reject(propEq('id', id), data),
  })),
  [types.SET_VIDEO_DRAFT]: mergeIn(({ payload: { data: { model: draft } } }, { data }) => ({
    currentDraft: { ...draft },
    data: data.find((item) => item.id === draft.id) ? uniq(data.map((item) => (item.id === draft?.id ? draft : item))) : [...data, draft],
  })),
  [types.FETCH_DRAFT_FILE]: mergeDeepByProp('fetching', ({ payload: { fileName } }) => ({
    [fileName]: true,
  })),
  [types.FETCH_DRAFT_FILE_SUCCESS]: mergeDeepByProp('fetching', ({ payload: { fileName } }) => ({
    [fileName]: false,
  })),
  [types.SET_STATUS_DRAFT]: mergeDeep(compose(objOf('status'), path(['payload']))),
  [types.SET_SAVING_DRAFT_ID]: mergeDeep(compose(objOf('savingDraftId'), path(['payload']))),
  [types.SET_DRAFT_FILES]: mergeDeep(compose(objOf('files'), path(['payload', 'data', 'data']))),
  [types.SET_DRAFT_OPTIONS]: mergeIn(compose(objOf('options'), path(['payload']))),
  [types.UPDATE_LIST_OF_TOP_DRAFTS]: mergeIn(compose(objOf('topDrafts'), path(['payload']))),
  [types.TOGGLE_ORIGINAL_ANIMATION_STATUS]: (state, { payload }) => ({
    ...state,
    isOriginalAnimation: payload,
  }),
}, {
  data: [],
  topDrafts: {
    data: [],
    count: 0,
  },
  currentDraft: {},
});

export default reducer;
