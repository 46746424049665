import { createSelector } from 'reselect';
import {
  pathOr, identity,
} from 'ramda';

export const getLinksEntities = createSelector(pathOr({}, ['links', 'entities', 'links']), identity);
export const getLinksResult = createSelector(pathOr([], ['links', 'result']), identity);
export const getLinksCount = createSelector(pathOr(-1, ['links', 'count']), identity);

export const selectLinkView = createSelector(identity, pathOr({}, ['links', 'linkView']));
export const selectLinkViewLoading = createSelector(pathOr(false, ['links', 'linkView', 'isLoading']), identity);

export const selectFilesOfLink = createSelector(pathOr([], ['links', 'filesOfLink', 'data']), identity);
export const selectFilesOfLinkCount = createSelector(pathOr(-1, ['links', 'filesOfLink', 'count']), identity);
export const getFilesOfLinkHasMore = createSelector(pathOr(true, ['links', 'filesOfLink', 'hasMore']), identity);
export const selectFilesOfLinkById = createSelector(
  selectFilesOfLink,
  (_, id) => id,
  (list, id) => list.find((item) => item.id === id),
);
