import sagasManager from 'utils/sagasManager';
import {
  fork, select, take, put, all, call, delay,
} from 'redux-saga/effects';

import { editorActions, editorSelectors } from 'state/editor';
import * as animationsSelectors from 'state/animations/selectors';
import {
  prop, keys, map, propEq, find, propOr, last,
  split, isEmpty, filter, values, pathOr, addIndex, assocPath,
} from 'ramda';
import { createFileByUrl, draftsFilesStorage, getFileTypeByUrl } from 'utils/helpers/filesHelper';
import {
  changeDraftTemplate,
  createVideoDraftRequest,
  sendAudioFileDraftRequest,
  sendDataFileDraftRequest,
  setStatusDraft,
} from 'state/drafts/actions';
import selectDraftFlow from 'state/drafts/selectDraftFlow';
import { notify } from 'utils/helpers/notificationHelpers';
import { getFileUrl } from 'utils/helpers/requestHelpers';
import { DRAFTS_ERRORS, DRAFTS_STATUS, NONE_DRAFT } from 'constants/drafts';
import { normalizeDraftsFileParamsToApi } from 'utils/helpers/renderHelpers';
import { normalizeDraftTitleByNumber } from 'utils/helpers';
import * as animationActions from '../animation/actions';
import * as animationSelectors from '../animation/selectors';
import * as draftsActions from './actions';
import * as draftsSelectors from './selectors';
import draftsTypes from './types';
import { getActiveAnimations } from '../animations/selectors';
import { getLastParams, selectVideoIdsForDelete } from '../videos/selectors';
import { changeVideosIdsForDelete, deleteVideosRequest, getVideosRequest } from '../videos/actions';
import { AVAILABLE_FORMATS } from '../../constants/rendering';

function* preparingImages(meta) {
  yield put(setStatusDraft(DRAFTS_STATUS.PREPARING_IMAGES));
  const listOfImagesData = yield select(editorSelectors.getImageRefs);
  const { createDraftEndCb } = meta;
  if (!isEmpty(listOfImagesData)) {
    yield map((imageName) => sagasManager.addSagaToRoot(function* watcher() {
      const dataOfImage = assocPath(['options', 'tempId'], null, listOfImagesData[imageName]);
      yield put(
        editorActions.adaptationImageToDraft({
          imageName,
          meta,
          dataOfImage,
        }),
      );
    }), keys(listOfImagesData));
  } else {
    // eslint-disable-next-line no-use-before-define
    yield whenImagesIsSaved(createDraftEndCb);
  }
}

function* senDataFileSuccessFlow() {
  while (true) {
    const {
      payload: {
        data: { id },
        meta: {
          title, audioFileId, createDraftEndCb,
          version, length, outputCommand, outputUrl,
        },
      },
    } = yield take(draftsTypes.SEND_DATA_FILE_DRAFT_SUCCESS);

    const {
      audioStart,
      audioFade,
      selectedAnimation,
      drafts,
      description,
      customLength,
      draft,
    } = yield select((store) => ({
      audioStart: editorSelectors.getAudioStartPoint(store),
      audioFade: editorSelectors.getAudioFade(store),
      selectedAnimation: animationsSelectors.getSelectedAnimation(store),
      drafts: draftsSelectors.getDrafts(store),
      draft: draftsSelectors.getDraft(store),
      description: editorSelectors.getDescription(store),
      customLength: editorSelectors.getCustomLength(store),
    }));
    const data = yield find(
      propEq('aspectratio', version.toLowerCase()),
      propOr([], 'template', selectedAnimation),
    );

    const normalizedTitle = normalizeDraftTitleByNumber(title, drafts);

    yield put(editorActions.setProjectName(normalizedTitle));
    yield put(createVideoDraftRequest({
      data_file_id: id,
      title: normalizedTitle,
      audio_file_id: audioFileId,
      animation_ratio: prop('aspectratio', data) || version || draft?.animation_ratio,
      animation_name: prop('name', selectedAnimation) || draft?.animation_name,
      original_audio_file_id: audioFileId,
      audio_fade_in: audioFade ? 1 : 0,
      audio_fade: audioFade ? 1 : 0,
      audio_fade_in_time: 2,
      audio_fade_out_time: 2,
      audio_start_at: audioStart,
      description,
      custom_length: length === customLength ? customLength : null,
      control_fields: draft?.control_fields || null,
      output_command: outputCommand,
      output_url: outputUrl,
      custom_name: draft?.custom_name || null,
      custom_description: draft?.custom_description || null,
      custom_length_options: draft?.custom_length_options || null,
      custom_ratio_options: draft?.custom_ratio_options || null,
    }, {
      callbacks: {
        success: ({ data: { model } }) => {
          const { id: draftId } = model;
          sagasManager.addSagaToRoot(function* watcher() {
            yield put(draftsActions.setSavingDraftId(draftId));
            yield put(draftsActions.setVideoDraft({ data: { model } }));
            yield put(draftsActions.setStatusDraft({
              status: DRAFTS_STATUS.READY_DATA,
              meta: { createDraftEndCb: () => createDraftEndCb({ data: { model } }) },
            }));
          });
        },
        error: () => {
          sagasManager.addSagaToRoot(function* watcher() {
            yield put(draftsActions.setStatusDraft(DRAFTS_STATUS.SAVING_DATA_ERROR));
          });
        },
      },
    }));
  }
}
function resetAssetsUrlFromBlobAndBase64(storeData) {
  let accumData = storeData;
  addIndex(map)((item, index) => {
    // clean all base64 and blob from assets, because API should not save it data in database
    const oldAssetUrl = accumData.assets[index].u || '';
    const newAssetUrl = oldAssetUrl.search('data:') > -1
    || oldAssetUrl.search('blob:') > -1
      ? '' : oldAssetUrl;

    accumData = assocPath(['assets', index, 'u'], newAssetUrl, accumData);
  }, pathOr([], ['assets'], accumData));
  return accumData;
}
function* sendDataFileRequestBuilder({
  audioFileId, title, createDraftEndCb, version, length, outputCommand, outputUrl,
}) {
  const data = yield select(animationSelectors.animationDataSelector);
  const newData = resetAssetsUrlFromBlobAndBase64(data);
  const formData = yield new FormData();
  const blob = yield new Blob([JSON.stringify(newData)], { type: 'application/json' });

  yield put(setStatusDraft(DRAFTS_STATUS.SAVING_DATA));
  yield formData.append('file', new File([blob], 'data.json'));
  yield put(sendDataFileDraftRequest(formData, {
    title, audioFileId, createDraftEndCb, version, length, outputCommand, outputUrl,
  }));
}

function* sendAudioFileSuccessFlow() {
  while (true) {
    const {
      payload: {
        data: { id: audioFileId },
        meta: {
          title, type, createDraftEndCb, version, length,
        },
      },
    } = yield take(draftsTypes.SEND_AUDIO_FILE_DRAFT_SUCCESS);

    yield sendDataFileRequestBuilder({
      audioFileId, title, type, createDraftEndCb, version, length,
    });
  }
}

function* createVideoFlow() {
  while (true) {
    const { payload: { title, createDraftEndCb, ...params } } = yield take(draftsTypes.CREATE_VIDEO_DRAFT);
    const { source, info } = yield select(editorSelectors.getAudioFile);
    const formData = yield new FormData();
    if (source) {
      yield put(setStatusDraft(DRAFTS_STATUS.SAVING_AUDIO));
      try {
        const file = yield createFileByUrl(source, info.name, info.type);
        yield formData.append('file', file);
        yield put(sendAudioFileDraftRequest(formData, { title, createDraftEndCb, ...params }));
      } catch (e) {
        createDraftEndCb('Audio file not send');
      }
    } else {
      yield sendDataFileRequestBuilder({
        audioFileId: null, title, createDraftEndCb, ...params,
      });
    }
    const idsForDelete = yield select(selectVideoIdsForDelete);
    const lastVideoRequestParams = yield select(getLastParams);
    if (idsForDelete.length > 0) {
      yield put(deleteVideosRequest(idsForDelete));
      yield put(changeVideosIdsForDelete([]));
      yield put(getVideosRequest(lastVideoRequestParams));
    }
  }
}
function* whenImagesIsSaved(cb) {
  const refs = yield select(editorSelectors.getImageRefs);
  const isPreparedImages = isEmpty(filter(propEq('isSaving', true), values(refs)));
  if (isPreparedImages) {
    yield put(draftsActions.setStatusDraft(false));
    yield cb();
  }
}

function* savingDraftsFiles(savingDraftId, { createDraftEndCb }) {
  const listOfImagesData = yield select(editorSelectors.getImageRefs);
  const listKeysOfImages = yield keys(listOfImagesData);
  yield put(draftsActions.setStatusDraft(DRAFTS_STATUS.SAVING_IMAGES));

  yield all(map((imageName) => {
    const {
      originalVideoTempId, originalVideoFileId, originalImageId, options: { syncToSlide },
    } = listOfImagesData[imageName];
    const meta = {
      callbacks: {
        finally: () => {
          sagasManager.addSagaToRoot(function* watcher() {
            yield put(editorActions.setSavingDraftFileStatus({ imageName, status: false }));
            yield whenImagesIsSaved(createDraftEndCb);
          });
        },
      },
    };

    const createFileId = (fileId) => `file_${fileId}`;

    sagasManager.addSagaToRoot(function* watcher() {
      yield put(editorActions.setImageTempFileId({ imageName, tempId: null }));
      yield put(editorActions.setSavingDraftFileStatus({ imageName, status: true }));
      if (originalVideoTempId || originalVideoFileId) {
        const fileId = originalVideoTempId || createFileId(originalVideoFileId);
        yield put(draftsActions.sendFileDraftRequest({
          id: savingDraftId,
          title: imageName,
          file_id: fileId,
          is_original: true,
          params: typeof syncToSlide !== 'undefined' ? JSON.stringify({ syncToSlide }) : '',
        }, {
          callbacks: {
            success: ({ data }) => {
              if (data.model) {
                const { model } = data;
                sagasManager.addSagaToRoot(function* secondWatcher() {
                  yield put(editorActions.setImageVideoFileId({ refId: model.title, fileId: model.file_id.id }));
                  yield put(editorActions.setImageVideoTempId({ refId: model.title, tempId: null }));
                });
              }
            },
          },
        }));
      }
    });

    return put(draftsActions.sendFileDraftRequest({
      id: savingDraftId,
      title: imageName,
      file_id: listOfImagesData[imageName].options.tempId || createFileId(originalImageId),
      is_original: !originalVideoTempId && !originalVideoFileId,
      params: JSON.stringify(normalizeDraftsFileParamsToApi(listOfImagesData[imageName])),
    }, meta));
  }, listKeysOfImages));
  yield put(draftsActions.setStatusDraft(false));
}

function* draftsStatusFlow() {
  while (true) {
    const { payload } = yield take(draftsTypes.SET_STATUS_DRAFT);
    const currentStatus = propOr(payload, 'status', payload);
    const meta = propOr({}, 'meta', payload);
    const savingDraftId = yield select(draftsSelectors.getSavingDraftId);
    yield delay(200);
    if (currentStatus === DRAFTS_STATUS.IMAGES_READY) {
      yield savingDraftsFiles(savingDraftId, meta);
    } else if (currentStatus === DRAFTS_STATUS.READY_DATA) {
      yield preparingImages(meta);
    }
  }
}

function* changeSelectedDraftFlow() {
  while (true) {
    const { payload: { id: draftId } } = yield take(draftsTypes.SELECT_DRAFT);
    yield put(draftsActions.setSelectedDraft(draftId));
    draftsFilesStorage.resetAll();
    const prevDraftId = yield select(draftsSelectors.getSelectedDraft);
    if (Number(draftId) === NONE_DRAFT.id) {
      yield put(animationActions.updateAnimationData({ withHardReset: false }));
    } else if (Number(prevDraftId) !== Number(draftId)) {
      yield put(editorActions.editorHardReset());
      yield put(animationActions.animationHardReset());
    }
  }
}

function* saveImageFileToFilesStorage(fileName, fileId, params) {
  try {
    const fileType = yield getFileTypeByUrl(getFileUrl({ fileUrl: `files/${fileId}`, isUseSalt: true, quality: 50 }));

    const fileData = yield createFileByUrl(
      getFileUrl({ fileUrl: `files/${fileId}`, isUseSalt: true, quality: 50 }),
      `image.${last(split(/\//, fileType))}`,
      fileType,
    );
    yield draftsFilesStorage.addFile(fileData, fileName, JSON.parse(params), true);
    if (!AVAILABLE_FORMATS.VIDEOS.includes(fileData.type)) {
      yield put(editorActions.setOriginalImageId({ refId: fileName, fileId }));
    }
  } catch (e) {
    yield notify.error(DRAFTS_ERRORS.FAILED_WHEN_CREATE_IMAGE);
  }
}

function* getDraftFilesSuccessFlow() {
  while (true) {
    const { payload } = yield take(draftsTypes.GET_DRAFT_FILES_SUCCESS);
    const { data: { data: filesList } } = payload;
    yield put(draftsActions.setDraftFiles(payload));
    filesList.forEach(({
      title, file_id: fileId, params, is_original: isOriginal,
    }) => {
      if (isOriginal === 0) {
        const originalFile = filesList.find((item) => item.title === title && item.is_original === 1);
        if (originalFile) {
          sagasManager.addSagaToRoot(function* watcher() {
            yield put(editorActions.setImageVideoFileId({
              refId: originalFile.title,
              fileId: originalFile.file_id,
            }));
          });
        }
      }
      sagasManager.addSagaToRoot(function* watcher() {
        yield call(saveImageFileToFilesStorage, title, fileId, params);
      });
    });
  }
}

function* getSelectedDraftFlow() {
  while (true) {
    const { payload: { meta: { draftId } } } = yield take(draftsTypes.GET_SELECTED_DRAFT_DATA_SUCCESS);
    yield put(draftsActions.getDraftFilesRequest({ id: draftId }));
    yield put(draftsActions.setStatusDraft(null));
  }
}

function* deleteVideoDraftSuccessFlow() {
  while (true) {
    const { payload: id } = yield take(draftsTypes.DELETE_VIDEO_DRAFT_SUCCESS);
    const selectedDraft = yield select(draftsSelectors.getSelectedDraft);

    if (selectedDraft && Number(selectedDraft) === id) {
      yield put(draftsActions.changeDraftTemplate({
        target: { value: NONE_DRAFT.id },
        status: DRAFTS_STATUS.DELETING_DRAFT,
      }));
    }
    yield put(draftsActions.deleteVideoDraft(id));
  }
}

function* setVideoFlow() {
  while (true) {
    const { payload: { data: { model: { id } } } } = yield take(draftsTypes.SET_VIDEO_DRAFT);
    yield put(draftsActions.setSelectedDraft(id));
  }
}

function* updateListOfDrafts() {
  while (true) {
    yield take([draftsTypes.UPDATE_LIST_OF_TOP_DRAFTS, draftsTypes.SET_VIDEO_DRAFTS]);
    yield put(draftsActions.updateVideoDrafts());
  }
}

function* getTopDraftsFlow() {
  while (true) {
    const { payload } = yield take(draftsTypes.UPDATE_LIST_OF_TOP_DRAFTS);
    const animations = yield select(getActiveAnimations);
    if (animations.length === 0 && payload.data.length > 0) {
      yield put(changeDraftTemplate({ target: { value: payload.data[0].id } }));
    }
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(draftsStatusFlow);
  yield fork(createVideoFlow);
  yield fork(senDataFileSuccessFlow);
  yield fork(sendAudioFileSuccessFlow);
  yield fork(selectDraftFlow);
  yield fork(changeSelectedDraftFlow);
  yield fork(getDraftFilesSuccessFlow);
  yield fork(getSelectedDraftFlow);
  yield fork(deleteVideoDraftSuccessFlow);
  yield fork(setVideoFlow);
  yield fork(updateListOfDrafts);
  yield fork(getTopDraftsFlow);
});
