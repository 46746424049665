import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

// todo: refactor, make local storage helpers
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
});

/**
 *
 * @param subUrl {string}
 * @param method {"get" | "post" | "put" | "delete"}
 * @param data {object} data of the request
 * @param config {object} params
 * @returns {(function(): Promise<AxiosResponse<any>>)|(function(): Promise<AxiosResponse<any>>)|(
 * function(): Promise<AxiosResponse<any>>)|(function(): Promise<AxiosResponse<any>>)|void}
 */
export const apiService = ({
  subUrl, method, data, config,
}) => {
  // eslint-disable-next-line no-console,max-len
  return axiosInstance[method](subUrl, data || config, config) || console.warn('Unknown api method');
};
