import {
  compose, curry, head, match as RMatch, omit, prop, apply, join, map, concat, replace,
  find, propEq, findIndex, values,
} from 'ramda';

/**
 *
 * @param num {number | string}
 * @returns {*}
 */
export const inc = (num) => parseInt(num, 10) + 1;

export const callOnlyLatest = (() => {
  // todo: this function probably has issue with context
  const stackList = [];
  const caller = (key) => {
    const index = findIndex(propEq('key', key), stackList);
    const { func, timerFn: timer, time } = stackList[index];

    if (timer) {
      clearTimeout(timer);
      delete stackList[index].timerFn;
    }
    const timerFn = setTimeout(() => {
      func();
      clearTimeout(timerFn);
    }, time);
    stackList[index] = { ...stackList[index], timerFn };
  };
  return (func, time, key) => {
    if (!find(propEq('key', key), stackList)) {
      stackList.push({
        key,
        time,
        func,
      });
    }
    caller(key);
  };
})();

export const debounceFunc = (func, time, n, immediately) => {
  const name = n || func.name;
  const waiterFunc = () => {
    window.debounceState[name] = setTimeout(() => {
      func();
      window.debounceState = omit([name], window.debounceState);
    }, time);
  };
  window.debounceState = window.debounceState || {};
  if (immediately && window.debounceState[name] === undefined) {
    func();
  }
  if (window.debounceState[name]) {
    clearTimeout(window.debounceState[name]);
    waiterFunc();
  }
  if (!window.debounceState[name]) waiterFunc();
};

// todo: debounce func does not work properly
export const debounce = curry((ms, fn) => {
  let timeout;

  return (...args) => {
    const later = () => {
      timeout = null;
      apply(fn, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, ms);

    return timeout;
  };
});

export const propStrInclude = curry((propName, substr, obj) => {
  return prop(propName, obj).search(substr) > -1;
});

/**
 *
 * @param propName {string}
 * @param obj {object}
 * @param substr {string}
 * @returns {boolean}
 */

export const isSubstrInProp = ({ propName, obj, substr }) => {
  return obj[propName].search(substr) > -1;
};

export const convertToNumber = compose(Number, head, RMatch(/\d*/g));

export const getTimeStringBySeconds = (value) => {
  const minutes = Math.floor(value / 60);
  const seconds = Math.floor(value - minutes * 60);
  const twoChar = (val) => (val < 10 ? `0${val}` : val);
  return `${twoChar(minutes)}:${twoChar(seconds)}`;
};

export const getSecondsByTimeString = (value) => {
  const [minutes, seconds] = value.split(':');

  return Number(minutes) * 60 + Number(seconds) || value;
};

export const generateAcceptStingByArray = (type, extension) => compose(
  join(','),
  map(
    concat(`${type}/`),
  ),
)(extension);

export const cleanLatestWordOfString = replace(/(\s|_)(.)*$/g, '');

export const getOptionsList = (value) => values(value).map(({ id, label }) => ({ value: id, label }));

export const normalizeDraftTitleByNumber = (originalTitle, drafts) => {
  const existedDraft = drafts.find(({ title }) => title === originalTitle);
  if (existedDraft) {
    return `${existedDraft.title}_1`;
  }
  return originalTitle;
};

/**
 * @param item {string | number}
 * @param list {array}
 * @returns {*|*[]}
 */

export const filterOrSet = (item, list) => (list.includes(item)
  ? list.filter((elem) => elem !== item)
  : [...list, item]);

/**
 *
 * @param arr {array}
 * @param key {string}
 * @returns {unknown[]}
 */

export const getUniqueListByProp = (arr, key) => {
  return arr
    .filter((value, index, self) => self
      .map((x) => x[key])
      .indexOf(value[key]) === index);
};

/**
 *
 * @param options {array}
 * @param value {string | number}
 * @returns {*}
 */

export const findLabelByValue = (options, value) => {
  const neededOption = options.find((option) => option.value === value);
  return neededOption?.label;
};

/**
 *
 * @param str {string | object}
 * @returns {any}
 */

export const parseJsonString = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};
