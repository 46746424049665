import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getOptions } from 'state/animations/selectors';
import { setAnimationDimensions } from 'state/animation/actions';
import { getEditorVersion } from 'state/editor/selectors';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import { PickerSelect } from '../../PickerSelect';
import { useTrackEvent } from '../../../../../hooks';

export const VersionTool = () => {
  const form = useFormContext();
  const dispatch = useDispatch();
  const { handleTrack } = useTrackEvent();

  const animationVersions = useSelector(getOptions);
  const selectedVersion = useSelector(getEditorVersion);

  const options = useMemo(() => animationVersions.map(({ title }) => ({
    label: title,
    value: title,
  })), [animationVersions]);

  const handleChange = useCallback((value) => {
    const { width, height } = animationVersions.find((version) => version.title === value);
    form.setValue('version', value);
    dispatch(setAnimationDimensions({ width, height }));
    handleTrack(MIXPANEL_EVENTS.CHANGE_ASPECT);
  }, [animationVersions]);

  return (
    <PickerSelect
      id="select-version"
      name="version"
      onChange={handleChange}
      defaultValue={selectedVersion}
      options={options}
    />
  );
};
