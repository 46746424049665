import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'react-toastify/dist/ReactToastify.min.css';
import './utils/styles/style.sass';
import { App } from './app';

if (/Android/.test(navigator.appVersion)) {
  window.addEventListener('resize', () => {
    if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
      document.activeElement.scrollIntoView();
    }
  });
}

Sentry.init({
  dsn: 'https://0cb91787e0bc441a9134581f9ce386e8@o1310048.ingest.sentry.io/6557036',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
