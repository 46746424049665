import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Preloader, Checkbox } from 'ui-kit';
import { themePrev } from 'assets/images';
import { getAnimations } from 'state/animations/selectors';
import { getUpdateAnimationPending } from 'state/ui/selectors';
import { updateAnimation, getAnimationsRequest } from 'state/animations/actions';
import { PRELOADER_TYPES } from 'constants/ui';
import './style.sass';

const SelectThemesList = ({ initial }) => {
  const dispatch = useDispatch();
  const onStatusChange = useCallback(({ target: { name, checked } }) => dispatch(updateAnimation({
    name,
    status: checked ? 1 : 0,
    // eslint-disable-next-line
  })), []);
  const animationsList = useSelector(getAnimations);
  const isPending = useSelector(getUpdateAnimationPending);

  useEffect(() => {
    if (initial) dispatch(getAnimationsRequest());
  }, []);

  return (
    <Preloader
      isLoading={isPending}
      type={PRELOADER_TYPES.CONTAINER}
    >
      {
          animationsList.map(({ name, status }) => (
            <div key={name} className="select-themes-list__theme-card theme-card">
              <div className="theme-card__info">
                <img src={themePrev} alt="Some theme icon" className="theme-card__image" />
                <h3 className="theme__name">{name}</h3>
              </div>
              <div>
                <Checkbox
                  checked={status}
                  id={`checkbox-${name}`}
                  className="checked-icon"
                  name={name}
                  onChange={onStatusChange}
                />
              </div>
            </div>
          ))
        }
    </Preloader>
  );
};

SelectThemesList.propTypes = {
  initial: PropTypes.bool,
};

SelectThemesList.defaultProps = {
  initial: false,
};

export default SelectThemesList;
