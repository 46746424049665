import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  equals,
  last,
  path,
} from 'ramda';

import { animationSelectors } from 'state/animation';
import { Radio } from 'ui-kit/Radio';
import { editorActions, editorSelectors } from 'state/editor';
import { useEditor, usePrevValue } from 'hooks';
import { getLastEditFrame } from 'state/editor/selectors';
import { DEFAULT_INDEX } from 'constants/common';
import { getRefId } from 'utils/animationHelpers';
import { getSelectedDraft } from 'state/drafts/selectors';
import { getSelectAnimationName } from 'state/animations/selectors';
import {
  Tab,
  Row,
  Controls,
  LabelWrapper,
  GroupListItem,
} from './ImageTab.styled';
import { ImageEditor } from '../ImageEditor';

const MAX_LABELS_LENGTH = 7;
const NONEXISTENT_FRAME = '0';
const GROUP_LIST_PATH = ['layers', 0, 'refId'];

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const ImageTab = () => {
  const dispatch = useDispatch();
  const imageRefs = useSelector(editorSelectors.getImageRefs);
  const editFramesList = useSelector(editorSelectors.getEditFramesList);
  const imagesEditFrames = useSelector(editorSelectors.getImagesEditFrames);
  const getImagesInGroup = useSelector(animationSelectors.imageLayerByGroupSelector);
  const groupList = useSelector(animationSelectors.imageLayerGroupsSelector);
  const filteredGroupList = useSelector(animationSelectors.filteredImageLayerGroupsSelector);
  const selectedDraft = useSelector(getSelectedDraft);
  const selectedAnimation = useSelector(getSelectAnimationName);
  const lastEditFrame = useSelector(getLastEditFrame);

  const prevFilteredGroupList = usePrevValue(filteredGroupList);
  const isChangingByClick = useRef(null);

  const { isPaused } = useEditor();

  const lastEditedFrame = useRef(null);
  const [currentGroup, setCurrentGroup] = useState(filteredGroupList[0]);

  const isEditFrameExist = useCallback((groupId) => {
    // get images in group list
    const imagesInGroupList = getImagesInGroup(groupId).map(path(GROUP_LIST_PATH));
    // normalize image refs
    const normalizedRefToImage = Object.keys(imageRefs).reduce((accum, key) => {
      // get image ref
      const ref = path(['options', 'refId'], imageRefs[key]);
      if (ref === imagesInGroupList[0]) {
        return key;
      }
      return accum;
    }, 0);

    const firstImageEditFrameIndex = imagesEditFrames[normalizedRefToImage];
    return editFramesList[firstImageEditFrameIndex] !== NONEXISTENT_FRAME;
  }, [getImagesInGroup, imagesEditFrames, editFramesList, imageRefs]);

  const handleClickOnGroup = useCallback(({ group }) => {
    // get layers
    const imageLayerNamesInGroup = getImagesInGroup(group);
    // get image edit frame from layers list
    const lastImageLayerNameInGroup = last(imageLayerNamesInGroup);
    const refId = getRefId(lastImageLayerNameInGroup.nm);
    const imageEditFrame = imagesEditFrames[refId];
    // check existing frame
    const isExistedNewFrame = isEditFrameExist(group) && imageEditFrame !== lastEditedFrame.current;
    if (isExistedNewFrame || !isPaused) {
      dispatch(editorActions.goToEditFrameByIndex(imageEditFrame));
      // set last layer for prevent useless actions
      lastEditedFrame.current = imageEditFrame;
      isChangingByClick.current = true;
    }
  }, [imagesEditFrames, getImagesInGroup, isPaused]);

  const handleChangeGroup = (groupId) => {
    if (isEditFrameExist(groupId)) {
      setCurrentGroup(groupId);
    }
  };

  useEffect(() => {
    const indexEditFrame = editFramesList.findIndex((frame) => Number(frame) === lastEditFrame);
    if (indexEditFrame !== DEFAULT_INDEX && !isChangingByClick.current) {
      const indexImageEditFrame = Object
        .keys(imagesEditFrames)
        .find((key) => imagesEditFrames[key] === Number(indexEditFrame));
      if (indexImageEditFrame) {
        const groupIndex = indexImageEditFrame.split('image_')[1];
        if (groupIndex) {
          setCurrentGroup(Number(groupIndex));
          lastEditedFrame.current = indexEditFrame;
        }
      }
    }
    isChangingByClick.current = false;
  }, [lastEditFrame]);

  useEffect(() => {
    if (!equals(prevFilteredGroupList, filteredGroupList)) {
      setCurrentGroup(filteredGroupList[0]);
    }
  }, [selectedDraft, selectedAnimation, filteredGroupList]);

  return (
    <Tab>
      <Row>
        <Controls>
          {filteredGroupList.map((id) => (
            <LabelWrapper
              hasManyLabels={filteredGroupList.length > MAX_LABELS_LENGTH}
              onClick={() => handleClickOnGroup({ group: id })}
              key={id}
            >
              <Radio
                name="groups-image"
                value={id}
                id={`${id}-image-slide`}
                isDisabled={!isEditFrameExist(id)}
                isChecked={currentGroup === id}
                onChange={() => handleChangeGroup(id)}
                label={id}
              />
            </LabelWrapper>
          ))}
        </Controls>
      </Row>
      {
        groupList.map((id) => (
          <GroupListItem
            onClick={() => handleClickOnGroup({ group: id })}
            isVisible={id === currentGroup}
            key={id}
          >
            {
              getImagesInGroup(id).map((group) => (
                <ImageEditor
                  isActive={id === currentGroup}
                  key={group.nm}
                  data={group}
                />
              ))
            }
          </GroupListItem>
        ))
      }
    </Tab>
  );
};
