import styled from 'styled-components';

export const Theme = styled.li`
  display: inline-block;
  margin: 8px 6px 12px;
  :last-child {
    padding-right: 25px;
  }
`;

export const Body = styled.div`
  position: absolute;
  top: 65%;
  left: -17px;
  display: none;
  width: 113px;
  padding: 6px 4px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  z-index: 2;
`;

export const Container = styled.div`
  
  @keyframes shownUp {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }
  
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  ${({ isActive, theme }) => isActive && `
    outline: 3px solid orange;
    box-shadow: 0 0 3px ${theme.colors.MAIN};
  `}
  &:hover ${Body} {
    display: block;
    animation: .5s shownUp;
    margin-bottom: 10px;
  }
`;

export const Preview = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.PLATINUM};
  border-radius: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
`;

export const PreviewText = styled.span`
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-height: 46px;
  max-width: 70px;
  margin: 15px 0;
  flex-wrap: wrap;
  font-size: 14px;
  text-overflow: clip;
  word-break: break-word;
`;

export const Content = styled.div`
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: 900;
`;

export const Text = styled.p`
  margin: 5px 0;
  font-size: 10px;
  line-height: 10px;
  font-weight: 300;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li`
  font-size: 10px;
  line-height: 12px;
  font-style: italic;
  font-weight: 500;
  word-break: break-all;
`;
