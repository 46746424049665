export const STORAGE_KEYS = {
  PASSWORD: 'sharedLinkPassword',
  CODE: 'sharedLinkCode',
};

export const HEAD_LIST = [
  {
    id: 'preview_image',
    label: '',
    width: '75px',
  },
  {
    id: 'title',
    label: 'Title',
    width: '130px',
    textAlign: 'left',
    paddingLeft: '10px',
  },
  {
    id: 'version',
    label: 'Version',
    width: '63px',
  },
  {
    id: 'length',
    label: 'Length',
    width: '63px',
  },
  {
    id: 'format',
    label: 'Format',
    width: '63px',
  },
  {
    id: 'status',
    label: 'Status',
    width: '63px',
  },
];

export const LINK_STATUSES = {
  IN_REVIEW: 0,
  APPROVED: 1,
  UNAPPROVED: 2,
};

export const LINK_STATUSES_OPTIONS = [
  {
    label: 'In review',
    value: LINK_STATUSES.IN_REVIEW,
  },
  {
    label: 'Approved',
    value: LINK_STATUSES.APPROVED,
  },
  {
    label: 'Unapproved',
    value: LINK_STATUSES.UNAPPROVED,
  },
];

export const FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
};
