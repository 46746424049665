export const NONE_DRAFT = {
  id: 0,
  title: 'None',
};

export const DRAFTS_STATUS = {
  SAVING_AUDIO: 'SAVING_AUDIO',
  SAVING_DATA: 'SAVING_DATA',
  PREPARING_IMAGES: 'PREPARING_IMAGES',
  IMAGES_READY: 'IMAGES_READY',
  READY_DATA: 'READY_DATA',
  SAVING_DATA_ERROR: 'SAVING_DATA_ERROR',
  SAVING_IMAGES: 'SAVING_IMAGES',
  CHANGING_DRAFT: 'CHANGING_DRAFT',
  DELETING_DRAFT: 'DELETING_DRAFT',
};

export const DRAFTS_STATUS_MESSAGES = {
  [DRAFTS_STATUS.SAVING_AUDIO]: 'Saving audio file',
  [DRAFTS_STATUS.SAVING_DATA]: 'Saving animation config',
  [DRAFTS_STATUS.PREPARING_IMAGES]: 'Preparing images',
  [DRAFTS_STATUS.IMAGES_READY]: 'Images are ready',
  [DRAFTS_STATUS.READY_DATA]: 'Data is ready',
  [DRAFTS_STATUS.SAVING_DATA_ERROR]: 'Saving of the animation config FAILED',
  [DRAFTS_STATUS.SAVING_IMAGES]: 'Saving image files',
};

export const DRAFTS_ERRORS = {
  IMAGE_TEMP_UPLOADING_ERROR: 'Uploading of a temp Image FAILED',
  CAN_NOT_FETCH_IMAGE: 'Fetching of an image from API FAILED',
  FAILED_WHEN_CREATE_IMAGE: 'Creation of an image file FAILED',
  FETCHING_DRAFT_FILE_FAILED: 'Fetching of the draft file FAILED',
};

export const CSV_STATUS = {
  PREPARING: 'PREPARING',
};

export const CSV_STATUS_MESSAGES = {
  [CSV_STATUS.PREPARING]: 'Preparing csv from draft',
};
