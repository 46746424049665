import React from 'react';
import {
  Error, Field, Label, RootStyle,
} from './TextArea.styled';

/**
 *
 * @param id {string | number}
 * @param name {string}
 * @param value {string | number}
 * @param onChange {function}
 * @param disabled {boolean}
 * @param label {string}
 * @param error {string}
 * @param type {string}
 * @param textAreaStyleProps {object} style props for the textArea
 * @param labelStyleProps {object} style props for the label
 * @param errorStyleProps {object} style props for the error
 * @param props {object} style props for the root
 * @returns {JSX.Element}
 * @constructor
 */

export const TextArea = ({
  id,
  name,
  value,
  onChange = () => {},
  disabled,
  label,
  error,
  type,
  textAreaStyleProps = {},
  labelStyleProps = {},
  errorStyleProps = {},
  ...props
}) => (
  <RootStyle {...props}>
    {label && (
    <Label {...labelStyleProps} htmlFor={id}>
      {label}
    </Label>
    )}
    <Field
      id={id}
      name={name}
      type={type}
      onChange={onChange}
      value={value}
      disabled={disabled}
      {...textAreaStyleProps}
    />
    {error && (<Error {...errorStyleProps}>{error}</Error>)}
  </RootStyle>
);
