import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${({ isOpened }) => isOpened && 'margin-bottom: 180px'}
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.MAIN};
  letter-spacing: 0.7px;
  color: ${({ theme }) => theme.colors.WHITE};
  transition-duration: .25s;
  cursor: pointer;
  &:hover {
    opacity: .9;
  }
`;

export const Icon = styled.div`
  position: relative;
  left: 7px;
  display: inline-block;
  border-top: 8px solid ${({ theme }) => theme.colors.WHITE};
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 8px solid transparent;
  transform: translateY(5px);
  ${({ isOpened }) => !isOpened && `
  transform: translateY(-5px) rotateZ(180deg);
  `}
`;

export const Body = styled.div`
  height: 160px;
  background-color: ${({ theme }) => theme.colors.MAIN_BACKGROUND};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  margin-bottom: -100%;
  padding-top: 2px;
  ${({ isOpened }) => !isOpened && `
  position: absolute;
  z-index: -5;
  transform: translateY(-1400px);
  `}
`;

export const ScrollBar = styled(Scrollbars)`
  height: 260px !important;
  & > div:nth-child(2) {
   transform: translateY(-98px);
    z-index: 1;
  }
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  min-width: 600px;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  ${({ isHorizontalScroll }) => !isHorizontalScroll && `
   justify-content: center;
    :last-child{
      margin-right: 32px;
    }
  `}
  &:last-child {
    margin-right:32px;
  }
  @media(${({ theme }) => theme.devices.SM}) {
    justify-content: start;
  }
`;
