import { createSelector } from 'reselect';
import {
  pathOr, identity, prop, memoizeWith,
} from 'ramda';

export const getVideosEntities = createSelector(pathOr({}, ['videos', 'entities', 'videos']), identity);
export const getVideosResult = createSelector(pathOr([], ['videos', 'result']), identity);
export const getVideosCount = createSelector(pathOr(0, ['videos', 'count']), identity);
export const getVideoById = createSelector(
  getVideosEntities,
  (videos) => memoizeWith(identity, (videoId) => prop(videoId, videos)),
);

export const getPrepareStatus = createSelector(pathOr(false, ['videos', 'isPreparingToRender']), identity);
export const getLastParams = createSelector(pathOr({}, ['videos', 'params']), identity);
export const getCountRenderingFiles = createSelector(pathOr(0, ['videos', 'renderingFilesCount']), identity);

export const getPendingSendingVideosToZapier = createSelector(
  pathOr(false, ['pending', 'sendVideoToZapierRequest']),
  identity,
);

export const selectVideoIdsForDelete = createSelector(
  pathOr([], ['videos', 'idsForDelete']),
  identity,
);

export const selectIsMadeExport = createSelector(
  pathOr(false, ['videos', 'isMadeExport']),
  identity,
);
