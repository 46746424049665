import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table, Body, Pagination, Modal, CheckboxGroup, Toggle,
} from 'ui-kit';
import {
  useListSearch, useMyFiles, usePagination, useSorting,
} from 'hooks';
import { DEFAULT_VIDEO_TYPES } from 'constants/rendering';
import { getVideosCount, getVideosEntities, getVideosResult } from 'state/videos/selectors';
import { deleteVideosRequest, getVideosRequest } from 'state/videos/actions';
import { DELETE_MODAL_QUESTIONS } from 'constants/myFiles';
import { generateTypesForApi } from 'utils/helpers';
import {
  Controls, LeftPart, Title,
  PaginationWrapper, CustomScrollbars,
  ToggleLabel, Filters, TableContainer, ToggleWrapper,
} from './List.styled';
import { Types } from '../Types';
import { FileItem } from '../FileItem';
import { HeadTable } from '../HeadTable';
import { RightToolBar } from '../RightToolBar';
import { Preview } from '../Preview';

const FIRST_PAGE = 1;

export const List = () => {
  const dispatch = useDispatch();
  const videosResult = useSelector(getVideosResult);
  const videosEntities = useSelector(getVideosEntities);
  const videosCount = useSelector(getVideosCount);
  const { t } = useTranslation(['account', 'common', 'forms']);
  const {
    isSelecting, selectedFileIdsList, setSelectedFileIdsList,
    toggleDeleteFilesModal, isOpenedDeleteFilesModal, selectedPreviewFileId,
  } = useMyFiles();

  const [types, setTypes] = useState(DEFAULT_VIDEO_TYPES);
  const [isTop, setIsTop] = useState(false);
  const [clientStatus, setClientStatus] = useState(false);
  const [isExactSearch, setIsExactSearch] = useState(false);
  const { orderBy, sortBy, handleSort } = useSorting();

  const {
    page, pages, setPage, lastPage, limit,
  } = usePagination({ count: videosCount });

  const { debouncedSearchValue, searchValue, setSearchValue } = useListSearch(setPage);

  const deleteQuestion = selectedFileIdsList.length > 1 ? DELETE_MODAL_QUESTIONS.MANY_ITEMS : DELETE_MODAL_QUESTIONS.ONE_ITEM;

  const toggleTopFilter = () => setIsTop((prev) => !prev);
  const toggleClientStatus = () => setClientStatus((prev) => !prev);
  const toggleExactSearch = () => setIsExactSearch((prev) => !prev);

  const handleToggling = useCallback((callback) => () => {
    callback();
    setPage(FIRST_PAGE);
  }, []);

  // todo: refactor
  const onDelete = useCallback((confirm) => confirm
      && dispatch(deleteVideosRequest(selectedFileIdsList, {
        callbacks: {
          success: () => {
            dispatch(getVideosRequest({
              order: orderBy,
              sortBy,
              limit,
              page: page - 1,
              title: debouncedSearchValue,
              type: generateTypesForApi(types),
              ...(isTop
                ? { is_top: Number(isTop) }
                : {}),
              ...(clientStatus
                ? { client_status: Number(clientStatus) }
                : {}),
              duplicates: Number(isExactSearch),
            }));
            setSelectedFileIdsList([]);
          },
        },
      })), [
    page, sortBy, orderBy,
    page, debouncedSearchValue, selectedFileIdsList,
    types, isTop, clientStatus, isExactSearch,
  ]);

  useEffect(() => {
    dispatch(getVideosRequest({
      order: orderBy,
      sortBy,
      limit,
      page: page - 1,
      title: debouncedSearchValue,
      type: generateTypesForApi(types),
      ...(isTop
        ? { is_top: Number(isTop) }
        : {}),
      ...(clientStatus
        ? { client_status: Number(clientStatus) }
        : {}),
      duplicates: Number(isExactSearch),
    }));
  }, [
    sortBy, orderBy, page,
    limit, debouncedSearchValue, types,
    isTop, clientStatus, isExactSearch,
  ]);

  return (
    <>
      <Controls isSelecting={isSelecting}>
        <LeftPart>
          <Title>{t('All files')}</Title>
          <Types types={types} setTypes={setTypes} setPage={setPage} />
        </LeftPart>
        <Filters>
          <CheckboxGroup
            id="favorite"
            isChecked={isTop}
            onChange={handleToggling(toggleTopFilter)}
            size="small"
            label={t('Favorite')}
          />
          <CheckboxGroup
            id="client_status"
            isChecked={clientStatus}
            onChange={handleToggling(toggleClientStatus)}
            size="small"
            label={t('Approved')}
          />
          <ToggleWrapper>
            <Toggle
              onChange={handleToggling(toggleExactSearch)}
              value={isExactSearch}
              id="exact_search"
              name="exact_search"
              toggleSize="small"
            />
            <ToggleLabel
              htmlFor="exact_search"
            >{t('Starts with...')}
            </ToggleLabel>
          </ToggleWrapper>
        </Filters>
        <RightToolBar searchValue={searchValue} setSearchValue={setSearchValue} />
      </Controls>
      <TableContainer>
        <Table>
          <HeadTable sortBy={sortBy} handleSort={handleSort} orderBy={orderBy} />
          <CustomScrollbars>
            <Body>
              {videosResult.length ? videosResult.map((id) => (
                <FileItem key={id} data={videosEntities[id]} />
              )) : (<></>)}
            </Body>
          </CustomScrollbars>
        </Table>
      </TableContainer>
      {videosResult.length ? (
        <PaginationWrapper>
          <Pagination pages={pages} page={page} setPage={setPage} lastPage={lastPage} />
        </PaginationWrapper>
      ) : (<></>)}
      <Modal
        isPrompt
        isOpen={isOpenedDeleteFilesModal}
        onChange={onDelete}
        onClose={toggleDeleteFilesModal(false)}
        question={t(deleteQuestion)}
      />
      {selectedPreviewFileId && (
      <Preview file={videosEntities[selectedPreviewFileId]} />
      )}
    </>
  );
};
