import { is, omit } from 'ramda';
import { notify } from './notificationHelpers';

export const getApiUrl = () => process.env.REACT_APP_API_URL;
export const getWsUrl = () => process.env.REACT_APP_WS_URL;
export const getToken = () => localStorage.getItem('authToken');

export const revokeObjectUrlWithDelay = (url, delay = 8000) => setTimeout(
  () => URL.revokeObjectURL(url),
  delay,
);

export const getTempFileUrl = (id = '') => {
  if (is(String, id) && id.includes('http')) return id;
  return id ? `${process.env.REACT_APP_FILES_URL}/uploads/temp-files/${id}/` : '';
};

/**
 *
 * @param fileUrl {string}
 * @param isUseSalt {boolean}
 * @param isUseBase64 {boolean}
 * @param isDownloading {boolean}
 * @param quality {number}
 * @returns {string}
 */

export const getFileUrl = ({
  fileUrl = '', isUseSalt = false, isUseBase64 = false, isDownloading = false, quality = 0,
}) => {
  if (!fileUrl) return '';
  const salt = localStorage.getItem('salt');
  let baseUrl = `${process.env.REACT_APP_FILES_URL}/${fileUrl}?`;
  if (isDownloading) {
    baseUrl = `${baseUrl}track=downloading&`;
  }
  if (isUseSalt) {
    baseUrl = `${baseUrl}salt=${salt}&`;
  }
  if (isUseBase64) {
    baseUrl = `${baseUrl}base64=1&`;
  }
  if (quality > 0) {
    baseUrl = `${baseUrl}quality=${quality}`;
  }
  return baseUrl;
};

export const blobAutoRevoker = (() => {
  let listOfUrls = {};

  return {
    add: (key, url) => {
      if (listOfUrls[key]) {
        revokeObjectUrlWithDelay(listOfUrls[key]);
        listOfUrls = omit([key], listOfUrls);
      }
      listOfUrls[key] = url;
    },
  };
})();

export const toBlobUrl = (url, callback, key) => {
  fetch(url)
    .then((file) => file.blob())
    .then((blob) => {
      const urlToBlob = URL.createObjectURL(blob, blob.type);
      callback(urlToBlob, blob.type);
      blobAutoRevoker.add(key, urlToBlob);
    });
};

/**
 *
 * @param res {object}
 * @returns {*}
 */

export const extractData = (res) => {
  const { data } = res;
  return data;
};

/**
 *
 * @param func {function}
 * @returns {Promise<*>}
 */

export const catchError = async (func) => {
  try {
    const data = await func;
    return extractData(data);
  } catch (e) {
    if (typeof e.response === 'undefined') {
      notify.error('Server is unavailable');
    } else {
      throw e.response.data;
    }
  }
};
