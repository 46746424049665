import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Table, Head, HeadCell, Row, Body, Button,
} from 'ui-kit';
import { COLORS } from 'constants/styles';
import { videoService } from 'api';
import { getVideosEntities } from 'state/videos/selectors';
import { useMyFiles } from 'hooks';
import { notify } from 'utils/helpers/notificationHelpers';
import { downloadZipHelper, getFileUrl } from 'utils/helpers';
import { ButtonContainer, CustomScrollbars, RootStyle } from './DownloadFilesModal.styled';
import { DownloadItem } from './DownloadItem';
import { BUTTON_STYLES, HEAD_LIST, TITLE_LINKS } from './constants';

export const DownloadFilesModal = () => {
  const { t } = useTranslation(['account', 'common', 'forms']);

  const {
    isOpenedDownloadFilesModal,
    toggleDownloadFilesModal,
    selectedFileIdsList,
    setSelectedFileIdsList,
  } = useMyFiles();

  const videosEntities = useSelector(getVideosEntities);

  const handleClick = useCallback(async () => {
    try {
      const res = await videoService.downloadVideos({ ids: selectedFileIdsList });
      downloadZipHelper(res.data);
      toggleDownloadFilesModal(false)();
      setSelectedFileIdsList([]);
    } catch (e) {
      notify.error(t('Download failed'));
    }
  }, [selectedFileIdsList]);

  const handleCopyAll = useCallback(() => {
    let links = TITLE_LINKS;
    for (const fileId of selectedFileIdsList) {
      const videoFileId = videosEntities[fileId].video_file_id;
      const { title } = videosEntities[fileId];
      const link = getFileUrl({ fileUrl: `files/${videoFileId}`, isUseSalt: true });

      links = `${links} ${title} \t ${link} \n`;
    }
    navigator.clipboard.writeText(links);
    notify.success(t('All links were copied to clipboard!'));
  }, [selectedFileIdsList, videosEntities]);

  return (
    <RootStyle
      isOpen={isOpenedDownloadFilesModal}
      afterClickingOutsideClose
      onClose={toggleDownloadFilesModal(false)}
    >
      <Table id="download-list-table">
        <Head>
          <Row isHead isBoxShadow>
            {HEAD_LIST.map(({
              id,
              label,
              ...props
            }) => (
              <HeadCell key={id} {...props}>{t(label)}</HeadCell>
            ))}
          </Row>
        </Head>
        <CustomScrollbars>
          <Body>
            {selectedFileIdsList.map((id) => (
              <DownloadItem key={`download-file-${id}`} data={videosEntities[id]} />
            ))}
          </Body>
        </CustomScrollbars>
      </Table>
      <ButtonContainer>
        <Button
          type="button"
          width={BUTTON_STYLES.WIDTH}
          height={BUTTON_STYLES.HEIGHT}
          fontSize={BUTTON_STYLES.FONT_SIZE}
          onClick={handleCopyAll}
          color={COLORS.MAIN}
          label={t('Copy all to clipboard')}
          title={t('Copy all to clipboard')}
          mr={1}
        />
        <Button
          type="button"
          width={BUTTON_STYLES.WIDTH}
          height={BUTTON_STYLES.HEIGHT}
          fontSize={BUTTON_STYLES.FONT_SIZE}
          onClick={handleClick}
          color={COLORS.MAIN}
          label={t('Download as a ZIP')}
          title={t('Download as a ZIP')}
        />
      </ButtonContainer>
    </RootStyle>
  );
};
