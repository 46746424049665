import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AdvancedItem } from './AdvancedItem';
import { List, Item } from './AdvancedList.styled';

/**
 *
 * @param id {number | string}
 * @param advanced {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const AdvancedList = ({
  id, advanced,
}) => {
  const form = useFormContext();
  return (
    <List>
      {advanced.map(({
        title, options, name,
      }) => (
        <Item key={`${id}-${name}`}>
          <Controller
            name="gifFps"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <AdvancedItem
                onChange={onChange}
                value={value || options[0].value}
                title={title}
                name={name}
                id={id}
                options={options}
              />
            )}
          />
        </Item>
      ))}
    </List>
  );
};
