import React, {
  useCallback, useRef, useState,
} from 'react';
import { EVENTS } from 'constants/trimmer';
import useEventListeners from './hooks/useEventListeners';
import { DraggerShell } from './Dragger.styled';

/**
 * @param className
 * @param x
 * @param onDrag
 * @param onDragStop
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */

export const Dragger = ({
  className, x, onDrag, onDragStop = () => {}, children,
}) => {
  const screenX = useRef(0);
  const startPosition = useRef(0);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseMove = useCallback((e) => {
    e.stopPropagation();
    if (e.type === EVENTS.TOUCH_MOVE) {
      onDrag({
        x: e.touches[0].clientX - screenX.current + startPosition.current,
      });
      return;
    }
    onDrag({
      x: e.screenX - screenX.current + startPosition.current,
    });
  }, [onDrag, screenX.current, startPosition.current]);

  const handleMouseUp = useCallback((e) => {
    e.stopPropagation();
    onDragStop();
    setIsMouseDown(false);
  }, [onDragStop]);

  const handleMouseDown = useCallback((e) => {
    e.stopPropagation();
    startPosition.current = x;
    if (e.type === EVENTS.TOUCH_START) {
      screenX.current = e.touches[0].clientX;
    } else {
      screenX.current = e.screenX;
    }
    setIsMouseDown(true);
  }, [x, screenX.current, startPosition.current]);

  useEventListeners({ handleMouseMove, handleMouseUp, isMouseDown });

  return (
    <DraggerShell
      className={className}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      style={{
        transform: `translateX(${x}px)`,
      }}
      role="button"
      tabIndex={0}
    >
      {children}
    </DraggerShell>
  );
};
