import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { companySelectors } from 'state/company';
import { RENDER_TYPES } from 'constants/rendering';
import { useEditor } from 'hooks';
import { DEFAULT_INDEX } from 'constants/common';
import { PickerSelect } from '../../PickerSelect';

/**
 *
 * @returns {unknown[]}
 * @constructor
 */

export const ExportTool = () => {
  const disabledVideoTypes = useSelector(companySelectors.getCompanyDisabledVideoTypes);
  const form = useFormContext();

  const {
    isOpenFileModal,
    setIsOpenFileModal,
  } = useEditor();

  const onOpenModal = () => setIsOpenFileModal(true);

  const options = RENDER_TYPES
    .filter((item) => !disabledVideoTypes.find((disabledVideoType) => disabledVideoType === item.typeForApi))
    .map((item) => ({
      label: item.label,
      value: item.fileTypes[0],
    }));

  const handleChange = useCallback((value) => {
    form.setValue('renderType', Number(value));
    onOpenModal(true);
  }, [isOpenFileModal]);

  return (
    <PickerSelect
      id="select-export"
      name="renderType"
      onChange={handleChange}
      label="Select type"
      defaultValue={DEFAULT_INDEX}
      options={options}
      isShowedValue={false}
      isDefaultSelectedOnOpen
    />
  );
};
