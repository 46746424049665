import { useEffect } from 'react';
import { EVENTS } from 'constants/trimmer';

/**
 *
 * @param handleMouseMove
 * @param handleMouseUp
 * @param isMouseDown
 */

const useEventListeners = ({ handleMouseMove, handleMouseUp, isMouseDown }) => {
  useEffect(() => {
    if (isMouseDown) {
      window.addEventListener(EVENTS.MOUSE_MOVE, handleMouseMove, false);
      window.addEventListener(EVENTS.MOUSE_UP, handleMouseUp, false);
      window.addEventListener(EVENTS.TOUCH_MOVE, handleMouseMove, false);
      window.addEventListener(EVENTS.TOUCH_END, handleMouseUp, false);
    }

    return () => {
      window.removeEventListener(EVENTS.MOUSE_MOVE, handleMouseMove);
      window.removeEventListener(EVENTS.MOUSE_UP, handleMouseUp);
      window.removeEventListener(EVENTS.TOUCH_MOVE, handleMouseMove);
      window.removeEventListener(EVENTS.TOUCH_END, handleMouseUp);
    };
  }, [isMouseDown, handleMouseUp]);
};

export default useEventListeners;
