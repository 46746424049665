import { useCallback, useEffect } from 'react';

const MOUSE_DOWN = 'mousedown';

/**
 *
 * @param ref {Element}
 * @param callback {function}
 * @param condition {boolean}
 */

export const useClickOutside = ({ ref, callback, condition = true }) => {
  const handleClickOutside = useCallback((e) => {
    if (ref.current && !ref.current.contains(e.target) && e.offsetX <= e.target.clientWidth) {
      if (condition) {
        setTimeout(() => callback());
      }
    }
  }, [ref, condition]);

  useEffect(() => {
    document.addEventListener(
      MOUSE_DOWN,
      handleClickOutside,
    );
    return () => {
      document.removeEventListener(MOUSE_DOWN, handleClickOutside);
    };
  },
  [handleClickOutside]);
};
