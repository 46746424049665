import { useMemo, useState } from 'react';
import { generatePagesArrayWithDots } from 'utils/helpers';

const DEFAULT_LIMIT = 15;
const FIRST_PAGE = 1;
const DEFAULT_SIBLING_COUNT = 1;

/**
 * @param count {number}
 * @param limit {number}
 */

export const usePagination = ({ count, limit = DEFAULT_LIMIT }) => {
  const [page, setPage] = useState(FIRST_PAGE);

  const lastPage = Math.ceil(count / limit);

  const pages = useMemo(() => generatePagesArrayWithDots({
    totalCount: count,
    itemsPerPage: limit,
    siblingCount: DEFAULT_SIBLING_COUNT,
    currentPage: page,
  }), [count, limit, page]);

  return {
    page,
    pages,
    setPage,
    lastPage,
    limit,
  };
};
