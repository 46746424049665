export default {
  GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
  GET_COMPANY_REQUEST_SUCCESS: 'GET_COMPANY_REQUEST_SUCCESS',

  UPDATE_COMPANY_REQUEST: 'UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_REQUEST_SUCCESS: 'UPDATE_COMPANY_REQUEST_SUCCESS',

  DELETE_COMPANY_REQUEST: 'DELETE_COMPANY_REQUEST',
  DELETE_COMPANY_REQUEST_SUCCESS: 'DELETE_COMPANY_REQUEST_SUCCESS',

  SET_COMPANY_IS_ENABLED_BRAND_COLORS: 'SET_COMPANY_IS_ENABLED_BRAND_COLORS',
  SET_COMPANY_IS_ENABLED_BRAND_FONTS: 'SET_COMPANY_IS_ENABLED_BRAND_FONTS',

  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',

  DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',

  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
};
