import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media(${({ theme }) => theme.devices.LG}) {
    flex-direction: column;
  }
`;

export const CsvUpload = styled.label`
  vertical-align: middle;
  border: 0;
  width: 75px;
  line-height: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-weight: 500;
  padding: 2px 30px;
  font-size: 14px;
  cursor: pointer;
  transition-duration: .25s;
  outline: none;
  @media(${({ theme }) => theme.devices.LG}) {
    background-color: ${({ theme }) => theme.colors.LIGHT_BLUE};
    color: ${({ theme }) => theme.colors.WHITE};
  }
  @media(${({ theme }) => theme.devices.XS_X}) {
    min-width: 42px;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    font-size: 12px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.LIGHT_BLUE};
    color: ${({ theme }) => theme.colors.WHITE};
  }
  img{
    transform: rotateX(180deg);
  }
`;
