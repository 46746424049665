import styled from 'styled-components';

export const RootStyle = styled.div`
  width: 100%;
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  white-space: pre-wrap;
  margin-top: 12px;
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
`;

export const SelectLabel = styled.div`
  max-height: 32px;
  min-width: 48px;
  max-width: 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 7px 5px;
  background-color: ${({ theme }) => theme.colors.MAIN};
  border: 1px solid ${({ theme }) => theme.colors.MAIN};
  color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 7px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SelectText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;
