import { handleActions } from 'redux-actions';
import { path } from 'ramda';

import { mergeByProp } from 'utils/helpers/ramdaStateHelpers';

import types from './types';

const reducer = handleActions({
  [types.GET_COMPANY_REQUEST_SUCCESS]: mergeByProp('company', path(['payload', 'data', 'model'])),
  [types.UPDATE_COMPANY_REQUEST_SUCCESS]: mergeByProp('company', path(['payload', 'data', 'model'])),
  [types.GET_NOTIFICATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    notifications: [...payload.data.data], // we get such format from the back-end
  }),
  [types.UPDATE_NOTIFICATIONS]: (state, { payload }) => ({
    ...state,
    notifications: [...payload],
  }),
}, {
  company: null,
  notifications: [],
});

export default reducer;
