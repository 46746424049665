import { compose } from 'redux';
import {
  always,
  curry,
  findIndex,
  head,
  ifElse,
  isNil,
  map,
  match as RMatch,
  path,
  replace,
  split,
  tryCatch,
  pathOr,
  trim,
} from 'ramda';
import { isSubstrInProp, propStrInclude } from 'utils/helpers/commonHelpers';
import {
  CONTROLS_CONST,
  CONTROLS_PATH,
  CONTROLS_PROP, GROUP_CONST, GROUP_PATH, TEXT_GROUP_POSITION,
  TEXT_PATH,
  TEXT_PROP,
  TEXTS_CONSTANTS,
} from 'constants/animationsPath';
import {
  EDIT_FRAME_REGEXP, HIDE_EVERYWHERE_REGEXP, HIDE_EVERYWHERE_WITH_SPACES_REGEXP,
  HIDE_REGEXP,
  HIDE_WITH_SPACES_REGEXP,
  REGEX_OF_ARRAY_SWITCHERS,
  SINGLE_WHITE_SPACE_CHAR_REG_EXP,
} from 'constants/regexp';

export const tryOr = (def, func) => tryCatch(func, always(def));

/**
 *
 * @param def {string | number | null | array<number | string | null>}
 * @param func {function}
 * @returns {*}
 */

export const tryOrDef = ({ def, func }) => {
  try {
    return func();
  } catch (e) {
    return def;
  }
};

export const searchArrayInString = (value) => compose(
  map(trim()),
  map(replace(/(\n|\\t|\\r|\[|\]|')/g, '')),
  ifElse(isNil, always([]), split(',')),
  head,
  RMatch(/\[(.|\s)*\]/g),
)(value);

export const searchValueByBeginStr = (value, string) => compose(replace(/'/g, ''),
  head,
  RMatch(/'.*'/g),
  head,
  RMatch(new RegExp(`^(${value}).*`, 'gm')))(string);

/**
 *
 * @param name {string}
 * @param value {string}
 * @param list {array}
 * @returns {[number,obj]}
 */

export const findIndexAndValueByProp = ({ name, value, list }) => {
  const index = list.findIndex((item) => {
    return isSubstrInProp({ propName: name, obj: item, substr: value });
  });
  return [
    index,
    list[index],
  ];
};

export const findValueAndIndexByPropValue = curry((name, value, obj) => {
  const index = findIndex(
    propStrInclude(name, value),
  )(obj);
  return [
    index,
    obj[index],
  ];
});

/**
 *
 * @param effect {array<object>}
 * @returns {[number,obj]}
 */

export const getTextLayerIndexAndValue = (effect) => findIndexAndValueByProp({
  name: TEXT_PROP.NAME,
  value: TEXTS_CONSTANTS.TEXT_LAYER_NAME,
  list: effect,
});

/**
 *
 * @param effect {array<object>}
 * @param value {string}
 * @returns {[number,obj]}
 */

export const getGroupOfTextIndexAndValue = (effect, value) => findIndexAndValueByProp({
  name: TEXT_PROP.NAME,
  value,
  list: effect,
});

/**
 *
 * @param str {string | null}
 * @returns {*}
 */

export const getArrayValuesFromString = (str) => tryOrDef({
  def: null,
  func: () => searchArrayInString(str),
});

/**
 *
 * @param effect {array<object>}
 * @returns {[null,null,null]|[*,(string|number|*|string)[],(*|(function(*): (*)))]}
 */

export const getScaleValues = (effect) => {
  const [textLayerIndex, textLayer] = getTextLayerIndexAndValue(effect);

  const [groupOfTextIndex, groupOfText] = getGroupOfTextIndexAndValue(textLayer.ef, TEXTS_CONSTANTS.TEXT_SCALE_LAYER_NAME);

  const scaleValues = path(TEXT_PATH.SCALE_VALUES, groupOfText);

  const values = getArrayValuesFromString(scaleValues);

  if (isNil(groupOfText)
    || isNil(values)
    || new RegExp(HIDE_REGEXP).test(scaleValues)
    || new RegExp(HIDE_WITH_SPACES_REGEXP).test(scaleValues)
    || new RegExp(HIDE_EVERYWHERE_REGEXP).test(scaleValues)
    || new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(scaleValues)) {
    return [null, null, null];
  }

  const value = path(TEXT_PATH.SCALE_VALUE, groupOfText);

  const originPath = [TEXT_PROP.EFFECT, textLayerIndex, TEXT_PROP.EFFECT, groupOfTextIndex,
    ...TEXT_PATH.SCALE_VALUE];

  return [values, originPath, value];
};

/**
 *
 * @param effect {array<object>}
 * @returns {[null,null,null]|[*,(string|number|string)[],(*|(function(*, *): (*))|(function(*): (*)))]}
 */

export const getVariableValues = (effect) => {
  const [textLayerIndex, textLayer] = getTextLayerIndexAndValue(effect);
  const [groupOfTextIndex, groupOfText] = getGroupOfTextIndexAndValue(textLayer.ef, TEXTS_CONSTANTS.TEXT_VARIABLES_LAYER_NAME);

  const variablesValues = pathOr(null, TEXT_PATH.VARIABLES_VALUES, groupOfText);

  if (variablesValues && (new RegExp(HIDE_REGEXP).test(variablesValues)
    || new RegExp(HIDE_WITH_SPACES_REGEXP).test(variablesValues)
    || new RegExp(HIDE_EVERYWHERE_REGEXP).test(variablesValues)
    || new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(variablesValues))) {
    return [null, null, null];
  }

  const values = getArrayValuesFromString(variablesValues);

  const value = pathOr(null, TEXT_PATH.VARIABLE_VALUE, groupOfText);

  const originPath = [TEXT_PROP.EFFECT, textLayerIndex, TEXT_PROP.EFFECT, groupOfTextIndex,
    ...TEXT_PATH.SCALE_VALUE];

  return [values, originPath, value];
};

/**
 *
 * @param config {string}
 * @returns {*}
 */

export const getEditFrameFromConfigStr = (config) => {
  const str = config.match(EDIT_FRAME_REGEXP)[0];
  return Number(str.split('').reverse()[0]);
};

/**
 *
 * @param layers {array<object>}
 * @returns {*}
 */

export const filterLayers = (layers) => layers.map((item, index) => ({
  ...item,
  index,
})) // map for making indexes first
  .filter((item) => {
    const isSwitcher = isSubstrInProp({
      propName: CONTROLS_PROP.NAME,
      substr: CONTROLS_CONST.SWITCHER,
      obj: item,
    });
    if (isSwitcher) {
      const val = path(CONTROLS_PATH.VALUES, item);
      return new RegExp(REGEX_OF_ARRAY_SWITCHERS).test(val)
        && !(new RegExp(HIDE_REGEXP).test(val))
        && !(new RegExp(HIDE_WITH_SPACES_REGEXP).test(val))
        && !(new RegExp(HIDE_EVERYWHERE_REGEXP).test(val))
        && !(new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(val));
    }
    return false;
  });

/**
 *
 * @param value {string}
 * @param string {string}
 * @returns {*}
 */

export const searchSwitcherValueByBegin = (value, string) => {
  let firstPart = string.match(new RegExp(`${value} = (.*?);`, 'gm'));
  if (!firstPart) {
    firstPart = string.match(new RegExp(`(${value})=(.*);`, 'gm'));
  }
  const firstValue = firstPart[0].match(/'.*'/g);
  if (firstValue[0]) {
    return firstValue[0].replace(/'/g, '');
  }
  const [val] = firstPart.match(new RegExp(`${value}=(.*)`, '/g'))[0];
  return val.replace(/'/g, '');
};

/**
 *
 * @param name {string}
 * @returns {number}
 */

const getIndexFromName = (name) => Number(name.split(SINGLE_WHITE_SPACE_CHAR_REG_EXP)[1]);

/**
 *
 * @param a {string}
 * @param b {string}
 * @returns {number}
 */

export const sortArrayByIndexes = (a, b) => {
  return getIndexFromName(b) > getIndexFromName(a) ? -1 : 1;
};

/**
 *
 * @param item {object}
 * @returns {*}
 */

export const getSwitcherLayers = (item) => {
  const childLayers = path(GROUP_PATH.CHILD_LAYERS, item);
  const normalizedChildLayers = childLayers.reduce((accum, layer, index) => (layer.nm.search(GROUP_CONST.SWITCHER) ? accum : [...accum, { index, ...layer }]), []);
  return normalizedChildLayers.filter((layer) => {
    const switcherValues = path(TEXT_GROUP_POSITION.SWITCHERS_VALUES, layer);
    return switcherValues
      && !(new RegExp(HIDE_REGEXP).test(switcherValues))
      && !(new RegExp(HIDE_WITH_SPACES_REGEXP).test(switcherValues))
      && !(new RegExp(HIDE_EVERYWHERE_REGEXP).test(switcherValues))
      && !(new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(switcherValues));
  });
};

/**
 *
 * @param name {string}
 * @param value {string}
 * @param list {array<object>}
 * @returns {*}
 */

const getFirstItemFromListByValue = ({ name, value, list }) => {
  return list.filter((item) => isSubstrInProp({ propName: name, obj: item, substr: value }))[0];
};

/**
 *
 * @param group {object}
 * @param texts {object}
 * @returns {*[]}
 */

export const getTextLayersListByGroup = (group, texts) => Object.keys(texts)
  .reduce((accum, key) => {
    const { ef, ind } = texts[key];
    const textLayerInfo = getFirstItemFromListByValue({
      name: TEXT_PROP.NAME,
      value: TEXTS_CONSTANTS.TEXT_LAYER_NAME,
      list: ef,
    });
    const groupOfText = getFirstItemFromListByValue({ name: TEXT_PROP.NAME, value: 'Group', list: textLayerInfo.ef });
    const groupOfTextIndex = path(TEXT_PATH.VARIABLE_VALUE, groupOfText);
    if (groupOfTextIndex === group.ind) {
      return [...accum, ind];
    }
    return accum;
  }, [])
  .reverse();

/**
 *
 * @param group {object}
 * @returns {*}
 */

export const getSwitchersNormalized = (group) => {
  const switcherLayers = tryOrDef({ def: null, func: () => getSwitcherLayers(group) });
  return switcherLayers.map((layer, index) => ({
    ind: index,
    value: pathOr(null, TEXT_GROUP_POSITION.SWITCHERS_VALUE, layer),
    options: tryOrDef({
      def: null,
      func: () => {
        const switchersValues = path(TEXT_GROUP_POSITION.SWITCHERS_VALUES, layer);
        return searchArrayInString(switchersValues);
      },
    }),
    nativeName: layer.nm,
    nativeIndex: layer.index,
  }));
};
