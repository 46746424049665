import mixpanel from 'mixpanel-browser';

export const initMixPanel = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
};

export const trackEvent = (event, props = {}) => {
  mixpanel.track(event, props);
};

export const identifyMixPanelUser = (id) => {
  mixpanel.identify(`${id}`);
};

export const setPropertiesToTheUser = ({ company, name }) => {
  mixpanel.people.set_once({
    company,
    name,
  });
};

export const resetConfig = () => {
  mixpanel.reset();
};
