import * as yup from 'yup';

/**
 *
 * @param isPassword {boolean}
 */

const getRules = (isPassword) => (
  yup.object().shape({
    title: yup
      .string()
      .min(3, 'Title must be at least 3 characters')
      .required('Title is required'),
    ...(isPassword ? ({
      password: yup
        .string()
        .min(6, 'Min length 6 chars')
        .max(16, 'Max length 16 chars')
        .required('Password is required'),
      repeatPassword: yup
        .string()
        .min(6, 'Min length 6 chars')
        .max(16, 'Max length 16 chars')
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    }) : {}),
  })
);

export default getRules;
