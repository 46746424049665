import React from 'react';
import { Root } from './Body.styled';

/**
 *
 * @param children {React.ReactNode}
 * @returns {JSX.Element}
 * @constructor
 */

export const Body = ({ children }) => {
  return (
    <Root>{children}</Root>
  );
};
