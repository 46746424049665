import { combineReducers } from 'redux';

import { state } from 'utils/middlewares/sagaRequestApi';
import user from './user';
import ui from './ui';
import editor from './editor';
import drafts from './drafts';
import animation from './animation';
import animations from './animations';
import videos from './videos';
import colors from './colors';
import company from './company';
import socket from './socket';
import fonts from './fonts';
import links from './links';
import webhooks from './webhooks';

const rootReducer = () => combineReducers({
  editor,
  animation,
  animations,
  user,
  ui,
  videos,
  colors,
  drafts,
  company,
  socket,
  fonts,
  links,
  webhooks,
  ...state,
});

export default rootReducer;
