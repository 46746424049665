import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsRequest } from 'state/company/actions';
import { getNotifications } from 'state/company/selectors';
import { NotificationItem } from './NotificationItem';
import { List } from './NotificationBar.styled';

const SIZE = 2;

const TIMEOUT_BEFORE_REQUEST = 1200000;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const NotificationBar = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);

  useEffect(() => {
    dispatch(getNotificationsRequest());
    setInterval(() => {
      dispatch(getNotificationsRequest()); // we need to update notifications each 20 minutes
    }, TIMEOUT_BEFORE_REQUEST);
  }, []);

  return (
    <List>
      {notifications.slice(0, SIZE).map(({ id, title, content }) => (
        <NotificationItem
          key={id}
          id={id}
          title={title}
          content={content}
        />
      ))}
    </List>
  );
};
