import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { lockIcon } from 'assets/images';
import { presetColorsSelector } from 'state/editor/selectors';
import { COLORS } from 'constants/styles';
import { Option } from './Option';
import {
  Picker, Lock, Header, MainColor, Options, Row, Column,
} from './ColorPicker.styled';
import { Modal } from '../Modal';
import { Toggle } from '../Toggle';
import { Button } from '../Button';
import { ColorPalette } from '../ColorPalette';

const BUTTON_WIDTH = 72;
const FONT_SIZE = 15;

/**
 *
 * @param name {string}
 * @param id {string | number}
 * @param globalColorIsDisabled {boolean}
 * @param value {string}
 * @param isGlobalByDefault {boolean}
 * @param initialPaletteColor {string}
 * @param enableColorInput {boolean}
 * @param onChange {function}
 * @param globalColor {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const ColorPicker = ({
  name, id, globalColorIsDisabled = false,
  value = '#fff', isGlobalByDefault = false, initialPaletteColor,
  enableColorInput = false, onChange, globalColor,
}) => {
  const colors = useSelector(presetColorsSelector);
  const navigate = useNavigate();
  const [isOpenedModalPicker, setIsOpenedModalPicker] = useState(false);
  const [isOpenedPalette, setIsOpenedModalPalette] = useState(false);
  const [color, setColor] = useState(value);
  const [isGlobal, setIsGlobal] = useState(isGlobalByDefault);
  const [paletteColor, setPaletteColor] = useState(initialPaletteColor);
  const [isPaletteActive, setIsPaletteActive] = useState(false);
  const innerColor = isPaletteActive ? paletteColor : colors[color];
  const currentColor = isGlobal && !isPaletteActive ? globalColor : innerColor;

  const { t } = useTranslation(['account', 'common', 'forms']);

  const handleTogglePicker = (modalState) => () => setIsOpenedModalPicker(modalState);

  const handleChange = (index) => () => {
    if (isGlobal) return;
    setPaletteColor({});
    setIsPaletteActive(false);
    setColor(index);
  };

  const onOpenPallet = () => enableColorInput && setIsOpenedModalPalette(true);

  const onCancel = () => {
    setColor(value);
    setIsOpenedModalPicker(false);
  };

  const onOpenAdjustPresets = () => {
    setIsOpenedModalPicker(false);
    navigate('/account/design-presets');
  };

  const onToggle = useCallback(({ target: { checked } }) => {
    setIsPaletteActive(false);
    setIsGlobal(checked);
  }, []);

  const onChooseColor = useCallback(() => {
    setIsOpenedModalPicker(false);
    onChange({
      target: {
        name,
        value: {
          color: currentColor,
          index: isPaletteActive ? -1 : color,
          isGlobalColor: isGlobal,
        },
      },
    });
  }, [isGlobal, isPaletteActive, color, currentColor, name]);

  const onCloseColorPallet = useCallback(() => {
    setIsOpenedModalPalette(false);
    setPaletteColor(paletteColor);
  }, [paletteColor]);

  const onAddColor = () => {
    setIsPaletteActive(true);
    setPaletteColor(paletteColor);
    setIsOpenedModalPalette(false);
  };

  useEffect(() => {
    if (enableColorInput && initialPaletteColor) setIsPaletteActive(true);
  }, []);

  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <>
      <Picker
        role="presentation"
        currentColor={currentColor}
        onClick={handleTogglePicker(true)}
        onKeyPress={handleTogglePicker(true)}
      >
        {!!isGlobal && <Lock src={lockIcon} alt="lock" />}
      </Picker>
      {isOpenedModalPicker && (
      <Modal
        isOpen={isOpenedModalPicker}
        onClose={handleTogglePicker(false)}
        afterClickingOutsideClose={!isOpenedPalette} // allow only, if color palette modal is closed
      >
        <Header>
          {t('Choose color')}:
          <MainColor
            role="presentation"
            onClick={onOpenPallet}
            style={{ backgroundColor: currentColor }}
          />
          {!globalColorIsDisabled && (
          <>
            <Toggle onChange={onToggle} value={!!isGlobal} />
            {t('Global color')}
          </>
          )}
        </Header>
        <Options>
          {colors.map((optionColor, i) => (
            <Option
              key={`color-option-${optionColor}`}
              isDisabled={isGlobal}
              isActive={color === i}
              optionColor={optionColor}
              onClick={handleChange(i)}
            />
          ))}
        </Options>
        <Row>
          <Column>
            <Button
              type="button"
              buttonTheme="primary"
              width={146}
              fontSize={FONT_SIZE}
              isDisabled={isGlobal}
              label={t('Adjust presets')}
              title={t('Adjust presets')}
              onClick={onOpenAdjustPresets}
            />
          </Column>
          <Column>
            <Button
              onClick={onCancel}
              buttonTheme="primary"
              width={BUTTON_WIDTH}
              color={COLORS.LIGHT_GRAY}
              fontSize={FONT_SIZE}
              type="button"
              label={t('Cancel')}
              title={t('Cancel')}
            />
            <Button
              onClick={onChooseColor}
              buttonTheme="primary"
              width={BUTTON_WIDTH}
              color={COLORS.GREEN}
              fontSize={FONT_SIZE}
              type="button"
              label={t('OK')}
              title={t('OK')}
            />
          </Column>
        </Row>
      </Modal>
      )}
      <ColorPalette
        isOpen={isOpenedPalette}
        id={`${id}-palette`}
        className="color-picker__pallet"
        color={paletteColor}
        handleChange={(palette) => setPaletteColor(palette?.hex)}
        handleAdd={onAddColor}
        handleClose={onCloseColorPallet}
      />
    </>
  );
};
