import React, { useCallback } from 'react';
import { Dragger } from './Dragger';
import { TrimmerOverLay } from './TrimmerOverlay';
import { TimeStamp } from './TimeStamp';

/**
 *
 * @param timeLimit
 * @param setStartPoint
 * @param setEndPoint
 * @param widthDurationRatio
 * @param containerWidth
 * @param startTime
 * @param endTime
 * @param currentDraggerTime
 * @param setCurrentDraggerTime
 * @param duration
 * @param onGetData
 * @param onPausePlayer
 * @param endPointPusher
 * @param convertPositionToTime
 * @returns {JSX.Element}
 * @constructor
 */

export const Trimmer = ({
  timeLimit,
  setStartPoint,
  setEndPoint,
  widthDurationRatio,
  containerWidth,
  startTime,
  endTime,
  currentDraggerTime,
  setCurrentDraggerTime,
  duration,
  onGetData,
  onPausePlayer,
  endPointPusher,
  convertPositionToTime,
}) => {
  const convertTimeToPosition = (time) => time * widthDurationRatio;
  const getTrimmerWidth = () => containerWidth - convertTimeToPosition(endTime);

  const keepInRange = useCallback((x) => {
    if (x < 0) return 0;
    if (x > containerWidth && containerWidth !== 0) return containerWidth;
    return x;
  }, [containerWidth]);

  const positionConverter = (pos) => convertPositionToTime(keepInRange(pos));

  const withinTimeLimit = useCallback((time) => {
    const timeTillEnd = duration - time;
    const currentRange = duration - startTime - timeTillEnd;
    return currentRange <= timeLimit;
  }, [timeLimit, startTime, duration]);

  const handleDragStart = useCallback((pos) => {
    let time = positionConverter(pos.x);
    if (time >= endTime) {
      time = startTime;
      onPausePlayer();
    } else if (time >= currentDraggerTime) {
      setCurrentDraggerTime(time);
    } else if (endTime <= currentDraggerTime) {
      setCurrentDraggerTime(endTime);
    }
    setStartPoint(time);
    if (endTime < duration) endPointPusher(time);
  }, [
    currentDraggerTime, duration, endPointPusher, endTime,
    onPausePlayer, setStartPoint, startTime, widthDurationRatio,
  ]);

  const handleDragCurrent = (pos) => {
    const time = positionConverter(pos.x);
    setCurrentDraggerTime(time);
  };

  const handleDragEnd = useCallback((pos) => {
    let time = positionConverter(pos.x);
    const currentTimeIsWithinLimit = withinTimeLimit(time);

    if (time <= startTime || !currentTimeIsWithinLimit) {
      time = endTime;
      onPausePlayer();
    } else if (time <= currentDraggerTime) {
      setCurrentDraggerTime(time);
    }

    setEndPoint(time);
  }, [
    currentDraggerTime, endTime,
    onPausePlayer, startTime, widthDurationRatio,
  ]);

  const handleDragStop = useCallback(() => {
    onGetData({ start: startTime, end: endTime });
  }, [onGetData, startTime, endTime]);

  return (
    <>
      <TrimmerOverLay left={0} width={convertTimeToPosition(startTime)} />
      <Dragger
        x={convertTimeToPosition(startTime)}
        onDrag={handleDragStart}
        onDragStop={handleDragStop}
        className="startpoint"
      >
        <TimeStamp time={startTime} />
      </Dragger>
      <Dragger
        x={convertTimeToPosition(currentDraggerTime)}
        onDrag={handleDragCurrent}
        className="trimmer_currentTime"
      >
        <TimeStamp noMicroSeconds time={currentDraggerTime} isCurrent />
      </Dragger>
      <Dragger
        x={convertTimeToPosition(endTime)}
        onDrag={handleDragEnd}
        onDragStop={handleDragStop}
        className="endpoint"
      >
        <TimeStamp time={endTime} />
      </Dragger>
      <TrimmerOverLay right={0} width={getTrimmerWidth()} />
    </>
  );
};
