import React from 'react';
import ScrollBar from 'react-custom-scrollbars';

import './style.sass';
import Input from 'components/forms/Input/Input';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { prop, propOr } from 'ramda';
import * as Yup from 'yup';
import { companySelectors, companyActions } from 'state/company';
import { Preloader } from 'ui-kit';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { ACTIONS, MODULES } from 'constants/permissions';
import { PermissionWrapper } from 'wrappers';
import { rules } from './rules';

export const BillingInformation = () => {
  const { t } = useTranslation(['account', 'common', 'forms']);
  const dispatch = useDispatch();
  const company = useSelector(companySelectors.getCompany);
  const isPendingUpdate = useSelector(companySelectors.getCompanyUpdateRequestPending);

  const {
    values, errors, handleChange, handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      billing_name: propOr('', 'billing_name', company),
      billing_company_name: propOr('', 'billing_company_name', company),
      billing_address_1: propOr('', 'billing_address_1', company),
      billing_address_2: propOr('', 'billing_address_2', company),
      billing_city: propOr('', 'billing_city', company),
      billing_zip: propOr('', 'billing_zip', company),
      billing_country: propOr('', 'billing_country', company),
      billing_tax_id: propOr('', 'billing_tax_id', company),
    },
    onSubmit: (formValues) => {
      dispatch(companyActions.updateCompanyRequest(formValues));
    },
    validationSchema: Yup.object().shape(rules),
  });
  return (
    <PermissionWrapper action={ACTIONS.READ} module={MODULES.BILLINGS}>
      <div className="billing-information">
        <Preloader
          type={PRELOADER_TYPES.CONTAINER}
          size={PRELOADER_SIZES.MIDDLE}
          isLoading={isPendingUpdate}
        >
          <form className="billing-information__form" onSubmit={handleSubmit}>
            <div className="billing-information__left">
              <Input
                label={t('account:Billing name:')}
                placeholder={t('Company name for billing (if billing is for a private person, enter the name)')}
                value={propOr('', 'billing_name', values)}
                error={prop('billing_name', errors)}
                onChange={handleChange}
                name="billing_name"
              />
              <Input
                label={t('account:Tax ID (if applicable):')}
                placeholder={t('xxxxxxx-xxxxx')}
                value={prop('billing_tax_id', values)}
                error={prop('billing_tax_id', errors)}
                onChange={handleChange}
                name="billing_tax_id"
              />
              <Input
                label={t('account:Company name:')}
                placeholder={t('Company name for billing')}
                value={prop('billing_company_name', values)}
                error={prop('billing_company_name', errors)}
                onChange={handleChange}
                name="billing_company_name"
                className="mb-0"
              />
              <div className="billing-information__address-group">
                <Input
                  label={t('account:Billig address:')}
                  value={prop('billing_address_1', values)}
                  error={prop('billing_address_1', errors)}
                  name="billing_address_1"
                  onChange={handleChange}
                  placeholder="Street Address 1"
                />
                <Input
                  placeholder={t('Street Address 2')}
                  value={prop('billing_address_2', values)}
                  error={prop('billing_address_2', errors)}
                  onChange={handleChange}
                  name="billing_address_2"
                />
                <Input
                  placeholder={t('City')}
                  value={prop('billing_city', values)}
                  error={prop('billing_city', errors)}
                  onChange={handleChange}
                  name="billing_city"
                />
                <Input
                  placeholder={t('ZIP/Postal code')}
                  value={prop('billing_zip', values)}
                  error={prop('billing_zip', errors)}
                  onChange={handleChange}
                  name="billing_zip"
                />
                <Input
                  placeholder={t('Country')}
                  value={prop('billing_country', values)}
                  error={prop('billing_country', errors)}
                  onChange={handleChange}
                  name="billing_country"
                />
              </div>
            </div>
            <div className="billing-information__right">
              <div className="billing-information__right-col">
                <button
                  type="button"
                  className="button billing-information__add-payment"
                >
                  Add a payment method
                </button>
                <div className="current-payment">
                  <ul>
                    <li>
                      <span>Current method</span>
                      {' '}
                      <span>Jakkoo`s visa</span>
                    </li>
                    <li>
                      <span />
                      <span>VISA</span>
                    </li>
                    <li>
                      <span />
                      <span>xxxx xxxx xxxx 1234</span>
                    </li>
                    <li>
                      <span />
                      <span>04/22</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="billing-information__right-col">

                <button
                  type="button"
                  className="button button--grey billing-information__download-invoices"
                >
                  Download all invoices
                </button>
                <div className="invoices-list">
                  <ScrollBar>
                    <ul>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                      <li><span>04/01/2020 - Invoice #1234 - $100 (PENDING)</span></li>
                    </ul>
                  </ScrollBar>
                </div>
              </div>
            </div>
            <div className="account-information__buttons-row">
              <button type="submit" className="button">{t('Save')}</button>
              <Link to="/" className="button button--grey">{t('Close')}</Link>
            </div>
          </form>
        </Preloader>
      </div>
    </PermissionWrapper>
  );
};
