import {
  last, values, includes, forEach, reduce, addIndex, compose, propOr,
  findIndex, pathEq, pathOr, prop, propEq, path, find, keys, memoizeWith, identity, test,
} from 'ramda';
import { animationActions, animationSelectors, animationTypes } from 'state/animation';
import { animationsSelectors } from 'state/animations';
import { draftsTypes } from 'state/drafts';
import editorAction from 'state/editor/types';
import { editorSelectors, editorActions } from 'state/editor';
import { IMAGE_TYPES } from 'constants/animationsPath';
import { findValueAndIndexByPropValue, getImageSizeByUrl } from '../../helpers';
import { animationInstance } from '../../helpers/animationInstance';

const SKIP_IMAGE_REF = 'image_';

export const getDraftJsonSuccessMiddleware = () => (next) => (action) => {
  // We should clear asset.u after pulling draft,
  // that would Lottie don`t start loading images what should be replace by images what saved to the draft
  // Also we should check here that the image has UI for (If images has not UI the images was not saved as files connected to the draft)]
  // So if Layes[LayerImage].nm not consist word `IMAGE` we suppose that the image has not UI for editing.

  if (propEq('type', draftsTypes.GET_SELECTED_DRAFT_DATA_SUCCESS, action)) {
    const { payload: { data: { data } } } = action;
    const { assets, layers } = data;
    const accumData = data;

    assets.forEach((asset) => {
      const isExistUI = compose(
        test(/IMAGE/gim),
        propOr('', 'nm'),
        find(
          propEq('refId', asset.id),
        ),
      )(layers);

      if (isExistUI) {
        const refId = pathOr(null, ['layers', 0, 'refId'], asset);
        if (refId) {
          const index = findIndex(propEq('id', refId), data.assets);
          accumData.assets[index].u = '';
          accumData.assets[index].p = '';
        }
      }
    });

    next({
      ...action,
      data: {
        data: accumData,
      },
    });
  } else {
    next(action);
  }
};

const preparingImage = ({
  getState, compAssetIndex, assetLayerIndex, imageAssetIndex, dispatch, assets, layers,
}) => memoizeWith(identity, ({ width, height, refToComp }) => {
  const data = animationSelectors.animationDataSelector(getState());
  const animationData = { ...data };

  try {
    const originalImageLayer = animationData.layers[assetLayerIndex];

    originalImageLayer.w = width;
    originalImageLayer.h = height;
    originalImageLayer.ks.a.k = [width, height, 0];
  } catch (e) {
    console.error(e);
  }
  try {
    const originalImageAsset = animationData.assets[imageAssetIndex];

    originalImageAsset.w = width;
    originalImageAsset.h = height;
  } catch (e) {
    console.error(e);
  }

  try {
    const originalCompAssetLayer = animationData.assets[compAssetIndex].layers[0];

    originalCompAssetLayer.w = width;
    originalCompAssetLayer.w = height;
    originalCompAssetLayer.ks.a.k = [width / 2, height / 2, 0];
    originalCompAssetLayer.ks.p.k = [width / 2, height / 2, 0];
  } catch (e) {
    console.error(e);
  }
  try {
    const connectedCompAssetsList = addIndex(reduce)(
      (accum, item, index) => (pathEq(['layers', 0, 'refId'], refToComp, item)
        ? [...accum, index] : accum), [], assets,
    );
    const rewrite = (ind) => {
      const connectedCompIndex = connectedCompAssetsList[ind];
      if (connectedCompIndex === undefined) return false;
      const refConnectedCompId = path([connectedCompIndex, 'id'], assets);

      const connectedImagesLayersList = addIndex(reduce)(
        (accum, item, index) => (pathEq(['refId'], refConnectedCompId, item)
          ? [...accum, index] : accum), [], layers,
      );

      forEach((connectedImageLayerIndex) => {
        const connectedImageLayer = animationData.layers[connectedImageLayerIndex];

        connectedImageLayer.w = width;
        connectedImageLayer.h = height;
        connectedImageLayer.ks.a.k = [width, height, 0];
        connectedImageLayer.ks.p.k = [width, height, 0];
      }, connectedImagesLayersList);
      const connectedCompAssetLayer = animationData.assets[connectedCompIndex].layers[0];
      requestAnimationFrame(() => {
        connectedCompAssetLayer.w = width;
        connectedCompAssetLayer.h = height;
        connectedCompAssetLayer.ks.a.k = [width / 2, height / 2, 0];
        connectedCompAssetLayer.ks.p.k = [width / 2, height / 2, 0];
      });
      rewrite(ind + 1);
    };
    rewrite(0);
  } catch (e) {
    console.log('Error');
    console.error(e);
  }
  requestAnimationFrame(() => {
    dispatch(animationActions.setDeepAnimationData(animationData));
  });
});

const adaptImageWorker = ({
  getState, refId, dataUrl, dispatch, width, height,
}) => {
  const store = getState();
  const {
    animationData, selectedAnimation, selectedVersion,
  } = {
    animationData: animationSelectors.animationDataSelector(store),
    selectedAnimation: animationsSelectors.getSelectedAnimation(store),
    selectedVersion: editorSelectors.getVersionEditorOrDraft(store),
  };
  const {
    assets, layers, w: maxWidth, h: maxHeight,
  } = animationData;
  if (!layers) return false;
  const imageLayer = last(
    findValueAndIndexByPropValue('nm', `IMAGE LAYER ${refId.split(/\D[^0-9]*/gm)[1]}`,
      layers),
  );
  if (!imageLayer) return false;
  const { refId: refToComp } = imageLayer;
  const refToAsset = path(['layers', 0, 'refId'], find(propEq('id', refToComp), assets));
  const assetLayerIndex = findIndex(pathEq(['refId'], refToComp), layers);
  queueMicrotask(() => {
    if (assetLayerIndex > -1 && refToAsset) {
      const assetLayer = layers[assetLayerIndex];
      const templateImageLayer = last(findValueAndIndexByPropValue('nm',
        'TumplateImageLayer', prop('ef', assetLayer)));
      const layerStyle = last(findValueAndIndexByPropValue('nm', 'Layer style',
        prop('ef', templateImageLayer)));
      const [compAssetIndex] = findValueAndIndexByPropValue('id',
        assetLayer.refId, assets);
      const [imageAssetIndex] = findValueAndIndexByPropValue('id',
        refToAsset, assets);

      const layerStyleValue = pathOr(0, ['v', 'k'], layerStyle);
      const memoVars = { selectedAnimation, selectedVersion };
      // if we have image type LOGO or DYNAMIC, we need to change width and height depends on the ratio
      // if we have image type BACKGROUND, we also need to change width and height, but sometimes we don't have such dimensions, so we use max dimension in such cases
      // todo: these functionalities will be refactored in the future, but it is ok for the quick hotfix
      if (layerStyleValue === IMAGE_TYPES.LOGO || layerStyleValue === IMAGE_TYPES.DYNAMIC) {
        if (dataUrl) {
          getImageSizeByUrl(dataUrl, (data) => preparingImage({
            getState,
            compAssetIndex,
            assetLayerIndex,
            imageAssetIndex,
            dataUrl,
            dispatch,
            refToAsset,
            assets,
            layers,
            layerStyleValue,
          })({ ...data, refToComp, memoVars }));
        } else if (width && height) {
          preparingImage({
            getState,
            compAssetIndex,
            assetLayerIndex,
            imageAssetIndex,
            dataUrl,
            dispatch,
            refToAsset,
            assets,
            refToComp,
            layers,
          })({
            width, height, refToComp, memoVars,
          });
        }
      } else if (layerStyleValue === IMAGE_TYPES.BACKGROUND) {
        preparingImage({
          getState,
          compAssetIndex,
          assetLayerIndex,
          imageAssetIndex,
          dataUrl,
          dispatch,
          refToAsset,
          assets,
          refToComp,
          layers,
        })({
          width: width || maxWidth,
          height: height || maxHeight,
          refToComp,
          memoVars,
        });
      }
    }
  });
};

export const changeImageDataMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  if (propEq('type', editorAction.SET_IMAGE_DATA, action)) {
    // We have four images types: 1 - background, 2 - foreground, 3 - logo, 4 - dynamic.
    //
    // 1) We should normalize `logo` and `dynamic` layers config in JSON by original images dimension
    // 2) Logo and dynamic layers can have connected layers (these layers have connected comps and compAssets,
    // we should also update it by original images dimension)
    //
    const { payload } = action;
    const refId = last(keys(payload));
    const { options: { width, height } } = last(values(payload));
    const animationData = animationSelectors.animationDataSelector(getState());
    const dataUrl = compose(path([refId, 'u']), prop('assets'))(animationData);
    if (dataUrl) {
      adaptImageWorker({
        getState, refId, dataUrl, dispatch, width, height,
      });
    }
  }
  next(action);
};

export const changeAssetUrlMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  if (propEq('type', animationTypes.CHANGE_ASSET_URL, action)) {
    const { payload: [refId, dataUrl] } = action;
    const animationData = animationSelectors.animationDataSelector(getState());
    const imageRef = compose(
      ({ nm }) => `image_${nm.split(/\D[^0-9]*/gm)[1]}`,
      (compId) => find(propEq('refId', compId), prop('layers', animationData)),
      prop('id'),
      find(pathEq(['layers', 0, 'refId'], refId)),
      prop('assets'),
    )(animationData);
    // todo: sometimes imageref equals to image_, so we need to skip this value, it is hotfix, but we need to check this issue more in detail in the future, maybe it is issue with the cropper
    if (SKIP_IMAGE_REF !== imageRef) {
      next(editorActions.setImageRefUrl({ imageRef, value: dataUrl }));
      adaptImageWorker({
        getState,
        refId: imageRef,
        dataUrl,
        dispatch,
      });
    }
  }
  next(action);
};

export const setAnimationDataMiddleware = ({ getState }) => (next) => (action) => {
  next(action);
  if (includes(action.type, [animationTypes.SET_ANIMATION_DATA,
    animationTypes.SET_DEEP_ANIMATION_DATA, animationTypes.SET_LENGTH, animationTypes.CHANGE_ASSET_URL,
    animationTypes.CHANGE_TINT_AMOUNT, animationTypes.CHANGE_TINT_COLOR, animationTypes.SET_CUSTOM_LENGTH_IN_ANIMATION])) {
    const data = animationSelectors.animationDataSelector(getState());
    animationInstance.set(data);
  }
};
