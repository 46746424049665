export const SORTING_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const API_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};

export const DEFAULT_INDEX = -1;

export const FILE_SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
