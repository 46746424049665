import React from 'react';
import { useTranslation } from 'react-i18next';
import { editIcon } from 'assets/images';
import { Button, Icon } from 'ui-kit';
import { COLORS } from 'constants/styles';
import {
  Body, Box, ButtonText, Header, IconButton, Image, Title,
} from './ShareLinksModal.styled';
import { BUTTON_SIZES, ICON_DIMENSION } from './constants';

/**
 *
 * @param onClick {function}
 * @param onCloseModal {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const SuccessSharedLinks = ({ onClick, onCloseModal }) => {
  const { t } = useTranslation(['account', 'common', 'forms']);

  return (
    <>
      <Header justifyContent="flex-start">
        <Image src={editIcon} alt="Logo" />
        <Title ml="10px">{t('Link was created successfully!')}</Title>
      </Header>
      <Body flexDirection="column">
        <Box width="100%" justifyContent="center" mb={20}>
          <IconButton onClick={onClick}>
            <ButtonText>{t('Copy Shareable link')}</ButtonText>
            <Icon name="link" height={ICON_DIMENSION} width={ICON_DIMENSION} />
          </IconButton>
        </Box>
        <Box justifyContent="flex-end">
          <Button
            width={BUTTON_SIZES.WIDTH}
            height={BUTTON_SIZES.HEIGHT}
            label={t('Close')}
            color={COLORS.RED}
            onClick={onCloseModal}
          />
        </Box>
      </Body>
    </>
  );
};
