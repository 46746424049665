import * as animationActions from './actions';
import * as animationSelectors from './selectors';
import * as animationOperations from './operations';
import animationTypes from './types';
import reducer from './reducers';

export {
  animationActions,
  animationTypes,
  animationSelectors,
  animationOperations,
};

export default reducer;
