import { useCallback, useState } from 'react';
import { SORTING_ORDER } from 'constants/common';

const DEFAULT_SORTING = 'created_at';

/**
 *
 * @returns {{handleSort: ((function(*): void)|*), orderBy: string, sortBy: string}}
 */

export const useSorting = () => {
  const [sortBy, setSortBy] = useState(DEFAULT_SORTING);
  const [orderBy, setOrderBy] = useState(SORTING_ORDER.DESC);

  const handleSort = useCallback((name) => {
    setOrderBy((prev) => (prev === SORTING_ORDER.DESC ? SORTING_ORDER.ASC : SORTING_ORDER.DESC));
    setSortBy(name);
  }, [setOrderBy, setSortBy]);

  return {
    sortBy,
    orderBy,
    handleSort,
  };
};
