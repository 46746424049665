import { createSelector } from 'reselect';
import {
  pathOr, identity, all, equals, unapply, compose, path,
} from 'ramda';

import { getFileUrl } from 'utils/helpers/requestHelpers';

const storeName = 'company';

export const getCompany = createSelector(
  pathOr({}, [storeName, 'company']),
  identity,
);

export const getCompanyPending = createSelector(
  pathOr(false, ['pending', 'getCompanyRequest']),
  pathOr(false, ['pending', 'updateCompanyRequest']),
  pathOr(false, ['pending', 'deleteCompanyRequest']),
  compose(
    all(equals(true)),
    unapply(identity),
  ),
);

export const getCompanyLogoUrl = createSelector(
  getCompany,
  compose((id) => (id ? getFileUrl({ fileUrl: `files/${id}` }) : ''), pathOr('', ['logo', 'id'])),
);

export const getCompanyUpdateRequestPending = createSelector(
  pathOr(false, ['pending', 'updateCompanyRequest']),
  identity,
);

export const getCompanyIsEnabledBrandColors = createSelector(
  pathOr(0, [storeName, 'company', 'enable_brand_colors']),
  identity,
);

export const getCompanyIsEnabledBrandFonts = createSelector(
  pathOr(0, [storeName, 'company', 'enable_brand_fonts']),
  identity,
);

export const getCompanyDisabledVideoTypes = createSelector(
  pathOr([], [storeName, 'company', 'disabledVideoTypes']),
  identity,
);

export const getNotifications = createSelector(
  pathOr([], [storeName, 'notifications']),
  identity,
);

export const selectCompanyImageType = createSelector(
  path([storeName, 'company', 'image_type']),
  identity,
);

export const selectChangingAnimationStructurePermission = createSelector(
  pathOr(false, [storeName, 'company', 'can_change_animation_structure']),
  identity,
);
