import React from 'react';
import PropTypes from 'prop-types';
import { TrimmerOverlay } from './TrimmerOverlay.styled';

/**
 *
 * @param event
 */

function handleMouseMove(event) {
  event.stopPropagation();
}

/**
 *
 * @param width {number}
 * @param right {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const TrimmerOverLay = ({ width, right }) => (
  <TrimmerOverlay
    onMouseDown={handleMouseMove}
    onTouchStart={handleMouseMove}
    role="button"
    tabIndex={0}
    style={{
      width: `${width}px`,
    }}
    right={right}
  />
);

TrimmerOverLay.propTypes = {
  width: PropTypes.number.isRequired,
  right: PropTypes.number.isRequired,
};
