import { path, pathOr } from 'ramda';
import {
  CONTROLS_PATH, CONTROLS_PROP, DATA_CONST, DATA_PATH, DATA_PROP,
} from 'constants/animationsPath';
import { MAIN_COLORS } from 'constants/editor';
import {
  HIDE_EVERYWHERE_REGEXP,
  HIDE_EVERYWHERE_WITH_SPACES_REGEXP,
  HIDE_REGEXP,
  HIDE_WITH_SPACES_REGEXP,
} from 'constants/regexp';
import {
  findIndexAndValueByProp, getEditFrameFromConfigStr, searchSwitcherValueByBegin, tryOrDef,
} from '../helpers';

export const decRGB2Hex = ([r, g, b] = [1, 1, 1]) => {
  const colors = [r, g, b].map((n) => {
    const hex = Math.round(n * 255).toString(16);
    return hex.length < 2 ? `0${hex}` : hex;
  });
  return `#${colors.join('')}`;
};

const hexToRgb = (hex) => {
  const bigint = parseInt(hex, 16);
  // eslint-disable-next-line no-bitwise
  const r = (bigint >> 16) & 255;
  // eslint-disable-next-line no-bitwise
  const g = (bigint >> 8) & 255;
  // eslint-disable-next-line no-bitwise
  const b = bigint & 255;

  return { r, g, b };
};

const hexToNumbers = (hex) => {
  const rgb = hexToRgb(hex);
  return [(rgb.r / 255), (rgb.g / 255), (rgb.b / 255)];
};

export const convertHexToColorNumbers = (val) => {
  let color = val.slice(1, val.length);
  if (color.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  return [...hexToNumbers(color)];
};

/**
 *
 * @param from {number}
 * @param to {number}
 * @param data {object}
 * @returns {*}
 */

export const getColorsByIndexes = ({ from, to, data }) => {
  const effectArray = data[DATA_PROP.EFFECT];
  return effectArray
    .slice(from, to)
    .map((item) => decRGB2Hex(path(DATA_PATH.COLOR_VALUE, item)))
    .filter((item, index) => !(index > 2 && item === '#000000'));
};

/**
 *
 * @param masterDataLayer {object}
 * @returns {*[]}
 */

export const getColorsFromMasterLayer = (masterDataLayer) => {
  return MAIN_COLORS.reduce((accum, layerName) => {
    const [layerIndex, layer] = findIndexAndValueByProp({
      name: DATA_PROP.NAME,
      value: layerName,
      list: masterDataLayer[DATA_PROP.EFFECT],
    });

    const config = pathOr(null, DATA_PATH.COLOR_CONFIG, layer);
    if (!config) {
      return accum;
    }
    if (config && (!config.includes(CONTROLS_PROP.UI_SWITCHER_NAME)
      || new RegExp(HIDE_REGEXP).test(config)
      || new RegExp(HIDE_WITH_SPACES_REGEXP).test(config)
      || new RegExp(HIDE_EVERYWHERE_REGEXP).test(config)
      || new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(config))) {
      return accum;
    }
    const color = path(CONTROLS_PATH.VALUE, layer);
    let normalizeName = null;
    let editFrame = null;
    if (config) {
      normalizeName = tryOrDef({
        def: null,
        func: () => searchSwitcherValueByBegin(DATA_CONST.SWITCHER_NAME, config),
      });
      editFrame = tryOrDef({
        def: null,
        func: () => getEditFrameFromConfigStr(config),
      });
    }
    return ([...accum,
      {
        colorOption: true,
        name: normalizeName || layerName,
        index: layerIndex,
        editFrame,
        selectedControlValue: color,
        colorStatus: layer.nm,
      },
    ]);
  }, []);
};
