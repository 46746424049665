import styled from 'styled-components';

export const Tab = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-bottom: 22px;
  z-index: 1;
`;

export const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  @media(${({ theme }) => theme.devices.MD}) {
    border-radius: 23px;
  }
`;

export const LabelWrapper = styled.div`
  margin: 4px 6px 4px 6px;
  &:hover {
    opacity: .8;
  }
`;

export const GroupListItem = styled.div`
  position: absolute;
  top: -1000vh;
  @media(${({ theme }) => theme.devices.XS}) {
    max-width: 280px;
  }
  ${({ isVisible, theme }) => isVisible && `
    position: relative;
    top: 0;
    @media(${() => theme.devices.XS}) {
      max-width: 280px;
    }
  `};
`;
