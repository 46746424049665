import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Button, Preloader } from 'ui-kit';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { animationActions, animationSelectors } from 'state/animation';
import { Wrapper, CsvUpload } from './CsvTool.styled';

const PRELOADER_DURATION = 800;
const CSV_ACCEPT_TYPE = '.csv';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const CsvTool = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const form = useFormContext();

  const [version, projectName, outputCommand, outputUrl] = form.watch(['version', 'projectName', 'outputCommand', 'outputUrl']);

  const isPendingUploadingCsv = useSelector(animationSelectors.getPendingUploadingCsv);
  const isPendingDownloadingCsv = useSelector(animationSelectors.getPendingDownloadingCsv);

  const handleDownloadCsv = () => dispatch(animationActions.downloadCsv({
    version: version.toLowerCase(), projectName, outputCommand, outputUrl,
  }));

  const handleUploadCsv = (e) => {
    const formData = new FormData();

    formData.append('file', e.target.files[0]);
    e.target.value = '';
    dispatch(animationActions.uploadAnimationCsvRequest(formData));
  };

  return (
    <Preloader
      type={PRELOADER_TYPES.CERTAIN}
      size={PRELOADER_SIZES.SMALL}
      minDuration={PRELOADER_DURATION}
      isLoading={isPendingDownloadingCsv || isPendingUploadingCsv}
      width="auto !important"
      height="auto !important"
    >
      <Wrapper>
        <Button
          title={t('Download CSV')}
          buttonTheme="secondary"
          height={32}
          width={75}
          label={t('Download')}
          onClick={handleDownloadCsv}
        />
        <input
          style={{ display: 'none' }}
          type="file"
          onChange={handleUploadCsv}
          id="uploadCsvField"
          accept={CSV_ACCEPT_TYPE}
        />
        <CsvUpload
          htmlFor="uploadCsvField"
          title={t('Upload CSV')}
        >
          {t('Upload')}
        </CsvUpload>
      </Wrapper>
    </Preloader>
  );
};
