import styled from 'styled-components';

export const Root = styled.div`
  min-width: 55px;
  max-width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.MAIN};
  color: ${({ theme }) => theme.colors.WHITE};
  white-space: nowrap;
`;

export const Text = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.WHITE};
  white-space: nowrap;
`;
