import styled from 'styled-components';
import { color } from 'styled-system';

const PRIMARY = 'primary';
const SECONDARY = 'secondary';

export const Input = styled.input`
  display: none;
`;

export const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: initial;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.LIGHT_GRAY};
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
  ${color};
  
  ${({ radioTheme }) => radioTheme === PRIMARY && `
    min-width: 32px;
    height: 31px;
    font-size: 14px;
    border-radius: 100%;
  `}
  ${({ radioTheme }) => radioTheme === SECONDARY && `
    min-width: 28px;
    height: 28px;
    font-size: 12px;
    border-radius: 14px;
    padding: 0 20px;
  `}
  
  ${({ isChecked, theme }) => isChecked && `background: ${theme.colors.MAIN};`}
  ${({ isDisabled, theme }) => isDisabled && `background: repeating-linear-gradient(-50deg, ${theme.colors.LIGHT_GRAY} -1px, ${theme.colors.LIGHT_GRAY} 1px, #e4e4e4 2px, #e4e4e4 5px);`}
  &:hover {
    opacity: .8;
  }
`;
