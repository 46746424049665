import {
  fork, put, select, take,
} from 'redux-saga/effects';
import sagasManager from 'utils/sagasManager';

import * as companyActions from './actions';
import companyTypes from './types';
import * as fontsActions from '../fonts/actions';
import * as colorsActions from '../colors/actions';
import { getNotifications } from './selectors';
import { updateNotifications } from './actions';

function* setCompanyIsEnabledBrandColorsFlow() {
  while (true) {
    const { payload: isEnable } = yield take(companyTypes.SET_COMPANY_IS_ENABLED_BRAND_COLORS);

    yield put(companyActions.getCompanyRequest());

    const { payload: { data: { model } } } = yield take(companyTypes.GET_COMPANY_REQUEST_SUCCESS);

    yield put(companyActions.updateCompanyRequest({ ...model, enable_brand_colors: Number(isEnable) }));
    yield put(colorsActions.setColorsFreshStatus(false));
  }
}

function* setCompanyIsEnabledBrandFontsFlow() {
  while (true) {
    const { payload: isEnable } = yield take(companyTypes.SET_COMPANY_IS_ENABLED_BRAND_FONTS);

    yield put(companyActions.getCompanyRequest());

    const { payload: { data: { model } } } = yield take(companyTypes.GET_COMPANY_REQUEST_SUCCESS);

    yield put(companyActions.updateCompanyRequest({ ...model, enable_brand_fonts: Number(isEnable) }));
    yield put(fontsActions.setFontsFreshStatus(false));
  }
}

function* updateNotificationsFlow() {
  while (true) {
    const { payload } = yield take(companyTypes.DELETE_NOTIFICATION_SUCCESS);
    const notifications = yield select(getNotifications);

    const updatedNotifications = [...notifications.filter((notification) => notification.id !== payload.data.model.notification_id)];
    yield put(updateNotifications(updatedNotifications));
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(setCompanyIsEnabledBrandColorsFlow);
  yield fork(setCompanyIsEnabledBrandFontsFlow);
  yield fork(updateNotificationsFlow);
});
