import {
  saveTextLayersMiddleware,
  saveTextGroupsMiddleware,
  saveControlsMiddleware,
  saveVisibleTextGroupIdsMiddleware,
} from './editor';
import {
  changeAssetUrlMiddleware,
  getDraftJsonSuccessMiddleware,
  setAnimationDataMiddleware,
  changeImageDataMiddleware,
} from './animation';

import { socketMiddleware } from './socket';

const middlewares = [
  setAnimationDataMiddleware,
  saveTextLayersMiddleware,
  saveTextGroupsMiddleware,
  saveControlsMiddleware,
  socketMiddleware,
  changeAssetUrlMiddleware,
  getDraftJsonSuccessMiddleware,
  changeImageDataMiddleware,
  saveVisibleTextGroupIdsMiddleware,
];

export default middlewares;
