import React, { useRef } from 'react';
import {
  NavLink, Link, Outlet,
} from 'react-router-dom';
import ScrollBars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { companySelectors } from 'state/company';
import { ACTIONS, MODULES } from 'constants/permissions';
import { PermissionWrapper } from 'wrappers';
import * as data from '../../../package.json';
import './style.sass';

export const AccountLayout = ({ children }) => {
  const { t } = useTranslation('account');
  const company = useSelector(companySelectors.getCompany);
  const ref = useRef(null);

  return (
    <div className="modal-page">
      <div ref={ref} className="account-information">
        <div className="account-information__left-col">
          <h2 className="account-information__title">{t('Account Information')}</h2>
          <nav className="account-information__nav">
            <ul className="account-information__nav-list">
              <li>
                <NavLink to="/account/overview/" activeClassName="active">{t('Overview')}</NavLink>
              </li>
              <li>
                <PermissionWrapper action={ACTIONS.READ} module={MODULES.DESIGN_PRESETS}>
                  {!!company.allow_design_presets && (
                    <NavLink
                      to="/account/design-presets/"
                      activeClassName="active"
                    >
                      {t('Design presets')}
                    </NavLink>
                  )}
                </PermissionWrapper>
              </li>

              <li>
                <PermissionWrapper action={ACTIONS.READ} module={MODULES.BILLINGS}>
                  <NavLink
                    to="/account/billing-information/"
                    activeClassName="active"
                  >
                    {t('Billing information')}
                  </NavLink>
                </PermissionWrapper>
              </li>
              <li>
                <PermissionWrapper action={ACTIONS.READ} module={MODULES.INTEGRATIONS}>
                  <NavLink
                    to="/account/integrations/"
                    activeClassName="active"
                  >
                    {t('Integrations')}
                  </NavLink>
                </PermissionWrapper>
              </li>
            </ul>
          </nav>
          <div className="account-information__changelog">
            <Link className="account-information__changelog-link" to="/account/changelog/">{`Version ${data.version}`}</Link>
          </div>
        </div>
        <div className="account-information__right-col">
          <ScrollBars>
            <div className="account-information__right-col-wrap">
              {children}
              <Outlet />
            </div>
          </ScrollBars>
        </div>
      </div>
    </div>
  );
};
