const API = 'api';

export const ROUTES = {
  VIDEOS: {
    ROOT: `${API}/videos`,
    DOWNLOAD: `${API}/videos/download`,
  },
  AUTH: {
    LOGIN: `${API}/auth/login`,
    LOGIN_GOOGLE: '/authenticated/google',
    CHECK_CODE_FORGOT_PASSWORD: `${API}/forgot-password/check-code`,
    RESET_PASSWORD: `${API}/forgot-password/change-password`,
    FORGOT_PASSWORD: `${API}/forgot-password`,
    REGISTER: `${API}/auth/register`,
    REGISTER_SECOND_STEP: `${API}/auth/register-step-2`,
    CHECK_ACTIVATION_CODE: `${API}/auth/check-activation-code`,
  },
  WEBHOOKS: {
    ROOT: `${API}/video-webhooks`,
    TEST: `${API}/video-webhooks/test`,
    PARAMS: `${API}/video-webhooks/params`,
  },
  LINKS: {
    ROOT: `${API}/video-links`,
    VIEW: `${API}/video-links/view`,
  },
  TEMP_FILES: {
    ROOT: `${API}/temp-files`,
    URL: `${API}/temp-files-from-url`,
    FILE: 'uploads/temp-files',
  },
  ANIMATIONS: {
    ROOT: `${API}/animations`,
    ALL: `${API}/animations/all`,
  },
};
