import React from 'react';
import { Head, HeadCell, Row } from 'ui-kit';
import { SelectingButton } from '../SelectingButton';

const DEFAULT_PADDING = '12px 0';

const DEFAULT_STYLES = {
  textAlign: 'center',
  padding: DEFAULT_PADDING,
};

const HEAD_LIST = [
  {
    id: 'select',
    render: () => <SelectingButton />,
    width: 75,
    ...DEFAULT_STYLES,
  },
  {
    id: 'file_name',
    label: 'File name',
    name: 'title',
    textAlign: 'left',
    padding: '16px 0 16px 5px',
    width: 254,
  },
  {
    id: 'favorite',
    label: 'Favorite',
    width: 70,
    ...DEFAULT_STYLES,
  },
  {
    id: 'approved',
    label: 'Approved',
    width: 70,
    ...DEFAULT_STYLES,
  },
  {
    id: 'version',
    label: 'Version',
    name: 'animation_ratio',
    width: 60,
    ...DEFAULT_STYLES,
  },
  {
    id: 'length',
    label: 'Length',
    name: 'duration',
    width: 60,
    ...DEFAULT_STYLES,
  },
  {
    id: 'format',
    label: 'Format',
    width: 60,
    ...DEFAULT_STYLES,
  },
  {
    id: 'audio',
    label: 'Audio',
    width: 60,
    ...DEFAULT_STYLES,
  },
  {
    id: 'created_at',
    label: 'Created',
    name: 'created_at',
    textAlign: 'left',
    padding: DEFAULT_PADDING,
    width: 80,
  },
  {
    id: 'editor',
    label: 'Open in Editor',
    width: 90,
    ...DEFAULT_STYLES,
  },
  {
    id: 'share',
    label: 'Share',
    width: 60,
    ...DEFAULT_STYLES,
  },
  {
    id: 'download',
    label: 'Download',
    width: 70,
    ...DEFAULT_STYLES,
  },
  {
    id: 'remove',
    label: '',
    width: 50,
    ...DEFAULT_STYLES,
  },
];

/**
 *
 * @param orderBy {"desc" | "asc"}
 * @param handleSort {function}
 * @param sortBy {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const HeadTable = ({ orderBy, handleSort, sortBy }) => {
  return (
    <Head>
      <Row isHead isBoxShadow>
        {HEAD_LIST.map(({
          id, label, name, render, ...props
        }) => (
          <HeadCell
            key={id}
            order={orderBy}
            onSort={handleSort}
            name={name}
            isLabel={name === sortBy}
            {...props}
          >{render ? render() : label}
          </HeadCell>
        ))}
      </Row>
    </Head>
  );
};
