import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import { Modal } from '../Modal';

export const PaletteModal = styled(Modal)`
  width: 100%;
  .modal-window__container {
    width: 260px;
    padding: 18px 12px 8px;
  }
`;

export const Picker = styled(ChromePicker)`
  width: 100% !important;
  box-shadow: none !important;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 140px;
  margin: 0 auto;
`;
