import { handleActions } from 'redux-actions';
import { mergeDeep, mergeIn } from 'utils/helpers/ramdaStateHelpers';
import {
  compose, objOf, prop, adjust, findIndex, propEq, mergeRight, over, set, view, pathEq, isEmpty, propOr,
} from 'ramda';
import {
  layer, tintAmount, tintColor, chosenColor, dataLayerEffects, animationLength, animationOP,
  getAllLengthOptions, animationFramerate, getAnimationPayload,
} from 'utils/animationHelpers';
import types from './types';

const reducer = handleActions(
  {
    [types.SET_CSV_STATUS]: mergeIn(compose(objOf('csvStatus'), prop('payload'))),
    [types.SET_ANIMATION_DATA]: mergeIn(compose(prop('payload'))),
    [types.SET_DEEP_ANIMATION_DATA]: mergeIn(compose(prop('payload'))),
    [types.ANIMATION_HARD_RESET]: () => ({
    }),
    [types.SET_ANIMATION_FONTS]: mergeDeep(compose(objOf('fonts'), prop('payload'))),
    [types.CHANGE_ASSET_URL]: mergeIn(({ payload: [refId, img] }, anim) => {
      const assets = propOr([], 'assets', anim);
      const assetIndex = findIndex(propEq('id', refId), assets);
      if (assetIndex === -1) return assets;

      return compose(
        objOf('assets'),
        adjust(assetIndex, (asset) => mergeRight(asset, { u: img, p: '' })),
      )(assets);
    }),
    [types.CHANGE_TINT_AMOUNT]: mergeIn(({ payload: [layerName, tint] }, anim) => over(
      layer(layerName)(anim),
      set(tintAmount, tint),
    )(anim)),
    [types.CHANGE_TINT_COLOR]: mergeIn(({
      payload: {
        name: layerName, color, isGlobal, index,
      },
    }, anim) => over(
      layer(layerName)(anim),
      isGlobal ? set(chosenColor, index) : set(tintColor, color),
    )(anim)),
    [types.SET_LENGTH]: mergeIn(({ payload: duration }, anim) => (!isEmpty(anim) ? compose(
      set(animationOP, duration * view(animationFramerate, anim)),
      over(dataLayerEffects, (e) => set(animationLength(e), compose(
        findIndex(pathEq(['v', 'k'], duration)),
        getAllLengthOptions,
        view(dataLayerEffects),
      )(anim), e)),
    )(anim) : {})),
    [types.SET_CUSTOM_LENGTH_IN_ANIMATION]: mergeIn(({ payload: { customLength, immediatelySet = true } }, anim) => getAnimationPayload(
      anim,
      Math.round(customLength * 100) / 100,
      immediatelySet,
    )),
    [types.SET_ANIMATION_DIMENSIONS]: (state, { payload: { width, height } }) => ({
      ...state,
      w: width,
      h: height,
    }),
  },
  {},
);

export default reducer;
