import { COLORS } from 'constants/styles';
import { BODY_TYPES, STATUSES, WEBHOOK_METHODS } from 'constants/webhook';

export const INPUT_STYLES = {
  height: '35px',
  borderRadius: '5px',
  fontSize: '16px',
};
export const LABEL_STYLES = {
  fontSize: '12px',
  textTransform: 'uppercase',
  color: COLORS.LABEL_COLOR,
  letterSpacing: '.08em',
};

export const DEFAULT_VALUES = {
  status: STATUSES.ACTIVE,
  type: WEBHOOK_METHODS.GET,
  url: '',
  body_type: BODY_TYPES.JSON,
  get_fields: [],
  headers: [],
  body_fields: [],
};

export const BUTTON_STYLES = {
  width: 102,
  minWidth: 102,
  height: 39,
  fontSize: 16,
};
