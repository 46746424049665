import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getControls, getEditorIsReady } from 'state/editor/selectors';
import { getColorsRequest } from 'state/colors/actions';
import { Toggle } from 'ui-kit';
import { changeDraftTemplate, toggleOriginalAnimationStatus } from 'state/drafts/actions';
import { getDraft, selectOriginalAnimationStatus } from 'state/drafts/selectors';
import { selectChangingAnimationStructurePermission } from 'state/company/selectors';
import { parseJsonString } from 'utils/helpers';
import { ACTIONS, MODULES } from 'constants/permissions';
import { PermissionWrapper } from 'wrappers';
import { Option } from './Option';
import {
  Container, Row, Wrapper,
} from './MainTab.styled';

const DEFAULT_PARAMS = {
  limit: 100,
  offset: 0,
  sortBy: 'id',
  order: 'asc',
};

export const MainTab = () => {
  // todo: need to rewrite this selector, but I have issue in Redux now....
  const {
    controls, draft, isOriginalAnimation, isReady, canChangeAnimationStructure,
  } = useSelector((store) => ({
    controls: getControls(store),
    draft: getDraft(store),
    isOriginalAnimation: selectOriginalAnimationStatus(store),
    isReady: getEditorIsReady(store),
    canChangeAnimationStructure: selectChangingAnimationStructurePermission(store),
  }));
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  useEffect(() => {
    dispatch(
      getColorsRequest(DEFAULT_PARAMS),
    );
  }, []);

  const toggleOriginalAnimation = (e) => {
    dispatch(changeDraftTemplate({ target: { value: draft.id }, isControls: e.target.checked }));
    dispatch(toggleOriginalAnimationStatus(e.target.checked));
  };

  const showOriginalAnimationToggle = useMemo(() => {
    const controlFields = draft?.control_fields ? parseJsonString(draft?.control_fields) : {};
    return (controlFields?.originalOptions || draft?.hasControlFields) && Boolean(canChangeAnimationStructure);
  }, [draft, canChangeAnimationStructure]);

  return (
    <Container>
      <PermissionWrapper action={ACTIONS.READ} module={MODULES.FULL_CONTROL_ANIMATION}>
        {showOriginalAnimationToggle && (
        <Row>
          <Toggle
            label={t('Original animation')}
            labelStyleProps={{
              fontSize: '14px',
            }}
            marginBottom="5px"
            onChange={toggleOriginalAnimation}
            value={isOriginalAnimation}
            isDisabled={!isReady}
          />
        </Row>
        )}
      </PermissionWrapper>
      {
        controls.map((control, index) => (
          <Wrapper
            key={`controlSchema${control.name}`}
            isColorOption={control.colorOption}
          >
            <Option
              index={index}
              control={control}
            />
          </Wrapper>
        ))
      }
    </Container>
  );
};
