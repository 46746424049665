/**
 *
 * @param lengthOption {string | number}
 * @returns {{label: string, value : {string | number}}}
 */

export function mapLengthToOption(lengthOption) {
  return { label: `${lengthOption} s`, value: lengthOption };
}

/**
 *
 * @param aspectratio {string}
 * @returns {{label: string, value: string}}
 */

export function mapRatioToOption({ aspectratio }) {
  return { label: aspectratio, value: aspectratio };
}

/**
 *
 * @param key {string | number}
 * @param value {string | number}
 * @returns {function(*): {label: *, value: *}}
 */

export function mapKeyToOption(key, value) {
  return (src) => ({
    label: src[key],
    value: src[value],
  });
}

/**
 *
 * @param input {string | number}
 * @returns {{label: string, value: string}}
 */

export function mapStringToOption(input) {
  return {
    label: `${input}`,
    value: `${input}`,
  };
}

/**
 *
 * @param array {array<string | number>}
 */

export const mapArrayToOptions = (array = []) => array.map((value) => ({ label: `${value}`, value }));

/**
 *
 * @param array {array<number | string>}
 * @returns {{label: string, value: number}[]}
 */

export const mapArrayToOptionsWithIndex = (array = []) => array.map((value, index) => ({ label: `${value}`, value: index }));
