import * as fontsActions from './actions';
import * as fontsSelectors from './selectors';
import * as fontsOperations from './operations';
import fontsTypes from './types';
import reducer from './reducers';

export {
  fontsActions,
  fontsTypes,
  fontsSelectors,
  fontsOperations,
};

export default reducer;
