import {
  lensPath, lensProp, compose, slice, filter, path,
} from 'ramda';
import { getLayerIndex } from './core';

export const animationLength = (efs = []) => lensPath([getLayerIndex('MainInfo')(efs), 'v', 'k']);

export const customAnimationLength = (param) => lensPath([param, 'v', 'k']);

export const getAllLengthOptions = slice(3, 7);

export const getLengthOptions = compose(
  filter(path(['v', 'k'])),
  getAllLengthOptions,
);

export const animationOP = lensProp('op');

export const animationFramerate = lensProp('fr');
