import React from 'react';
import { Root } from './Cell.styled';

/**
 * @param children {React.ReactNode | string}
 * @param props {object} style props
 * @returns {JSX.Element}
 * @constructor
 */

export const Cell = ({ children, ...props }) => {
  return (
    <Root {...props}>{children}</Root>
  );
};
