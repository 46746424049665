import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Button } from 'ui-kit';
import { editorActions } from 'state/editor';

export const ResetTool = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const form = useFormContext();

  const [selectedDraft] = form.watch(['selectedDraft']);

  const handleResetToDefault = () => {
    dispatch(editorActions.resetToDefault({ projectName: '' }));
    form.reset();
  };

  return (
    <Button
      title={t('Reset')}
      label={t('Reset')}
      height={32}
      width={75}
      buttonTheme="reset"
      isDisabled={selectedDraft}
      onClick={handleResetToDefault}
    />
  );
};
