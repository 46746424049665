import styled from 'styled-components';

export const RootStyle = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
`;

export const Input = styled.input`
  box-sizing: border-box;
  height: 46px;
  padding: 0 80px 0 10px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.BORDER_COLOR};
  outline: none;
  background: ${({ theme }) => theme.colors.WHITE};
  font-size: 13px;
  box-shadow: none;
`;

export const Button = styled.button`
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.MAIN};
  border-radius: 0 3px 3px 0;
  background-color: ${({ theme }) => theme.colors.MAIN};
  cursor: pointer;
  transform: translate(-1px, 0);
  &:hover {
    opacity: 0.8;
  }
`;
