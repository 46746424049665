import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui-kit';
import { COLORS } from 'constants/styles';
import { useMyFiles } from 'hooks';
import { Wrapper } from './SelectedItemsControls.styled';

const BUTTON_STYLES = {
  HEIGHT: 25,
  WIDTH: 55,
  BORDER_RADIUS: 35,
  FONT_SIZE: 10,
};

const getButtonsConfig = (toggleShare, toggleDownload, toggleDelete) => [{
  text: 'Share',
  onClick: toggleShare,
  color: COLORS.MAIN,
},
{
  text: 'Download',
  onClick: toggleDownload,
  color: COLORS.MAIN,
},
{
  text: 'Delete',
  onClick: toggleDelete,
  color: COLORS.CANCEL,
}];

export const SelectedItemsControls = memo(() => {
  const { t } = useTranslation(['account', 'common', 'forms']);
  const {
    toggleDeleteFilesModal,
    selectedFileIdsList,
    toggleDownloadFilesModal,
    toggleShareLinksModal,
  } = useMyFiles();

  return (
    <Wrapper>
      {getButtonsConfig(
        toggleShareLinksModal,
        toggleDownloadFilesModal,
        toggleDeleteFilesModal,
      ).map(({ text, onClick, color }) => (
        <Button
          type="button"
          buttonTheme="primary"
          height={BUTTON_STYLES.HEIGHT}
          width={BUTTON_STYLES.WIDTH}
          borderRadius={BUTTON_STYLES.BORDER_RADIUS}
          fontSize={BUTTON_STYLES.FONT_SIZE}
          disabled={!selectedFileIdsList.length}
          color={color}
          title={t(text)}
          onClick={onClick(true)}
          label={t(text)}
        />
      ))}
    </Wrapper>
  );
});
