import styled from 'styled-components';
import { Icon as SimpleIcon } from 'ui-kit';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 132px;
  font-size: 12px;
  font-weight: 300;
  white-space: pre-wrap;
  @media(${({ theme }) => theme.devices.XS}) {
    min-width: 132px;
  };
`;

export const Item = styled.div`
  min-width: 46%;
  max-width: 46%;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  border: 1px solid ${({ theme }) => theme.colors.MAIN};
  border-radius: 7px;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.MAIN};
  font-size: 14px;
  line-height: 32px;
  white-space: nowrap;
`;

export const Text = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Icon = styled(SimpleIcon)`
  transform: translateY(-4px);
`;
