import styled from 'styled-components';
import { layout, color, position } from 'styled-system';
import { space } from '@styled-system/space';

export const Wrapper = styled.div`
  ${position};
  ${layout};
  ${space};
  ${color};
`;
