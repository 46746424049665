import styled from 'styled-components';
import { layout } from 'styled-system';
import { Swiper } from 'swiper/react/swiper-react';

export const StyledSwiper = styled(Swiper)`
  max-width: 60vh;
  max-height: 60vh;
  ${layout}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const Image = styled.img`
  max-width: 60vh;
  max-height: 60vh;
  ${layout}
`;
