import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.WHITE};
  :only-child {
    background-color: ${({ theme }) => theme.colors.MAIN_BACKGROUND};
  }
  :nth-child(2) {
    background-color: ${({ theme }) => theme.colors.MAIN_BACKGROUND};
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`;

export const Content = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.colors.MAIN};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  font-weight: 500;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.ORANGE};
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const Hide = styled.div`
  cursor: pointer;
`;
