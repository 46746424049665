export const TEXT_PATH = {
  NAME: ['nm'],
  INDEX: ['ind'],
  PARENT: ['parent'],
  VALUE: ['t', 'd', 'k', 0, 's', 't'],
  VALUES: ['t', 'd', 'x'],
  HORIZONTAL_ALIGN: ['t', 'd', 'k', 0, 's', 'j'],
  DISABLE: ['t', 'd', 'k', 0, 's', 'disable'],
  SCALE_VALUES: ['v', 'x'],
  SCALE_VALUE: ['v', 'k'],
  VARIABLES_VALUES: ['v', 'x'],
  VARIABLE_VALUE: ['v', 'k'],
  COLOR_VALUE: ['v', 'k'],
  EDITFRAME_VALUE: ['v', 'k'],
  FONT_FAMILY_VALUE: ['t', 'd', 'k', '0', 's', 'f'],
  FONT_FAMILY_COLOR: ['t', 'd', 'k', '0', 's', 'fc'],
  FONT_FAMILY_EFFECT: ['t', 'a', '0', 'a', 'fc', 'x'],
  SLAVE_TEXTS_PARENT: ['ef', 0, 'nm'],
  SLAVE_TEXTS_PARENT_VALUE: ['ef', 0, 'ef', 0, 'v', 'k'],
  COLORS_SCRIPT: ['t', 'a', '0', 'a', 'fc', 'x'],
  EDIT_FRAME_VALUE: ['ef', 0, 'ef', 1, 'v', 'x'],
};

export const IMAGE_PATH = {
  NAME: ['nm'],
  CUSTOM_NAME: ['ef', 0, 'ef', 1, 'v', 'x'],
  TYPE: ['ef', 0, 'ef', 0, 'v', 'k'],
  FRAME: ['ef', 0, 'ef', 1, 'v', 'k'],
  TINT_COLOR: ['ef', 0, 'ef', 2, 'v', 'k'],
  CHOSEN_COLOR: ['ef', 0, 'ef', 3, 'v', 'k'],
  TINT_AMOUNT: ['ef', 0, 'ef', 4, 'v', 'k'],
  TINT_OPTIONS_ARRAY: ['ef', 0, 'ef', 4, 'v', 'x'],
  TINT_CHOSEN_COLOR_PATH: ['ef', 0, 'ef', 3, 'v', 'x'],
};

export const TEXT_PROP = {
  INDEX: 'ind',
  EFFECT: 'ef',
  PARENT: 'parent',
  TYPE: 'ty',
  NAME: 'nm',
  POSITION: 'SWITCHER Position',
  MAIN_PRESETS: 'Main Presets',
  CTA: 'CTA',
};

export const GROUP_PATH = {
  NAME: ['nm'],
  INDEX: ['ind'],
  CONNECTED_LAYERS: ['ef', 0, 'ef'],
  TEXT_LAYER_INDEX: ['v', 'k'],
  CHILD_LAYERS: ['ef', 0, 'ef'],
};

export const TEXT_GROUP_POSITION = {
  CONNECTED_LAYERS: ['ef', 0, 'ef'],
  SWITCHERS_VALUES: ['v', 'x'],
  SWITCHERS_VALUE: ['v', 'k'],
};

export const GROUP_CONST = {
  SWITCHER: 'SWITCHER',
  SWITCHER_POSITION: 'SWITCHER Position',
  SWITCHER_HORIZONTAL_POSITION: 'SWITCHER Horizontal Position',
  SWITCHER_VARIABLE: 'SWITCHER Variable',
  HORIZONTAL_POSITION: 'Horizontal Positioning',
};

export const CONTROLS_PROP = {
  NAME: 'nm',
  UI_SWITCHER_NAME: 'UIswitcherName',
  EDITFRAME: 'EDITFRAME',
};

export const CONTROLS_PATH = {
  LAYERS: ['ef', 0, 'ef'],
  VALUES: ['v', 'x'],
  VALUE: ['v', 'k'],
};

export const CONTROLS_CONST = {
  SWITCHER: 'SWITCHER',
};

export const GROUP_LAYER = 'GROUP';
export const TEXT_LAYER = 'TEXT';
export const DATA_LAYER = 'TUMPLATEDATA';
export const IMAGE_LAYER = 'IMAGE LAYER';

export const TEXTS_CONSTANTS = {
  TEXT_LAYER_NAME: 'TumplateTextLayer',
  TEXT_VARIABLES_LAYER_NAME: 'Variable',
  TEXT_SCALE_LAYER_NAME: 'Scale',
  SWITCHER_COLOR: 'SWITCHER Color',
  GLOBAL_COLOR_LAYER_NAME: 'Global color',
  EDITFRAME: 'EDITFRAME',
  SLAVE_LAYERS_TYPE: 5,
  SLAVE_PARENT: 'PARENT',
};

export const DATA_PROP = {
  EFFECT: 'ef',
  NAME: 'nm',
};

export const DATA_CONST = {
  MASTER_DATA_LAYER_NAME: 'MasterData',
  COLOR: 'MAIN TEXT COLOR',
  SWITCHER_NAME: 'UIswitcherName',
  FORCE_HIDE: 'FORCE HIDE',
  EDITFRAME: 'EDITFRAME',
  SECOND_SWITCHER_NAME: 'UIswitcherName=',
};

export const DATA_PATH = {
  COLOR_VALUE: ['v', 'k'],
  COLOR_CONFIG: ['v', 'x'],
};

export const IMAGES_CONSTANTS = {
  IMAGE_LAYER_NAME: 'TumplateImageLayer',
  EDITFRAME: 'EDITFRAME',
};

export const IMAGES_PROP = {
  NAME: 'nm',
  EFFECT: 'ef',
};

export const IMAGES_PATH = {
  EDITFRAME_VALUE: ['v', 'k'],
};

export const ANIMATION_PATH_NAME = ['nm'];

export const IMAGE_TYPES = {
  BACKGROUND: 1,
  PRODUCT: 2,
  LOGO: 3,
  DYNAMIC: 4, // we don't have explanations for third and fourth types so far
};

export const ASSETS_PROP = {
  COMP: 'comp',
  TY: 5,
};
