import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { animationActions, animationSelectors } from 'state/animation';
import { useEditor } from 'hooks';
import { editorSelectors } from 'state/editor';
import { PickerSelect } from '../../PickerSelect';

const DEFAULT_LENGTH = 15;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const LengthTool = () => {
  const form = useFormContext();
  const { setIsCustomLength } = useEditor();
  const dispatch = useDispatch();
  const lengthOptions = useSelector(animationSelectors.animationLengthOptionsSelector);
  const customLength = useSelector(editorSelectors.getCustomLength);
  const onLengthChange = (value) => dispatch(animationActions.setLength(parseInt(value, 10)));
  const onCustomLengthChange = (value) => dispatch(animationActions.setCustomLengthInAnimation({ customLength: value }));

  const handleChange = useCallback((newValue) => {
    form.setValue('length', newValue);
    if (customLength === newValue) {
      onCustomLengthChange(newValue);
      setIsCustomLength(true);
    } else {
      onLengthChange(newValue);
      setIsCustomLength(false);
    }
  }, [customLength]);

  const options = lengthOptions.map((option) => ({
    label: `${option} sec`,
    value: option,
  }));

  useEffect(() => () => setIsCustomLength(false), []);

  return (
    <PickerSelect
      id="select-length"
      name="length"
      onChange={handleChange}
      label="sec"
      defaultValue={DEFAULT_LENGTH}
      options={options}
    />
  );
};
