import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import './style.sass';
import { useFormik } from 'formik';
import {
  ColorsSelector, FontsSelector, ThemesSelector,
} from 'components';
import { Toggle, Preloader } from 'ui-kit';
import {
  setCompanyIsEnabledBrandColors,
  setCompanyIsEnabledBrandFonts,
  updateCompanyRequest,
} from 'state/company/actions';
import {
  getCompany,
  getCompanyIsEnabledBrandColors, getCompanyIsEnabledBrandFonts,
  getCompanyUpdateRequestPending,
} from 'state/company/selectors';
import { colorsActions } from 'state/colors';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { Select } from 'components/forms';
import { VIDEO_BITRATE, RENDER_IMAGE_FORMATS } from 'constants/rendering';
import { getOptionsList } from 'utils/helpers/commonHelpers';
import { ACTIONS, MODULES } from 'constants/permissions';
import { PermissionWrapper } from 'wrappers';

const DEFAULT_COLORS_PARAMS = {
  limit: 100, offset: 0, sortBy: 'id', order: 'asc',
};

export const DesignPresets = () => {
  const dispatch = useDispatch();
  const [isOpenSelectThemes, setIsOpenSelectThemes] = useState(false);

  const {
    company, isPendingUpdateCompany,
  } = useSelector((store) => ({
    company: getCompany(store),
    isPendingUpdateCompany: getCompanyUpdateRequestPending(store),
  }));

  const [bitrate, setBitrate] = useState(company.video_max_bitrate);
  const [imagesFormat, setImagesFormat] = useState(company.image_type);

  const {
    values, setFieldValue, handleSubmit,
  } = useFormik({
    initialValues: {
      fonts: [],
      themes: [],
      company: {
        brand_title: company.brand_title,
        logo: pathOr('', ['logo', 'id'], company),
      },
    },
    enableReinitialize: 1,
    onSubmit: ({ company: { brand_title, logo } }) => dispatch(updateCompanyRequest({
      brand_title,
      image_type: imagesFormat,
      video_max_bitrate: VIDEO_BITRATE[bitrate].value,
      ...(logo !== pathOr('', ['logo', 'id'], company) ? { logo } : {}),
    })),
  });

  const { t } = useTranslation(['account', 'common', 'forms']);

  const isEnabledColor = useSelector(getCompanyIsEnabledBrandColors);
  const isEnabledFont = useSelector(getCompanyIsEnabledBrandFonts);

  useEffect(() => {
    dispatch(colorsActions.getColorsRequest(DEFAULT_COLORS_PARAMS));
  }, []);
  return (
    <PermissionWrapper action={ACTIONS.READ} module={MODULES.DESIGN_PRESETS}>
      <div className="design-presets">
        <Preloader
          type={PRELOADER_TYPES.CONTAINER}
          size={PRELOADER_SIZES.MIDDLE}
          isLoading={isPendingUpdateCompany}
        >
          <form className="design-presets__form" onSubmit={handleSubmit}>
            <div className="design-presets__wrap">
              <div className="design-presets__left-col">
                <div className="select-colors__toggle">
                  <span className="title">
                    Use account colors
                  </span>
                  <Toggle
                    onChange={({ target: { checked } }) => dispatch(setCompanyIsEnabledBrandColors(checked))}
                    value={!!isEnabledColor}
                  />
                </div>
                <ColorsSelector />
                <div className="select-colors__info-text">
                  These colors will replace Tumplate’s colors.
                </div>
                <div className="select-colors__toggle">
                  <span className="title">
                    Use account fonts
                  </span>
                  <Toggle
                    onChange={({ target: { checked } }) => dispatch(setCompanyIsEnabledBrandFonts(checked))}
                    value={!!isEnabledFont}
                  />
                </div>
                <FontsSelector />
                <div className="select-font__info-text">
                  These fonts will be added to all Tumplate animations.
                </div>
              </div>
              <div className="design-presets__right-col">
                <ThemesSelector
                  isOpenSelectThemes={isOpenSelectThemes}
                  setIsOpenSelectThemes={setIsOpenSelectThemes}
                  setFieldValue={setFieldValue}
                  value={values.themes}
                  label={t('AccountLayout tumplates:')}
                />
                <div className="design-presets__row">
                  <span className="select-theme__title">
                    Export settings:
                  </span>
                  <div className="design-presets__field">
                    <b className="design-presets__title">Bitrate</b>
                    <Select
                      name="bitrate"
                      id="bitrateField"
                      label={(value) => (
                        <div className="design-presets__select-label text-cut">
                          {pathOr(VIDEO_BITRATE[1].label,
                            [value, 'label'], VIDEO_BITRATE)}
                        </div>
                      )}
                      onChange={({ target: { value } }) => setBitrate(Number(value))}
                      value={bitrate}
                      options={getOptionsList(VIDEO_BITRATE)}
                    />
                  </div>
                  <div className="design-presets__field">
                    <b className="design-presets__title">Export frames as</b>
                    {/* <button type="button" className="design-presets__customButton">{t('JPG')}</button> */}
                    <Select
                      id="imagesFormatField"
                      name="imagesFormat"
                      label={(value) => (
                        <div className="design-presets__select-label text-cut">
                          {pathOr(RENDER_IMAGE_FORMATS[1].label,
                            [value, 'label'], RENDER_IMAGE_FORMATS)}
                        </div>
                      )}
                      onChange={({ target: { value } }) => setImagesFormat(value)}
                      value={imagesFormat}
                      options={getOptionsList(RENDER_IMAGE_FORMATS)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="account-information__buttons-row">
              <Link to="/" className="button  button--grey">{t('Close')}</Link>
              <button type="submit" className="button">{t('Save')}</button>
            </div>
          </form>
        </Preloader>
      </div>
    </PermissionWrapper>
  );
};
