export default {
  GET_ANIMATIONS_REQUEST: 'GET_ANIMATIONS_REQUEST',
  GET_ANIMATIONS_SUCCESS: 'GET_ANIMATIONS_SUCCESS',
  SET_ANIMATIONS: 'SET_ANIMATIONS',

  UPDATE_ANIMATION: 'UPDATE_ANIMATION',
  UPDATE_ANIMATION_SUCCESS: 'UPDATE_ANIMATION_SUCCESS',

  CHANGE_SELECTED_ANIMATION: 'CHANGE_SELECTED_ANIMATION',
  SET_SELECT_ANIMATION: 'SET_SELECT_ANIMATION',

  SET_ANIMATIONS_FRESH_STATUS: 'SET_ANIMATIONS_FRESH_STATUS',

  SET_ANIMATION_STATUS: 'SET_ANIMATION_STATUS',

  FETCH_ALL_ANIMATIONS_REQUEST: 'FETCH_ALL_ANIMATIONS_REQUEST',
  FETCH_ALL_ANIMATIONS_SUCCESS: 'FETCH_ALL_ANIMATIONS_SUCCESS',
  FETCH_ALL_ANIMATIONS_ERROR: 'FETCH_ALL_ANIMATIONS_ERROR',
};
