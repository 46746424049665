import React from 'react';
import { Tools } from './Tools';
import { Wrapper, Container } from './LeftToolBar.styled';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const LeftToolBar = () => (
  <Container>
    <Wrapper>
      <Tools />
    </Wrapper>
  </Container>
);
