import styled from 'styled-components';
import { flexbox } from 'styled-system';
import { space } from '@styled-system/space';

export const RootStyle = styled.div`
  margin-bottom: 16px;
`;

export const Body = styled.div`
  display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};
  flex-wrap: wrap;
  padding-top: 20px;
  ${space};
  ${flexbox};
`;

export const Header = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Triangle = styled.div`
  height: 1px;
  margin-top: 2px;
  display: inline-block;
  border-top: 6px solid transparent;
  border-right: 9px solid transparent;
  border-left: 9px solid ${({ theme }) => theme.colors.MAIN};
  border-bottom: 6px solid transparent;
  ${({ isOpened }) => isOpened && `
   margin-top: -8px;
   transform-origin: left bottom;
   transform: rotateZ(90deg);
  `}
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
