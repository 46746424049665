import React from 'react';
import { Wrapper } from './Table.styled';

/**
 *
 * @param children {React.ReactNode}
 * @params props {string | number} style props
 * @returns {JSX.Element}
 * @constructor
 */
export const Table = ({ children, ...props }) => {
  return (
    <Wrapper cellspacing={0} cellpadding={0} {...props}>{children}</Wrapper>
  );
};
