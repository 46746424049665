import { API_METHODS } from 'constants/common';
import { ROUTES } from 'constants/api.routes';
import { apiService } from './api.service';

export const videoService = {
  getVideos: async (params = {}) => {
    return apiService({
      method: API_METHODS.GET,
      subUrl: ROUTES.VIDEOS.ROOT,
      config: { params },
    });
  },
  downloadVideos: async (params = {}) => {
    return apiService({
      method: API_METHODS.GET,
      subUrl: ROUTES.VIDEOS.DOWNLOAD,
      config: { params, responseType: 'blob' },
    });
  },
  getVideoPreview: async (id, params = {}) => {
    return apiService({
      method: API_METHODS.GET,
      subUrl: `${ROUTES.VIDEOS.ROOT}/${id}/preview`,
      config: { params },
    });
  },
};
