import moment from 'moment';
import { RENDER_STATUS, RENDER_TYPES, RENDER_TYPES_FOR_API } from 'constants/rendering';
import { errorFile, processingFile, renderingFile } from 'assets/images';
import { ROUTES } from 'constants/routes';
import { getRenderTypeByFileType } from './filesHelper';
import { getFileUrl } from './requestHelpers';
import { notify } from './notificationHelpers';

/**
 *
 * @param theme {theme}
 * @param type {number} render_type from api
 * @param isDefaultBackground {boolean}
 * @returns {string|*|string|number|"info"|"success"|"warning"|"error"|"default"|"dark"}
 */
export const getColorByType = ({ theme, type, isDefaultBackground }) => {
  if (isDefaultBackground) return theme.colors.EXTRA_LIGHT_GREY;
  const renderType = getRenderTypeByFileType(type);
  switch (renderType?.type) {
    case RENDER_TYPES_FOR_API.mp4: return theme.colors.VIOLET;
    case RENDER_TYPES_FOR_API.gif: return theme.colors.BLUE;
    case RENDER_TYPES_FOR_API.html5: return theme.colors.SUCCESS;
    case RENDER_TYPES_FOR_API.image: return theme.colors.SECONDARY;
    case RENDER_TYPES_FOR_API.keyFramesPdf: return theme.colors.ORANGE;
    default: return theme.colors.PURPLE;
  }
};

/**
 *
 * @param fileId {number}
 * @param status {number}
 * @returns {string|string|*}
 */

export const getPreview = (fileId, status) => {
  if (fileId) {
    return getFileUrl({ fileUrl: `files/${fileId}`, isUseSalt: true });
  }
  switch (status) {
    case RENDER_STATUS.RENDERING: return renderingFile;
    case RENDER_STATUS.PROCESSING: return processingFile;
    case RENDER_STATUS.ERROR: return errorFile;
    default: return processingFile;
  }
};
/**
 *
 * @param date {Date}
 * @returns {string}
 */
export const makeDate = (date) => {
  // todo: get rid of the moment
  return moment(`${date}Z`).format('YYYY-MM-DD HH:mm:ss');
};

/**
 *
 * @param types {object}
 * @returns {{}}
 */
export const resetTypesFilters = (types) => {
  const obj = {};
  for (const key of Object.keys(types)) {
    obj[key] = false;
  }
  return obj;
};

/**
 *
 * @param types {object}
 * @returns {{}}
 */

export const generateTypesForApi = (types) => {
  let result = [];
  for (const key of Object.keys(types)) {
    // check if true
    if (types[key]) {
      // find type
      const neededType = RENDER_TYPES.find(({ type }) => type === Number(key));
      if (neededType) {
        // add subtypes to the result array
        result = [...result, ...neededType.fileTypes];
      }
    }
  }
  return result;
};

/**
 *
 * @param error {object} error from the API
 */

export const showErrorAfterCreatingLink = (error) => {
  const errorValues = Object.values(error.data.errors);
  const { message } = errorValues[errorValues.length - 1];
  notify.error(message);
};

/**
 *
 * @param code {string}
 */

export const generateLinkForDiscussion = (code) => {
  return `${process.env.REACT_APP_URL}${ROUTES.DISCUS_VIDEOS}/${code}`;
};
