import { equals, memoizeWith, identity } from 'ramda';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounceFunc } from 'utils/helpers/commonHelpers';
import { searchArrayInString } from 'utils/helpers/animationsPath';
import { editorActions } from 'state/editor';

import { getAnimationDataRequestPending } from 'state/animation/selectors';
import { getEditFramesList } from 'state/editor/selectors';
import { DEFAULT_INDEX } from '../constants/common';

const DEBOUNCE_DELAY = 1000;
const RECALCULATE_KEY_FRAMES = 'RECALCULATE_KEY_FRAMES';

export const useKeyFrames = ({ lottieElement }) => {
  const dispatch = useDispatch();
  const {
    isLoadingDataOfAnimation, editFramesListStore,
  } = useSelector(memoizeWith(identity, (store) => ({
    isLoadingDataOfAnimation: getAnimationDataRequestPending(store),
    editFramesListStore: getEditFramesList(store),
  })));

  const memoizedDispatch = useCallback(
    (editFramesList) => {
      const editFrameIndex = editFramesList.findIndex((frame) => frame !== '0');
      dispatch(editorActions.saveEditFrames(editFramesList));
      if (editFrameIndex > DEFAULT_INDEX) {
        dispatch(editorActions.goToEditFrameByIndex(editFrameIndex));
      }
    }, [dispatch],
  );

  const recalculate = useCallback(
    () => {
      const editFramesAccumElement = document.querySelector('*[aria-label^="editFrames"]');

      if (editFramesAccumElement) {
        const editFrames = editFramesAccumElement.getAttribute('aria-label');
        const editFramesList = searchArrayInString(editFrames);
        if (!equals(editFramesListStore, editFramesList)) {
          memoizedDispatch(editFramesList);
        }
      }
    },
    [editFramesListStore, memoizedDispatch],
  );
  const tryRecalculateKeyFrames = useCallback(
    () => debounceFunc(recalculate, DEBOUNCE_DELAY, RECALCULATE_KEY_FRAMES),
    [recalculate],
  );

  const handleRecalculate = useCallback(
    () => {
      const lottieRef = lottieElement.current;
      let observer;
      if (lottieRef && !isLoadingDataOfAnimation) {
        observer = new MutationObserver(() => setTimeout(tryRecalculateKeyFrames));
        tryRecalculateKeyFrames();
        observer.observe(lottieRef.el, { childList: true, attributeFilter: ['aria-label'] });

        return () => {
          observer.disconnect();
        };
      }
    },
    [
      isLoadingDataOfAnimation,
      lottieElement, tryRecalculateKeyFrames,
    ],
  );

  useEffect(() => {
    handleRecalculate();
    return identity;
    // eslint-disable-next-line
    },
  [handleRecalculate]);

  return {
    tryRecalculateKeyFrames,
  };
};
