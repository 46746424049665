import styled from 'styled-components';

export const RootStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding-top: 80px;
  z-index: 3;
  background-color: rgba(255,255,255,.85);
`;

export const Card = styled.div`
  max-width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  @media(${({ theme }) => theme.devices.SM}) {
    flex-direction: column;
  }
`;

export const Title = styled.h3`
  max-width: 280px;
  margin: 0 15px 0;
  font-size: 24px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  @media(${({ theme }) => theme.devices.SM}) {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
  }
`;

export const Row = styled.div`
  width: 180px;
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px 0 32px;
  @media(${({ theme }) => theme.devices.SM}) {
    margin: 16px;
  }
`;

export const Image = styled.img`
  max-width: 60vw;
  max-height: 60vh;
`;
