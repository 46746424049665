import React from 'react';
import {
  Checkbox, Container, Pseudo, Error, RootStyle, LabelText,
} from './Toggle.styled';

/**
 * @param id {string | number}
 * @param name {string}
 * @param value {boolean}
 * @param onChange {function}
 * @param isDisabled {boolean}
 * @param className {string}
 * @param toggleSize {string}
 * @param label {string}
 * @param {error} {string | undefined}
 * @param labelStyleProps {object} style props for the label
 * @param errorStyleProps {object} style props for the error
 * @param props {object}
 * @returns {JSX.Element}
 * @constructor
 */

export const Toggle = ({
  id,
  name = 'toggle',
  value,
  onChange = () => {},
  isDisabled = false,
  className = '',
  toggleSize = '',
  label = '',
  labelStyleProps,
  error,
  errorStyleProps,
  ...props
}) => (
  <RootStyle>
    {label && (
      <LabelText {...labelStyleProps}>
        {label}
      </LabelText>
    )}
    <Container className={className} toggleSize={toggleSize} {...props}>
      <Checkbox
        id={id}
        type="checkbox"
        checked={value}
        name={name}
        onChange={onChange}
        disabled={isDisabled}
        toggleSize={toggleSize}
      />
      <Pseudo isChecked={value} toggleSize={toggleSize} />
    </Container>
    {error && (<Error {...errorStyleProps}>{error}</Error>)}
  </RootStyle>
);
