import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin: 20px 0;
`;

export const BaseButton = styled.button`
  border: none;
  background: none;
  font-size: 20px;
  margin: 0 5px;
  padding: 0;
  color: ${({ theme }) => theme.colors.BORDER_COLOR};
  cursor: pointer;
  :focus {
    border: none;
    padding: 0;
    outline: none;
  }
`;

export const PageItem = styled(BaseButton)`
  ${({ isActive }) => isActive && 'color: #000'}
`;
