import {
  fork, put, take,
} from 'redux-saga/effects';
import sagasManager from 'utils/sagasManager';
import fontsTypes from './types';
import * as fontsActions from './actions';

function* fontsSelectingFlow() {
  while (true) {
    yield take([fontsTypes.SELECT_FONT, fontsTypes.UNSELECT_FONT]);
    yield put(fontsActions.setFontsFreshStatus(false));
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(fontsSelectingFlow);
});
