import React from 'react';
import { path } from 'ramda';
import { useSelector } from 'react-redux';
import { selectUserType } from 'state/user/selectors';
import { PERMISSIONS } from 'constants/permissions';

/**
 *
 * @param module {string}
 * @param action {string}
 * @param forceShow {boolean}
 * @param children {React.ReactNode}
 * @returns {JSX.Element}
 * @constructor
 */

export const PermissionWrapper = ({
  module, action, forceShow = false, children,
}) => {
  const userType = useSelector(selectUserType);
  const permission = path([userType, module, action], PERMISSIONS);
  return permission || forceShow ? (
    <>{children}</>
  ) : (<></>);
};
