import styled from 'styled-components';
import {
  color, layout, system, typography,
} from 'styled-system';
import { space } from '@styled-system/space';

const PSEUDO_BACKGROUND = '#CBCBCB';
const TOGGLE_SIZE_SMALL = 'small';

export const RootStyle = styled.div`
  display: block;
  ${space}
  ${layout}
  ${typography}
`;

export const Container = styled.label`
  display: inline-block;
  position: relative;
  height: 32px;
  width: 55px;
  min-width: 53px;
  ${layout}
  ${space};
  border: 1px solid ${({ theme }) => theme.colors.PLATINUM};
  border-radius: 17px;
  overflow: hidden;
  margin-right: 10px;
  ${({ toggleSize }) => toggleSize === TOGGLE_SIZE_SMALL && `
    min-width: initial;
    width: 29px;
    height: 17px;
  `}
`;

export const Checkbox = styled.input`
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
`;

export const Pseudo = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.WHITE};
  ${({ toggleSize }) => toggleSize === TOGGLE_SIZE_SMALL && `
    min-width: initial;
    width: 29px;
    height: 14px;
  `}
  &::before {
    position: absolute;
    top: 2px;
    left: 2px;
    content: "";
    background-color: ${PSEUDO_BACKGROUND};
    border: 1px solid transparent;
    height: 26px;
    width: 26px;
    min-width: 26px;
    border-radius: 50%;
    cursor: pointer;
    transition-duration: .25s;
    ${({ isChecked, theme }) => isChecked && `
      transform: translateX(calc(100% - 5px));
      background-color: ${theme.colors.WHITE};
    `}
    ${({ toggleSize }) => toggleSize === TOGGLE_SIZE_SMALL && `
      top: 1px;
      min-width: 0;
      width: 13px;
      height: 13px;
      border-radius: 100%;
    `}
  ${({ isChecked, theme }) => isChecked && `background-color: ${theme.colors.MAIN}`}
`;

export const LabelText = styled.span`
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  font-size: 12px;
  ${typography}
  ${color}
  ${system({
    textTransform: true,
    letterSpacing: true,
  })}
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.ERROR};
  font-size: 12px;
  :first-letter{
    text-transform: capitalize
  }
  ${typography}
`;
