import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  width: 80%;
`;

export const Title = styled.input`
  width: 320px;
  padding: 6px 0;
  margin-bottom: 4px;
  border: 0;
  box-sizing: border-box;
  background-color: #F9FAFC;
  font-weight: 700;
  letter-spacing: -0.03em;
  font-size: 20px;
  &::placeholder {
    font-weight: 300;
    letter-spacing: 0;
  }
  @media(${({ theme }) => theme.devices.SM}) {
    max-width: 300px;
  }
  &:hover {
    box-shadow: 0 1px 3px #c9c9c9;
    border-radius: 3px;
    @media(${({ theme }) => theme.devices.SM}) {
      font-size: 16px;
    }
  }
`;

export const FullViewBtn = styled.button`
  padding-top: 10px;
  background-color: transparent;
  border: 0;
  font-size: 12px;
  cursor: pointer;
`;

export const FullViewImg = styled.img`
  width: 20px;
`;

export const NotesWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const NotesLabel = styled.div`
  padding-top: 2px;
  font-weight: 300;
  font-size: 12px;
  color: #8e8c8c;
`;

export const Notes = styled.textarea`
  width: 280px;
  height: 25px;
  border: 0;
  background-color: #F9FAFC;
  font-weight: 300;
  font-size: 12px;
  resize: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover {
    box-shadow: 0 1px 3px #C9C9C9;
    border-radius: 3px;
    @media(${({ theme }) => theme.devices.SM}) {
      max-width: 260px;
    }
  }
`;
