import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 55px;
  margin-bottom: 200px;
  
  @media(${({ theme }) => theme.devices.LG}) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
  }
  
  @media(${({ theme }) => theme.devices.XS}) {
    padding: 0 8px
  }
  
  .preloader--type-overlay {
    position: relative;
    z-index: 0 !important;
  }
`;

export const Player = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;

export const EmptyPlayer = styled.div`
  font-size: 21px;
  text-align: center;
  @media(${({ theme }) => theme.devices.SM}) {
    font-size: 18px;
  }
`;

export const PlayerWrapper = styled.div`
  position: relative;
  width: 77%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  
  @media(${({ theme }) => theme.devices.LG}) {
    width: 100%;
    justify-content: center;
  }

  @media(${({ theme }) => theme.devices.MD}) {
    flex-wrap: wrap;
  }
`;
