import {
  compose, identity, juxt, path, prop, pathOr, ap,
} from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { uploadTempFileRequest } from 'state/ui/actions';
import { getTempFileUploadPending } from 'state/ui/selectors';
import { notify } from 'utils/helpers/notificationHelpers';
import { blobAutoRevoker, getFileUrl, getTempFileUrl } from 'utils/helpers';

const onUploadFileHandler = ({
  uploadTempFile, setFileValue, handleSuccess, handleError, key,
}) => (e) => {
  const file = e.currentTarget.files[0];
  const previewData = file ? URL.createObjectURL(file) : null;
  const formData = new FormData();
  if (previewData && key) {
    blobAutoRevoker.add(key, previewData);
  }
  formData.append('file', e.currentTarget.files[0]);
  setFileValue(previewData);
  uploadTempFile(formData, {
    errorPath: pathOr('Something happened while uploading file', ['response', 'data', 'message']),
    callbacks: {
      // eslint-disable-next-line no-shadow
      error: (e) => {
        handleError(e);
        notify.error(e);
      },
      success: handleSuccess,
    },
  });
};

export const useFileUpload = (options = {}) => {
  const { fileId = '', onSuccess = identity, onError = identity } = options;
  const [fileUrl, setFileValue] = useState(null);
  const [fileModel, setFileModel] = useState(null);
  const [tempId, setTempId] = useState(fileId);
  const isPending = useSelector(getTempFileUploadPending);
  const uploadTempFile = compose(useDispatch(), uploadTempFileRequest);
  const onSuccessHandler = juxt([compose(setTempId, prop('id')), setFileModel, onSuccess]);

  const onErrorHandler = (error) => {
    onError(error);
  };

  const onDeleteFile = () => ap([setTempId, setFileModel, setFileValue], [null]);

  return ({
    onDeleteFile,
    onUploadFile: onUploadFileHandler({
      setFileValue,
      handleSuccess: compose(onSuccessHandler, path(['data'])),
      handleError: onErrorHandler,
      uploadTempFile,
      key: `onUploadFileHandler${options.refId}`,
    }),
    value: getTempFileUrl(fileId),
    tempId,
    isPending,
    fileUrl: fileUrl || (tempId ? getFileUrl({ fileUrl: `files/${tempId}`, isUseSalt: true }) : null),
    fileModel,
  });
};
