import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUserZapierKey } from 'state/user/selectors';
import { zapierLogo } from 'assets/images';
import { notify } from 'utils/helpers';
import { generateZapierKeyRequest } from 'state/user/actions';
import { Button } from 'ui-kit';
import { PermissionWrapper } from 'wrappers';
import { Webhooks } from './Webhooks';
import {
  Close, RootStyle, Container, Title, Image,
  Integration, Text, ZapierLink, Row,
} from './Integrations.styled';
import { BUTTON_STYLES } from './constants';
import { ACTIONS, MODULES } from '../../constants/permissions';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const Integrations = () => {
  const { t } = useTranslation(['account', 'common']);
  const dispatch = useDispatch();
  const zapierKey = useSelector(getUserZapierKey);

  const handleCopyZapierText = () => {
    navigator.clipboard.writeText(zapierKey);
    notify.success(t('Zapier key was copied to clipboard!'));
  };

  const handleGenerateZapierLink = () => {
    dispatch(generateZapierKeyRequest());
  };

  return (
    <PermissionWrapper action={ACTIONS.READ} module={MODULES.INTEGRATIONS}>
      <RootStyle className="integrations">
        <Container>
          <Title>{t('My connection')}</Title>
          <Integration mb="16px">
            <Image src={zapierLogo} alt="zapier" />
            <Text
              role="presentation"
              title={t('Click to copy')}
              onClick={handleCopyZapierText}
            >
              {zapierKey}
            </Text>
            <Row width="40%">
              <ZapierLink
                href={process.env.REACT_APP_ZAPIER}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Connect')}
              </ZapierLink>
              <Button
                type="button"
                {...BUTTON_STYLES}
                onClick={handleGenerateZapierLink}
              >
                {t('Generate')}
              </Button>
            </Row>
          </Integration>
          <Webhooks />
        </Container>
        <Row justifyContent="flex-end">
          <Close to="/">{t('Close')}</Close>
        </Row>
      </RootStyle>
    </PermissionWrapper>
  );
};
