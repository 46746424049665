import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes';
import { Icon } from '../Icon';
import { Navigation, List, ListItem } from './MobilesNavigation.styled';

/**
 *
 * @param handleLogout
 * @returns {JSX.Element}
 * @constructor
 */

export const MobilesNavigation = ({ handleLogout }) => {
  const { t } = useTranslation('common');
  return (
    <Navigation>
      <List>
        <ListItem>
          <NavLink to={ROUTES.MY_FILES} activeClassName="active">
            <Icon name="files-icon" width={48} height={41} />
            <span>{t('Files')}</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to={ROUTES.ACCOUNT.OVERVIEW} activeClassName="active">
            <Icon name="account-icon" width={48} height={41} />
            <span>{t('Account')}</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to={ROUTES.ACCOUNT.DESIGN_PRESETS} activeClassName="active">
            <Icon name="design-presets" width={48} height={41} />
            <span>
              {t('Design Presets')}
            </span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to={ROUTES.SHARED_LINKS} activeClassName="active">
            <Icon name="shared-icon" width={48} height={42} />
            <span>
              {t('Shared links')}
            </span>
          </NavLink>
        </ListItem>
        <ListItem>
          <a href="/" onClick={handleLogout}>
            <Icon name="logout-mobile" width={48} height={41} />
            <span>{t('Log Out')}</span>
          </a>
        </ListItem>
      </List>
    </Navigation>
  );
};
