import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';

export const {
  convertAudioFileToMp3Request,
  uploadVideoFileAsGifRequest,
  uploadVideoFileToTrimRequest,
  setImageCropperData,
  setRendererSettings,
  setAutoplay,
  setLoop,
  setVersion,
  saveTextLayers,
  setTextLayers,
  saveTextGroups,
  setTextGroups,
  changeTextField,
  setTextFieldValue,
  setTextScale,
  changeTextScale,
  setTextVariable,
  changeTextVariable,
  changeTextStatus,
  setTextStatus,
  changeTextColor,
  setTextColor,
  saveData,
  setControls,
  changeControlValue,
  setImageData,
  setEditorColors,
  setAudioDuration,
  setAudioFade,
  setAudioMute,
  setAudioFileInfo,
  changeLastEditFrame,
  saveFonts,
  setFonts,
  changeFontFamily,
  setFontFamily,
  adaptationImageToDraft,
  adaptationImageToDraftSuccess,
  setImageTempFileId,
  convertAudioFileToMp3Success,
  convertAudioToMp3,
  setConvertAudio,
  editorHardReset,
  setImageZoom,
  changeImageControl,
  setImageControl,
  resetToDefault,
  saveEditFrames,
  goToEditFrameByIndex,
  uploadVideoFileAsGif,
  uploadVideoFileAsGifSuccess,
  setPreparingToGifStatus,
  setSavingDraftFileStatus,
  setPrevVersionData,
  setIsReady,
  setImageReady,
  changeTextGroupVariable,
  setTextGroupVariable,
  setAudioFetchStatus,
  setProjectName,
  resetImagesData,
  setImageRefUrl,
  uploadVideoFileToTrim,
  setVideoId,
  uploadVideoFileToTrimSuccess,
  saveTrimmedVideo,
  setLayerType,
  setVersionBackground,
  setPrevVersion,
  setBackgroundImageToDefault,
  resetVersionBackground,
  setImageFileType,
  setDescription,
  setPlayerState,
  setPercentOfPlayer,
  setImageVideoTempId,
  setImageVideoFileId,
  setOriginalImageId,
  setInitialSwitcherState,
  setHasAudioStatus,
  setCustomLength,
  setAudioPoints,
  setVideoTypeSynchronization,
  goToLatestTextEditFrame,
  prepareImageEditFrames,
  saveImageEditFrames,
  saveVisibleTextGroups,
  setVisibleTextGroups,
} = createActions(
  {
    [types.CONVERT_AUDIO_FILE_TO_MP3_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/api/video-drafts/audio/convert-to-mp3',
        selectorName: 'convertAudioFileToMp3Request',
        method: 'POST',
        actions: {
          success: convertAudioFileToMp3Success,
        },
        params,
        ...meta,
      }),
    ],
    [types.UPLOAD_VIDEO_FILE_AS_GIF_REQUEST]: [
      identity,
      ({ tempId, ...params }, meta) => ({
        async: true,
        route: `/api/video-drafts/gif/${tempId}`,
        selectorName: 'uploadVideoFileAsGifRequest',
        method: 'POST',
        actions: {
          meta,
          success: uploadVideoFileAsGifSuccess,
        },
        params,
        ...meta,
      }),
    ],
    [types.UPLOAD_VIDEO_FILE_TO_TRIM_REQUEST]: [
      identity,
      ({ tempId, ...params }, meta) => ({
        async: true,
        route: `/api/video-drafts/trim-video/${tempId}`,
        selectorName: 'uploadVideoFileToTrimRequest',
        method: 'POST',
        actions: {
          meta,
          success: uploadVideoFileToTrimSuccess,
        },
        params,
        ...meta,
      }),
    ],
  },
  types.SET_IMAGE_CROPPER_DATA,
  types.SET_RENDERER_SETTINGS,
  types.SET_AUTOPLAY,
  types.SET_LOOP,
  types.SET_VERSION,
  types.SAVE_TEXT_LAYERS,
  types.SET_TEXT_LAYERS,
  types.SAVE_TEXT_GROUPS,
  types.SET_TEXT_GROUPS,
  types.CHANGE_TEXT_FIELD,
  types.SET_TEXT_FIELD_VALUE,
  types.SET_TEXT_SCALE,
  types.CHANGE_TEXT_SCALE,
  types.SET_TEXT_VARIABLE,
  types.CHANGE_TEXT_VARIABLE,
  types.CHANGE_TEXT_STATUS,
  types.SET_TEXT_STATUS,
  types.CHANGE_TEXT_COLOR,
  types.SET_TEXT_COLOR,
  types.SAVE_DATA,
  types.SET_CONTROLS,
  types.CHANGE_CONTROL_VALUE,
  types.SET_EDITOR_COLORS,
  types.SET_IMAGE_DATA,
  types.SET_AUDIO_DURATION,
  types.SET_AUDIO_FADE,
  types.SET_AUDIO_MUTE,
  types.SET_AUDIO_FILE_INFO,
  types.CHANGE_LAST_EDIT_FRAME,
  types.SAVE_FONTS,
  types.CHANGE_FONT_FAMILY,
  types.SET_FONT_FAMILY,
  types.ADAPTATION_IMAGE_TO_DRAFT,
  types.ADAPTATION_IMAGE_TO_DRAFT_SUCCESS,
  types.SET_IMAGE_TEMP_FILE_ID,
  types.CONVERT_AUDIO_FILE_TO__MP3_SUCCESS,
  types.CONVERT_AUDIO_TO_MP3,
  types.SET_CONVERT_AUDIO,
  types.EDITOR_HARD_RESET,
  types.SET_IMAGE_ZOOM,
  types.CHANGE_IMAGE_CONTROL,
  types.SET_IMAGE_CONTROL,
  types.RESET_TO_DEFAULT,
  types.SAVE_EDIT_FRAMES,
  types.GO_TO_EDIT_FRAME_BY_INDEX,
  types.UPLOAD_VIDEO_FILE_AS_GIF,
  types.UPLOAD_VIDEO_FILE_AS_GIF_SUCCESS,
  types.SET_PREPARING_TO_GIF_STATUS,
  types.SET_SAVING_DRAFT_FILE_STATUS,
  types.SET_PREV_VERSION_DATA,
  types.SET_IS_READY,
  types.SET_IMAGE_READY,
  types.CHANGE_TEXT_GROUP_VARIABLE,
  types.SET_TEXT_GROUP_VARIABLE,
  types.SET_AUDIO_FETCH_STATUS,
  types.SET_PROJECT_NAME,
  types.RESET_IMAGES_DATA,
  types.SET_IMAGE_REF_URL,
  types.UPLOAD_VIDEO_FILE_TO_TRIM,
  types.SET_VIDEO_ID,
  types.UPLOAD_VIDEO_FILE_TO_TRIM_SUCCESS,
  types.SAVE_TRIMMED_VIDEO,
  types.SET_LAYER_TYPE,
  types.SET_VERSION_BACKGROUND,
  types.SET_PREV_VERSION,
  types.SET_BACKGROUND_IMAGE_TO_DEFAULT,
  types.RESET_VERSION_BACKGROUND,
  types.SET_IMAGE_FILE_TYPE,
  types.SET_DESCRIPTION,
  types.SET_PLAYER_STATE,
  types.SET_PERCENT_OF_PLAYER,
  types.SET_IMAGE_VIDEO_TEMP_ID,
  types.SET_IMAGE_VIDEO_FILE_ID,
  types.SET_ORIGINAL_IMAGE_ID,
  types.SET_INITIAL_SWITCHER_STATE,
  types.SET_HAS_AUDIO_STATUS,
  types.SET_CUSTOM_LENGTH,
  types.SET_AUDIO_POINTS,
  types.SET_VIDEO_TYPE_SYNCHRONIZATION,
  types.GO_TO_LATEST_TEXT_EDIT_FRAME,
  types.PREPARE_IMAGE_EDIT_FRAMES,
  types.SAVE_IMAGE_EDIT_FRAMES,
  types.SAVE_VISIBLE_TEXT_GROUPS,
  types.SET_VISIBLE_TEXT_GROUPS,
);
