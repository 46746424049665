import styled from 'styled-components';
import {
  layout, color, typography,
} from 'styled-system';
import { space } from '@styled-system/space';

export const Root = styled.tr`
  background-color: ${({ theme }) => theme.colors.WHITE};
  ${({ isHead, theme }) => !isHead && `
  &:nth-child(odd) {
    background-color: ${theme.colors.EXTRA_LIGHT_BLUE};
  }`}
  ${({ isBoxShadow }) => isBoxShadow && `
    box-shadow: 0 0 3px rgba(0,0,0,.05),0 1px 1px rgba(0,0,0,.05);
  `}
  ${({ isCursor }) => isCursor && `
    cursor: pointer;
  `}
  ${layout};
  ${space};
  ${color};
  ${typography};
`;
