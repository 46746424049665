import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 30px;
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  height: inherit;
  
  @media(${({ theme }) => theme.devices.LG}) {
    position: static;
  }

  @media(${({ theme }) => theme.devices.MD}) {
    width: 50%;
  }
  
  @media(${({ theme }) => theme.devices.SM}) {
    position: static;
    display: flex;
    justify-content: center;
    order: 3;
    width: 100%;
    max-height: initial;
    margin: 45px 0 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-height: 100%;
  margin-bottom: 8px;
  @media(${({ theme }) => theme.devices.XS}) {
    box-sizing: border-box;
  }
`;

export const Header = styled.div`
  position: relative;
  z-index: 1;
`;

export const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  box-sizing: border-box;
  padding: 0 45px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color:${({ theme, isActive }) => (isActive ? theme.colors.MAIN_BACKGROUND : theme.colors.TAB_BACKGROUND)};
  transform: translateY(${({ isActive }) => (isActive ? '3px' : '10px')});
  transition-duration: .25s;
  cursor: pointer;
  ${({ isActive }) => !isActive && `:hover {
    opacity: .8;
    transform: translateY(4px);
  }`}
  svg path {
    stroke: ${({ theme, isActive }) => (isActive ? theme.colors.BLACK : theme.colors.WHITE)};
  }

  @media(${({ theme }) => theme.devices.STANDARD}) {
    padding: 0 30px;
  }

  @media(${({ theme }) => theme.devices.LG}) {
    padding: 0 28px;
  }

  @media(${({ theme }) => theme.devices.LG}) {
    padding: 0 28px;
  }

  @media(${({ theme }) => theme.devices.XS}) {
    padding: 0 22px;
  }
`;

export const TabWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 710px;
  border-radius: 10px 10px 0 10px;
  box-sizing: border-box;
  padding: 10px;
  z-index: 2;
  background-color: #E3F3FF;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.03), 0 6px 10px 2px rgba(0, 0, 0, 0.08);
  @media(${({ theme }) => theme.devices.SM}) {
    min-height: calc(100vh - 108px);
    margin-bottom: 8px;
  }
`;

export const Tab = styled.div`
  position: absolute;
  top: -500vh;
  width: 100%;
  box-sizing: border-box;
  padding: 35px 15px 0 15px;
  ${({ isActive }) => isActive && `
    position: static;
    display: block;
  `}
  @media(${({ theme }) => theme.devices.SM}) {
    height: initial;
  }
  
  @media(${({ theme }) => theme.devices.XS_X}) {
    padding: 10px 12px 0 12px;
  }
`;
