import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';

import './style.sass';
import SelectThemesList from 'components/SelectThemesList/SelectThemesList';
import { useClickOutside } from 'hooks';

const SelectThemesModal = ({ onChange, value, isPortal }) => {
  const [selectedFonts] = useState(value);
  const ref = useRef(null);
  const onChangeCb = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    onChange(selectedFonts);
  };
  useClickOutside({
    ref,
    callback: () => onChangeCb,
  });

  return (
    <div className={cn({ 'select-modal-portal-themes': isPortal })}>
      <div className="select-modal" ref={ref}>
        <div className="select-modal__left-col">
          <h3 className="select-modal__title">Available themes</h3>
          <div className="select-modal__prev-text">
            Choose the Tumplates you would like to see on your Theme Selector.
            {' '}
            <br />
            {' '}
            <br />
            If you would like to get a custom made Tumplate, contact our sales team.
          </div>
          <div className="select-modal__row-request">
            <a
              href="https://www.tumplate.com/contact"
              target="_blank"
              rel="noopener noreferrer"
              className="button select-modal__request button--black"
            >
              Request
            </a>
          </div>
          <button
            type="button"
            className="button select-modal__done"
            onClick={onChangeCb}
          >
            Done
          </button>
        </div>
        <div className="select-modal__right-col">
          <Scrollbars>
            <SelectThemesList />
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

SelectThemesModal.propTypes = {
  isPortal: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Array).isRequired,
};

export default SelectThemesModal;
