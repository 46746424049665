import styled from 'styled-components';

export const Text = styled.span`
  font-size: 16px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
`;
