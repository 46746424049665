import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Icon, Toggle } from 'ui-kit';
import { notify } from 'utils/helpers';
import { webhookUpdateRequest } from 'state/webhooks/actions';
import { webhookService } from 'api';
import { BUTTON_STYLES } from '../constants';
import {
  Integration, Text, Wrapper, Row, IconButton,
} from '../Integrations.styled';

/**
 *
 * @param webhook {object}
 * @param setSelectedWebhookId {function}
 * @param setIsOpenedDeleteWebhookModal {function}
 * @param setIsOpenedWebhookModal {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const WebhookItem = ({
  webhook,
  setSelectedWebhookId,
  setIsOpenedDeleteWebhookModal,
  setIsOpenedWebhookModal,
}) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(webhook.url);
    notify.success(t('Webhook url was copied to clipboard!'));
  };

  const handleChangeStatus = useCallback(() => {
    dispatch(webhookUpdateRequest({
      id: webhook.id,
      data: {
        url: webhook.url,
        type: webhook.type,
        status: Number(!webhook.status),
      },
    }));
  }, [webhook]);

  const text = `${webhook.type.toUpperCase()} ${webhook.url}`;

  const handleRemove = useCallback(() => {
    setSelectedWebhookId(webhook.id);
    setIsOpenedDeleteWebhookModal(true);
  }, []);

  const handleUpdate = useCallback(() => {
    setSelectedWebhookId(webhook.id);
    setIsOpenedWebhookModal(true);
  }, []);

  const handleTestWebhook = useCallback(async () => {
    try {
      const { success } = await webhookService.testWebhook({
        type: webhook.type,
        url: webhook.url,
      });
      if (success) {
        notify.success('Webhook works correctly');
      } else {
        notify.error('Webhook works incorrectly');
      }
    } catch (e) {
      notify.error((t('An issue with testing of the webhook')));
    }
  }, [webhook]);

  return (
    <Wrapper>
      <Integration py="10px">
        <Text
          role="presentation"
          title={t('Click to copy')}
          onClick={handleCopyUrl}
          width="80%"
        >
          {text}
        </Text>
        <Row width="14%">
          <IconButton onClick={handleUpdate}>
            <Icon name="edit-icon" />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <Icon name="remove" />
          </IconButton>
        </Row>
      </Integration>
      <Row width="25%" ml="10px">
        <Button
          type="button"
          buttonTheme="submit"
          {...BUTTON_STYLES}
          onClick={handleTestWebhook}
          label={t('Test')}
          title={t('Test')}
        />
        <Toggle
          onChange={handleChangeStatus}
          value={webhook.status}
        />
      </Row>
    </Wrapper>
  );
};
