import React, { useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'components/forms';
import { changeFontFamily } from 'state/editor/actions';
import { getFonts, selectTextLayer } from 'state/editor/selectors';
import { Label, Row, Title } from './Header.styled';

/**
 *
 * @type {React.NamedExoticComponent<{readonly id?: number}>}
 */

export const Header = memo(({ id }) => {
  const dispatch = useDispatch();
  const layer = useSelector((state) => selectTextLayer(state)(id));

  const fonts = useSelector(getFonts);

  const handleChangeFont = ({ target: { name: fontName, value } }) => {
    dispatch(changeFontFamily([fontName, value]));
  };

  const fontsOptions = useMemo(() => fonts.map(({ fName }) => ({ label: fName, value: fName })), [fonts]);

  return (
    <Row>
      <Title>{layer.name}</Title>
      {layer.isFontSelector && (
      <Select
        id={`select-font-${id}`}
        name={id}
        onChange={handleChangeFont}
        value={layer.selectedFont}
        label={(
          <Label>
            {layer.selectedFont}
          </Label>
        )}
        options={fontsOptions}
      />
      )}
    </Row>
  );
});
