import styled from 'styled-components';
import { right } from 'styled-system';

export const TrimmerOverlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  ${right};
  z-index: 5;
  background: rgba(255, 255, 255, 0.7);
`;
