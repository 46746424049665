import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  @media(${({ theme }) => theme.devices.SM}) {
    align-items: flex-end;
  }
`;
