export default {
  WEBHOOK_CREATE_REQUEST: 'WEBHOOK_CREATE_REQUEST',
  WEBHOOK_CREATE_SUCCESS: 'WEBHOOK_CREATE_SUCCESS',

  WEBHOOKS_GET_REQUEST: 'WEBHOOKS_GET_REQUEST',
  WEBHOOKS_GET_SUCCESS: 'WEBHOOKS_GET_SUCCESS',

  WEBHOOK_UPDATE_REQUEST: 'WEBHOOK_UPDATE_REQUEST',
  WEBHOOK_UPDATE_SUCCESS: 'WEBHOOK_UPDATE_SUCCESS',

  WEBHOOK_DELETE_REQUEST: 'WEBHOOK_DELETE_REQUEST',
  WEBHOOK_DELETE_SUCCESS: 'WEBHOOK_DELETE_SUCCESS',

  WEBHOOK_REQUEST_FAILURE: 'WEBHOOK_REQUEST_FAILURE',

  GET_WEBHOOK_PARAMS_REQUEST: 'GET_WEBHOOK_PARAMS_REQUEST',
  GET_WEBHOOK_PARAMS_SUCCESS: 'GET_WEBHOOK_PARAMS_SUCCESS',
};
