import { createSelector } from 'reselect';
import { pathOr, propOr, identity } from 'ramda';
import { USER_ROLE } from '../../constants/permissions';

const storeName = 'user';

export const getUser = createSelector(
  pathOr({}, [storeName, 'user']),
  identity,
);

export const getUserZapierKey = createSelector(
  getUser,
  propOr('Not connected', 'zapier_api_key'),
);

export const getIsAuthenticated = createSelector(
  pathOr(false, [storeName, 'isAuthenticated']),
  identity,
);

export const getUpdateUserRequestPending = createSelector(
  pathOr(false, ['pending', 'updateUserProfileRequest']),
  identity,
);

export const selectUserType = createSelector(
  pathOr(USER_ROLE.USER, [storeName, 'user', 'type']),
  identity,
);
