import React from 'react';
import { useDispatch } from 'react-redux';
import { Select, StatusLabel } from 'ui-kit';
import { videosActions } from 'state/videos';
import { CLIENT_STATUSES } from 'constants/myFiles';

const OPTIONS = [
  {
    value: CLIENT_STATUSES.IN_REVIEW,
    label: 'In Review',
  },
  {
    value: CLIENT_STATUSES.APPROVED,
    label: 'Approved',
  },
  {
    value: CLIENT_STATUSES.UNAPPROVED,
    label: 'Unapproved',
  },
];

export const StatusFile = ({ id, clientStatus }) => {
  const dispatch = useDispatch();
  const handleChangeStatus = ({ target: { name: fileId, value } }) => {
    dispatch(videosActions.updateVideoClientStatusRequest({ id: fileId, client_status: value }));
  };

  return (
    <Select
      id={`status-file-id${id}`}
      name={id}
      onChange={handleChangeStatus}
      value={clientStatus}
      label={(
        <StatusLabel
          isApproved={CLIENT_STATUSES.APPROVED === clientStatus}
          isCanceled={CLIENT_STATUSES.UNAPPROVED === clientStatus}
        />
)}
      options={OPTIONS}
    />
  );
};
