import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 11px;
  .checkbox__pseudo {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
`;
