import { handleActions } from 'redux-actions';
import {
  pathOr, map, compose, objOf, prop,
} from 'ramda';
import { mergeIn } from 'utils/helpers/ramdaStateHelpers';
import types from './types';

const reducer = handleActions({
  [types.SET_DATA_OF_FONTS]: mergeIn(pathOr([], ['payload', 'data'])),
  [types.UNSELECT_FONT]: mergeIn(({ payload: { meta: { id } } }, { data }) => compose(
    objOf('data'),
    map((item) => (item.id === Number(id)
      ? ({ ...item, selected: 0 }) : item)),
  )(data)),
  [types.SELECT_FONT]: mergeIn(
    ({ payload: { meta: { id } } },
      { data }) => compose(objOf('data'),
      map((item) => (item.id === Number(id)
        ? ({ ...item, selected: 1 }) : item)))(data),
  ),
  [types.SET_FONTS_FRESH_STATUS]: mergeIn(compose(objOf('isFresh'), prop('payload'))),
}, {

});

export default reducer;
