import styled from 'styled-components';
import { layout, flexbox } from 'styled-system';
import { space } from '@styled-system/space';

export const Form = styled.form`
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  ${layout};
  ${space};
  ${flexbox};
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  max-width: 100px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.MAIN};
  color: ${({ theme }) => theme.colors.WHITE};
  padding: 2px 0;
  font-size: 14px;
  border-radius: 7px;
`;
