import { useDispatch, useSelector } from 'react-redux';
import { equals, path } from 'ramda';
import {
  useCallback, useEffect, useMemo,
} from 'react';
import { decRGB2Hex } from 'utils/animationHelpers';
import { changeTintAmount, changeTintColor } from 'state/animation/actions';
import { editorSelectors } from 'state/editor';
import { searchArrayInString } from 'utils/helpers/animationsPath';
import { IMAGE_PATH, IMAGES_PROP } from 'constants/animationsPath';

const TINT_OPTIONS_LENGTH = 11;

const DEFAULT_TINT_OPTIONS_FROM_ANIMATION = ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'];

const makeBackgroundColor = (presetsColors, chosen) => presetsColors[chosen - 1] || '#000';
const makeOpacity = (tint) => ((1 / 10) * (tint - 1)).toFixed(2);

/**
 *
 * @param data {object}
 * @param cropperRef {object}
 * @param imageGifRef {object}
 * @returns {{setTintColor: ((function({target: {value: {color: *, index: *, custom: *}}}): void)|*), isGlobalColor: boolean, tintColor: (number|string), tintOptions: unknown, onTintChange: (function({target: {value: *}}): *), tint: (*|(function(*): (*))), tintAmount: (*|string)}}
 */

export const useTint = ({
  data, cropperRef, imageGifRef,
}) => {
  const dispatch = useDispatch();
  const presetsColors = useSelector(editorSelectors.presetColorsSelector);
  const name = data[IMAGES_PROP.NAME];
  const tint = path(IMAGE_PATH.TINT_AMOUNT, data);
  const chosen = path(IMAGE_PATH.CHOSEN_COLOR, data);
  const isGlobalColor = useMemo(() => chosen !== 0, [chosen]);
  const color = useMemo(() => decRGB2Hex(path(IMAGE_PATH.TINT_COLOR, data)), [data]);
  const tintOptionsArrayPulled = path(IMAGE_PATH.TINT_OPTIONS_ARRAY, data);

  const tintOptions = useMemo(() => {
    const arrayOfOptions = searchArrayInString(tintOptionsArrayPulled || '');
    const isDefaultOptions = equals(arrayOfOptions, DEFAULT_TINT_OPTIONS_FROM_ANIMATION);
    if (arrayOfOptions) {
      return arrayOfOptions.map((option, index) => ({
        label: `${option} ${isDefaultOptions ? 'tint' : ''}`,
        value: index + 1,
      }));
    }
  }, [tintOptionsArrayPulled]);

  const onTintChange = useCallback(
    ({ target: { value: tintValue } }) => dispatch(changeTintAmount([
      name,
      parseInt(tintValue, 10),
    ])),
    [name],
  );

  const setTintColor = useCallback(
    ({
      target: { value: { color: currentColor, index, custom } },
    }) => {
      dispatch(changeTintColor({
        name, isGlobal: !custom, index: index + 1, color: currentColor,
      }));
    }, [name],
  );

  useEffect(() => {
    if (cropperRef.current) {
      const { cropper: { container } } = cropperRef.current;
      if (container && tintOptions.length === TINT_OPTIONS_LENGTH) {
        const pseudoTintElement = document.createElement('div');
        const cropBoxElement = container.querySelector('.cropper-crop-box');
        pseudoTintElement.className = 'pseudo-tint';
        pseudoTintElement.style.backgroundColor = makeBackgroundColor(presetsColors, chosen);
        pseudoTintElement.style.opacity = makeOpacity(tint);
        if (cropBoxElement) {
          cropBoxElement.appendChild(pseudoTintElement);
          return () => pseudoTintElement.remove();
        }
      }
    }
  }, [cropperRef, tint, chosen, presetsColors, tintOptions]);

  useEffect(() => {
    if (imageGifRef.current && tintOptions.length === TINT_OPTIONS_LENGTH) {
      const pseudoTintElement = document.createElement('div');
      pseudoTintElement.className = 'image-editor__tint-gif';
      pseudoTintElement.style.cssText += 'position: absolute; top: 0; width: 100%; height: 100%;';
      pseudoTintElement.style.backgroundColor = makeBackgroundColor(presetsColors, chosen);
      pseudoTintElement.style.opacity = makeOpacity(tint);
      imageGifRef.current.parentNode.insertBefore(pseudoTintElement, imageGifRef.current.nextSibling);
      return () => pseudoTintElement.remove();
    }
  }, [imageGifRef, presetsColors, chosen, tint]);

  return {
    tintAmount: tintOptions[tint - 1] ? tintOptions[tint - 1].label : '-',
    tint,
    isGlobalColor,
    tintColor: isGlobalColor ? chosen - 1 : color,
    tintOptions,
    setTintColor,
    onTintChange,
  };
};
