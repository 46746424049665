import React, { memo } from 'react';
import { useMyFiles } from 'hooks';
import { SearchGroup } from 'ui-kit';
import { SelectedItemsControls } from '../SelectedItemsControls';
import { Root } from './RightToolBar.styled';

/**
 *
 * @param searchValue {string}
 * @param setSearchValue {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const RightToolBar = memo(({ searchValue, setSearchValue }) => {
  const { isSelecting } = useMyFiles();
  return (
    <Root>
      <SearchGroup value={searchValue} onSearch={setSearchValue} />
      {isSelecting && (<SelectedItemsControls />)}
    </Root>
  );
});
