import React from 'react';
import { is } from 'ramda';
import { DEFAULT_INDEX } from 'constants/common';
import {
  Label, StyledSelect, Wrapper, Option, Error, LabelText,
} from './Select.styled';

/**
 *
 * @param label {function | string | React.Node | JSX.Element}
 * @param labelText {string}
 * @param options {array}
 * @param id {string}
 * @param name {string | number}
 * @param onChange {function}
 * @param value {number | string}
 * @param labelStyleProps {object} style props for the label
 * @param error {string}
 * @param errorStyleProps {object} style props for the error field
 * @param props {object} style for wrapper
 * @param ref {object | undefined}
 * @param isDefaultSelectedOnOpen {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const Select = ({
  label = '-',
  labelText = '',
  options = [],
  id = '',
  name,
  onChange,
  value,
  labelStyleProps,
  error,
  errorStyleProps,
  ref,
  isDefaultSelectedOnOpen,
  ...props
}) => (
  <Wrapper className="select" {...props}>
    <Label
      className="select__label"
      htmlFor={id}
    >
      {labelText.length > 0 && (<LabelText {...labelStyleProps}>{labelText}</LabelText>)}
      {is(Function, label) ? label(value) : label}
    </Label>
    <StyledSelect
      value={value || DEFAULT_INDEX}
      onChange={onChange}
      className="select__select"
      name={name}
      id={id.toString()}
      ref={ref}
    >
      {
        options.map((option, index) => (
          <Option
            key={option.key || `select-${option.value}-${index}`}
            value={option.value}
          >
            {option.label}
          </Option>
        ))
            }
    </StyledSelect>
    {error && (<Error {...errorStyleProps}>{error}</Error>)}
  </Wrapper>
);
