import React from 'react';

/**
 *
 * @param children {React.ReactNode}
 * @returns {JSX.Element}
 * @constructor
 */

export const Head = ({ children }) => {
  return (
    <thead>{children}</thead>
  );
};
