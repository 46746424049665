import styled from 'styled-components';
import { Checkbox } from 'ui-kit';

export const Root = styled.div`
  position: relative;
  height: 75px;
  width: 75px;
  cursor: pointer;
`;

export const Preview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PreviewCheckbox = styled(Checkbox)`
  position: absolute !important;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: none;
`;
