import { handleActions } from 'redux-actions';
import {
  compose, of, path, concat, reject, propEq, prop, objOf, pathOr,
} from 'ramda';
import { mergeDeep, mergeIn } from 'utils/helpers/ramdaStateHelpers';
import { DEFAULT_COLORS } from 'constants/editor';
import types from './types';

const reducer = handleActions({
  [types.SET_COLORS]: mergeIn(compose(objOf('data'),
    concat(DEFAULT_COLORS),
    pathOr([], ['payload', 'data', 'data']))),
  [types.SET_COLOR]: mergeIn(({ payload }, { data }) => ({
    data: compose(concat(data), of, path(['data', 'model']))(payload),
  })),
  [types.SET_COLORS_FRESH_STATUS]: mergeDeep(compose(objOf('isFresh'), prop('payload'))), // when colors preset is not fresh we must reorder animation list after close Design Presets Page
  [types.DELETE_COLOR]: mergeIn(({ payload: { colorId } }, { data }) => ({
    data: reject(propEq('id', colorId), data),
  })),
}, {});

export default reducer;
