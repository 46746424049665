import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeStringBySeconds } from 'utils/helpers/commonHelpers';
import { PlayerTrack } from 'ui-kit';
import { AUDIO_PLAYER_STATE } from 'constants/editor';
import { animationLengthSelector } from 'state/animation/selectors';
import { getPlayerState } from 'state/editor/selectors';
import { setPercentOfPlayer, setPlayerState } from 'state/editor/actions';
import { useEditor } from 'hooks';

const TIMEOUT = 10;
const MAX_PERCENTS = 100;

/**
 *
 * @param lottieElement {object}
 * @param setFrameToLottie {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const Track = ({
  lottieElement, setFrameToLottie,
}) => {
  const dispatch = useDispatch();
  const animationLength = useSelector(animationLengthSelector);
  const playerState = useSelector(getPlayerState);

  const { isPaused, setIsPaused } = useEditor();

  const [currentProgressPercent, setCurrentProgressPercent] = useState(0);

  const setFrameByRange = useCallback((value) => {
    setFrameToLottie(
      (lottieElement.current.anim.totalFrames / MAX_PERCENTS) * value, 'goToAndStopSuper',
    );
    if (playerState !== AUDIO_PLAYER_STATE.NOT_USED) {
      dispatch(setPlayerState(AUDIO_PLAYER_STATE.PAUSE));
      dispatch(setPercentOfPlayer(value));
    }
    setIsPaused(true);
  }, [playerState, lottieElement]);

  const makeSpecialLabel = useCallback((value) => getTimeStringBySeconds(Math.ceil((animationLength / MAX_PERCENTS) * value)) || 0,
    [animationLength]);

  // set progress to the track, check state of lottie after changing point of the audio
  const makeInterval = useMemo(() => setInterval(() => {
    if (lottieElement.current) {
      const { currentFrame, totalFrames } = lottieElement.current.anim;
      if (currentFrame > 1) {
        setCurrentProgressPercent((currentFrame / totalFrames) * MAX_PERCENTS);
      }
      if (lottieElement.current.anim.isPaused !== isPaused) {
        setIsPaused(!isPaused);
      }
    }
  }, TIMEOUT), [lottieElement.current, isPaused]);

  // change current time of input range regarding frames
  useEffect(() => {
    let interval;

    if (lottieElement.current) {
      interval = makeInterval;
    }
    return () => clearInterval(interval);
  }, [lottieElement.current, makeInterval]);

  return (
    <PlayerTrack
      maxValue={MAX_PERCENTS}
      value={currentProgressPercent}
      formatLabel={makeSpecialLabel}
      onChange={setFrameByRange}
    />
  );
};
