import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper, SearchField } from './SearchGroup.styled';

/**
 *
 * @param value {string}
 * @param onSearch {function}
 * @returns {JSX.Element}
 * @constructor
 */
export const SearchGroup = ({ value, onSearch }) => {
  const { t } = useTranslation(['account', 'common', 'forms']);
  return (
    <Wrapper>
      <SearchField
        type="text"
        placeholder="Search"
        value={value}
        onChange={(e) => onSearch(e.target.value)}
      />
      <Link to="/">
        {t('Done')}
      </Link>
    </Wrapper>
  );
};
