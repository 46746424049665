import React from 'react';
import { Input, Label } from './Radio.styled';

/**
 *
 * @param id {string | number}
 * @param name {string}
 * @param isDisabled {boolean}
 * @param isChecked {boolean}
 * @param onChange {function}
 * @param label {string | number | React.ReactNode}
 * @param value {string | number}
 * @param radioTheme {string}
 * @param props {object} style_props
 * @returns {JSX.Element}
 * @constructor
 */

export const Radio = ({
  id,
  name,
  isDisabled = false,
  isChecked,
  onChange,
  label = '',
  value = '',
  radioTheme = 'primary',
  ...props
}) => (
  <>
    <Input
      type="radio"
      name={name}
      value={value}
      id={id}
      disabled={isDisabled}
      checked={isChecked}
      onChange={onChange}
    />
    <Label
      htmlFor={id}
      radioTheme={radioTheme}
      isChecked={isChecked}
      isDisabled={isDisabled}
      {...props}
    >{label}
    </Label>
  </>
);
