import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { prop } from 'ramda';
import PropTypes from 'prop-types';

import './style.sass';
import { Checkbox, Preloader } from 'ui-kit';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';

const SelectFontsModal = ({
  onChange, isPortal, fonts, isSelectingPending,
}) => (
  <div className={`${isPortal ? 'select-modal-portal-fonts' : ''}`}>
    <div className="select-modal select-modal__fonts">
      <div className="select-modal__left-col">
        <h3 className="select-modal__title">Available fonts</h3>
        <div className="select-modal__prev-text">
          Choose the font families
          {' '}
          <br />
          {' '}
          you wish to bring up as
          {' '}
          <br />
          {' '}
          font options in your Tumplates.
          <br />
          <br />
          To request a new set of fonts
          {' '}
          <br />
          {' '}
          to be added, please contact
          {' '}
          <br />
          {' '}
          our graphics team.
        </div>
        <div className="select-modal__row-request">
          <button type="button" className="button select-modal__request button--black">Request</button>
        </div>
        <button
          type="button"
          className="button select-modal__done"
          onClick={onChange}
        >
          Done
        </button>
      </div>
      <div className="select-modal__right-col">
        <Scrollbars>
          <Preloader
            type={PRELOADER_TYPES.CONTAINER}
            size={PRELOADER_SIZES.SMALL}
            isLoading={isSelectingPending}
          >
            <div className="font-card__container">
              {
                      fonts.map((font) => (
                        <div key={prop('id', font)} className="select-modal__font-card font-card">
                          <ul className="font-card__list">
                            <li className="font-card__item font--regular">{prop('title', font)}</li>
                          </ul>
                          <Checkbox
                            checked={prop('selected', font)}
                            className="checked-icon"
                            name={prop('id', font)}
                            id={`checkbox${prop('id', font)}`}
                            onChange={onChange}
                          />
                        </div>
                      ))
                    }
            </div>
          </Preloader>
        </Scrollbars>
      </div>
    </div>
  </div>
);

SelectFontsModal.propTypes = {
  onChange: PropTypes.func.isRequired,
  fonts: PropTypes.instanceOf(Array).isRequired,
  isPortal: PropTypes.bool.isRequired,
  isSelectingPending: PropTypes.bool.isRequired,
};

export default SelectFontsModal;
