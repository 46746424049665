import React from 'react';
import { useSelector } from 'react-redux';
import { Radio } from 'ui-kit';
import { getTextLayers } from 'state/editor/selectors';
import { Row } from './MobileTextSwitchers.styled';

/**
 *
 * @param list {array<number>}
 * @param activeTab {number}
 * @param onChangeTab {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const MobileTextSwitchers = ({ list, activeTab, onChangeTab }) => {
  const textLayers = useSelector(getTextLayers);
  return (
    <Row>
      {list.map((id, index) => {
        const { name } = textLayers[id];
        return (
          <Radio
            key={id}
            id={name}
            radioTheme="secondary"
            name="subgroup_fields"
            label={name}
            value={index}
            isChecked={activeTab === index}
            onChange={onChangeTab(index)}
          />
        );
      })}
    </Row>
  );
};
