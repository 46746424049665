import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 282px;
  padding: 0 10px 0 19px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.WHITE};;
  @media(${({ theme }) => theme.devices.SM}){
    width: 240px;
    padding: 0 8px;
  }
`;

export const Header = styled.div`;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  button {
    margin-left: auto;
    margin-right: 10px;
  }
  button:last-child {
    margin: 0
  }
`;

export const Title = styled.div`
  font-weight: 300;
`;

export const Body = styled.div`
  width: 100%;
  min-height: 295px;
  margin-right: -8px;
  & > div > div:first-child {
    padding-right: 8px;
    box-sizing: border-box;
  }
  @media(${({ theme }) => theme.devices.SM}) {
    max-height: 160px;
    min-height: auto;
  }
`;

export const Item = styled.div`
  width: 100%;
  max-width: calc(100% - 12px);
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 5px;
`;

export const ItemWrapper = styled.div`
  max-width: calc(100% - 32px);
  position: relative;
  width: initial;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  max-height: 32px;
  padding: 0 14px;
  line-height: 32px;
  background-color: ${({ theme }) => theme.colors.MAIN};
  border-radius: 30px;
  :hover {
    background-color: #000;
    cursor: pointer;
    button {
      display: block;
    }
  }
`;

export const ItemTitle = styled.h6`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.WHITE};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .text-cut {
    display: flex;
  }
`;

export const SelectLabel = styled.div`
  min-width: 75px;
  max-width: 112px;
  height: 32px;
  display: flex;
  justify-content: center;
  padding: 0 12px;
  box-sizing: border-box;
  align-items: center;
  margin-right: 7px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  white-space: nowrap;
  color: #000;
  @media(${({ theme }) => theme.devices.LG}){
    width: 100%;
    max-width: 90px;
    border-radius: 0;
    margin-right: 0;
  }
  @media(${({ theme }) => theme.devices.XS_X}){
    min-width: 42px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
`;

export const Label = styled.div`
  box-sizing: border-box;
  width: 75px;
  white-space: nowrap;
  height: 32px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  cursor: pointer;
  ${({ isChecked, theme }) => isChecked && `
    color: ${theme.colors.WHITE};
    background-color: ${theme.colors.MAIN};
  `}
  &:hover {
    color: ${({ theme }) => theme.colors.WHITE};
    background-color: ${({ theme }) => theme.colors.LIGHT_BLUE};
  }
  @media(${({ theme }) => theme.devices.LG}) {
    width: 100%;
    border-radius: 0;
  }
  @media(${({ theme }) => theme.devices.XS_X}) {
    min-width: 42px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
`;

export const CancelButton = styled.button`
  position: absolute;
  top: 50%;
  left: -10px;
  margin-top: -6px;
  padding: 0;
  width: 15px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: none;
  @media(${({ theme }) => theme.devices.SM}){
    display: block;
  }
  img {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 100%;
  }
`;

export const RootForm = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.02), 0 4px 6px 1px rgba(0, 0, 0, 0.06);
  @media(${({ theme }) => theme.devices.LG}){
    position: initial;
  }
`;
