import {
  compose, curry, find,
  has, identity, includes, is, multiply, path, propOr,
} from 'ramda';
import axios from 'axios';
import { RENDER_STATUSES_LISTS, RENDER_TYPES, RENDER_TYPES_FOR_API } from 'constants/rendering';
import { DOWNLOAD_FILE_FORMATS } from 'constants/myFiles';
import { FILE_SIZES } from 'constants/common';
import { ROUTES } from 'constants/api.routes';
import { notifyStore } from './notifyStore';
import { notify } from './notificationHelpers';
import { getApiUrl } from './requestHelpers';

export const createFileByUrl = async (url, fileName, fileType, options) => {
  const response = await fetch(url, options);
  const data = await response.blob();
  return new File([data], fileName, { type: fileType });
};

export const draftsFilesStorage = (() => {
  let files = {};
  let handlersInformation = {};

  const subscriber = (() => ({
    call: (fileData, fileName) => {
      if (handlersInformation[fileName]) {
        const { callback, selectorName } = handlersInformation[fileName];
        if (is(Function, callback)) {
          if (!selectorName) {
            callback(files);
          } else if (selectorName === fileName) {
            callback(fileData);
          }
        }
      }
    },
    handler: (fn, name) => {
      handlersInformation[name] = {
        callback: fn,
        selectorName: name,
      };
    },
  }))();

  return {
    addFile: (fileData, fileName, meta, isResetOld = false) => {
      if (has(fileName, files) && !isResetOld) {
        throw Error(`File with name ${fileName} already exist in the storage,
         you must delete old file before update`);
      }
      const filesStorageShape = {
        file: fileData,
        meta,
      };
      files[fileName] = filesStorageShape;
      subscriber.call(filesStorageShape, fileName);

      return files;
    },
    deleteFile: (fileName, isCall = true) => {
      if (!has(fileName, files)) {
        return false;
      }
      if (has(fileName, handlersInformation) && isCall) {
        subscriber.call(null, fileName);
      }
      delete handlersInformation[fileName];
      delete files[fileName];
      return true;
    },
    getFile: (fileName) => files[fileName],
    subscribe: subscriber.handler,
    resetAll: () => {
      files = {};
      handlersInformation = {};
    },
  };
})();

export const prettierTheUrlPathToImages = (url) => {
  // When assetUrl consider `uploads` we suggest that is it url to brand logo
  const isCompanyLogoUrl = /uploads/g.test(url);
  const apiUrl = getApiUrl();

  if (isCompanyLogoUrl) {
    const [, pathToAccess] = url.split('/dist/..');
    return `${apiUrl}/files?path=${pathToAccess}`;
  }
  const [, pathToAccess] = url.split('/dist/');
  return `${apiUrl}/files?path=/dist/${pathToAccess}`;
};

export const bytesToMb = multiply(10 ** -6);
export const mbToBytes = multiply(10 ** 6);

export const validateFileSize = ({
  maxSize,
  onSuccess = () => {},
  onError = () => {},
}) => (e) => {
  const file = path(['currentTarget', 'files', 0], e) || e;
  if (file.size > maxSize) onError(e);
  else onSuccess(e);
};

export const getFileTypeByUrl = async (fileUrl, success = identity, error = identity) => {
  try {
    const file = await fetch(fileUrl);
    const blob = await file.blob();
    success(blob.type);
    return blob.type;
  } catch (e) {
    error(e);
    return e;
  }
};

export const getImageSizeByUrl = (fileUrl, success) => {
  const img = new Image();
  img.onload = function () {
    success({ width: this.width, height: this.height });
  };
  img.src = fileUrl;
};

export const getFileType = (type) => compose(
  propOr('-', 'label'),
  find(({ fileTypes }) => includes(type, fileTypes)),
)(RENDER_TYPES);

export const getRenderTypeByFileType = (type) => {
  return RENDER_TYPES.find((renderType) => renderType.fileTypes.includes(type));
};

const replaceDots = (name) => name.replace(/\./g, '_');

export const downloadFile = (href, name, type, status) => async (e) => {
  e.preventDefault();
  if (!includes(status, RENDER_STATUSES_LISTS.NOT_READY)) {
    notify.success(`Loading ${0}% of ${name}...`, '', { key: href, hideProgressBar: true, autoClose: false });
    const normalizedName = replaceDots(name); // we need to replace dots, because we had an issue in mac with dots in the name
    const { data } = await axios.get(href, {
      responseType: type === RENDER_TYPES_FOR_API.mp4 ? 'blob' : 'text',
      onDownloadProgress: (event) => {
        const percentage = parseInt(Math.round((event.loaded * 100) / event.total), 10);
        notifyStore.set({ content: `Loading ${percentage}% of ${name}...` }, href);
        return percentage;
      },
    });
    const hiddenElement = document.createElement('a');
    hiddenElement.href = type === RENDER_TYPES_FOR_API.mp4 ? URL.createObjectURL(data) : data;
    hiddenElement.target = '_blank';
    hiddenElement.setAttribute('rel', 'nofollow');
    hiddenElement.download = `${normalizedName}.${DOWNLOAD_FILE_FORMATS[type]}`;
    hiddenElement.click();
  } else {
    notify.warning('Cannot download the file');
  }
};

export const resizeImage = curry((file, maxWidth, maxHeight, callback) => {
  if (/(gif)/.test(file.type)) {
    callback(file);
    // We could not resize gifs files
    return false;
  }
  // Create an image
  const img = document.createElement('img');
  // Create a file reader
  const reader = new FileReader();
  // Set the image once loaded into file reader
  reader.onload = (e) => {
    img.src = e.target.result;
    img.onload = () => {
      // Resize the image
      const canvas = document.createElement('canvas');
      let { width, height } = img;
      if (width / maxWidth > height / maxHeight) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      } else {
        callback(file);
        // We should not resize change image file if it is small
        return false;
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(img, 0, 0, width, height);
      canvas.toBlob(callback, file.type);
    };
  };
  // Load files into file reader
  reader.readAsDataURL(file);
});

/**
 *
 * @param data {string} blobParts
 */
export const downloadZipHelper = (data) => {
  const blob = new Blob([data], { type: 'application/zip' });
  const urlToBlob = URL.createObjectURL(blob);
  const hiddenElement = document.createElement('a');
  hiddenElement.href = urlToBlob;
  hiddenElement.target = '_blank';
  hiddenElement.download = 'downloads.zip';
  hiddenElement.click();
};

/**
 *
 * @param bytes {number}
 * @returns {string}
 */

export const convertBytesToSize = (bytes) => {
  if (bytes === 0) return 'N/A';
  const FILE_SIZE_INDEX = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** FILE_SIZE_INDEX)} ${FILE_SIZES[FILE_SIZE_INDEX]}`;
};

/**
 *
 * @param name {string}
 * @returns {`${string}uploads/temp-files/${string}?base64=1`}
 */

export const getBase64TempFileUrl = (name) => {
  return `${process.env.REACT_APP_API_URL}/${ROUTES.TEMP_FILES.FILE}/${name}?base64=1`;
};
