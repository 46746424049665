import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getImagePreparingToGif, getImageRef } from 'state/editor/selectors';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { tumplateLoadAnimator } from 'assets/images';
import { Preloader } from 'ui-kit';
import { FILE_TYPES } from 'constants/files';
import {
  CropperContainer, GifImage, PreloaderText, RootStyle, StyledCropper,
} from './MediaView.styled';

export const MediaView = ({
  refId, imageGifRef, cropperRef, aspectRatio, cropData, onCrop,
}) => {
  const { t } = useTranslation('common');
  const imageRef = useSelector((store) => getImageRef(store)(refId));
  const isPreparingGif = useSelector((store) => getImagePreparingToGif(store)(refId));
  const isGif = imageRef?.fileType === FILE_TYPES.GIF;
  return (
    <RootStyle>
      <Preloader
        type={PRELOADER_TYPES.CERTAIN}
        size={PRELOADER_SIZES.SMALL}
        isDestroyChildren={false}
        isLoading={isPreparingGif}
        preloaderSrc={tumplateLoadAnimator}
        position="relative"
        overflow="hidden"
        height={isPreparingGif ? '250px' : 'auto'}
        preloaderChildren={(
          <PreloaderText>
            {t('Trimming video... Please wait...')}
          </PreloaderText>
        )}
      >
        {
          isGif ? (
            <GifImage ref={imageGifRef} src={imageRef?.value} alt={t('Uploaded GIF')} />
          ) : (
            <CropperContainer>
              <StyledCropper
                ref={cropperRef}
                aspectRatio={aspectRatio}
                src=""
                data={cropData}
                scalable
                cropend={onCrop}
                autoCropArea={1}
                viewMode={0}
                zoomable={false}
                background
                guides
              />
            </CropperContainer>
          )
        }
      </Preloader>
    </RootStyle>
  );
};
