import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';

export const {
  getFontsRequest,
  unselectFontRequest,
  selectFontRequest,
  setDataOfFonts,
  unselectFont,
  selectFont,
  setFontsFreshStatus,
} = createActions(
  {
    [types.GET_FONTS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/api/fonts',
        selectorName: 'getFontsRequest',
        method: 'GET',
        actions: {
          success: setDataOfFonts,
        },
        params,
        ...meta,
      }),
    ],
    [types.UNSELECT_FONT_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/api/fonts/${id}`,
        selectorName: 'unselectFontRequest',
        method: 'DELETE',
        actions: {
          success: (action) => unselectFont({ ...action, meta: { id } }),
        },
        params,
        ...meta,
      }),
    ],
    [types.SELECT_FONT_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/api/fonts/${id}`,
        selectorName: 'selectFontRequest',
        method: 'POST',
        actions: {
          success: (action) => selectFont({ ...action, meta: { id } }),
        },
        params,
        ...meta,
      }),
    ],
  },
  types.SET_DATA_OF_FONTS,
  types.SELECT_FONT,
  types.UNSELECT_FONT,
  types.SET_FONTS_FRESH_STATUS,
);
