import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_FORMATS } from 'constants/rendering';
import {
  MainText, SecondaryText, StyledDropZone, StyledIcon,
} from './DropZone.styled';

/**
 *
 * @param setFile {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const DropZone = ({ setFile }) => {
  const { t } = useTranslation('common');
  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const {
    getRootProps, getInputProps,
  } = useDropzone({
    multiple: false,
    onDrop,
    accept: [...AVAILABLE_FORMATS.IMAGES, ...AVAILABLE_FORMATS.VIDEOS],
  });

  return (
    <StyledDropZone {...getRootProps()}>
      <input {...getInputProps()} />
      <StyledIcon name="add-file" width={50} height={50} />
      <MainText>{t('Select file to upload')}</MainText>
      <SecondaryText>{t('or drag and drop, copy and paste files')}</SecondaryText>
      <SecondaryText>{t('Acceptable formats: .png, .jpg, .gif, .jpeg, .svg, .mp4, .mov, .quicktime')}</SecondaryText>
    </StyledDropZone>
  );
};
