import React, { useState, useEffect, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import ChangeLogMD from 'CHANGELOG.md';
import { Preloader } from 'ui-kit';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { Container } from './Changelog.styled';

const MIN_DURATION_PRELOADER = 800;

export const Changelog = () => {
  const [changelog, setChangelog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const parseChangelog = useCallback(async () => {
    try {
      const text = await fetch(ChangeLogMD).then((data) => data.text());
      setChangelog(text);
    } catch (e) {
      console.log(e, 'Thrown while executing parseChangelog');
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    parseChangelog();
  },
  []);

  return (
    <Preloader
      size={PRELOADER_SIZES.MIDDLE}
      type={PRELOADER_TYPES.CONTAINER}
      minDuration={MIN_DURATION_PRELOADER}
      isLoading={isLoading}
    >
      <Container>
        <ReactMarkdown>{changelog}</ReactMarkdown>
      </Container>
    </Preloader>
  );
};
