import { createSelector } from 'reselect';
import {
  compose, filter, find, identity, values, pathEq, pathOr, prop, propEq, propOr, path,
} from 'ramda';
import { NONE_DRAFT } from '../../constants/drafts';
import { getRatios } from '../../utils/animationHelpers/ratio';
import { animationDataSelector } from '../animation/selectors';
import { getDrafts, getSelectedDraft, getTopDrafts } from '../drafts/selectors';
import { getVideosEntities } from '../videos/selectors';

export const getAnimations = createSelector(pathOr([], ['animations', 'animations']), identity);

export const getActiveAnimations = createSelector(
  getAnimations,
  filter(propEq('status', 1)),
);

export const getSelectAnimationName = createSelector(
  pathOr([], ['animations', 'selectAnimation']),
  identity,
);

export const getSelectedAnimation = createSelector(
  getAnimations,
  getSelectAnimationName,
  getActiveAnimations,
  getSelectedDraft,
  getDrafts,
  getVideosEntities,
  (animations, selectAnimationName, activeAnimations, selectedDraft, drafts, files) => {
    if (Number(selectedDraft) && Number(selectedDraft) !== NONE_DRAFT.id) {
      let animationDraftName;
      animationDraftName = compose(
        prop('animation_name'),
        find(
          propEq('id', Number(selectedDraft)),
        ),
      )(drafts);
      if (!animationDraftName) {
        animationDraftName = compose(
          path(['draft', 'animation_name']),
          find(pathEq(['draft', 'id'], Number(selectedDraft))),
          values,
        )(files);
      }
      return find(propEq('name', animationDraftName), animations);
    }
    return find(propEq('name', selectAnimationName), activeAnimations);
  },
);

export const getRatiosSelectedAnimation = createSelector(
  getSelectedAnimation,
  (animation) => {
    const template = propOr([], 'template', animation);
    return template.map(({ aspectratio }) => aspectratio);
  },
);

export const getAnimationsFreshStatus = createSelector(
  pathOr(false, ['animations', 'isFresh']),
  identity,
);

export const getOptions = createSelector(
  animationDataSelector,
  (anim) => getRatios(anim),
);

export const getActiveTheme = createSelector(
  getSelectAnimationName,
  getSelectedDraft,
  getTopDrafts,
  (animationId, draftId, drafts) => {
    const selectedDraft = drafts.find((draft) => draft.id === draftId); // we need to check selected draft first of all
    return selectedDraft ? selectedDraft.id : animationId; // if we don't have draft, we use selected animation
  },
);

export const selectSelectedAnimationName = createSelector(
  [
    getSelectAnimationName,
    getActiveAnimations,
    getSelectedDraft,
    getTopDrafts,
    getDrafts],
  (selectAnimationName, activeAnimations, selectedDraft, topDrafts, drafts) => {
    const topDraft = topDrafts.find((item) => item.id === selectedDraft);
    if (topDraft) {
      return topDraft.animation_name;
    }
    const draft = drafts.find((item) => item.id === selectedDraft);
    if (draft) {
      return draft.animation_name;
    }
    const animation = activeAnimations.find((item) => item.name === selectAnimationName);
    if (animation) {
      return animation.name;
    }
    return null;
  },
);

export const selectAllAnimations = createSelector(
  pathOr([], ['animations', 'all']),
  identity,
);

export default getAnimations;
