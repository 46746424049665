import sagasManager from 'utils/sagasManager';
import {
  fork, put, select, take, call,
} from 'redux-saga/effects';
import { animationActions } from 'state/animation';
import { NONE_DRAFT } from 'constants/drafts';
import { draftsFilesStorage } from 'utils/helpers/filesHelper';
import AudioInstance from 'utils/helpers/audioHelper';
import { animationService } from 'api';
import { notify } from 'utils/helpers';
import animationsTypes from './types';
import * as animationsSelectors from './selectors';
import * as animationsActions from './actions';
import * as draftsActions from '../drafts/actions';
import * as editorActions from '../editor/actions';
import { fetchAllAnimationsError, fetchAllAnimationsSuccess } from './actions';
import { createVideoDraftFromAnimationRequest } from '../drafts/actions';

const DRAFT_ID = 'draft_id';

function* changeSelectedAnimationFork() {
  while (true) {
    const {
      payload: {
        name,
        isFetching = true,
        isResetSelectedDraft = true,
      },
    } = yield take(animationsTypes.CHANGE_SELECTED_ANIMATION);

    const prevAnimationIndex = yield select(animationsSelectors.getSelectAnimationName());
    const isAnimationWasChanged = prevAnimationIndex !== name;

    if (isAnimationWasChanged) {
      yield put(editorActions.setProjectName(''));
      yield put(animationsActions.setSelectAnimation(name));
    }

    if (isResetSelectedDraft) {
      yield put(draftsActions.setSelectedDraft(NONE_DRAFT.id));
      yield put(editorActions.setCustomLength(null));
      draftsFilesStorage.resetAll();
      AudioInstance.delete();
    }

    if (isFetching || !isAnimationWasChanged) {
      yield put(animationActions.animationHardReset());
      yield put(animationActions.updateAnimationData({ withHardReset: true }));
    }
  }
}

function* getAnimationSuccessFlow() {
  while (true) {
    const { payload } = yield take(animationsTypes.GET_ANIMATIONS_SUCCESS);
    const animation = payload.data.animations.find(({ status }) => Boolean(status));
    yield put(animationsActions.setAnimations(payload));

    // check if we have draft_id in query

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const draftId = searchParams.get(DRAFT_ID);
    if (draftId) {
      yield put(draftsActions.changeDraftTemplate({ target: { value: draftId } }));
    } else if (animation) {
      yield put(animationsActions.setSelectAnimation(animation?.name));
      yield put(createVideoDraftFromAnimationRequest({ name: animation?.name, ratio: animation?.template[0]?.version }));
    }
  }
}

function* updateAnimationFlow() {
  while (true) {
    const {
      payload: { data: { model: { name, status } } },
    } = yield take(animationsTypes.UPDATE_ANIMATION_SUCCESS);

    yield put(animationsActions.setAnimationStatus({ name, status }));
    yield put(animationsActions.setAnimationsFreshStatus(false));
  }
}

function* fetchAllAnimations() {
  while (true) {
    try {
      yield take(animationsTypes.FETCH_ALL_ANIMATIONS_REQUEST);
      const res = yield call(() => animationService.fetchAllAnimations());
      yield put(fetchAllAnimationsSuccess(res));
    } catch (e) {
      yield put(fetchAllAnimationsError());
      notify.error('Themes were not loaded');
    }
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(changeSelectedAnimationFork);
  yield fork(getAnimationSuccessFlow);
  yield fork(updateAnimationFlow);
  yield fork(fetchAllAnimations);
});
