import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 250px;
  padding: 0;
  @media(${({ theme }) => theme.devices.SM}) {
    width: 220px;
  }
`;
