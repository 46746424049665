import { API_METHODS } from 'constants/common';
import { ROUTES } from 'constants/api.routes';
import { catchError } from 'utils/helpers';
import { apiService } from './api.service';

export const linkService = {
  getLinkView: async (code, params = {}) => {
    return catchError(apiService({
      method: API_METHODS.GET,
      subUrl: `${ROUTES.LINKS.VIEW}/${code}`,
      config: { params },
    }));
  },
  getFilesOfLink: async (code, params = {}) => {
    return catchError(apiService({
      method: API_METHODS.GET,
      subUrl: `${ROUTES.LINKS.VIEW}/${code}/videos`,
      config: { params },
    }));
  },
  updateFileOfLink: async (code, data = {}) => {
    return catchError(apiService({
      method: API_METHODS.POST,
      subUrl: `${ROUTES.LINKS.VIEW}/${code}/video-status`,
      data,
    }));
  },
};
