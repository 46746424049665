import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';

export const {
  getCompanyRequest,
  getCompanyRequestSuccess,
  updateCompanyRequest,
  updateCompanyRequestSuccess,
  deleteCompanyRequest,
  deleteCompanyRequestSuccess,
  setCompanyIsEnabledBrandColors,
  setCompanyIsEnabledBrandFonts,
  getNotificationsRequest,
  getNotificationsSuccess,
  deleteNotificationRequest,
  deleteNotificationSuccess,
  updateNotifications,
} = createActions(
  {
    [types.GET_COMPANY_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/api/company',
        selectorName: 'getCompanyRequest',
        method: 'GET',
        actions: {
          success: getCompanyRequestSuccess,
        },
        ...meta,
      }),
    ],
    [types.UPDATE_COMPANY_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/api/company',
        selectorName: 'updateCompanyRequest',
        method: 'PUT',
        actions: {
          success: updateCompanyRequestSuccess,
        },
        params,
        ...meta,
      }),
    ],
    [types.DELETE_COMPANY_REQUEST]: [
      identity,
      (id, meta) => ({
        async: true,
        route: `/api/company/${id}`,
        selectorName: 'deleteCompanyRequest',
        method: 'DELETE',
        actions: {
          success: deleteCompanyRequestSuccess,
        },
        ...meta,
      }),
    ],
    [types.GET_NOTIFICATIONS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/api/notifications',
        selectorName: 'getNotificationsRequest',
        method: 'GET',
        actions: {
          success: getNotificationsSuccess,
        },
        params,
        ...meta,
      }),
    ],
    [types.DELETE_NOTIFICATION_REQUEST]: [
      identity,
      (id, meta) => ({
        async: true,
        route: `/api/notifications/${id}`,
        selectorName: 'deleteNotificationRequest',
        method: 'DELETE',
        actions: {
          success: deleteNotificationSuccess,
        },
        ...meta,
      }),
    ],
  },

  types.GET_COMPANY_REQUEST_SUCCESS,
  types.UPDATE_COMPANY_REQUEST_SUCCESS,
  types.DELETE_COMPANY_REQUEST_SUCCESS,

  types.SET_COMPANY_IS_ENABLED_BRAND_COLORS,
  types.SET_COMPANY_IS_ENABLED_BRAND_FONTS,

  types.GET_NOTIFICATIONS_SUCCESS,
  types.DELETE_NOTIFICATION_SUCCESS,

  types.UPDATE_NOTIFICATIONS,
);
