import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { i18next } from 'utils';
import store from 'store';
import { MAIN_THEME } from 'constants/styles';
import { Preloader } from 'ui-kit';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { initMixPanel } from 'utils/mixpanel';
import { Router } from './Router';

initMixPanel();

export const App = () => (
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={MAIN_THEME}>
          <Suspense
            fallback={(
              <Preloader
                type={PRELOADER_TYPES.OVERLAY}
                size={PRELOADER_SIZES.LARGE}
                minDuration={800}
                isLoading
                isDestroyChildren={false}
              />
            )}
          >
            <Router />
          </Suspense>
          <ToastContainer />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);
