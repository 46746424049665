import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const EndFileName = styled.div`
  position: absolute;
  top: 40px;
  left: 240px;
  width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
