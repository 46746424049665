import { createSelector } from 'reselect';
import {
  pathOr, identity,
} from 'ramda';

export const getPageError = createSelector(pathOr({}, ['ui', 'pageError']), identity);

export const getTempFileUploadPending = createSelector(pathOr(false,
  ['pending', 'uploadTempFileRequest']), identity);

export const getVideosPending = createSelector(pathOr(true,
  ['pending', 'getVideosRequest']), identity);

export const getUpdateAnimationPending = createSelector(pathOr(false,
  ['pending', 'updateAnimationRequest']), identity);
