export default {
  CREATE_VIDEO_DRAFT: 'CREATE_VIDEO_DRAFT',

  SEND_DATA_FILE_DRAFT_REQUEST: 'SEND_DATA_FILE_DRAFT_REQUEST',
  SEND_DATA_FILE_DRAFT_SUCCESS: 'SEND_DATA_FILE_DRAFT_SUCCESS',

  SEND_AUDIO_FILE_DRAFT_REQUEST: 'SEND_AUDIO_FILE_DRAFT_REQUEST',
  SEND_AUDIO_FILE_DRAFT_SUCCESS: 'SEND_AUDIO_FILE_DRAFT_SUCCESS',

  SEND_FILE_DRAFT_REQUEST: 'SEND_FILE_DRAFT_REQUEST',

  CREATE_VIDEO_DRAFT_REQUEST: 'CREATE_VIDEO_DRAFT_REQUEST',
  SET_VIDEO_DRAFT: 'SET_VIDEO_DRAFT',

  DELETE_VIDEO_DRAFT_REQUEST: 'DELETE_VIDEO_DRAFT_REQUEST',
  DELETE_VIDEO_DRAFT_SUCCESS: 'DELETE_VIDEO_DRAFT_SUCCESS',
  DELETE_VIDEO_DRAFT: 'DELETE_VIDEO_DRAFT',

  GET_VIDEO_DRAFTS_REQUEST: 'GET_VIDEO_DRAFTS_REQUEST',
  SET_VIDEO_DRAFTS: 'SET_VIDEO_DRAFTS',

  SET_STATUS_DRAFT: 'SET_STATUS_DRAFT',

  CHANGE_DRAFT_TEMPLATE: 'CHANGE_DRAFT_TEMPLATE',

  GET_SELECTED_DRAFT_DATA_REQUEST: 'GET_SELECTED_DRAFT_DATA_REQUEST',
  GET_SELECTED_DRAFT_DATA_SUCCESS: 'GET_SELECTED_DRAFT_DATA_SUCCESS',

  FETCH_DRAFT_FILE: 'FETCH_DRAFT_FILE',
  FETCH_DRAFT_FILE_SUCCESS: 'FETCH_DRAFT_FILE_SUCCESS',

  SET_SELECTED_DRAFT: 'SET_SELECTED_DRAFT',
  SET_SAVING_DRAFT_ID: 'SET_SAVING_DRAFT_ID',

  GET_DRAFT_FILES_REQUEST: 'GET_DRAFT_FILES_REQUEST',
  GET_DRAFT_FILES_SUCCESS: 'GET_DRAFT_FILES_SUCCESS',
  SET_DRAFT_FILES: 'SET_DRAFT_FILES',

  UPDATE_DRAFT_FILE_REQUEST: 'UPDATE_DRAFT_FILE_REQUEST',
  SET_DRAFT_FILE: 'SET_DRAFT_FILE',

  GET_VIDEO_DRAFT_REQUEST: 'GET_VIDEO_DRAFT_REQUEST',
  GET_VIDEO_DRAFT_SUCCESS: 'GET_VIDEO_DRAFT_SUCCESS',
  SET_DRAFT_OPTIONS: 'SET_DRAFT_OPTIONS',

  GET_DRAFT_JSON_REQUEST: 'GET_DRAFT_JSON_REQUEST',
  GET_DRAFT_JSON_SUCCESS: 'GET_DRAFT_JSON_SUCCESS',

  GET_CSV_BY_DRAFT_REQUEST: 'GET_CSV_BY_DRAFT_REQUEST',
  GET_CSV_BY_DRAFT_SUCCESS: 'GET_CSV_BY_DRAFT_SUCCESS',

  SELECT_DRAFT: 'SELECT_DRAFT',
  TOGGLE_TOP_DRAFT_STATUS: 'TOGGLE_TOP_DRAFT_STATUS',
  UPDATE_LIST_OF_TOP_DRAFTS: 'UPDATE_LIST_OF_TOP_DRAFTS',
  GET_TOP_DRAFTS: 'GET_TOP_DRAFTS',
  UPDATE_VIDEO_DRAFTS: 'UPDATE_VIDEO_DRAFTS',

  TOGGLE_ORIGINAL_ANIMATION_STATUS: 'TOGGLE_ORIGINAL_ANIMATION_STATUS',

  DUPLICATE_VIDEO_DRAFT_REQUEST: 'DUPLICATE_VIDEO_DRAFT_REQUEST',
  DUPLICATE_VIDEO_DRAFT_SUCCESS: 'DUPLICATE_VIDEO_DRAFT_SUCCESS',

  CREATE_VIDEO_DRAFT_FROM_ANIMATION_REQUEST: 'CREATE_VIDEO_DRAFT_FROM_ANIMATION_REQUEST',
  CREATE_VIDEO_DRAFT_FROM_ANIMATION_SUCCESS: 'CREATE_VIDEO_DRAFT_FROM_ANIMATION_SUCCESS',
};
