import React from 'react';
import { Icon } from 'ui-kit';
import { AudioTab } from './AudioTab';
import { ImageTab } from './ImageTab';
import { MainTab } from './MainTab';
import { TextTab } from './TextTab';

export const TABS = {
  TEXT: 'TEXT',
  IMAGES: 'IMAGES',
  CONTROLS: 'CONTROLS',
  AUDIO: 'AUDIO',
};

export const TAB_PANELS = [
  {
    tabName: TABS.CONTROLS,
    content: <Icon name="controls" />,
  },
  {
    tabName: TABS.TEXT,
    content: <Icon name="text" />,
  },
  {
    tabName: TABS.IMAGES,
    content: <Icon name="image" />,
  },
  {
    tabName: TABS.AUDIO,
    content: (
      <>
        <Icon name="headphones" marginRight="5px" />
        <Icon name="video" />
      </>
    ),
  },
];

export const TAB_VIEWS = [
  {
    tabName: TABS.CONTROLS,
    content: () => (<MainTab />),
  },
  {
    tabName: TABS.TEXT,
    content: () => (<TextTab />),
  },
  {
    tabName: TABS.IMAGES,
    content: () => (<ImageTab />),
  },
  {
    tabName: TABS.AUDIO,
    content: ({ lottieElement }) => (
      <AudioTab lottieElement={lottieElement} />
    ),
  },
];
