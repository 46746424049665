import styled from 'styled-components';

export const Root = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.EXTRA_LIGHT_GREY};
  ${({ isApproved, theme }) => isApproved && `background-color: ${theme.colors.SUCCESS};`}
  ${({ isCanceled, theme }) => isCanceled && `
    position: relative;
    background-color: ${theme.colors.RED};
    ::before, ::after {
      position: absolute;
      left: 6px;
      top: 2px;
      content: ' ';
      height: 10px;
      width: 2px;
      background-color: ${theme.colors.WHITE};
    }
    ::before {
      transform: rotate(45deg);
    }
    ::after {
      transform: rotate(-45deg);
    }
  `}
`;
