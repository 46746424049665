/* todo: need refactoring */
import {
  useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  pathEq, compose, includes, last, is, isNil, always, cond, lt, gt, T, identity,
} from 'ramda';

import { uiActions } from 'state/ui';
import { editorActions } from 'state/editor';
import {
  getSecondsByTimeString,
  getTimeStringBySeconds,
  debounceFunc,
} from 'utils/helpers/commonHelpers';
import { AVAILABLE_FORMATS } from 'constants/rendering';
import { getFileUrl, blobAutoRevoker } from 'utils/helpers/requestHelpers';
import { createFileByUrl, draftsFilesStorage } from 'utils/helpers/filesHelper';

import AudioInstance from 'utils/helpers/audioHelper';
import { AUDIO_METHOD, AUDIO_PLAYER_STATE } from 'constants/editor';
import { selectReadinessImages } from 'state/editor/selectors';

const MAX_PERCENTS = 100;

const DELAY_BEFORE_CHANGE_AUDIO_START_POINT = 800;

const DELETE_EVENT = 'delete';

const getFadeInVolumeValue = (currentTime, startPoint, duration) => (currentTime - startPoint) / duration;

const getFadeOutVolumeValue = (currentTime, endPoint, duration) => (endPoint - currentTime) / duration;

const normalizeVolumeValue = cond([[lt(1), always(1)], [gt(0), always(0)], [T, identity]]);

/**
 * @param audioElement
 * @param animationLength
 * @param startPoint
 * @param isFade
 * @param isMute
 */

export const useVolume = ({
  audioElement, animationLength, startPoint, isFade, isMute,
}) => {
  const endPoint = animationLength + startPoint;

  const fadeWatcher = useCallback((currentTime, duration) => {
    if (currentTime < startPoint + duration) {
      audioElement.volume = normalizeVolumeValue(getFadeInVolumeValue(currentTime, startPoint, duration));
    } else if ((currentTime >= endPoint - duration) && (currentTime <= endPoint)) {
      audioElement.volume = normalizeVolumeValue(getFadeOutVolumeValue(currentTime, endPoint, duration));
    } else {
      audioElement.volume = 1;
    }
  }, [audioElement, startPoint, animationLength, isMute]);

  useEffect(() => {
    const handleTimeUpdate = ({ target: { currentTime } }) => {
      if (!isMute && isFade && currentTime >= 0) {
        fadeWatcher(currentTime, 2);
      }
      if (currentTime >= endPoint) {
        audioElement.currentTime = startPoint;
      }
    };

    if (audioElement) {
      audioElement.addEventListener('timeupdate', handleTimeUpdate, false, { once: true });
      return () => {
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [audioElement, isFade, animationLength, startPoint, isMute, fadeWatcher]);

  useEffect(() => {
    if (audioElement) {
      if (isMute) {
        audioElement.volume = 0;
      } else audioElement.volume = 1;
    }
  }, [isMute]);
};

export const usePlayControls = ({
  audioRef, lottieElement, startPoint, audioPlayerRef,
}) => {
  if (
    audioRef.current
    && audioPlayerRef.current
    && lottieElement.current
    && lottieElement.current.anim
  ) {
    const onTogglePlayerPlay = () => {
      audioRef.current.currentTime = startPoint;
    };

    const onResetPlayerPlay = () => {
      const lottieElementAnim = lottieElement.current.anim;
      audioPlayerRef.current.currentTime = startPoint;
      audioPlayerRef.current.pause();
      audioRef.current.currentTime = startPoint;
      audioRef.current.play();
      lottieElement.current.goToAndPlaySuper(0, true);
      lottieElementAnim.play();
    };

    return {
      onResetPlayerPlay,
      onTogglePlayerPlay,
    };
  }
  return {
    onResetPlayerPlay: () => {},
    onTogglePlayerPlay: () => {},
  };
};

const useResetAudioFile = ({
  audioRef, audioPlayerRef, setAudioFile, lottieElement, audioInputRef, dispatch,
}) => {
  const handleDeleteAudio = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.src = '';
      audioPlayerRef.current.src = '';
      setAudioFile(null);
      dispatch(editorActions.setAudioFileInfo(null));
      if (lottieElement.current.el) lottieElement.current.el.dispatchEvent(new CustomEvent('deleteAudio'));
      audioInputRef.current.value = '';
    }
  }, [dispatch, lottieElement]);
  return handleDeleteAudio;
};

export const usePlayHandlers = ({
  audioRef,
  audioPlayerRef,
  lottieElement,
  setAudioFile,
  audioInputRef,
  setFade,
  setMute,
  startPoint,
  animationLength,
  audioFile,
  playerState,
  editorIsReady,
}) => {
  const dispatch = useDispatch();
  const handleDeleteAudio = useResetAudioFile({
    audioRef,
    audioPlayerRef,
    setAudioFile,
    lottieElement,
    audioInputRef,
    dispatch,
  });

  const getEndPoint = useCallback((point) => {
    return point + animationLength < animationLength ? point + animationLength : animationLength;
  }, [animationLength]);

  const handleToggleFade = compose(setFade, pathEq(['target', 'value'], 'On'));

  const handleToggleMute = compose(setMute, pathEq(['target', 'value'], 'On'));

  const handleChangeRangeValue = ({ min, max }) => {
    const newStartPoint = min < startPoint
      ? min
      : max - animationLength;

    const newEndPoint = getEndPoint(newStartPoint);
    dispatch(editorActions.setAudioPoints({ startPoint: newStartPoint, endPoint: newEndPoint }));
    dispatch(editorActions.setPlayerState(AUDIO_PLAYER_STATE.NOT_USED));
  };

  const handleChangeStartPointField = useCallback(({ target: { value } }) => debounceFunc(() => {
    if (audioPlayerRef.current) {
      const maxAudioStartPoint = Math.floor(audioPlayerRef.current.duration - animationLength);
      const valueInNumber = Number(getSecondsByTimeString(value)) || 0;
      const newStartPoint = valueInNumber > maxAudioStartPoint ? maxAudioStartPoint : valueInNumber;
      const newEndPoint = getEndPoint(valueInNumber);

      audioPlayerRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      // audio-start-point-rewriting-after-trimmer
      if (value !== 'NaN:NaN') {
        dispatch(editorActions.setAudioPoints({ startPoint: newStartPoint, endPoint: newEndPoint }));
        if (newStartPoint && editorIsReady) {
          dispatch(editorActions.setPlayerState(AUDIO_PLAYER_STATE.NOT_USED));
        }
      }
      if (lottieElement.current && lottieElement.current.goToAndStopSuper) {
        lottieElement.current.goToAndStopSuper(0, true);
        if (playerState !== AUDIO_PLAYER_STATE.NOT_USED && editorIsReady) dispatch(editorActions.setPlayerState(AUDIO_PLAYER_STATE.PAUSE));
      }
    }
  }, DELAY_BEFORE_CHANGE_AUDIO_START_POINT, 'handleChangeAudioStartPoint'), [audioPlayerRef, lottieElement, editorIsReady]);

  const handleChangeAudio = (e) => {
    const audioElement = audioRef.current;
    const audioPlayerElement = audioPlayerRef.current;
    if (audioElement && audioPlayerElement) {
      audioElement.removeAttribute('src');
      audioPlayerElement.removeAttribute('src');
    }
    const file = e.target.files[0];
    const fileExtension = last(file.type.split('/'));

    if (includes(fileExtension, AVAILABLE_FORMATS.CONVERTED_AUDIO)) {
      const formData = new FormData();
      formData.append('file', file);
      dispatch(editorActions.setConvertAudio({ isPreparing: true }));
      dispatch(uiActions.uploadTempFileRequest(formData, {
        callbacks: {
          success: (result) => {
            dispatch(editorActions.convertAudioToMp3(result));
          },
        },
      }));
    } else {
      setAudioFile(file);
    }
    dispatch(editorActions.setAudioPoints({
      startPoint: 0,
      endPoint: animationLength,
    }));
  };

  useEffect(() => {
    if (lottieElement.current) {
      const audioElement = audioRef.current;
      const audioElementPlayer = audioPlayerRef.current;
      const lottieEl = lottieElement.current.el;

      const handlePlay = (e) => requestAnimationFrame(() => {
        if (audioElement) {
          audioElement.currentTime = Math.floor((animationLength / MAX_PERCENTS)
              * Math.ceil(e.detail.progress)) + startPoint;
          audioElement.play();
          audioElementPlayer.pause();
        }
      });

      const handlePause = () => requestAnimationFrame(() => {
        if (audioElement) audioElement.pause();
      });

      lottieEl.addEventListener('play', handlePlay, false, { once: true });
      lottieEl.addEventListener('pause', handlePause);

      return () => {
        lottieEl.removeEventListener('play', handlePlay);
        lottieEl.removeEventListener('pause', handlePause);
      };
    }
  }, [lottieElement, audioFile, audioRef, animationLength, startPoint]);

  return {
    handleDeleteAudio,
    handleToggleFade,
    handleToggleMute,
    handleChangeAudio,
    handleChangeStartPointField,
    handleChangeRangeValue,
  };
};

export const useVisualize = ({ setWaveFormData, audioFile, audioRef }) => {
  useEffect(() => {
    const visualize = (audioBuffer) => {
      const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
      const samples = 100; // Number of samples we want to have in our final data set
      const blockSize = Math.floor(rawData.length / samples); // Number of samples in each subdivision
      const filteredData = [];
      for (let i = 0; i < samples; i += 1) {
        filteredData.push(rawData[i * blockSize]);
      }
      setWaveFormData(filteredData);
      return filteredData;
    };

    if (audioFile) {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext();
      const blob = new Blob([audioFile], { type: 'audio/mp3' });
      const blobUrl = URL.createObjectURL(blob, blob.type);
      blobAutoRevoker.add('audioInTab', blobUrl);
      fetch(blobUrl)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => visualize(audioBuffer));
    }
  }, [audioFile, audioRef]);
};

export const useControlPlayback = ({
  startPoint,
  animationLength,
  audioPlayerRef,
  audioFile,
  audioFileStorage,
  startPointInputElement,
  lottieElement,
  audioRef,
  trackDuration,
  setAudioFile,
}) => {
  const dispatch = useDispatch();

  const getEndPoint = useCallback((point) => {
    return point + animationLength < animationLength ? point + animationLength : animationLength;
  }, [animationLength]);

  useEffect(() => {
    // Receive new start point for the audio track
    if (startPoint && audioPlayerRef.current) {
      audioPlayerRef.current.currentTime = startPoint;
    }
  }, [startPoint, animationLength, audioPlayerRef]);

  useEffect(() => {
    if (!audioFile && audioFileStorage.source) {
      const { source, info: { name, type } } = audioFileStorage;
      createFileByUrl(source, name, type).then((file) => {
        setAudioFile(file);
      });
    }
  }, [audioFileStorage, audioFile]);

  useEffect(() => {
    const filedElement = startPointInputElement.current;
    if (filedElement) {
      if (startPoint > trackDuration && trackDuration) {
        const endPoint = getEndPoint(trackDuration);
        dispatch(editorActions.setAudioPoints({ startPoint: trackDuration, endPoint }));
      } else if (startPoint < 0 || !is(Number, +startPoint)) {
        dispatch(editorActions.setAudioPoints({ startPoint: 0, endPoint: animationLength }));
      } else {
        filedElement.value = getTimeStringBySeconds(startPoint);
      }
    }
  }, [startPointInputElement, trackDuration, startPoint, animationLength]);

  useEffect(() => {
    if (audioRef && audioRef.current) {
      if (lottieElement.current) lottieElement.current.handleClickToPause();
      audioRef.current.pause();
    }
  }, [startPoint, audioRef, lottieElement]);
};

export const useAudioFileHandle = ({
  audioRef,
  audioPlayerRef,
  lottieElement,
  audioFile,
  setAudioDuration,
  setTrackDuration,
  setAudioFile,
  handleChangeStartPointField,
  convertedAudioData,
  audioInputRef,
  selectedDraft,
  startPoint,
}) => {
  const dispatch = useDispatch();
  const handleDeleteAudio = useResetAudioFile({
    audioRef,
    audioPlayerRef,
    setAudioFile,
    lottieElement,
    audioInputRef,
    dispatch,
  });

  useEffect(() => {
    AudioInstance.subscribe((event) => {
      if (event === DELETE_EVENT) {
        handleDeleteAudio();
      }
    });
  }, []);

  useEffect(() => {
    if (lottieElement.current) {
      const audioElement = audioRef.current;
      const audioPlayerElement = audioPlayerRef.current;
      const lottieNativeElement = lottieElement.current.el;
      if (audioFile && audioPlayerElement && audioElement) {
        if (!audioElement.hasAttribute('src')) { // todo: fix condition
          const source = URL.createObjectURL(audioFile);
          blobAutoRevoker.add('audioFileInTab', source);
          dispatch(editorActions.setAudioFileInfo({
            source,
            info: {
              name: audioFile.name,
              type: audioFile.type,
            },
          }));
          audioElement.setAttribute('src', source);
          audioPlayerElement.setAttribute('src', source);
          audioElement.addEventListener('loadedmetadata', ({ target: { duration } }) => {
            setAudioDuration(Math.floor(duration));
            setTrackDuration(Math.floor(duration), false, { once: true });
            if (startPoint !== 0) {
              audioRef.current.currentTime = startPoint;
            }
          });
          lottieNativeElement.dispatchEvent(new CustomEvent('addAudio'));
        }
      }
    }
  }, [audioFile, lottieElement, audioRef, setTrackDuration, audioPlayerRef]);

  useEffect(() => {
    draftsFilesStorage.subscribe((data) => {
      if (data) {
        const { file, meta } = data;
        setAudioFile(file);
        setTimeout(() => {
          if (meta) handleChangeStartPointField({ target: { value: getTimeStringBySeconds(meta.startPoint) } });
        });
      } else {
        setAudioFile(null);
      }
    }, 'AUDIO');
    return () => {
      draftsFilesStorage.deleteFile('AUDIO');
    };
  }, [selectedDraft]);

  useEffect(() => {
    if (!isNil(convertedAudioData)) {
      const { file: { filename } } = convertedAudioData;
      createFileByUrl(
        getFileUrl({ fileUrl: `uploads/temp-files/${filename}` }),
        filename, 'mp3',
      ).then(setAudioFile);
    }
  }, [convertedAudioData]);
};

/**
 *
 * @param {object} audioRef reference on audio tag
 * @param {object} audioPlayerRef reference on audio tag
 * @param {object} lottieElement reference on lottie tag
 * @param {boolean} editorIsReady
 * @param {number} playerState
 * @param {number} percentOfPlayer
 * @param {number} animationLength
 * @param {number} startPoint
 * @param {object} audioFile
 *
 * This hook for check audio state in the redux state and change audio state
 */

export const useAutoPlayAudio = ({
  audioRef,
  audioPlayerRef,
  lottieElement,
  editorIsReady,
  playerState,
  percentOfPlayer,
  animationLength,
  startPoint,
  audioFile,
}) => {
  const dispatch = useDispatch();
  const areReadyImages = useSelector(selectReadinessImages);
  const toggleForReset = useRef(false);
  const lottie = lottieElement.current;
  const audioElement = audioRef.current;
  const audioPlayerElement = audioPlayerRef.current;
  const isReadyToHandleAudioPlayer = lottie
      && audioFile
      && audioPlayerElement
      && audioElement
      && areReadyImages
      && editorIsReady;

  const toggleAudioPlayer = ({ audio, method }) => { audio[method](); };

  const changeCurrentTimeOfAudioPlayer = ({ audio }) => {
    audio.currentTime = startPoint;
    toggleAudioPlayer({ audio, method: AUDIO_METHOD.PAUSE });
    toggleAudioPlayer({ audio, method: AUDIO_METHOD.PLAY });
  };

  const getSeconds = useCallback(() => compose(
    parseInt,
    getSecondsByTimeString,
    getTimeStringBySeconds,
    Math.ceil,
  )((animationLength / MAX_PERCENTS) * percentOfPlayer), [percentOfPlayer, animationLength]);

  const audioPlayHandler = useCallback(() => {
    if (percentOfPlayer) {
      const seconds = getSeconds();
      audioElement.currentTime = startPoint + seconds;
      dispatch(editorActions.setPercentOfPlayer(null));
    }
    toggleAudioPlayer({ audio: audioElement, method: AUDIO_METHOD.PLAY });
    // eslint-disable-next-line no-unused-expressions
    lottie.anim?.play();
  }, [audioElement, lottie, percentOfPlayer, toggleAudioPlayer]);

  const audioPauseHandler = useCallback(() => {
    toggleAudioPlayer({ audio: audioElement, method: AUDIO_METHOD.PAUSE });
  }, [audioElement, toggleAudioPlayer]);

  const audioResetHandler = useCallback(() => {
    changeCurrentTimeOfAudioPlayer({ audio: audioElement });
  }, [audioElement, changeCurrentTimeOfAudioPlayer]);

  const audioOnlyPlayerHandler = useCallback(() => {
    lottie.anim.pause();
    changeCurrentTimeOfAudioPlayer({ audio: audioElement });
  }, [audioElement, lottie, changeCurrentTimeOfAudioPlayer]);

  useEffect(() => {
    if (isReadyToHandleAudioPlayer) {
      if (playerState === AUDIO_PLAYER_STATE.PLAY && lottie.goToAndPlaySuper) {
        audioPlayHandler();
      } else if (playerState === AUDIO_PLAYER_STATE.PAUSE) {
        audioPauseHandler();
      } else if (playerState === AUDIO_PLAYER_STATE.RESET) {
        audioResetHandler();
        toggleForReset.current = !toggleForReset.current; // we need to have opportunity to reset audio many times in a row
      } else if (playerState === AUDIO_PLAYER_STATE.USE_ONLY_PLAYER) {
        audioOnlyPlayerHandler();
      }
    }
  }, [
    playerState,
    isReadyToHandleAudioPlayer,
    lottie,
    toggleForReset,
    audioPlayHandler,
    audioPauseHandler,
    audioResetHandler,
    audioOnlyPlayerHandler,
  ]);
};
