import { RENDER_TYPES_FOR_API } from './rendering';

export const CLIENT_STATUSES = {
  IN_REVIEW: 0,
  APPROVED: 1,
  UNAPPROVED: 2,
};

export const DELETE_MODAL_QUESTIONS = {
  ONE_ITEM: 'Are you sure you want to delete this file?',
  MANY_ITEMS: 'Are you sure you want to delete these files?',
};

export const DOWNLOAD_FILE_FORMATS = {
  [RENDER_TYPES_FOR_API.mp4]: 'mp4',
  [RENDER_TYPES_FOR_API.gif]: 'gif',
  [RENDER_TYPES_FOR_API.html5]: 'zip',
  [RENDER_TYPES_FOR_API.image]: 'zip',
  [RENDER_TYPES_FOR_API.oneFrame]: 'png',
  [RENDER_TYPES_FOR_API.mov]: 'mov',
  [RENDER_TYPES_FOR_API.keyframesZip]: 'zip',
  [RENDER_TYPES_FOR_API.keyframesGif]: 'gif',
  [RENDER_TYPES_FOR_API.templates]: 'zip',
  [RENDER_TYPES_FOR_API.htmlBanner]: 'zip',
  [RENDER_TYPES_FOR_API.html5Adform]: 'zip',
  [RENDER_TYPES_FOR_API.keyFramesPdf]: 'zip',
};
