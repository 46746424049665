import styled from 'styled-components';

export const PlayerContainer = styled.div`
    position: relative;
    padding-top: 10px;
    .rvt__player {
      margin: 0 auto;
      padding: 10px;
      width: 443px !important;
      height: 251px !important;
      border-radius: 10px;
      box-shadow: 0 0 5px gray;

      @media(${({ theme }) => theme.devices.SM}){
        width: 420px !important;
      }

      @media(${({ theme }) => theme.devices.XS}){
        width: 310px !important;
        height: 190px !important;
      }
      @media(max-height: 600px) {
        height: 220px !important;
      }
    }
`;
