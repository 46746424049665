import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColorPicker } from 'ui-kit';
import { changeTextColor } from 'state/editor/actions';
import { decRGB2Hex } from 'utils/animationHelpers';
import { getControls } from 'state/editor/selectors';

/**
 *
 * @param id {number | string}
 * @param selectedColor {number}
 * @param relatedToColor {string}
 * @param isGlobalColor {number | boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const ColorControl = ({
  id, selectedColor, relatedToColor, isGlobalColor,
}) => {
  const dispatch = useDispatch();

  const controls = useSelector(getControls);

  const handleChangeColor = useCallback(({ target: { name, value } }) => {
    dispatch(changeTextColor([name, value]));
  }, []);

  const globalTextColor = useMemo(() => {
    const control = controls.find(({ colorStatus }) => colorStatus === relatedToColor); // get control with such color
    return control ? decRGB2Hex(control.selectedControlValue) : null;
  }, [relatedToColor, controls]);

  return (
    <ColorPicker
      value={selectedColor}
      onChange={handleChangeColor}
      isGlobalByDefault={isGlobalColor}
      globalColor={globalTextColor}
      name={id}
      id={`colorPicker-${id}`}
    />
  );
};
