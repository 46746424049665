import { handleActions } from 'redux-actions';
import types from './types';

const reducer = handleActions({
  [types.SET_PAGE_ERROR]: (state, action) => ({
    ...state,
    pageError: action.payload,
  }),
}, {});

export default reducer;
