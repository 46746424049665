import React, {
  useCallback, useLayoutEffect,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { debounceFunc } from 'utils/helpers/commonHelpers';
import { EVENTS } from 'constants/trimmer';
import { Trimmer } from './Trimmer';
import useEventListeners from './hooks/useEventListeners';
import { Container } from './TrimmerLine.styled';

const DELAY_CHANGE_CURRENT_TIME = 300;
const NAME_CHANGE_CURRENT_TIME = 'moveCurrentTime';

const DELAY_RESIZE = 300;
const NAME_RESIZE = 'resizeWindow';

/**
 *
 * @param onPausePlayer
 * @param duration
 * @param onTrim
 * @param timeLimit
 * @param timeRange
 * @param currentTime
 * @param trimmerImages
 * @param moveCurrentTime
 * @returns {JSX.Element}
 * @constructor
 */

export const TrimmerLine = ({
  onPausePlayer,
  duration,
  onTrim,
  timeLimit,
  timeRange,
  currentTime,
  trimmerImages,
  moveCurrentTime,
}) => {
  const [startPoint, setStartPoint] = useState(timeRange.start);
  const [endPoint, setEndPoint] = useState(timeRange.end);
  const [width, setWidth] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [currentDraggerTime, setCurrentDraggerTime] = useState(currentTime);
  const containerRef = useRef();

  // trying to use same variables for mobile and pc
  const screenX = useRef(0);

  const widthDurationRatio = width / duration;
  const convertPositionToTime = (pos) => pos / widthDurationRatio;

  const handleResize = useCallback(() => {
    debounceFunc(() => setWidth(containerRef.current.getBoundingClientRect().width), DELAY_RESIZE, NAME_RESIZE);
  }, [containerRef.current]);

  const onGetData = useCallback(() => {
    const fixTimePoint = (time) => Number(time).toFixed(2);
    const fixedEndPoint = endPoint ? fixTimePoint(endPoint) : fixTimePoint(timeRange.end);
    setTimeout(
      () => onTrim({
        start: fixTimePoint(startPoint),
        end: fixedEndPoint,
      }),
    );
  }, [onTrim, startPoint, endPoint, timeRange.end]);

  // maybe will be better to rewrite with styled components
  const trimmerBackground = useMemo(() => {
    const backgroundSize = width / trimmerImages.length;
    return {
      backgroundImage: trimmerImages.map((img) => `url(${img})`).join(','),
      backgroundPosition: trimmerImages
        .map((img, i) => `${backgroundSize * i}px top`)
        .join(','),
      backgroundRepeat: 'no-repeat',
      backgroundSize: `${backgroundSize}px 100%`,
    };
  }, [trimmerImages, width]);

  const onDrag = useCallback((pos) => {
    const time = convertPositionToTime(pos.x);
    const newStartTime = +timeRange.start + time;
    const newEndTime = +timeRange.end + time;
    const newDuration = timeRange.end - timeRange.start;

    if (newEndTime < duration && newStartTime > 0) {
      if (newStartTime >= currentDraggerTime) setCurrentDraggerTime(newStartTime);
      if (newEndTime <= currentDraggerTime) setCurrentDraggerTime(newEndTime);
      setStartPoint(newStartTime);
      setEndPoint(newEndTime);
    } else if (time < 0) {
      setStartPoint(0);
      setEndPoint(newDuration);
    } else {
      setStartPoint(duration - newDuration);
      setEndPoint(duration);
    }
  }, [duration, currentDraggerTime, timeRange.end, timeRange.start, width]);

  const handleMouseMove = useCallback((e) => {
    if (e.type === EVENTS.TOUCH_MOVE) {
      onDrag({
        x: e.touches[0].clientX - screenX.current,
      });
      return;
    }
    onDrag({
      x: e.screenX - screenX.current,
    });
  }, [onDrag, screenX.current]);

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
    onGetData();
  }, [onGetData]);

  const handleMouseDown = useCallback((e) => {
    if (e.type === EVENTS.TOUCH_START) {
      screenX.current = e.touches[0].clientX;
    } else {
      screenX.current = e.screenX;
    }
    setIsMouseDown(true);
  }, [screenX.current]);

  useEventListeners({ handleMouseMove, handleMouseUp, isMouseDown });

  const endPointPusher = useCallback((time) => {
    const newEndTime = +timeRange.end + (time - timeRange.start);
    if (newEndTime < duration) {
      setEndPoint(newEndTime);
    } else {
      setEndPoint(duration);
    }
  }, [duration, timeRange]);

  // update current player time, when user stops to move dragger
  useEffect(() => {
    debounceFunc(() => moveCurrentTime(currentDraggerTime), DELAY_CHANGE_CURRENT_TIME, NAME_CHANGE_CURRENT_TIME);
  }, [currentDraggerTime]);

  // update local current time, when player current time was changed
  useEffect(() => {
    setCurrentDraggerTime(currentTime);
  }, [currentTime]);

  // representing ComponentDidUpdate
  useEffect(() => {
    setStartPoint(timeRange.start);
    setEndPoint(timeRange.end);
  }, [timeRange.start, timeRange.end]);

  // representing mix of ComponentDidMount and WillUnmount
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useLayoutEffect(() => {
    setWidth(containerRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <Container
      ref={containerRef}
      style={trimmerBackground}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      role="button"
      tabIndex={0}
    >
      <Trimmer
        timeLimit={timeLimit}
        setStartPoint={setStartPoint}
        setEndPoint={setEndPoint}
        widthDurationRatio={widthDurationRatio}
        containerWidth={width}
        startTime={startPoint}
        endTime={endPoint}
        setCurrentDraggerTime={setCurrentDraggerTime}
        duration={duration}
        onGetData={onGetData}
        currentDraggerTime={currentDraggerTime}
        onPausePlayer={onPausePlayer}
        endPointPusher={endPointPusher}
        convertPositionToTime={convertPositionToTime}
      />
    </Container>
  );
};
