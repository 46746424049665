import styled from 'styled-components';

export const RootStyle = styled.div`
  width: 100%;
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  white-space: pre-wrap;
  margin-top: 12px;
`;
