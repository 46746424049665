import styled from 'styled-components';

export const Container = styled.div`
  .input-range {
    display: flex;
    align-items: center;
    width: 96%;
    margin: 0 auto;
  }
  .input-range__track {
    position: relative;
    width: 100%;
    height: 9px;
    margin-top: -12px;
    &::after {
      content: '';
      position: absolute;
      top: 4px;
      width: 100%;
      height: 12px;
      border-radius: 30px;
      border: 1px solid ${({ theme }) => theme.colors.TRACK_BORDER};
      background-color: ${({ theme }) => theme.colors.WHITE};
    }
  }
  .input-range__track--active {
    display: none;
  }
  .input-range__track--background {
    background-color: transparent
  }
  .input-range__slider {
    position: absolute;
    top: 50%;
    left: 0;
    width: 26px;
    height: 26px;
    z-index: 1;
    margin-top: -2px;
    border-radius: 100%;
    border: 0;
    background-color: ${({ theme }) => theme.colors.MAIN}
  }
  .input-range__slider-container {
    transition: none !important;
  }
  .input-range__label {
    position: absolute;
    margin-top: 55px;
    margin-left: 5px;
    font-size: 13px;
    color: #000;
  }
  .input-range__label--min {
    display: none;
  }
  .input-range__label--max {
    position: absolute;
    top: 10px;
    right: 0;
    margin-top: 0;
    margin-right: -37px;
  }
  .input-range-point {
    position: absolute;
    top: 50%;
    left: 0;
    width: 17px;
    height: 17px;
    margin-top: -8px;
    border-radius: 100%;
    background-color: #000;
  }
`;
