import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import 'react-input-range/lib/css/index.css';
import { ScrollToTop, ZapierModal, ProcessNotify } from 'ui-kit';
import { selectAllAnimations, getSelectedAnimation } from 'state/animations/selectors';
import { EditorProvider } from 'contexts';
import { draftsActions } from 'state/drafts';
import { SaveFileModal } from './SaveFileModal';
import { RightToolBar } from './RightToolBar';
import { LeftToolBar } from './LeftToolBar';
import { LottiePlayer } from './LottiePlayer';
import {
  PlayerWrapper, EmptyPlayer, Container, Player,
} from './Editor.styled';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const Editor = () => {
  const lottieElement = useRef(null);
  const lastAnimName = useRef(null);
  const dispatch = useDispatch();
  const animations = useSelector(selectAllAnimations);
  const selectedAnimation = useSelector(getSelectedAnimation);

  const [isOpenZapierModal, setIsOpenZapierModal] = useState();

  const handleToggleZapierModal = (value) => () => setIsOpenZapierModal(value);

  // we need to upload new drafts, if animation was changed
  useEffect(() => {
    if (selectedAnimation && selectedAnimation.name && selectedAnimation.name !== lastAnimName.current) {
      dispatch(draftsActions.getVideoDraftsRequest({
        limit: 100,
        offset: 0,
        animation_name: selectedAnimation.name,
      }));
      lastAnimName.current = selectedAnimation.name;
    }
  }, [selectedAnimation?.name]);

  return (
    <EditorProvider>
      <Container
        role="presentation"
      >
        {
          !animations.length ? (
            <EmptyPlayer>
              You don't have any active animations
            </EmptyPlayer>
          ) : (
            <Player>
              <PlayerWrapper>
                <LeftToolBar />
                <LottiePlayer lottieElement={lottieElement} />
                <RightToolBar lottieElement={lottieElement} />
                <ScrollToTop />
              </PlayerWrapper>
            </Player>
          )
        }
      </Container>
      <ProcessNotify />
      <SaveFileModal onOpenZapierModal={handleToggleZapierModal(true)} />
      <ZapierModal
        isOpen={isOpenZapierModal}
        onClose={handleToggleZapierModal(false)}
        isPending={false}
      />
    </EditorProvider>
  );
};

export default Editor;
