import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { prop, is } from 'ramda';

import './style.sass';
import { decRGB2Hex } from 'utils/animationHelpers';
import { ColorPalette } from 'ui-kit';

const normalizeColor = (color) => (is(Array, color) ? decRGB2Hex(color) : color);

const InputColor = ({
  onChange, color, isFill, name, type,
}) => {
  const [selectedColor, setSelectedColor] = useState(normalizeColor(color));
  const [isOpenedPaletteModal, setIsOpenedPaletteModal] = useState(false);
  const handleChange = (value) => setSelectedColor(prop(type, value));

  const handleAdd = useCallback((e) => {
    onChange(name
      ? { target: { name, color: selectedColor } }
      : selectedColor);
    setIsOpenedPaletteModal(e);
  }, [name, selectedColor, setIsOpenedPaletteModal]);
  // todo: need refactoring

  const togglePaletteModal = useCallback((value) => () => setIsOpenedPaletteModal(value), []);

  return (
    <>
      <label
        onClick={togglePaletteModal(true)}
        className={`select-colors__color-box select-colors__color-box--add
      ${isFill ? 'select-colors--is-fill' : ''}
      `}
        role="presentation"
        htmlFor={`selectedColor${name}`}
      >
        <span className="select-colors__icon">+</span>
      </label>
      <ColorPalette
        isOpen={isOpenedPaletteModal}
        id={`selectedColor${name}`}
        handleClose={togglePaletteModal(false)}
        handleAdd={handleAdd}
        color={selectedColor}
        handleChange={handleChange}
      />
    </>
  );
};

InputColor.propTypes = {
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  isFill: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.oneOf(['hex', 'hsl']),
};

InputColor.defaultProps = {
  color: '#fff',
  name: '',
  type: 'hex',
  isFill: false,
};

export default InputColor;
