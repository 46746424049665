import { useCallback, useEffect } from 'react';
import { fullViewInstance } from 'utils/helpers/fullViewHelper';
import { LottieInstance } from 'utils/helpers/lottieHelpers';

const openWindow = (path) => {
  const url = `${process.env.REACT_APP_URL}${path}`;
  const BLANK = '_blank';
  window.open(url, BLANK);
};

export const useFullView = ({ uniqKey, lottieElement, animationData }) => {
  const route = `/full-view/${uniqKey.current}`;

  const handleOpenFullView = useCallback(() => {
    fullViewInstance.set(uniqKey.current, animationData);
    openWindow(route);
  }, [animationData, uniqKey, route]);

  useEffect(() => {
    const key = uniqKey.current;
    LottieInstance.set(lottieElement);
    return () => {
      fullViewInstance.destroy(key);
    };
  }, [uniqKey, lottieElement]);

  return {
    handleOpenFullView,
  };
};
