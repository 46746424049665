import styled from 'styled-components';
import {
  layout, typography, border, color, system, flexbox,
} from 'styled-system';
import { space } from '@styled-system/space';

export const RootStyle = styled.div`
  display: flex;
  flex-direction: column;
  ${flexbox}
  ${space}
  ${layout}
  ${typography}
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.label`
  margin-bottom: 10px;
  white-space: nowrap;
  font-size: 12px;
  ${space}
  ${typography}
  ${color}
  ${system({
    textTransform: true,
    letterSpacing: true,
  })}
`;

export const Field = styled.input`
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  padding-left: 10px;
  margin-bottom: 5px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  font-size: 10px;
  line-height: 12px;
  ${space}
  ${layout}
  ${typography}
  ${border}
  &:hover {
    outline: none;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.ERROR};
  font-size: 12px;
  :first-letter{
    text-transform: capitalize
  }
  ${typography}
`;

export const IconButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
