import React from 'react';
import { Root } from './Row.styled';

/**
 *
 * @param isHead {boolean}
 * @param isBoxShadow {boolean}
 * @param isCursor {boolean}
 * @param props {object} style props
 * @param children {React.ReactNode}
 * @returns {JSX.Element}
 * @constructor
 */

export const Row = ({
  isHead = false, isBoxShadow = false, isCursor = false, children, ...props
}) => {
  return (
    <Root isCursor={isCursor} isBoxShadow={isBoxShadow} isHead={isHead} {...props}>{children}</Root>
  );
};
