import React from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button, Icon, Input, Select,
} from 'ui-kit';
import { findLabelByValue } from 'utils/helpers';
import { selectWebhookParams } from 'state/webhooks/selectors';
import {
  IconButton, Item, List, RootStyle, StyledIcon, Text, Label,
} from './FieldListGenerator.styled';

/**
 *
 * @param name {string} name of field
 * @returns {JSX.Element}
 * @constructor
 */

export const FieldListGenerator = ({ name }) => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();
  const params = useSelector(selectWebhookParams);

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <RootStyle>
      <List>
        {fields.map((item, index) => (
          <Item key={item.id}>
            <Controller
              render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                <Input
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={t('Key')}
                  error={t(error?.message)}
                  width="33%"
                  inputStyleProps={{
                    borderRadius: '5px',
                  }}
                />
              )}
              name={`${name}.${index}.key`}
              control={control}
            />
            <Controller
              render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                <Input
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={t('Value')}
                  error={t(error?.message)}
                  width="33%"
                  inputStyleProps={{
                    borderRadius: '5px',
                  }}
                />
              )}
              name={`${name}.${index}.value`}
              control={control}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  id={`default_param_${index}`}
                  label={() => (
                    <Label>
                      <Text>{findLabelByValue(params, value) || t('Select default')}</Text>
                      <Icon name="dropdown-icon" width={10} height={16} marginLeft="10px" marginRight="4px" />
                    </Label>
                  )}
                  value={value}
                  onChange={onChange}
                  options={params}
                />
              )}
              name={`${name}.${index}.value`}
              control={control}
            />
            <IconButton type="button" onClick={() => remove(index)}>
              <StyledIcon name="cancel" width={20} height={20} />
            </IconButton>
          </Item>
        ))}
      </List>
      <Button
        buttonTheme="primary"
        onClick={() => append({ key: '', value: '' })}
        title={t('Add field')}
        label={t('Add field')}
        width={70}
        height={30}
      />
    </RootStyle>
  );
};
