import styled from 'styled-components';

export const Label = styled.label`
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  svg {
    filter: grayscale(80%);
  }
  ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'}
  ${({ isChecked }) => isChecked && `
    svg {
      filter: grayscale(0%);
    }
  `}
`;
