import React, {
  useEffect, useRef, useState, useCallback, memo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InputRange from 'react-input-range';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

import { Preloader } from 'ui-kit';
import { debounce } from 'utils/helpers/commonHelpers';
import { setImageData, setLayerType } from 'state/editor/actions';
import {
  getImageLayerTitle, getRatioAndLayerType, getRefId, testHide,
} from 'utils/animationHelpers';
import { animationAspectRatioSelector, assetByIdSelector } from 'state/animation/selectors';
import {
  getImagePreparing, getImageRef,
} from 'state/editor/selectors';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { BEGIN_FILE, FILE_TYPES } from 'constants/files';
import { DATA_CONST } from 'constants/animationsPath';
import {
  useCropper,
  useDisabled,
  useDraftsImages,
  useFetchRefs,
  useTint,
  useScale,
  useFileUploading,
  useReadyWatcher, useGif,
} from './hooks';
import { Switchers } from './Switchers';
import { MediaView } from './MediaView';
import {
  InputRangeContainer, RootStyle, Title, Cover,
} from './ImageEditor.styled';
import { Controls } from './Controls';
import { FileModal } from '../FileModal';

export const ImageEditor = memo(({ data }) => {
  const refId = getRefId(data.nm);
  const { t } = useTranslation('common');
  const animationAspectRatio = useSelector(animationAspectRatioSelector);
  const asset = useSelector((store) => assetByIdSelector(store)(refId));
  const imageRef = useSelector((store) => getImageRef(store)(refId));
  const isPreparing = useSelector((store) => getImagePreparing(store)(refId));
  const firstRender = useRef(true);
  const cropperRef = useRef(null);
  const inputElementRef = useRef(null);
  const prevTempIdAndZoomRef = useRef([]);
  const imageGifRef = useRef(null);
  const [isTouched, setIsTouched] = useState(false);
  const [cropData, setCropData] = useState(null);
  const isGif = imageRef?.fileType === FILE_TYPES.GIF;
  const isHidden = !testHide(data, DATA_CONST.EDITFRAME);

  const { aspectRatio, layerType } = getRatioAndLayerType(data, animationAspectRatio);

  const title = getImageLayerTitle(data);
  const [file, setFile] = useState(null);
  const [isOpenedFileModal, setIsOpenedFileModal] = useState(false);

  const toggleOpenFileModal = useCallback((value) => () => {
    setIsOpenedFileModal(value);
  }, []);

  const {
    tintOptions, tintAmount, tintColor, isGlobalColor, tint,
    setTintColor, onTintChange,
  } = useTint({ data, cropperRef, imageGifRef });

  const {
    fileUrl, onUploadFile, tempId,
  } = useFileUploading({ refId });

  const dispatch = useDispatch();
  const { isDisabled, setDisabled } = useDisabled({
    cropperRef, firstRender, asset, imageRef, refId,
  });

  const handleSetImagesData = useCallback((id, dataValue) => {
    // TODO: We should add hook setImageData for one end point in the ImageEditor for setting data
    const options = {
      ...(cropData || {}),
      isGlobalColor, // todo: from file
      isDisabled,
      isTouched,
      tintColor,
      tint,
      refId: asset.ref.id || '',
    };
    dispatch(setImageData({ [id]: { ...(dataValue ? ({ value: dataValue }) : ({})), options } }));
  }, [isDisabled, tint, isGlobalColor, tintAmount, tintColor, tintOptions, isTouched, cropData, aspectRatio]);

  const handleOnCrop = useCallback(({ isTouched: isTouchedProp, isForceUpdate, ...options }) => {
    const cropperDataCurrently = cropperRef.current ? cropperRef.current.getData() : null;
    const isCurrentForceUpdate = cropperDataCurrently ? cropperDataCurrently.isForceUpdate : null;

    dispatch(setImageData({
      [refId]: {
        options: {
          ...options,
          ...cropperDataCurrently,
          isTouched: isTouchedProp,
          isForceUpdate: isForceUpdate || isCurrentForceUpdate,
        },
      },
    }));
  },
  [isTouched, isDisabled, cropData]);

  const { onCrop, onRotate } = useCropper({
    cropperRef,
    asset,
    setIsTouched,
    refId,
    isTouched,
    setCropData,
  });

  const {
    zoom, onZoom, minZoom, maxZoom,
  } = useScale({
    cropperRef, refId, fileUrl, onCrop,
  });

  useEffect(() => {
    handleSetImagesData(refId, fileUrl);
  }, [tempId, isDisabled, isTouched, tint, cropData]);

  useEffect(() => {
    dispatch(setLayerType({ refId, layerType }));
  }, [layerType, refId]);

  useEffect(() => {
    const [oldTempId, oldZoom] = prevTempIdAndZoomRef.current;
    if (oldTempId !== tempId && oldZoom !== zoom) {
      prevTempIdAndZoomRef.current = [tempId, zoom];
      handleSetImagesData(refId, fileUrl);
    }
  }, [zoom, fileUrl, tempId, handleSetImagesData, cropData]);

  const handleCrop = useCallback(debounce(800, (skipCrop) => {
    if (skipCrop !== true) onCrop(false, true, true);
    handleOnCrop({ isTouched: true });
    if (cropperRef.current) {
      setCropData(cropperRef.current.getData());
    }
  }), [onCrop, cropperRef, handleOnCrop]);

  useEffect(() => {
    const url = asset?.ref?.u || '';
    const pathOfUrl = asset?.ref?.p || '';
    const assetUrl = url.startsWith(BEGIN_FILE.BLOB) || url.startsWith(BEGIN_FILE.DATA) ? url : `${url}${pathOfUrl}`;
    handleSetImagesData(refId, assetUrl);
    firstRender.current = false;
  }, []);

  useDraftsImages({
    refId,
    inputElementRef,
    onUploadFile,
    setCropData,
    cropperRef,
    onZoom,
    setDisabled,
    onCrop,
    setIsTouched,
  });

  useFetchRefs({
    value: imageRef?.value,
    cropperRef,
    asset,
    isDisabled,
    refId,
  });

  useEffect(() => {
    setIsTouched(false);
  }, [fileUrl]);

  useEffect(() => {
    if (cropData && cropData.isForceUpdate) {
      setTimeout(() => {
        onCrop(false, true);
        handleCrop(true);
      });
    }
  }, [cropData]);

  useReadyWatcher({
    refId, cropperRef, cropData, imageRef,
  });

  useGif({ asset, refId });

  return (
    <>
      <RootStyle>
        <Preloader
          type={PRELOADER_TYPES.CONTAINER}
          size={PRELOADER_SIZES.SMALL}
          isDestroyChildren={false}
          isLoading={isPreparing}
          preloaderChildren={(
            <div>
              {t('Uploading/Converting...')}
            </div>
        )}
        >
          <>
            <Switchers data={data} />
            <Title>{title}</Title>
            <MediaView
              refId={refId}
              cropData={cropData}
              cropperRef={cropperRef}
              onCrop={handleCrop}
              imageGifRef={imageGifRef}
              aspectRatio={aspectRatio}
            />
            {!isGif && (
            <InputRangeContainer>
              <InputRange
                maxValue={maxZoom}
                minValue={minZoom}
                step={0.01}
                value={zoom}
                disabled={isDisabled}
                onChange={(z) => onZoom(z)}
              />
            </InputRangeContainer>
            )}
            <Controls
              isDisabled={isDisabled}
              refId={refId}
              onOpenFileModal={toggleOpenFileModal(true)}
              onCrop={onCrop}
              onRotate={onRotate}
              inputElementRef={inputElementRef}
              tintColor={tintColor}
              setTintColor={setTintColor}
              tintAmount={tintAmount}
              tint={tint}
              tintOptions={tintOptions}
              onTintChange={onTintChange}
              data={data}
              setDisabled={setDisabled}
            />
          </>
        </Preloader>
        {(isHidden && <Cover />)}
      </RootStyle>
      <FileModal
        isOpened={isOpenedFileModal}
        onClose={toggleOpenFileModal(false)}
        file={file}
        setFile={setFile}
        refId={refId}
        onUploadFile={onUploadFile}
        cropperRef={cropperRef}
        setCropData={setCropData}
        onZoom={onZoom}
      />
    </>
  );
});
