export const DEFAULT_PADDING = '12px 0';

export const TITLE_LINKS = 'Title \t Url \n';

export const BUTTON_STYLES = {
  WIDTH: 120,
  HEIGHT: 34,
  FONT_SIZE: 12,
};

export const WIDTHS = {
  TITLE: 246,
  URL: 350,
  LINK: 150,
};

export const DEFAULT_STYLES = {
  textAlign: 'center',
  padding: DEFAULT_PADDING,
};

export const HEAD_LIST = [
  {
    id: 'title',
    label: 'Title',
    width: WIDTHS.TITLE,
    ...DEFAULT_STYLES,
  },
  {
    id: 'url',
    label: 'Url',
    width: WIDTHS.URL,
    ...DEFAULT_STYLES,
  },
  {
    id: 'copy',
    label: '',
    width: WIDTHS.LINK,
    ...DEFAULT_STYLES,
  },
];
