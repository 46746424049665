const PAUSE = 'pause';
const PLAY = 'play';

export const LottieInstance = (() => {
  let lottieElement;
  const subscribers = [];
  const ACTIONS = {
    PLAY: 'goToAndPlaySuper',
    STOP: 'goToAndStopSuper',
  };

  const goTo = (frame, type, forceAction, element) => {
    if (lottieElement && (frame || frame === 0) && element) {
      if (element.anim.totalFrames < frame) {
        element.anim.pause();
      } else {
        const defaultAction = element.anim.isPaused ? ACTIONS.STOP : ACTIONS.PLAY;
        const actionLottie = forceAction || defaultAction;
        const action = element[actionLottie];

        if (action) action(Number(frame) || 1, type);
      }
    }
  };

  return {
    set: (el) => {
      lottieElement = el;
    },
    subscribe: (fn, eventName) => subscribers.push([fn, eventName]),
    get: () => lottieElement,
    delete: () => {
      lottieElement = null;
    },
    pause: () => {
      if (lottieElement.current) {
        subscribers.forEach(([fn, eventName]) => { if (eventName === PAUSE) fn(lottieElement); });
        lottieElement.current.anim.pause();
      }
    },
    play: () => {
      if (lottieElement.current) {
        subscribers.forEach(([fn, eventName]) => { if (eventName === PLAY) fn(lottieElement); });
        lottieElement.current.anim.play();
      }
    },
    goToAndPlay: (frame, type = true) => goTo(frame, type, ACTIONS.PLAY, lottieElement.current),
    goToAndStop: (frame, type = true) => goTo(frame, type, ACTIONS.STOP, lottieElement.current),
    getCurrentFrame: () => (lottieElement.current ? lottieElement.current.anim.currentFrame : 0),
    goTo: (frame, type = true) => goTo(frame, type, null, lottieElement.current),
  };
})();
