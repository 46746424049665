import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/forms';
import { optionsByArray } from 'utils/helpers/optionsHelper';
import { selectTextGroups } from 'state/editor/selectors';
import { changeTextGroupVariable } from 'state/editor/actions';
import {
  Container, Item, Label, Text, Icon,
} from './TextSwitchers.styled';

/**
 *
 * @type {React.NamedExoticComponent<{readonly activeGroup?: number}>}
 */

export const TextSwitchers = memo(({ activeGroup }) => {
  const dispatch = useDispatch();
  const textGroups = useSelector(selectTextGroups);
  const { t } = useTranslation('common');

  const normalizedSwitchers = useMemo(() => {
    const switchers = pathOr([], [activeGroup, 'switchersNormalized'], textGroups); // get switchers from text groups
    return switchers.filter(({ options }) => options?.length); // return only switchers with exists options
  }, [textGroups, activeGroup]);

  const handleChangeGroupSwitcher = useCallback((index) => ({ target: { name, value } }) => {
    dispatch(changeTextGroupVariable([name, parseInt(value, 10), index]));
  }, []);

  return (
    <Container>
      {
       normalizedSwitchers.length ? normalizedSwitchers
         .map(({ ind, value, options }, index) => (
           <Item key={ind}>
             <Select
               onChange={handleChangeGroupSwitcher(index)}
               id={ind}
               name={activeGroup}
               value={value}
               label={(
                 <Label>
                   <Text>{(options[value] || t('None'))}</Text>
                   <Icon name="arrow-down" height={16} width={10} />
                 </Label>
               )}
               options={optionsByArray(options || [])}
             />
           </Item>
         )) : ''
      }
    </Container>
  );
});
