import React, { memo, useCallback } from 'react';
import { Root } from './HeadCell.styled';

/**
 * @param name {string} field for sorting (name of the param from the api)
 * @param onSort {function}
 * @param order {"asc" | "desc"}
 * @param isLabel {boolean}
 * @param children {React.ReactNode | string}
 * @param props {object} style props
 * @returns {JSX.Element}
 * @constructor
 */

export const HeadCell = memo(({
  onSort,
  isLabel,
  order,
  name = '',
  children,
  ...props
}) => {
  const handleClick = useCallback(() => {
    if (name.length > 0) {
      onSort(name);
    }
  }, [name, onSort]);
  return (
    <Root
      isLabel={isLabel}
      order={order}
      onClick={handleClick}
      {...props}
    >{children}
    </Root>
  );
});
