import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAnimationsRequest, getAnimationsRequest } from 'state/animations/actions';
import { getTopDrafts } from 'state/drafts/actions';
import { useClickOutside } from 'hooks';
import { selectAllAnimations } from 'state/animations/selectors';
import { ThemeItem } from './ThemeItem';
import {
  Container, Header, Icon, Body, ScrollBar, List,
} from './ThemesSelector.styled';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const ThemesSelector = () => {
  const dispatch = useDispatch();

  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef(null);
  const scrollbarRef = useRef(null);
  const listRef = useRef(null);
  const { t } = useTranslation('common');

  const themes = useSelector(selectAllAnimations);

  const onToggleTab = () => setIsOpened((prevState) => !prevState);

  useClickOutside({
    ref: containerRef,
    callback: () => setIsOpened(false),
    condition: isOpened,
  });

  const isHorizontalScroll = useMemo(() => {
    return isOpened && listRef.current?.offsetWidth < listRef.current?.scrollWidth;
  }, [listRef.current, isOpened]);

  // get animations and top drafts from back once
  useEffect(() => {
    dispatch(getAnimationsRequest());
    dispatch(getTopDrafts());
    dispatch(fetchAllAnimationsRequest());
  }, []);

  return (
    <Container
      isOpened={isOpened}
      ref={containerRef}
    >
      <Header
        title={t(isOpened ? 'Hide list of themes' : 'Show list of themes')}
        onClick={onToggleTab}
      >
        <b>{t('Tumplates')}</b>
        <Icon isOpened={isOpened} />
      </Header>
      <Body isOpened={isOpened}>
        <ScrollBar
          ref={scrollbarRef}
        >
          <List
            ref={listRef}
            isHorizontalScroll={isHorizontalScroll}
          >
            {themes.map((theme) => <ThemeItem key={theme.id || theme.name} theme={theme} />)}
          </List>
        </ScrollBar>
      </Body>
    </Container>
  );
};
