import React from 'react';
import { useDispatch } from 'react-redux';
import { ColorPicker, Icon, SelectGroup } from 'ui-kit';
import { convertHexToColorNumbers, decRGB2Hex } from 'utils/animationHelpers';
import { optionsByArray } from 'utils/helpers/optionsHelper';
import { changeControlValue } from 'state/editor/actions';
import {
  ColorOptionLabel, ColorOptionWrapper, Label, LabelContainer,
} from './MainTab.styled';

export const Option = ({ control, index }) => {
  const dispatch = useDispatch();

  const handleChangeControl = ({ target: { name, value } }) => dispatch(changeControlValue([name, Number(value)]));

  const handleChangeControlsColor = ({ target: { name, value: { color } } }) => dispatch(changeControlValue([name, convertHexToColorNumbers(color)]));

  return (
    control.colorOption ? (
      <ColorOptionWrapper>
        <ColorOptionLabel>{control.name}</ColorOptionLabel>
        <ColorPicker
          enableColorInput
          name={index}
          id={`controls-color-picker-${index}`}
          initialPaletteColor={decRGB2Hex(control.selectedControlValue)}
          onChange={handleChangeControlsColor}
          globalColorIsDisabled
        />
      </ColorOptionWrapper>
    ) : (
      <SelectGroup
        id={`colorScheme${control.name}`}
        value={control.selectedControlValue}
        onChange={handleChangeControl}
        name={index}
        label={(
          <LabelContainer>
            <Label>
              {control.values[control.selectedControlValue]}
            </Label>
            <Icon name="dropdown-icon" width={10} height={16} marginLeft="4px" />
          </LabelContainer>
          )}
        options={optionsByArray(control.values)}
        title={control.label}
      />
    )
  );
};
