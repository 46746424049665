import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getWebhookParamsRequest } from 'state/webhooks/actions';
import { selectWebhookParams } from 'state/webhooks/selectors';
import {
  Row, Tab, TabPanel,
} from './AdvancedOptions.styled';
import { TAB_IDS, TAB_VIEWS } from './constants';
import { FieldListGenerator } from '../FieldListGenerator';

export const TAB_PANELS = [{
  id: 1,
  label: 'Params',
},
{
  id: 2,
  label: 'Headers',
},
{
  id: 3,
  label: 'Body',
},

];

export const AdvancedOptions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const webhookParams = useSelector(selectWebhookParams);

  const [activeTab, setActiveTab] = useState(TAB_IDS.PARAMS);

  const handleChangeTab = (id) => () => {
    setActiveTab(id);
  };

  useEffect(() => {
    if (webhookParams.length === 0) {
      // todo: move actions to the different layer of logic
      dispatch(getWebhookParamsRequest());
    }
  }, [webhookParams]);

  return (
    <>
      <Row>
        {TAB_PANELS.map(({ id, label }) => (
          <TabPanel
            isActive={activeTab === id}
            role="presentation"
            onClick={handleChangeTab(id)}
          >
            {t(label)}
          </TabPanel>
        ))}
      </Row>
      {TAB_VIEWS.map(({ id, fieldName }) => (
        <Tab isActive={activeTab === id} key={id}>
          <FieldListGenerator name={fieldName} />
        </Tab>
      ))}
    </>
  );
};
