import { createSelector } from 'reselect';
import {
  pick, prop, identity, pathOr, memoizeWith, slice, length, propOr,
} from 'ramda';

import { animationDataSelector } from 'state/animation/selectors';
import { getColorsSelector } from 'state/colors/selectors';
import { NONE_DRAFT } from 'constants/drafts';
import { RATIOS } from 'constants/editor';
import { getSelectedDraft } from '../drafts/selectors';
import { getCompanyIsEnabledBrandColors } from '../company/selectors';
import { getUniqueListByProp } from '../../utils/helpers';

const getRatioAnimationByData = ({ w, h }) => {
  if (w > h) return RATIOS.WIDE;
  if (w < h) return RATIOS.TALL;
  return RATIOS.SQUARE;
};

export const getEditor = createSelector(
  prop('editor'),
  identity,
);

export const getEditorSettings = createSelector(
  getEditor,
  (editorState) => ({
    ...(pick(['loop', 'autoplay', 'rendererSettings'], editorState)),
  }),
);

export const getEditorVersion = createSelector(
  pathOr('', ['editor', 'version']),
  identity,
);

export const getVersionBackground = createSelector(
  pathOr({}, ['editor', 'versionBackground']),
  identity,
);

export const getVersionEditorOrDraft = createSelector(
  getEditorVersion,
  animationDataSelector,
  getSelectedDraft,
  (selectedEditorVersion, data, selectedDraft) => {
    if (selectedDraft && selectedDraft !== NONE_DRAFT.id) {
      return getRatioAnimationByData(data);
    }
    return selectedEditorVersion;
  },
);

export const selectTextGroups = createSelector(
  pathOr([], ['editor', 'textGroups']),
  identity,
);

export const getTextLayers = createSelector(
  pathOr([], ['editor', 'textLayers']),
  identity,
);

export const getControls = createSelector(
  pathOr([], ['editor', 'controls']),
  identity,
);

export const getImageRefs = createSelector(
  pathOr({}, ['editor', 'imageRefs']),
  identity,
);

export const getImageRef = createSelector(
  getImageRefs,
  (refs) => memoizeWith(identity, (ref) => refs[ref]),
);

export const getImagesEditFrames = createSelector(
  identity,
  pathOr({}, ['editor', 'imageEditFrames']),
);

export const getImagePreparing = createSelector(
  getImageRefs,
  (refs) => memoizeWith(identity, (ref) => pathOr(false, [ref, 'isPreparing'], refs)),
);

export const getImagePreparingToGif = createSelector(
  getImageRefs,
  (refs) => memoizeWith(identity, (ref) => pathOr(false, [ref, 'isPreparingGif'], refs)),
);

export const presetColorsSelector = createSelector(
  getColorsSelector,
  pathOr([], ['editor', 'colors', 'presetColors']),
  getCompanyIsEnabledBrandColors,
  (colorsFromApi, animationColors, isEnable) => slice(0,
    length(isEnable ? colorsFromApi : animationColors),
    animationColors),
);

export const globalColorsSelector = createSelector(
  pathOr([], ['editor', 'colors', 'globalColors']),
  identity,
);

export const getAudioStartPoint = createSelector(
  pathOr(0, ['editor', 'audio', 'startPoint']),
  identity,
);

export const getAudioEndPoint = createSelector(
  pathOr(null, ['editor', 'audio', 'endPoint']),
  identity,
);

export const getAudioDuration = createSelector(
  pathOr(null, ['editor', 'audio', 'duration']),
  identity,
);

export const getAudioFade = createSelector(
  pathOr(false, ['editor', 'audio', 'fade']),
  identity,
);

export const getAudioMute = createSelector(
  pathOr(false, ['editor', 'audio', 'mute']),
  identity,
);

export const getPlayerState = createSelector(
  pathOr(false, ['editor', 'audio', 'playerState']),
  identity,
);

export const getPercentOfPlayer = createSelector(
  pathOr(false, ['editor', 'audio', 'percentOfPlayer']),
  identity,
);

export const getAudioFetchingStatus = createSelector(
  pathOr(null, ['editor', 'audio', 'isFetching']),
  identity,
);

export const getAnimationMeta = createSelector(
  pathOr(null, ['editor', 'meta']),
  identity,
);

export const getAudioFile = createSelector(
  pathOr({}, ['editor', 'audio', 'audioFile']),
  identity,
);

export const getLastEditFrame = createSelector(
  pathOr(null, ['editor', 'lastEditFrame']),
  identity,
);

export const getChangeRatioByUI = createSelector(
  pathOr(false, ['editor', 'isChangeRatioByUI']),
  identity,
);

export const selectTextLayer = createSelector(
  getTextLayers,
  (textLayer) => (fieldId) => pathOr(0, [fieldId], textLayer),
);

export const getTextGroup = createSelector(
  selectTextGroups,
  (groups) => memoizeWith(identity, (id) => prop(id, groups)),
);

export const getFonts = createSelector(pathOr([],
  ['editor', 'fonts']), identity);

export const getConvertAudio = createSelector(
  pathOr([], ['editor', 'convertAudio']),
  identity,
);

export const getAnimationDuration = createSelector(
  getEditor,
  pathOr(null, ['meta', 'duration']),
);

export const getImagesControls = createSelector(
  pathOr([], ['editor', 'imagesControls']),
  identity,
);

export const getEditFramesList = createSelector(
  pathOr([], ['editor', 'editFrames']),
  identity,
);

export const getImagesControlsByLayerName = createSelector(
  getImagesControls,
  (data) => memoizeWith(identity, (layerName) => propOr([], layerName, data)),
);

export const getPrevVersionData = createSelector(
  pathOr({}, ['editor', 'prevVersionData']),
  identity,
);

export const getEditorIsReady = createSelector(
  pathOr(false, ['editor', 'isReady']),
  identity,
);

export const getProjectName = createSelector(
  pathOr('', ['editor', 'projectName']),
  identity,
);

export const getVideoId = createSelector(
  getImageRefs,
  (refs) => memoizeWith(identity, (ref) => pathOr(null, [ref, 'videoId'], refs)),
);

export const getTrimmedVideo = createSelector(
  getImageRefs,
  (refs) => memoizeWith(identity, (ref) => pathOr(null, [ref, 'video'], refs)),
);

export const getPrevVersion = createSelector(
  pathOr('', ['editor', 'prevVersion']),
  identity,
);

export const getDescription = createSelector(
  pathOr('', ['editor', 'description']),
  identity,
);

export const getCustomLength = createSelector(
  pathOr(null, ['editor', 'customLength']),
  identity,
);

export const selectUniqueFramesWithIndexes = createSelector(
  pathOr([], ['editor', 'editFrames']),
  (list) => {
    const arr = list.map((item, index) => ({ frame: item, index }));
    return getUniqueListByProp(arr, 'frame');
  },
);

export const selectAmountImages = createSelector(
  pathOr({}, ['editor', 'imageRefs']),
  (refs) => Object.values(refs).length,
);

export const selectAmountReadyImages = createSelector(
  pathOr({}, ['editor', 'imageRefs']),
  (refs) => Object.values(refs).filter((value) => value.isReady).length,
);

export const selectReadinessImages = createSelector(
  selectAmountImages,
  selectAmountReadyImages,
  (amountImages, amountReadyImages) => amountImages === amountReadyImages,
);

export const selectVisibleTextGroups = createSelector(
  pathOr([], ['editor', 'visibleTextGroups']),
  identity,
);
