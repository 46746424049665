import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'ui-kit';
import { selectUniqueFramesWithIndexes } from 'state/editor/selectors';
import { goToEditFrameByIndex } from 'state/editor/actions';
import { KeyFrame } from './PlayerControls.styled';

const ICON_DIMENSION = 10;

/**
 *
 * @param keyFrame {string}
 * @param totalKeyFrames {number}
 * @returns {number}
 */
const getMarginLeftToKeyFrame = (keyFrame, totalKeyFrames = 0) => (100 / totalKeyFrames) * parseInt(keyFrame, 10);

/**
 *
 * @param lottieElement {object}
 * @returns {unknown[]}
 * @constructor
 */

export const Frames = ({ lottieElement }) => {
  const dispatch = useDispatch();
  const uniqFramesWithIndexes = useSelector(selectUniqueFramesWithIndexes);

  const handleClick = useCallback((index) => () => {
    dispatch(goToEditFrameByIndex(index));
  }, []);

  return (
    uniqFramesWithIndexes.map(({ frame, index }) => (
      <KeyFrame
        role="presentation"
        marginLeft={getMarginLeftToKeyFrame(frame, lottieElement.current?.anim?.totalFrames)}
        key={frame}
        onClick={handleClick(index)}
      >
        <Icon
          name="player-arrow"
          width={ICON_DIMENSION}
          height={ICON_DIMENSION}
        />
      </KeyFrame>
    ))
  );
};
