import styled from 'styled-components';

export const Container = styled.div`
  width: 85%;
  position: relative;
  z-index: 2;
  & .preloader-parent {
      .preloader__image {
        display: none
      }
    }
  ${({ isPending, theme }) => isPending && `
  .preloader-parent {
  width: 100%;
  height: 700px;
  box-sizing: border-box;
  box-shadow: 0 0 3px #8e8c8c;
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
  @media(${theme.devices.SM}){
    width: 320px;
    height: 320px;
    margin: 0 auto;
  }
    .preloader__image {
      display: flex
    }
  }
  `}
  
  @media(${({ theme }) => theme.devices.LG}) {
    width: 420px;
  };
  
  @media(${({ theme }) => theme.devices.SM}) {
    width: 420px;
    height: 367px;
  };
  
  @media(${({ theme }) => theme.devices.MD}) {
    width: 100%;
    height: auto;
  }
`;

export const Header = styled.div`
  width: 100%;
  min-height: 25px;
  
  @media(${({ theme }) => theme.devices.SM}) {
    min-height: 16px;
    box-sizing: border-box;
    padding: 0 16px;
  }
  
  @media(${({ theme }) => theme.devices.MD}) {
    width: 100%;
  }
`;

export const PreloaderContainer = styled.div`
  position: absolute;
  top: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  @media(${({ theme }) => theme.devices.SM}) {
    top: 70%;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li`
  font-weight: 300;
  font-size: 12px;
`;
