import styled from 'styled-components';
import { Icon } from '../Icon';

export const CHECKBOX_SIZE_SMALL = 'small';
export const CHECKBOX_SIZE_MIDDLE = 'middle';

export const CheckboxItem = styled.input`
  display: none;
`;
export const Label = styled.label`
  position: relative;
  width: 34px;
  height: 34px;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.BORDER_COLOR};
  border-radius: 100%;
  cursor: pointer;
  transition-duration: .25s;
  &:hover {
    opacity: .9;
  }
  ${({ size, theme }) => size === CHECKBOX_SIZE_SMALL && `
    width: 14px;
    height: 14px;
    border: none;
    background-color: ${theme.colors.EXTRA_LIGHT_GREY};
  `}
  ${({ size }) => size === CHECKBOX_SIZE_MIDDLE && `
    width: 22px;
    height: 22px;
    margin-right: 10px;
  `}
  ${({ checked }) => checked && `
    background-color: ${({ theme }) => theme.colors.SUCCESS};
  `}
`;

export const CheckedIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
