import React, {
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import useScrollPosition from '@react-hook/window-scroll';
import animateScrollTo from 'animated-scroll-to';
import { Button, Arrow } from 'ui-kit/ScrollToTop/ScrollToTop.styled';

export const ScrollToTop = () => {
  const scrollY = useScrollPosition(60);
  const topNode = useRef();
  const bottomNode = useRef();

  const isReversed = useMemo(() => {
    return topNode.current && topNode.current.getBoundingClientRect().y < 0;
  }, [scrollY, topNode]);

  const handleScrollTo = useCallback(() => {
    const target = isReversed ? topNode : bottomNode;
    const position = target.current.getBoundingClientRect().y;

    // calculate point to scroll depends on position
    const pointToScroll = position < 0 ? topNode.current.getBoundingClientRect().y : bottomNode.current.getBoundingClientRect().y;
    animateScrollTo(pointToScroll);
  }, [isReversed, topNode.current, bottomNode.current]);

  useEffect(() => {
    topNode.current = document.getElementById('lottiePlayer');
    bottomNode.current = document.getElementById('rightToolBar');
  }, []);

  return (
    <Button
      isReversed={isReversed}
      type="button"
      onClick={handleScrollTo}
    >
      <Arrow />
    </Button>
  );
};
