import React from 'react';
import { useSelector } from 'react-redux';

import { preloaderAnim } from 'assets/images';
import { draftsSelectors } from 'state/drafts';
import { CSV_STATUS_MESSAGES, DRAFTS_STATUS_MESSAGES } from 'constants/drafts';
import { animationSelectors } from 'state/animation';
import {
  Container, Logo, Image, Title, Content,
} from './ProcessNotify.styled';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ProcessNotify = () => {
  const draftsStatus = useSelector(draftsSelectors.getPrepareStatus);
  const csvStatus = useSelector(animationSelectors.getCsvStatus);
  const message = DRAFTS_STATUS_MESSAGES[draftsStatus] || CSV_STATUS_MESSAGES[csvStatus];

  return (
    <Container isShowed={!!message}>
      <Logo>
        <Image src={preloaderAnim} alt="Pending icon" />
        <div>
          <Title>Saving drafts</Title>
          <Content>{message}</Content>
        </div>
      </Logo>
    </Container>
  );
};
