import React from 'react';
import { Root } from './StatusLabel.styled';

/**
 *
 * @param isApproved {boolean}
 * @param isCanceled {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const StatusLabel = ({ isApproved = false, isCanceled = false }) => {
  return (
    <Root isApproved={isApproved} isCanceled={isCanceled} />
  );
};
