import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  a {
    display: inline-block;
    min-width: 83px;
    box-sizing: border-box;
    line-height: 34px;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 13px;
    border-radius: 7px;
    background-color: ${({ theme }) => theme.colors.SUCCESS};
    color: ${({ theme }) => theme.colors.WHITE};
    border: 1px solid ${({ theme }) => theme.colors.SUCCESS};
    :hover {
      opacity: 0.9;
    }
    @media(${({ theme }) => theme.devices.SM}) {
      display: none;
    }
  }
`;

export const SearchField = styled.input`
  width: 250px;
  max-height: 36px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.BORDER_COLOR};
  border-radius: 25px;
  line-height: 36px;
  letter-spacing: 0.48px;
  padding: 0 19px;
  font-size: 13px;
  font-weight: 300;
  outline: none;
  @media(${({ theme }) => theme.devices.SM}) {
    max-height: 40px;
    width: 100%;
    line-height: 40px;
    font-size: 20px;
  }
  &::placeholder {
    font-weight: 300;
    letter-spacing: 0;
  }
`;
