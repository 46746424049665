import styled from 'styled-components';
import { layout } from 'styled-system';

export const Iframe = styled.iframe`
  height: 80vh;
  width: 80vw;
  border: none;
  overflow: hidden;
  ${layout}
`;
