import styled from 'styled-components';

export const Container = styled.div`
  width: 33px;
  height: 33px;
  margin-right: 9px;
  margin-bottom: 13px;
  border-radius: 100%;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.BORDER_COLOR};
  cursor: pointer;
  transition: .3s;
  background-color: ${({ currentColor }) => currentColor};
  ${({ isActive, theme }) => isActive && `box-shadow: 0 0 0 1px ${theme.colors.BORDER_COLOR}, 0 0 0 5px ${theme.colors.LIGHT_GRAY}`}
  ${({ isDisabled }) => isDisabled && `
     cursor: default;
     opacity: .18;
  `}
`;
