import * as colorsActions from './actions';
import * as colorsSelectors from './selectors';
import * as colorsOperations from './operations';
import colorsTypes from './types';
import reducer from './reducers';

export {
  colorsActions,
  colorsTypes,
  colorsSelectors,
  colorsOperations,
};

export default reducer;
