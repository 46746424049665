import { createSelector } from 'reselect';
import {
  pathOr, identity, memoizeWith, find, propEq, equals,
} from 'ramda';
import { DRAFTS_STATUS } from '../../constants/drafts';

export const getDrafts = createSelector(pathOr([], ['drafts', 'data']), identity);

export const getTopDrafts = createSelector(pathOr([], ['drafts', 'topDrafts', 'data']), identity);

export const getPrepareStatus = createSelector(pathOr(false, ['drafts', 'status']), identity);

export const getSavingDraftId = createSelector(pathOr(null, ['drafts', 'savingDraftId']), identity);

export const getDraftsRequestPending = createSelector(pathOr(true,
  ['pending', 'getVideoDraftsRequest']), identity);

export const getDraftById = createSelector(
  getDrafts,
  (data) => memoizeWith(identity, (id) => find(propEq('id', id), data)),
);

export const getSelectedDraft = createSelector(pathOr(null, ['drafts', 'selectedDraft']), identity);

export const getDraftFilePending = createSelector(
  (store) => (fileName) => pathOr(false, ['drafts', 'fetching', fileName], store),
  identity,
);

export const getDraft = createSelector(pathOr(null, ['drafts', 'currentDraft']), identity);

export const getChangingDraftPending = createSelector(
  getPrepareStatus,
  equals(DRAFTS_STATUS.CHANGING_DRAFT),
);

export const getSelectedDraftDataRequestStatus = createSelector(
  pathOr(true, ['pending', 'getSelectedDataRequest']),
  identity,
);

export const selectOriginalAnimationStatus = createSelector(
  pathOr(false, ['drafts', 'isOriginalAnimation']),
  identity,
);
