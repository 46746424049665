import { toast } from 'react-toastify';
import React from 'react';
import { is } from 'ramda';
import { Notification } from 'components/Notification/Notification';

// eslint-disable-next-line react/prop-types

const isStringOr = (content, alt) => (is(String, content) ? content : alt);

export const notify = {
  error: (content, title, options = {}) => toast.error(
    <Notification iconClass="icon-cancel-icon" title={title} options={options}>
      {isStringOr(content, 'Error')}
    </Notification>,
    options,
  ),
  success: (content, title, options = {}) => toast.success(
    <Notification title={title} options={options}>
      {isStringOr(content, 'Success')}
    </Notification>,
    options,
  ),
  warning: (content, title, options = {}) => toast.warn(
    <Notification iconClass="exclamation-icon" title={title} options={options}>
      {isStringOr(content, 'Warning')}
    </Notification>,
    options,
  ),
};
