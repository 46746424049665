import React, { memo, useCallback } from 'react';
import ScrollBars from 'react-custom-scrollbars';
import { Cell, Row } from 'ui-kit';
import { makeDate } from 'utils/helpers';
import { RemoveButton } from './RemoveButton';
import { Comment } from './LinkItem.styled';

const DEFAULT_STYLES = {
  padding: '20px 0',
};

const CELLS = [
  {
    name: 'title',
    render: (data) => (<span>{data.title || ''}</span>),
    ...DEFAULT_STYLES,
    paddingLeft: '10px',
    width: 176,
  },
  {
    name: 'emails',
    ...DEFAULT_STYLES,
    width: 250,
  },
  {
    name: 'comment',
    render: (data) => (data.comment && data.comment !== '' ? (
      <ScrollBars style={{ height: '85px' }}>
        <Comment>{data.comment}</Comment>
      </ScrollBars>
    ) : (<></>)),
    ...DEFAULT_STYLES,
    width: 176,
  },
  {
    name: 'createdAt',
    render: (data) => (<span>{makeDate(data.created_at)}</span>),
    ...DEFAULT_STYLES,
    width: 176,
  },
  {
    name: 'copy',
    render: (data) => (<a href={`/discus-videos/${data.code}`}>Shared link</a>),
    ...DEFAULT_STYLES,
    width: 176,
  },
  {
    name: 'remove',
    render: (data, handleClick) => (
      <RemoveButton
        title={data.title}
        onClick={handleClick}
      />
    ),
    ...DEFAULT_STYLES,
    textAlign: 'center',
    width: 106,
  },
];

/**
 *
 * @type {React.NamedExoticComponent<{
 * readonly toggleDeleteLinkModal?: function,
 * readonly setCurrentLinkId?: function,
 * readonly data?: object}>}
 */

export const LinkItem = memo(({ data, toggleDeleteLinkModal, setCurrentLinkId }) => {
  const handleClick = useCallback(() => {
    toggleDeleteLinkModal(true)();
    setCurrentLinkId(data.id);
  }, [data.id]);

  return (
    <Row>
      {CELLS.map(({ name, render, ...props }) => (
        <Cell
          key={name}
          {...props}
        >
          {render ? render(data, handleClick) : data[name]}
        </Cell>
      ))}
    </Row>
  );
});
