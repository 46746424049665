import React, { useCallback } from 'react';
import { getPreview } from 'utils/helpers';
import { useMyFiles } from 'hooks';
import { Preview, PreviewCheckbox, Root } from './ItemPreview.styled';
import { RENDER_STATUS } from '../../../constants/rendering';

/**
 *
 * @param id {number}
 * @param previewFileId {number | object}
 * @param status {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const ItemPreview = ({ id, previewFileId, status }) => {
  const {
    isSelecting, selectedFileIdsList,
    setSelectedFileIdsList, setSelectedPreviewFileId,
  } = useMyFiles();
  const checked = selectedFileIdsList.includes(id);
  const fileId = previewFileId?.id || previewFileId;

  const handleChange = useCallback((e) => {
    // check if file was rendered
    if (status === RENDER_STATUS.SUCCESS) {
      if (e.target.checked) {
        // set if checked is true
        setSelectedFileIdsList((prev) => [...prev, id]);
      } else {
        // filter if checked is false
        setSelectedFileIdsList((prev) => prev.filter((elem) => elem !== id));
      }
    }
  }, [id, status]);

  const handleClick = () => setSelectedPreviewFileId(id);

  return (
    <Root>
      {isSelecting && status === RENDER_STATUS.SUCCESS && (
      <PreviewCheckbox
        id={`my-file-${id}`}
        checked={checked}
        onChange={handleChange}
      />
      )}
      <Preview onClick={handleClick} src={getPreview(fileId, status)} alt="" />
    </Root>
  );
};
