import styled from 'styled-components';
import { layout } from 'styled-system';
import { space } from '@styled-system/space';

export const Wrapper = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  ${layout};
  ${space};
`;
