import {
  fork, take, put, select, takeEvery, delay,
} from 'redux-saga/effects';
import {
  last, path, split, values, filter, propEq, isEmpty, pathEq, compose, not, length, keys,
} from 'ramda';

import sagasManager from 'utils/sagasManager';
import * as animationActions from 'state/animation/actions';
import * as animationSelectors from 'state/animation/selectors';
import { uiActions } from 'state/ui';
import {
  createFileByUrl,
  draftsFilesStorage,
  getFileTypeByUrl,
} from 'utils/helpers/filesHelper';
import * as draftsActions from 'state/drafts/actions';
import * as draftsSelectors from 'state/drafts/selectors';
import draftsTypes from 'state/drafts/types';
import { DRAFTS_STATUS, DRAFTS_ERRORS } from 'constants/drafts';
import { notify } from 'utils/helpers/notificationHelpers';
import { LottieInstance } from 'utils/helpers/lottieHelpers';
import { SET_FILES_ERRORS } from 'constants/errors';
import { getFileUrl } from 'utils/helpers/requestHelpers';
import { AUDIO_PLAYER_STATE } from 'constants/editor';
import * as editorActions from './actions';
import editorTypes from './types';
import animationsTypes from '../animations/types';
import { getTextLayers, selectTextGroups } from './selectors';
import { findIndexAndValueByProp } from '../../utils/helpers';
import { IMAGES_CONSTANTS, IMAGES_PATH, IMAGES_PROP } from '../../constants/animationsPath';
import { getRefId } from '../../utils/animationHelpers';
import * as editorSelectors from './selectors';

function* changeTextFieldFlow() {
  while (true) {
    const { payload: [name, value] } = yield take(editorTypes.CHANGE_TEXT_FIELD);

    yield put(editorActions.goToLatestTextEditFrame(Number(name)));
    yield put(editorActions.setTextFieldValue({ name, value }));
    yield put(animationActions.rewriteAnimationData());
  }
}

function* changeTextScaleFlow() {
  while (true) {
    const { payload: [name, value] } = yield take(editorTypes.CHANGE_TEXT_SCALE);

    yield put(editorActions.goToLatestTextEditFrame(Number(name)));
    yield put(editorActions.setTextScale({ name, value }));
    yield put(animationActions.rewriteAnimationData());
  }
}

function* changeTextVariableFlow() {
  while (true) {
    const { payload: [name, value] } = yield take(editorTypes.CHANGE_TEXT_VARIABLE);

    yield put(editorActions.goToLatestTextEditFrame(Number(name)));
    yield put(editorActions.setTextVariable({ name, value }));
    yield put(animationActions.rewriteAnimationData());
  }
}

function* changeTextStatusFlow() {
  while (true) {
    const { payload: [name, value] } = yield take(editorTypes.CHANGE_TEXT_STATUS);

    yield put(editorActions.goToLatestTextEditFrame(Number(name)));
    yield put(editorActions.setTextStatus({ name, value }));
    yield put(animationActions.rewriteAnimationData());
  }
}

function* changeTextColorFlow() {
  while (true) {
    const { payload: [name, value] } = yield take(editorTypes.CHANGE_TEXT_COLOR);

    yield put(editorActions.goToLatestTextEditFrame(Number(name)));
    yield put(editorActions.setTextColor({ name, value }));
    yield put(animationActions.rewriteAnimationData());
  }
}

function* changeTexGroupVariableFlow() {
  while (true) {
    const { payload: [name, value, index] } = yield take(editorTypes.CHANGE_TEXT_GROUP_VARIABLE);
    const { textLayersList } = yield select((store) => editorSelectors.getTextGroup(store)(name));

    yield put(editorActions.goToLatestTextEditFrame(last(textLayersList)));
    yield put(editorActions.setTextGroupVariable({ name, value, index }));
    yield put(animationActions.rewriteAnimationData());
  }
}

function* changeControlValueFlow() {
  while (true) {
    const { payload: [name, value] } = yield take(editorTypes.CHANGE_CONTROL_VALUE);
    const controls = yield select(editorSelectors.getControls);
    const editFrame = yield path([name, 'editFrame'], controls);
    const result = [...controls];
    result[name].selectedControlValue = value;
    yield put(editorActions.setControls(result));
    if (editFrame || editFrame === 0) yield put(editorActions.goToEditFrameByIndex(editFrame));
    yield put(animationActions.rewriteAnimationData());
  }
}

function* changeFontFamilyFlow() {
  while (true) {
    const { payload: [name, value] } = yield take(editorTypes.CHANGE_FONT_FAMILY);

    yield put(editorActions.goToLatestTextEditFrame(Number(name)));
    yield put(editorActions.setFontFamily({ name, value }));
    yield put(animationActions.rewriteAnimationData());
  }
}

function* whenImageIsLast({ createDraftEndCb }) {
  const refs = yield select(editorSelectors.getImageRefs);
  const isPreparedImages = isEmpty(filter(propEq('isPreparing', true), values(refs)));
  if (isPreparedImages) {
    yield put(draftsActions.setStatusDraft({ status: DRAFTS_STATUS.IMAGES_READY, meta: { createDraftEndCb } }));
  }
}

function* adaptImageDetector({
  payload: {
    imageName,
    meta,
    dataOfImage,
  },
}) {
  const formData = new FormData();

  if (!dataOfImage.options.tempId && !dataOfImage.originalImageId) {
    const assetUrl = dataOfImage.value;

    try {
      const fileType = yield getFileTypeByUrl(assetUrl);
      const file = yield createFileByUrl(assetUrl, `image.${last(split(/\//, fileType))}`, fileType);
      formData.append('file', file);
      yield put(uiActions.uploadTempFileRequest(formData, {
        callbacks: {
          success: ({ data: { id } }) => {
            sagasManager.addSagaToRoot(function* successWatcher() {
              yield put(editorActions.setImageTempFileId({ imageName, tempId: id }));
            });
          },
          error: () => notify.error(DRAFTS_ERRORS.IMAGE_TEMP_UPLOADING_ERROR),
          finally: () => {
            sagasManager.addSagaToRoot(function* finallyWatcher() {
              yield put(editorActions.adaptationImageToDraftSuccess({ imageName }));
              yield whenImageIsLast(meta);
            });
          },
        },
      }));
    } catch (e) {
      yield put(editorActions.adaptationImageToDraftSuccess({ imageName }));
      yield whenImageIsLast(meta);
      yield notify.error(DRAFTS_ERRORS.CAN_NOT_FETCH_IMAGE);
    }
  } else {
    yield put(editorActions.adaptationImageToDraftSuccess({ imageName }));
    yield whenImageIsLast(meta);
  }
}

function* adaptationImageToDraftFlow() {
  yield takeEvery(editorTypes.ADAPTATION_IMAGE_TO_DRAFT, adaptImageDetector);
}

function* changeImageControlFlow() {
  while (true) {
    const { payload } = yield take(editorTypes.CHANGE_IMAGE_CONTROL);
    yield put(editorActions.setImageControl(payload));
    yield put(animationActions.rewriteAnimationData());
  }
}

function* resetToDefaultFlow() {
  while (true) {
    const { payload } = yield take(editorTypes.RESET_TO_DEFAULT);
    const draftId = yield select(draftsSelectors.getSelectedDraft);
    if (draftId) {
      yield put(draftsActions.changeDraftTemplate({ target: { value: draftId } }));
    } else {
      yield put(animationActions.updateAnimationData({ withHardReset: payload || true }));
    }
  }
}

function* goToEditFrameByIndexFlow() {
  while (true) {
    const { payload: editFrameIndex } = yield take(editorTypes.GO_TO_EDIT_FRAME_BY_INDEX);
    const listOfEditFrames = yield select(editorSelectors.getEditFramesList);
    const playerState = yield select(editorSelectors.getPlayerState);
    const animation = yield select(animationSelectors.animationDataSelector);
    const { op: totalFrames } = animation;
    const editFrame = Number(listOfEditFrames[editFrameIndex] || editFrameIndex);

    if (LottieInstance.getCurrentFrame() !== editFrame) {
      yield put(editorActions.changeLastEditFrame(editFrame));
      const percentsOfPlayer = (editFrame / totalFrames) * 100;
      if (playerState !== AUDIO_PLAYER_STATE.NOT_USED
          && playerState !== AUDIO_PLAYER_STATE.USE_ONLY_PLAYER) {
        yield put(editorActions.setPercentOfPlayer(percentsOfPlayer));
        if (playerState !== AUDIO_PLAYER_STATE.PAUSE) yield put(editorActions.setPlayerState(AUDIO_PLAYER_STATE.PAUSE));
      }
    }
    if ((editFrame || editFrame === 0)
        && LottieInstance.getCurrentFrame() !== editFrame) {
      LottieInstance.pause();
      LottieInstance.goToAndStop(editFrame);
    }
  }
}

function* uploadVideoFileAsGif() {
  while (true) {
    const {
      payload: {
        refId, file, start, end,
      },
    } = yield take(editorTypes.UPLOAD_VIDEO_FILE_AS_GIF);
    const formData = new FormData();

    formData.append('file', file);

    yield put(editorActions.setPreparingToGifStatus({ imageName: refId, status: true }));
    yield put(uiActions.uploadTempFileRequest(formData, {
      callbacks: {
        success: ({ data: { id } }) => {
          sagasManager.addSagaToRoot(function* successWatcher() {
            yield put(editorActions.uploadVideoFileAsGifRequest({ tempId: id, start, end }, { refId }));
          });
        },
      },
    }));
  }
}

function* uploadVideoFileToTrim() {
  while (true) {
    const { payload: { refId, file } } = yield take(editorTypes.UPLOAD_VIDEO_FILE_TO_TRIM);
    const formData = new FormData();

    formData.append('file', file);

    yield put(editorActions.setPreparingToGifStatus({ imageName: refId, status: true }));
    yield put(uiActions.uploadTempFileRequest(formData, {
      callbacks: {
        success: ({ data: { id } }) => {
          sagasManager.addSagaToRoot(function* successWatcher() {
            yield put(editorActions.setVideoId({ refId, videoId: id }));
          });
        },
      },
    }));
  }
}

function* setFileFromVideoToUi({
  fileData, fileStorageKey, errorMessage,
}) {
  try {
    const file = yield createFileByUrl(
      getFileUrl({ fileUrl: `uploads/temp-files/${fileData.filename}` }),
      fileData.filename,
      fileData.type,
    );
    yield draftsFilesStorage.addFile(
      file,
      fileStorageKey,
      { tempId: fileData.id },
      true,
    );
  } catch (e) {
    notify.error(errorMessage);
  }
}

function* uploadVideoAsGifSuccess() {
  while (true) {
    const {
      payload:
        {
          data: { image, audio, video },
          meta: { refId },
        },
    } = yield take(editorTypes.UPLOAD_VIDEO_FILE_AS_GIF_SUCCESS);
    if (audio) {
      yield setFileFromVideoToUi({
        fileData: audio,
        fileStorageKey: 'AUDIO',
        errorMessage: SET_FILES_ERRORS.AUDIO_FAILED,
      });
    }

    yield setFileFromVideoToUi({
      fileData: image,
      fileStorageKey: `GIF:${refId}`,
      errorMessage: SET_FILES_ERRORS.IMAGE_FAILED,
    });

    if (video) {
      yield setFileFromVideoToUi({
        fileData: video,
        fileStorageKey: 'VIDEO',
        errorMessage: SET_FILES_ERRORS.VIDEO_FAILED,
      });
      yield put(editorActions.setImageVideoTempId({ refId, tempId: video.id }));
    }

    if (audio) {
      yield put(editorActions.setHasAudioStatus({ refId, hasAudio: true }));
    }
  }
}

function* uploadVideoFileToTrimSuccess() {
  while (true) {
    const {
      payload:
        {
          data: { video, audio },
          meta: { refId },
        },
    } = yield take(editorTypes.UPLOAD_VIDEO_FILE_TO_TRIM_SUCCESS);

    yield setFileFromVideoToUi({
      fileData: audio,
      fileStorageKey: 'AUDIO',
      refId,
      errorMessage: 'There was an issue with your request.',
    });

    video.type = 'video/mp4';

    yield put(editorActions.saveTrimmedVideo({ refId, video }));
  }
}

function* readyEditorFlow() {
  while (true) {
    yield take([draftsTypes.CHANGE_DRAFT_TEMPLATE, animationsTypes.CHANGE_SELECTED_ANIMATION]);
    yield put(editorActions.setIsReady(false));
  }
}

function* readyImagesWatcherFlow() {
  while (true) {
    yield take([editorTypes.SET_IMAGE_READY, editorTypes.SET_AUDIO_FETCH_STATUS]);
    const { imageRefs, isFetchingAudio } = yield select((store) => ({
      imageRefs: editorSelectors.getImageRefs(store),
      isFetchingAudio: editorSelectors.getAudioFetchingStatus(store),
    }));
    const refs = compose(filter(compose(not, pathEq(['isReady'], true))), values)(imageRefs);
    if ((length(keys(imageRefs)) && isEmpty(refs) && !isFetchingAudio) || length(keys(imageRefs)) === 0) {
      yield delay(2000);
      yield put(editorActions.setIsReady(true));
    }
  }
}

function* goToLatestTextEditFrameFlow() {
  while (true) {
    const { payload: textId } = yield take(editorTypes.GO_TO_LATEST_TEXT_EDIT_FRAME);
    const textGroups = yield select(selectTextGroups);
    const textLayers = yield select(getTextLayers);
    const textGroup = textGroups.find((group) => group.textLayersList.includes(Number(textId)));
    const latestTextLayerId = last(textGroup.textLayersList);
    const editFrameIndex = textLayers[latestTextLayerId]?.editFrame;
    yield put(editorActions.goToEditFrameByIndex(editFrameIndex));
  }
}

function* prepareImageEditFrames() {
  while (true) {
    const { payload: images } = yield take(editorTypes.PREPARE_IMAGE_EDIT_FRAMES);
    let editFrames = {};
    for (const image of Object.values(images)) {
      const refId = getRefId(image[IMAGES_PROP.NAME]);
      const templateLayer = last(findIndexAndValueByProp({
        name: IMAGES_PROP.NAME,
        value: IMAGES_CONSTANTS.IMAGE_LAYER_NAME,
        list: image[IMAGES_PROP.EFFECT],
      }));
      if (templateLayer) {
        const editFrameLayer = last(findIndexAndValueByProp({
          name: IMAGES_PROP.NAME,
          value: IMAGES_CONSTANTS.EDITFRAME,
          list: templateLayer[IMAGES_PROP.EFFECT],
        }));
        const editFrame = path(IMAGES_PATH.EDITFRAME_VALUE, editFrameLayer);
        editFrames = { ...editFrames, [refId]: editFrame };
      }
    }
    yield put(editorActions.saveImageEditFrames(editFrames));
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(readyImagesWatcherFlow);
  yield fork(uploadVideoAsGifSuccess);
  yield fork(uploadVideoFileAsGif);
  yield fork(uploadVideoFileToTrim);
  yield fork(uploadVideoFileToTrimSuccess);
  yield fork(readyEditorFlow);
  yield fork(goToEditFrameByIndexFlow);
  yield fork(changeImageControlFlow);
  yield fork(changeTextFieldFlow);
  yield fork(changeTextScaleFlow);
  yield fork(changeTextVariableFlow);
  yield fork(changeTextStatusFlow);
  yield fork(changeTextColorFlow);
  yield fork(changeTexGroupVariableFlow);
  yield fork(changeControlValueFlow);
  yield fork(changeFontFamilyFlow);
  yield fork(adaptationImageToDraftFlow);
  yield fork(resetToDefaultFlow);
  yield fork(goToLatestTextEditFrameFlow);
  yield fork(prepareImageEditFrames);
});
