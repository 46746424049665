import { useDispatch } from 'react-redux';
import { path } from 'ramda';
import { useCallback, useEffect } from 'react';
import {
  findIndexAndValueByProp,
  searchArrayInString,
  filterLayers,
} from 'utils/helpers';
import {
  CONTROLS_PATH,
  IMAGES_CONSTANTS,
  IMAGES_PROP,
} from 'constants/animationsPath';
import { editorActions } from 'state/editor';

const getSwitchers = (layer) => {
  const effectArray = layer[IMAGES_PROP.EFFECT];
  return filterLayers(effectArray);
};

/**
 *
 * @param data {object}
 * @returns {{handleChange: ((function({target: {name: *, value: *}}): void)|*), switchers: *[]}}
 */

export const useSwitchers = ({ data }) => {
  const dispatch = useDispatch();
  let switchers = [];
  const [templateLayerIndex, templateLayer] = findIndexAndValueByProp({
    name: IMAGES_PROP.NAME,
    value: IMAGES_CONSTANTS.IMAGE_LAYER_NAME,
    list: data[IMAGES_PROP.EFFECT],
  });

  const handleChange = useCallback(({ target: { name, value } }) => {
    dispatch(editorActions.changeImageControl({
      name: Number(name),
      value,
      layerName: data[IMAGES_PROP.NAME],
      templateLayerIndex,
    }));
  }, [data]);

  if (templateLayer) {
    const switchersArray = getSwitchers(templateLayer);
    switchers = switchersArray.map(({ nm, index, ...item }) => ({
      name: nm,
      index,
      selectedControlValue: path(CONTROLS_PATH.VALUE, item),
      values: [...searchArrayInString(path(CONTROLS_PATH.VALUES, item))],
    }));
  }

  useEffect(() => {
    const initialSwitchers = {};

    switchers.forEach(({ index, selectedControlValue }) => {
      initialSwitchers[index] = {
        selectedValue: selectedControlValue,
        templateLayerIndex,
        switcherLayerIndex: index,
      };
    });
    dispatch(editorActions.setInitialSwitcherState({
      initialSwitchers,
      layerName: data[IMAGES_PROP.NAME],
    }));
  }, [data.nm]);
  return {
    switchers,
    handleChange,
  };
};
