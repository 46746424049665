import {
  assocPath, compose, findIndex, forEach, indexOf, map, prop, values, keys,
  propEq, find, includes, cond, equals, always, T, path, pathOr, filter, startsWith, isEmpty, pathEq,
} from 'ramda';
import { select, delay, put } from 'redux-saga/effects';
import { propStrInclude } from 'utils/helpers/commonHelpers';
import {
  ASSETS_PROP,
  CONTROLS_PATH, DATA_LAYER, GROUP_CONST, IMAGES_PROP, TEXT_GROUP_POSITION, TEXT_PATH, TEXT_PROP, TEXTS_CONSTANTS,
} from 'constants/animationsPath';
import { tryOr } from 'utils/helpers/animationsPath';
import * as editorActions from '../editor/actions';
import * as editorSelectors from '../editor/selectors';
import * as animationActions from './actions';
import * as animationSelectors from './selectors';

const normalizeTextValue = (value) => value.replace(/\n/g, '\r');

function* reorderAnimationData() {
  yield delay(0);
  let {
    // eslint-disable-next-line prefer-const
    oldAnimationData, textData, textGroups, controlsData, lastEditFrameIndex, imagesControls,
  } = yield select(
    (store) => ({
      oldAnimationData: animationSelectors.animationDataSelector(store),
      textData: editorSelectors.getTextLayers(store),
      textGroups: editorSelectors.selectTextGroups(store),
      controlsData: editorSelectors.getControls(store),
      lastEditFrameIndex: editorSelectors.getLastEditFrame(store),
      imagesControls: editorSelectors.getImagesControls(store),
      listOfEditFrames: editorSelectors.getEditFramesList(store),
    }),
  );
  if (Number(lastEditFrameIndex)) {
    yield put(editorActions.changeLastEditFrame(null));
  }
  const comps = oldAnimationData.assets.filter((asset) => {
    return asset.id.startsWith(ASSETS_PROP.COMP) && asset.layers.find(((layer) => layer?.ty === ASSETS_PROP.TY));
  });

  const slaveCompsWithParent = isEmpty(comps) ? [] : filter(pathEq(['ef', 0, 'nm'], 'PARENT'), comps[0].layers);

  const slaveLayers = compose(
    filter(propEq(TEXT_PROP.TYPE, TEXTS_CONSTANTS.SLAVE_LAYERS_TYPE)),
  )(prop('layers', oldAnimationData));
  const slaveLayersWithParent = filter(pathEq(TEXT_PATH.SLAVE_TEXTS_PARENT, TEXTS_CONSTANTS.SLAVE_PARENT), slaveLayers);

  compose(forEach((layerName) => {
    compose(forEach((name) => {
      const index = compose(findIndex(propEq(IMAGES_PROP.NAME, layerName)), prop('layers'))(oldAnimationData);
      const { selectedValue, templateLayerIndex, switcherLayerIndex } = path([layerName, name], imagesControls);

      oldAnimationData = assocPath(['layers', index, IMAGES_PROP.EFFECT, templateLayerIndex,
        IMAGES_PROP.EFFECT, switcherLayerIndex, ...CONTROLS_PATH.VALUE],
      Number(selectedValue), oldAnimationData);
    }), keys)(imagesControls[layerName]);
  }), keys)(imagesControls);

  compose(
    forEach(({ selectedControlValue, index }) => {
      const indexDataLayer = compose(
        findIndex(propStrInclude('nm', DATA_LAYER)),
        prop('layers'),
      )(oldAnimationData);
      const fullPathToControlValue = [
        'layers',
        indexDataLayer,
        ...CONTROLS_PATH.LAYERS,
        index,
        ...CONTROLS_PATH.VALUE,
      ];

      oldAnimationData = assocPath(fullPathToControlValue, selectedControlValue, oldAnimationData);
    }),
    values,
  )(controlsData);

  compose(forEach(({
    ind: indexGroup, switchersNormalized,
  }) => {
    map(({ value, nativeIndex }) => {
      const fullPathToTextGroupVariableValue = ['layers', indexGroup - 1,
        ...TEXT_GROUP_POSITION.CONNECTED_LAYERS,
        nativeIndex,
        ...TEXT_GROUP_POSITION.SWITCHERS_VALUE];
      oldAnimationData = assocPath(fullPathToTextGroupVariableValue,
        value, oldAnimationData);
    }, switchersNormalized);
  }))(textGroups);

  compose(
    forEach(
      ({
        ind,
        value,
        scaleValues,
        selectedScale,
        selectedVariable,
        selectedColor,
        isDisabled,
        variablesPath,
        scalePath,
        colorPath,
        pathGlobalColor,
        isGlobalColor,
        selectedFont,
        nameIndex,
      }) => {
        const group = find(({ textLayersList }) => includes(ind, textLayersList), textGroups);
        const slaveParentIds = compose(
          map(prop(TEXT_PROP.INDEX)),
          filter(pathEq(TEXT_PATH.SLAVE_TEXTS_PARENT_VALUE, Number(nameIndex))),
        )(slaveLayersWithParent);

        const slaveAssetParentIds = compose(
          map(prop(TEXT_PROP.INDEX)),
          filter(pathEq(TEXT_PATH.SLAVE_TEXTS_PARENT_VALUE, Number(nameIndex))),
        )(slaveCompsWithParent);
        const listIndexesOfSlaveParents = compose(
          map(prop('ind')), filter(({ ind: id }) => includes(id, slaveParentIds)), prop('layers'),
        )(oldAnimationData);

        const comp = compose(
          filter((localComp) => find(propEq('ind', slaveAssetParentIds[0]))(localComp.layers)),
          filter((localComp) => find(propEq('ty', 5))(localComp.layers)),
          filter((asset) => startsWith('comp', asset.id)),
          prop('assets'),
        )(oldAnimationData);

        const compIndex = isEmpty(comp) ? null : compose(
          findIndex(propEq('id', comp[0].id)),
          prop('assets'),
        )(oldAnimationData);

        const groupLayer = find(({ ind: id }) => equals(id, group.ind), prop('layers', oldAnimationData));
        const groupEffectLayers = compose(pathOr([], ['ef', 0, 'ef']))(groupLayer);
        const horizontalSwitcherLayer = find(propEq('nm', GROUP_CONST.SWITCHER_HORIZONTAL_POSITION), groupEffectLayers);
        const selectedHorizontalValue = tryOr(null, compose(
          cond(
            [
              [equals(0), always(0)],
              [equals(1), always(2)],
              [equals(2), always(1)],
              [T, null],
            ],
          ),
          path(['v', 'k']),
        ))(horizontalSwitcherLayer);

        if (scaleValues && scalePath) {
          const fullPathToTextScale = ['layers', ind - 1, ...scalePath];
          const scaleValueIndex = indexOf(selectedScale, scaleValues);
          oldAnimationData = assocPath(fullPathToTextScale, scaleValueIndex, oldAnimationData);
        }
        const fullPathToTextValue = ['layers', ind - 1, ...TEXT_PATH.VALUE];
        const fullPathToTextDisable = ['layers', ind - 1, ...TEXT_PATH.DISABLE];
        const fullPathToTextVariable = variablesPath === null ? null : ['layers', ind - 1, ...variablesPath];
        const fullPathToTexColor = ['layers', ind - 1, ...colorPath];
        const fullPathToGlobalColor = ['layers', ind - 1, ...pathGlobalColor];
        const fullPathToTextFont = ['layers', ind - 1, ...TEXT_PATH.FONT_FAMILY_VALUE];
        const fullPathToHorizontalAlign = ['layers', ind - 1, ...TEXT_PATH.HORIZONTAL_ALIGN];
        forEach((slaveIndex) => {
          const fullPathToFontForSlave = ['layers', slaveIndex - 1, ...TEXT_PATH.FONT_FAMILY_VALUE];
          const fullPathToHorizontalAlignFoSlave = ['layers', slaveIndex - 1, ...TEXT_PATH.HORIZONTAL_ALIGN];
          oldAnimationData = assocPath(fullPathToFontForSlave, selectedFont, oldAnimationData);
          if (selectedHorizontalValue !== null) {
            oldAnimationData = assocPath(fullPathToHorizontalAlignFoSlave,
              selectedHorizontalValue,
              oldAnimationData);
          }
        }, listIndexesOfSlaveParents);

        if (compIndex) {
          const fullPathToFontForSlave = ['assets', compIndex, 'layers', slaveAssetParentIds[0] - 1, ...TEXT_PATH.FONT_FAMILY_VALUE];
          const fullPathToHorizontalAlignFoSlave = ['assets', compIndex, 'layers', slaveAssetParentIds[0] - 1, ...TEXT_PATH.HORIZONTAL_ALIGN];
          oldAnimationData = assocPath(fullPathToFontForSlave, selectedFont, oldAnimationData);
          if (selectedHorizontalValue !== null) {
            oldAnimationData = assocPath(fullPathToHorizontalAlignFoSlave,
              selectedHorizontalValue,
              oldAnimationData);
          }
        }

        oldAnimationData = assocPath(fullPathToTextValue, isDisabled ? '' : normalizeTextValue(value), oldAnimationData);
        if (fullPathToTexColor !== null) {
          oldAnimationData = assocPath(fullPathToTextFont, selectedFont, oldAnimationData);
        }
        if (fullPathToTexColor !== null) {
          oldAnimationData = assocPath(fullPathToTexColor, selectedColor, oldAnimationData);
        }
        if (fullPathToGlobalColor !== null) {
          oldAnimationData = assocPath(fullPathToGlobalColor, isGlobalColor ? 1 : 0, oldAnimationData);
        }
        if (fullPathToTextDisable !== null) {
          oldAnimationData = assocPath(fullPathToTextDisable, isDisabled ? 1 : 0, oldAnimationData);
        }
        if (selectedHorizontalValue !== null) {
          oldAnimationData = assocPath(fullPathToHorizontalAlign,
            selectedHorizontalValue,
            oldAnimationData);
        }
        if (fullPathToTextVariable !== null) {
          oldAnimationData = assocPath(fullPathToTextVariable,
            selectedVariable, oldAnimationData);
        }
      },
    ), values,
  )(textData);
  yield put(animationActions.setDeepAnimationData({ ...oldAnimationData }));
}

export default reorderAnimationData;
