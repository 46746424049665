import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: block;
  height: 34px;
  width: 440px;
  background-color: ${({ theme }) => theme.colors.LIGHT_GREEN};
  border-radius: 10px;
  @media(${({ theme }) => theme.devices.SM}){
    height: 30px;
    width: 360px;
  }

  @media(${({ theme }) => theme.devices.XS}){
    width: 240px;
  }
}
`;
