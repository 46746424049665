import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import * as englishTranslations from './translations/en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNs: 'common',
    ns: ['common', 'forms', 'account'],
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        ...englishTranslations,
      },
    },
    interpolation: {
      escapeValue: true, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
    },
  });

export default i18n;
