import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  padding: 1px 3px;
  border-radius: 4px;
  transform: translate(${({ isCurrent }) => (isCurrent ? '-25%, 43px' : '-40%, -17px')}) scale(0.8);
  color: ${({ theme }) => theme.colors.BLACK};
  background: ${({ theme }) => theme.colors.WHITE};
  box-shadow: 0 0 2px ${({ theme }) => theme.colors.GRAY};
  text-align: center;
  font-size: 12px;
  user-select: none;
`;
