import {
  path,
  pathOr,
  last,
} from 'ramda';
import {
  CONTROLS_PATH, CONTROLS_PROP, DATA_CONST, DATA_PROP,
  GROUP_PATH, TEXT_PATH, TEXT_PROP, TEXTS_CONSTANTS,
} from 'constants/animationsPath';
import {
  getScaleValues,
  getVariableValues,
  searchArrayInString,
  findIndexAndValueByProp,
  tryOrDef,
  getEditFrameFromConfigStr,
  filterLayers,
  sortArrayByIndexes,
  getTextLayersListByGroup,
  getSwitchersNormalized,
  searchSwitcherValueByBegin,
} from 'utils/helpers/animationsPath';
import {
  testHide, getColorsByIndexes, getColorsFromMasterLayer,
} from 'utils/animationHelpers';
import { editorActions, editorTypes } from 'state/editor';
import {
  ALL_SYMBOLS_END_REGEXP,
  BM_RT_REGEXP,
  HIDE_WITH_SPACES_REGEXP,
  CUSTOM_NAMES_REGEXP,
  DIGIT_REGEXP,
  FONTS_SWITCHER_HIDE_REGEXP,
  HIDE_REGEXP,
  SINGLE_WHITE_SPACE_CHAR_REG_EXP,
  FONTS_SWITCHER_HIDE_WITH_SPACES_REGEXP, HIDE_EVERYWHERE_REGEXP, HIDE_EVERYWHERE_WITH_SPACES_REGEXP,
} from 'constants/regexp';
import { GLOBAL_COLOR_INDEXES, MAIN_COLORS, PRESET_COLOR_INDEXES } from 'constants/editor';

const DEFAULT_ARRAY = [null, null, null];
const LAYER_NAME_BY_PARTS_LENGTH = 3;

/**
 *
 * @param uglyName {string}
 * @returns {string}
 */

const normalizeTitleOfTextLayer = (uglyName) => {
  const layerNameByParts = uglyName.split(DIGIT_REGEXP);
  const layerDescription = last(layerNameByParts);
  const layerNumber = uglyName.match(DIGIT_REGEXP)[5];

  if (layerNameByParts.length === LAYER_NAME_BY_PARTS_LENGTH) {
    return `${layerNumber}: ${layerDescription}`;
  }

  return `Text ${layerNumber}`;
};

/**
 *
 * @param colorScript {string | null}
 * @returns {string | null}
 */

const getRelatedToColor = (colorScript) => {
  let color = null;
  MAIN_COLORS.forEach((colorType) => {
    if (colorScript) {
      if (colorScript.includes(colorType)) {
        color = colorType;
      }
    }
  });
  return color;
};

const saveTextLayersMiddleware = () => (next) => (action) => {
  if (action.type === editorTypes.SAVE_TEXT_LAYERS) {
    const { texts } = action.payload;

    const data = Object.keys(texts).reduce((accum, key) => {
      const item = texts[key];
      const [scaleValues, scalePath, selectedScale] = tryOrDef({
        def: DEFAULT_ARRAY,
        func: () => getScaleValues(item.ef),
      });
      const [variables, variablesPath, selectedVariable] = tryOrDef({
        def: DEFAULT_ARRAY,
        func: () => getVariableValues(item.ef),
      });

      const selectedFont = pathOr(null, TEXT_PATH.FONT_FAMILY_VALUE, item);

      const [tumplateLayerIndex, tumplateLayer] = tryOrDef({
        def: null,
        func: () => findIndexAndValueByProp({
          name: TEXT_PROP.NAME,
          value: TEXTS_CONSTANTS.TEXT_LAYER_NAME,
          list: item[TEXT_PROP.EFFECT],
        }),
      });

      const editFrameData = tryOrDef({
        def: null,
        func: () => findIndexAndValueByProp({
          name: TEXT_PROP.NAME,
          value: TEXTS_CONSTANTS.EDITFRAME,
          list: tumplateLayer[TEXT_PROP.EFFECT],
        }),
      });

      const editFrameVariables = pathOr('', TEXT_PATH.VARIABLES_VALUES, editFrameData[1]);

      const customName = editFrameVariables.match(CUSTOM_NAMES_REGEXP)?.[1];

      const [colorLayerIndex, colorLayer] = tryOrDef({
        def: [null, null],
        func: () => findIndexAndValueByProp({
          name: TEXT_PROP.NAME,
          value: TEXTS_CONSTANTS.SWITCHER_COLOR,
          list: tumplateLayer[TEXT_PROP.EFFECT],
        }),
      });

      const [indexGlobalColorLayer, globalColorLayer] = tryOrDef({
        def: [null, null],
        func: () => findIndexAndValueByProp({
          name: TEXT_PROP.NAME,
          value: TEXTS_CONSTANTS.GLOBAL_COLOR_LAYER_NAME,
          list: tumplateLayer[TEXT_PROP.EFFECT],
        }),
      });

      const isAllowColorPicker = tryOrDef({
        def: true,
        func: () => {
          const colorScript = path(TEXT_PATH.COLORS_SCRIPT, item);
          if (!colorScript) return false;
          const colorValues = path(CONTROLS_PATH.VALUES, colorLayer);
          if (colorValues) {
            return !(new RegExp(HIDE_REGEXP).test(colorValues))
              && !(new RegExp(HIDE_WITH_SPACES_REGEXP).test(colorValues))
              && !(new RegExp(HIDE_EVERYWHERE_REGEXP).test(colorValues))
              && !(new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(colorValues));
          }
          return !(new RegExp(BM_RT_REGEXP).test(colorScript.replace(ALL_SYMBOLS_END_REGEXP, '')));
        },
      });

      const colorScript = path(TEXT_PATH.COLORS_SCRIPT, item);

      const selectedColor = pathOr(null, TEXT_PATH.COLOR_VALUE, colorLayer);
      const isGlobalColor = pathOr(null, TEXT_PATH.COLOR_VALUE, globalColorLayer);
      const colorPath = [TEXT_PROP.EFFECT, tumplateLayerIndex, TEXT_PROP.EFFECT, colorLayerIndex,
        ...TEXT_PATH.COLOR_VALUE];
      const pathGlobalColor = [TEXT_PROP.EFFECT, tumplateLayerIndex, TEXT_PROP.EFFECT,
        indexGlobalColorLayer, ...TEXT_PATH.COLOR_VALUE];
      const nameIndex = last(path(TEXT_PATH.NAME, item).split(SINGLE_WHITE_SPACE_CHAR_REG_EXP));
      const editFrameValue = path(TEXT_PATH.EDIT_FRAME_VALUE, item);
      const editFrame = pathOr(null, [1, ...TEXT_PATH.EDITFRAME_VALUE], editFrameData);

      const isTextLayer = !(new RegExp(HIDE_REGEXP).test(editFrameValue))
        && !(new RegExp(HIDE_WITH_SPACES_REGEXP).test(editFrameValue))
        && !(new RegExp(HIDE_EVERYWHERE_REGEXP).test(editFrameValue))
        && !(new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(editFrameValue));

      return {
        ...accum,
        [path(TEXT_PATH.INDEX, item)]: {
          name: customName || normalizeTitleOfTextLayer(path(TEXT_PATH.NAME, item)),
          value: pathOr('', TEXT_PATH.VALUE, item),
          editFrame,
          nameIndex,
          variablesPath,
          isGlobalColor,
          scalePath,
          isAllowColorPicker,
          scaleValues,
          isDisabled: !!pathOr(false, TEXT_PATH.DISABLE, item),
          variables,
          selectedColor,
          colorPath,
          pathGlobalColor,
          selectedScale: scaleValues ? scaleValues[selectedScale] : null,
          selectedFont,
          selectedVariable,
          [TEXT_PROP.INDEX]: pathOr('', TEXT_PATH.INDEX, item),
          relatedToColor: getRelatedToColor(colorScript),
          isFontSelector: !(new RegExp(FONTS_SWITCHER_HIDE_REGEXP).test(editFrameValue)) && !(new RegExp(FONTS_SWITCHER_HIDE_WITH_SPACES_REGEXP).test(editFrameValue)),
          isTextLayer,
        },
      };
    }, {});
    next(editorActions.setTextLayers(data));
  } else {
    next(action);
  }
};

const saveTextGroupsMiddleware = () => (next) => {
  return (action) => {
    if (editorTypes.SAVE_TEXT_GROUPS === action.type) {
      const {
        payload: {
          groups, texts,
        },
      } = action;

      const normalizedGroupsArray = Object.values(groups).sort((a, b) => sortArrayByIndexes(a.nm, b.nm));

      const result = normalizedGroupsArray.map((item) => ({
        switchersNormalized: getSwitchersNormalized(item),
        ind: path(GROUP_PATH.INDEX, item),
        name: path(GROUP_PATH.NAME, item),
        textLayersList: tryOrDef({ def: null, func: () => getTextLayersListByGroup(item, texts) }),
      }));

      next(editorActions.setTextGroups(result));
    } else {
      next(action);
    }
  };
};

const saveVisibleTextGroupIdsMiddleware = ({ getState }) => (next) => (action) => {
  if (action.type === editorTypes.SAVE_VISIBLE_TEXT_GROUPS) {
    const groups = action.payload;
    const { editor: { textGroups, textLayers } } = getState();
    const visibleGroups = Object.values(groups).filter((group) => testHide(group));
    const parsedVisibleGroups = visibleGroups.map((group) => {
      let arePresets = false;
      let isCTA = false;
      const label = group.nm.split(/GROUP /)[1];
      const selectedGroup = Object.values(textGroups).find((textGroup) => textGroup.name === group.nm);
      if (selectedGroup?.textLayersList) {
        for (const item of selectedGroup.textLayersList) {
          if (textLayers[item].name === TEXT_PROP.MAIN_PRESETS) {
            arePresets = true;
          }
          if (textLayers[item].name?.includes(TEXT_PROP.CTA)) {
            isCTA = true;
          }
        }
      }
      return {
        idx: Number(label) - 1,
        label,
        name: group.nm,
        arePresets,
        isCTA,
      };
    }).reverse();

    next(editorActions.setVisibleTextGroups(parsedVisibleGroups));
    next(action);
  } else {
    next(action);
  }
};

const saveControlsMiddleware = () => (next) => (action) => {
  if (action.type === editorTypes.SAVE_DATA) {
    const { payload } = action;

    const effectsOfData = payload[DATA_PROP.EFFECT];
    const layers = path(CONTROLS_PATH.LAYERS, payload);

    const masterDataLayer = last(findIndexAndValueByProp({
      name: DATA_PROP.NAME,
      value: DATA_CONST.MASTER_DATA_LAYER_NAME,
      list: effectsOfData,
    }));

    const presetColors = getColorsByIndexes({
      from: PRESET_COLOR_INDEXES.FIRST,
      to: PRESET_COLOR_INDEXES.LAST,
      data: masterDataLayer,
    });
    const globalColors = getColorsByIndexes({
      from: GLOBAL_COLOR_INDEXES.FIRST,
      to: GLOBAL_COLOR_INDEXES.LAST,
      data: masterDataLayer,
    });

    const filteredLayers = filterLayers(layers);

    const data = filteredLayers.map(({ nm, index, ...item }) => ({
      name: nm,
      index,
      editFrame: tryOrDef({
        def: null,
        func: () => getEditFrameFromConfigStr(path(CONTROLS_PATH.VALUES, item)),
      }),
      selectedControlValue: path(CONTROLS_PATH.VALUE, item),
      label: tryOrDef({
        def: `SWITCHER ${index}`,
        func: () => searchSwitcherValueByBegin(CONTROLS_PROP.UI_SWITCHER_NAME, path(CONTROLS_PATH.VALUES, item)),
      }),
      values: searchArrayInString(path(CONTROLS_PATH.VALUES, item)),
    }));

    next(editorActions.setEditorColors({
      presetColors,
      globalColors,
    }));

    next(editorActions.setControls([...getColorsFromMasterLayer(masterDataLayer), ...data]));
  } else {
    next(action);
  }
};

export {
  saveTextLayersMiddleware,
  saveTextGroupsMiddleware,
  saveControlsMiddleware,
  saveVisibleTextGroupIdsMiddleware,
};
