import React, { useCallback, useMemo, useState } from 'react';
import { MyFilesContext } from './MyFilesContext';

export const MyFilesProvider = ({ children }) => {
  const [isSelecting, setIsSelecting] = useState(false);
  // file ids for actions (share, delete etc)
  const [selectedFileIdsList, setSelectedFileIdsList] = useState([]);

  const [isOpenedDeleteFilesModal, setIsOpenedDeleteFilesModal] = useState(false);

  const [isOpenedDownloadFilesModal, setIsOpenedDownloadFilesModal] = useState(false);

  const [isOpenedShareLinksModal, setIsOpenedShareLinksModal] = useState(false);

  const [selectedPreviewFileId, setSelectedPreviewFileId] = useState(null);

  const toggleDeleteFilesModal = useCallback((value) => () => setIsOpenedDeleteFilesModal(value), []);

  const toggleDownloadFilesModal = useCallback((value) => () => setIsOpenedDownloadFilesModal(value), []);

  const toggleShareLinksModal = useCallback((value) => () => setIsOpenedShareLinksModal(value), []);

  const value = useMemo(() => ({
    isSelecting,
    setIsSelecting,
    selectedFileIdsList,
    setSelectedFileIdsList,
    isOpenedDeleteFilesModal,
    toggleDeleteFilesModal,
    selectedPreviewFileId,
    setSelectedPreviewFileId,
    isOpenedDownloadFilesModal,
    toggleDownloadFilesModal,
    isOpenedShareLinksModal,
    toggleShareLinksModal,
  }), [
    isSelecting, setIsSelecting,
    setSelectedFileIdsList, selectedFileIdsList,
    isOpenedDeleteFilesModal, toggleDeleteFilesModal,
    selectedPreviewFileId, setSelectedPreviewFileId,
    isOpenedDownloadFilesModal, toggleDownloadFilesModal,
    isOpenedShareLinksModal, toggleShareLinksModal,
  ]);

  return (
    <MyFilesContext.Provider value={value}>
      {children}
    </MyFilesContext.Provider>
  );
};
