import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LottieInstance } from 'utils/helpers';
import { getUser } from 'state/user/selectors';
import { getColorsFreshStatus } from 'state/colors/selectors';
import { getFontsFreshStatus } from 'state/fonts/selectors';
import { getAnimationsFreshStatus } from 'state/animations/selectors';
import { updateAnimationData } from 'state/animation/actions';
import { getAnimationsRequest } from 'state/animations/actions';
import { ROUTES } from '../constants/routes';

export const useLocationChange = () => {
  const previousRoute = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const areColorsFresh = useSelector(getColorsFreshStatus);
  const areFontsFresh = useSelector(getFontsFreshStatus);
  const areAnimationsFresh = useSelector(getAnimationsFreshStatus);

  useEffect(() => {
    if (location.pathname !== ROUTES.ROOT) {
      const lottie = LottieInstance.get();
      if (lottie) LottieInstance.pause();
    }
    if (previousRoute.current !== location.pathname && user.id) {
      if (!areColorsFresh || !areFontsFresh) {
        dispatch(updateAnimationData());
      }
      if (!areAnimationsFresh) {
        dispatch(getAnimationsRequest());
      }
    }
    previousRoute.current = location.pathname;
  }, [location]);
};
