import React, { useCallback } from 'react';
import DOMPurify from 'dompurify';
import { deleteNotificationRequest } from 'state/company/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cancelIcon } from 'assets/images';
import {
  Content, Item, Container, Hide,
} from './NotificationBar.styled';

/**
 *
 * @param id {number}
 * @param content {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const NotificationItem = ({ id, content }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['account', 'common', 'forms']);

  const cleanContent = DOMPurify.sanitize(content);

  const deleteNotification = useCallback((notificationId) => () => {
    dispatch(deleteNotificationRequest(notificationId));
  }, []);

  return (
    <Item>
      <Container>
        <Content dangerouslySetInnerHTML={{ __html: cleanContent }} />
        <Hide
          // we have Button component in the refactoring branch,
          // I will use Button instead of div, when refactoring will be approved
          role="presentation"
          onClick={deleteNotification(id)}
        >
          <img
            src={cancelIcon}
            alt={t('Hide notification')}
          />
          {/* we have Icon component in the refactoring branch */ }
          { /* I will use Icon instead of img, when refactoring will be approved */}
        </Hide>
      </Container>
    </Item>
  );
};
