import {
  fork, take, put,
} from 'redux-saga/effects';
import sagasManager from 'utils/sagasManager';
import editorTypes from './types';
import * as editorActions from './actions';

function* convertAudioToMp3() {
  while (true) {
    const { payload: { data: { id } } } = yield take(editorTypes.CONVERT_AUDIO_TO_MP3);
    yield put(editorActions.convertAudioFileToMp3Request({ file_id: id }));
  }
}

function* convertAudioToMp3Success() {
  while (true) {
    const { payload: { data } } = yield take(editorTypes.CONVERT_AUDIO_FILE_TO__MP3_SUCCESS);

    yield put(editorActions.setConvertAudio({ isPreparing: false, data }));
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(convertAudioToMp3);
  yield fork(convertAudioToMp3Success);
});
