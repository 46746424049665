import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'ui-kit';
import { useMyFiles } from 'hooks';
import { getVideosResult } from 'state/videos/selectors';
import { Text } from './SelectingButton.styled';

export const SelectingButton = () => {
  const { t } = useTranslation(['account', 'common', 'forms']);
  const videoIds = useSelector(getVideosResult);
  const {
    setIsSelecting, isSelecting, setSelectedFileIdsList, selectedFileIdsList,
  } = useMyFiles();
  const idsLength = selectedFileIdsList.length;
  const areAllContains = videoIds.every((id) => selectedFileIdsList.includes(id));
  const label = isSelecting ? 'Close' : 'Select';
  const selectBtnLabel = areAllContains ? 'Deselect' : 'All';

  const handleSelectAll = () => {
    if (areAllContains) {
      const idsToDelete = new Set(videoIds);
      setSelectedFileIdsList((prev) => prev.filter((item) => !idsToDelete.has(item)));
    } else {
      setSelectedFileIdsList((prev) => [...new Set([...prev, ...videoIds])]);
    }
  };
  const toggleSelectView = () => setIsSelecting((prev) => !prev);

  return (
    <>
      {idsLength > 0 && (<Text>{idsLength} {t('selected')}</Text>)}
      { isSelecting && (
      <Button
        type="button"
        buttonTheme="submit"
        display="inline-block"
        height={26}
        width={64}
        borderRadius={8}
        fontSize={9}
        mb={1}
        title={t(selectBtnLabel)}
        onClick={handleSelectAll}
        label={t(selectBtnLabel)}
      />
      )}
      <Button
        type="button"
        buttonTheme="primary"
        display="inline-block"
        height={26}
        width={64}
        borderRadius={8}
        fontSize={9}
        title={t(label)}
        onClick={toggleSelectView}
        label={t(label)}
      />
    </>
  );
};
