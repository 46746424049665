import styled from 'styled-components';
import { Icon } from 'ui-kit';

export const RootStyle = styled.div`
  width: 100%;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
`;

export const StyledIcon = styled(Icon)`
`;

export const IconButton = styled.button`
  margin: 5px 0 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  :hover ${StyledIcon} {
     opacity: 0.7;
   }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 26px;
  padding: 2px 5px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.MAIN};
  color: ${({ theme }) => theme.colors.WHITE};
  font-size: 12px;
  white-space: nowrap;
`;

export const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
