import styled from 'styled-components';

export const Button = styled.button`
  position: fixed;
  bottom: 50px;
  right: 13px;
  z-index: 5;
  width: 28px;
  height: 28px;
  padding: 0;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.WHITE};
  color: ${({ theme }) => theme.colors.WHITE};
  transition: opacity .3s;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.BORDER_COLOR};
  transform: rotateZ(${({ isReversed }) => (isReversed ? '0' : '180')}deg);
  transform-origin: center;
  @media(${({ theme }) => theme.devices.SM}) {
    display: flex;
  }
`;

export const Arrow = styled.span`
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  padding: 0;
  margin: -11px 0 0 0;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid ${({ theme }) => theme.colors.BORDER_COLOR};
  border-right: 10px solid transparent;
  &::after {
    content: '';
    position: absolute;
    top: -6px;
    left: -8px;
    border-top: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid ${({ theme }) => theme.colors.WHITE};
    border-right: 8px solid transparent;
  }
`;
