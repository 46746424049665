export const EVENTS = {
  TOUCH_START: 'touchstart',
  MOUSE_MOVE: 'mousemove',
  MOUSE_UP: 'mouseup',
  TOUCH_MOVE: 'touchmove',
  TOUCH_END: 'touchend',
};

export const CUSTOM = 'CUSTOM';
export const FIRST_IMAGE = 'image_1';

export const CUSTOM_LENGTH = {
  label: 'Custom length',
  value: CUSTOM,
};

export const DEFAULT_SYNCHRONIZE_OPTIONS = [
  {
    label: 'To animation',
    value: 0,
  },
  {
    label: 'To layer',
    value: 1,
  },
];
