import {
  useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorActions } from 'state/editor';
import { FILE_TYPES } from 'constants/files';
import { getUser } from 'state/user/selectors';
import { animationDataSelector } from 'state/animation/selectors';
import { getSelectAnimationName } from 'state/animations/selectors';
import { getVersionEditorOrDraft } from 'state/editor/selectors';
import { getPrepareStatus } from 'state/drafts/selectors';

/**
 *
 * @param refId {string}
 * @param cropperRef {object}
 * @param cropData {object}
 * @param imageRef {object}
 */

export const useReadyWatcher = ({
  refId, cropperRef, cropData, imageRef,
}) => {
  const dispatch = useDispatch();
  const initialReadyAccum = { isReady: false, timer: null };
  const readyAccum = useRef(initialReadyAccum);
  const user = useSelector(getUser);
  const { assets } = useSelector(animationDataSelector);
  const selectedAnimation = useSelector(getSelectAnimationName);
  const selectedVersion = useSelector(getVersionEditorOrDraft);
  const draftStatus = useSelector(getPrepareStatus);
  // if we upload draft, we need to add assets to the dependencies, in other case - no
  const isDraftUploading = draftStatus ? assets : false;

  const assetData = assets.find((asset) => asset.id === refId);
  const setReadyStatus = useCallback(() => {
    if (readyAccum.current.timer) clearTimeout(readyAccum.current.timer);
    const isImageGif = imageRef?.fileType === FILE_TYPES.GIF;
    readyAccum.current.timer = setTimeout(() => {
      if (readyAccum.current.isReady || isImageGif) {
        dispatch(editorActions.setImageReady({ imageName: refId, status: true }));
      }
    //  If we have not changes during two seconds I suppose that we can say that the image is ready to view
    }, 200);
    // eslint-disable-next-line
  }, [readyAccum, cropData, dispatch, refId]);

  useEffect(setReadyStatus, [assetData]);

  useEffect(() => {
    const handlerReady = () => {
      readyAccum.current.isReady = true;
      setReadyStatus();
    };
    readyAccum.current.isReady = false;
    if (cropperRef.current) {
      cropperRef.current.img.addEventListener('ready', handlerReady, { once: true });
    }
    return () => {
      if (user.id) {
        readyAccum.current = initialReadyAccum;
      }
      clearTimeout(readyAccum.current.timer);
      // eslint-disable-next-line
      cropperRef.current && cropperRef.current.img.removeEventListener('ready', handlerReady);
    };
    // eslint-disable-next-line
  }, [refId, cropperRef, readyAccum, selectedAnimation, selectedVersion, isDraftUploading]);
};
