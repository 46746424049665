import socket from 'utils/helpers/socketHelpers';
import { getToken } from 'utils/helpers/requestHelpers';
import { videoReady } from 'state/socket/actions';
// eslint-disable-next-line import/named
import { convertCsvToDraftsSuccess } from 'state/animation/actions';
import { authTypes } from 'state/user';

export const socketMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.type === authTypes.GET_CURRENT_USER_SUCCESS) {
    const token = getToken();

    socket.subscribe('onOpen', () => {
      const common = socket.getChannel('common');
      common.on('readyVideo', ({ video }) => dispatch(videoReady(video)));
      common.on('convertCSVToDraft', (data) => dispatch(convertCsvToDraftsSuccess(data)));
    });
    socket.initSocketConnection({ token });
  } else if (action.type === authTypes.USER_LOGOUT) {
    socket.closeSocketConnection();
  }
  next(action);
};
