export default {
  SET_LOOP: 'SET_LOOP',
  SET_AUTOPLAY: 'SET_AUTOPLAY',
  SET_RENDERER_SETTINGS: 'SET_RENDERER_SETTINGS',
  SET_IMAGE_CROPPER_DATA: 'SET_IMAGE_CROPPER_DATA',
  SET_VERSION: 'SET_VERSION',
  SAVE_TEXT_LAYERS: 'SAVE_TEXT_LAYERS',
  SET_TEXT_LAYERS: 'SET_TEXT_LAYERS',
  SAVE_TEXT_GROUPS: 'SAVE_TEXT_GROUPS',
  SET_TEXT_GROUPS: 'SET_TEXT_GROUPS',

  CHANGE_TEXT_FIELD: 'CHANGE_TEXT_FIELD',
  SET_TEXT_FIELD_VALUE: 'SET_TEXT_FIELD_VALUE',

  CHANGE_TEXT_SCALE: 'CHANGE_TEXT_SCALE',
  SET_TEXT_SCALE: 'SET_TEXT_SCALE',

  CHANGE_TEXT_VARIABLE: 'CHANGE_TEXT_VARIABLE',
  SET_TEXT_VARIABLE: 'SET_TEXT_VARIABLE',

  CHANGE_TEXT_STATUS: 'CHANGE_TEXT_STATUS',
  SET_TEXT_STATUS: 'SET_TEXT_STATUS',

  CHANGE_TEXT_COLOR: 'CHANGE_TEXT_COLOR',
  SET_TEXT_COLOR: 'SET_TEXT_COLOR',

  SAVE_DATA: 'SAVE_DATA',
  SET_CONTROLS: 'SET_CONTROLS',

  CHANGE_CONTROL_VALUE: 'CHANGE_CONTROL_VALUE',

  SET_EDITOR_COLORS: 'SET_EDITOR_COLORS',

  SET_IMAGE_DATA: 'SET_IMAGE_DATA',
  RESET_IMAGES_DATA: 'RESET_IMAGES_DATA',
  SET_IMAGE_TEMP_FILE_ID: 'SET_IMAGE_TEMP_FILE_ID',

  SET_AUDIO_POINTS: 'SET_AUDIO_POINTS',
  SET_AUDIO_DURATION: 'SET_AUDIO_DURATION',
  SET_AUDIO_FADE: 'SET_AUDIO_FADE',
  SET_AUDIO_MUTE: 'SET_AUDIO_MUTE',
  SET_AUDIO_FILE_INFO: 'SET_AUDIO_FILE_INFO',
  SET_AUDIO_FETCH_STATUS: 'SET_AUDIO_FETCH_STATUS',

  CHANGE_LAST_EDIT_FRAME: 'CHANGE_LAST_EDIT_FRAME',

  SET_CUSTOM_LENGTH: 'SET_CUSTOM_LENGTH',

  SAVE_FONTS: 'SAVE_FONTS',

  CHANGE_FONT_FAMILY: 'CHANGE_FONT_FAMILY',
  SET_FONT_FAMILY: 'SET_FONT_FAMILY',

  ADAPTATION_IMAGE_TO_DRAFT: 'ADAPTATION_IMAGE_TO_DRAFT',
  ADAPTATION_IMAGE_TO_DRAFT_SUCCESS: 'ADAPTATION_IMAGE_TO_DRAFT_SUCCESS',

  CONVERT_AUDIO_TO_MP3: 'CONVERT_AUDIO_TO_MP3',
  SET_CONVERT_AUDIO: 'SET_CONVERT_AUDIO',
  CONVERT_AUDIO_FILE_TO_MP3_REQUEST: 'CONVERT_AUDIO_FILE_TO_MP3_REQUEST',
  CONVERT_AUDIO_FILE_TO__MP3_SUCCESS: 'CONVERT_AUDIO_FILE_TO__MP3_SUCCESS',

  EDITOR_HARD_RESET: 'EDITOR_HARD_RESET',

  SET_IMAGE_ZOOM: 'SET_IMAGE_ZOOM',

  CHANGE_IMAGE_CONTROL: 'CHANGE_IMAGE_CONTROL',
  SET_IMAGE_CONTROL: 'SET_IMAGE_CONTROL',

  RESET_TO_DEFAULT: 'RESET_TO_DEFAULT',
  SAVE_EDIT_FRAMES: 'SAVE_EDIT_FRAMES',

  GO_TO_EDIT_FRAME_BY_INDEX: 'GO_TO_EDIT_FRAME_BY_INDEX',

  UPLOAD_VIDEO_FILE_AS_GIF_REQUEST: 'UPLOAD_VIDEO_FILE_AS_GIF_REQUEST',
  UPLOAD_VIDEO_FILE_AS_GIF_SUCCESS: 'UPLOAD_VIDEO_FILE_AS_GIF_SUCCESS',
  UPLOAD_VIDEO_FILE_AS_GIF: 'UPLOAD_VIDEO_FILE_AS_GIF',
  UPLOAD_VIDEO_FILE_TO_TRIM_REQUEST: 'UPLOAD_VIDEO_FILE_TO_TRIM_REQUEST',
  UPLOAD_VIDEO_FILE_TO_TRIM: 'UPLOAD_VIDEO_FILE_TO_TRIM',
  UPLOAD_VIDEO_FILE_TO_TRIM_SUCCESS: 'UPLOAD_VIDEO_FILE_TO_TRIM_SUCCESS',

  SET_PREPARING_TO_GIF_STATUS: 'SET_PREPARING_TO_GIF_STATUS',
  SET_SAVING_DRAFT_FILE_STATUS: 'SET_SAVING_DRAFT_FILE_STATUS',
  SET_PREV_VERSION_DATA: 'SET_PREV_VERSION_DATA',

  SET_IS_READY: 'SET_IS_READY',
  SET_IMAGE_READY: 'SET_IMAGE_READY',
  SET_BACKGROUND_IMAGE_TO_DEFAULT: 'SET_BACKGROUND_IMAGE_TO_DEFAULT',

  CHANGE_TEXT_GROUP_VARIABLE: 'CHANGE_TEXT_GROUP_VARIABLE',
  SET_TEXT_GROUP_VARIABLE: 'SET_TEXT_GROUP_VARIABLE',
  SET_PROJECT_NAME: 'SET_PROJECT_NAME',
  SET_IMAGE_REF_URL: 'SET_IMAGE_REF_URL',
  SET_VIDEO_ID: 'SET_VIDEO_ID',
  SAVE_TRIMMED_VIDEO: 'SAVE_TRIMMED_VIDEO',
  SET_LAYER_TYPE: 'SET_LAYER_TYPE',
  SET_VERSION_BACKGROUND: 'SET_VERSION_BACKGROUND',
  SET_PREV_VERSION: 'SET_PREV_VERSION',
  RESET_VERSION_BACKGROUND: 'RESET_VERSION_BACKGROUND',
  SET_IMAGE_FILE_TYPE: 'SET_IMAGE_FILE_TYPE',
  SET_DESCRIPTION: 'SET_DESCRIPTION',

  SET_PLAYER_STATE: 'SET_PLAYER_STATE',
  SET_PERCENT_OF_PLAYER: 'SET_PERCENT_OF_PLAYER',
  SET_IMAGE_VIDEO_TEMP_ID: 'SET_IMAGE_VIDEO_TEMP_ID',
  SET_IMAGE_VIDEO_FILE_ID: 'SET_IMAGE_VIDEO_FILE_ID',
  SET_ORIGINAL_IMAGE_ID: 'SET_ORIGINAL_IMAGE_ID',
  SET_VIDEO_TYPE_SYNCHRONIZATION: 'SET_VIDEO_TYPE_SYNCHRONIZATION',

  SET_INITIAL_SWITCHER_STATE: 'SET_INITIAL_SWITCHER_STATE',
  SET_HAS_AUDIO_STATUS: 'SET_HAS_AUDIO_STATUS',

  GO_TO_LATEST_TEXT_EDIT_FRAME: 'GO_TO_LATEST_TEXT_EDIT_FRAME',

  PREPARE_IMAGE_EDIT_FRAMES: 'PREPARE_IMAGE_EDIT_FRAMES',
  SAVE_IMAGE_EDIT_FRAMES: 'SAVE_IMAGE_EDIT_FRAMES',
  SAVE_VISIBLE_TEXT_GROUPS: 'SAVE_VISIBLE_TEXT_GROUPS',
  SET_VISIBLE_TEXT_GROUPS: 'SET_VISIBLE_TEXT_GROUPS',
};
