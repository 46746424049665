import styled from 'styled-components';
import ScrollBars from 'react-custom-scrollbars';

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: ${({ isSelecting }) => (isSelecting ? 'baseline' : 'center')};
  width: 100%;
  padding: ${({ isSelecting }) => (isSelecting ? '30px 73px 3px' : '30px 73px')};
  @media(${({ theme }) => theme.devices.MD}) {
    padding: 0 16px 14px;
  }
  @media(${({ theme }) => theme.devices.SM}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.h2`
  font-weight: 900;
  font-size: 25px;
  @media(${({ theme }) => theme.devices.SM}) {
  font-size: 18px;
  }
`;

export const LeftPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  @media(${({ theme }) => theme.devices.SM}) {
    width: 100%;
    max-width: 400px;
  }
`;

export const RightPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 425px;
  @media(${({ theme }) => theme.devices.SM}) {
    align-items: flex-end;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 72px;
  overflow-y: hidden;
  overflow-x: hidden;
  @media(${({ theme }) => theme.devices.LGX}) {
    height: 400px;
  }
  @media(${({ theme }) => theme.devices.LG}) {
    overflow-x: auto;
  }
  @media(${({ theme }) => theme.devices.SM}) {
    padding: 0 20px;
  }
  @media(max-height: 570px) {
    height: 350px !important;
  }
`;

export const CustomScrollbars = styled(ScrollBars)`
  //set important because inline styles are used by default in this component
  height: 620px !important; 
  width: 1059px !important;
  @media(${({ theme }) => theme.devices.LGX}) {
    height: 330px !important;
  }
  @media(max-height: 850px) {
    height: 470px !important;
  }
  @media(max-height: 720px) {
    height: 420px !important;
  }
  @media(max-height: 640px) {
    height: 360px !important;
  }
  @media(max-height: 570px) {
    height: 350px !important;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding: 0 5px 0;
  @media(${({ theme }) => theme.devices.SM}) {
    width: 100%;
    max-width: 320px;
    padding: 0;
    margin-bottom: 20px;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleLabel = styled.label`
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
`;
