import React, {
  useCallback, useRef, useState, useMemo, useEffect,
} from 'react';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { Preloader } from 'ui-kit';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { ANIMATION_ACTIONS, LOTTIE_LISTENERS } from 'constants/editor';
import { useKeyFrames, useFullView, useEditor } from 'hooks';
import { LottieInstance } from 'utils/helpers/lottieHelpers';
import { debounceFunc } from 'utils/helpers/commonHelpers';
import {
  getAudioFetchingStatus, getEditorIsReady,
  getEditorSettings,
  selectAmountImages, selectAmountReadyImages,
  selectReadinessImages,
} from 'state/editor/selectors';
import {
  getChangingDraftPending,
  getSelectedDraftDataRequestStatus,
} from 'state/drafts/selectors';
import {
  animationDataSelector, animationLengthSelector,
  getAnimationDataRequestPending,
  imageLayerGroupsSelector,
} from 'state/animation/selectors';
import {
  Container, Header, PreloaderContainer, List, Item,
} from './LottiePlayer.styled';
import { PlayerHeader } from './PlayerHeader';
import { PlayerControls } from './PlayerControls';
import { SuperLottie } from './SuperLottie';
import { MobileToolbar } from '../LeftToolBar/MobileToolbar';

const UPLOADER_DURATION = 200;
const LOTTIE_SPEED = 1;
const DEBOUNCE_DELAY = 600;
const TEMP_LOTTIE_READY = 'TEMP_LOTTIE_READY';

const DIMENSIONS = {
  SQUARE: {
    WIDTH: 1080,
    HEIGHT: 1080,
  },
  INSTAGRAM: {
    WIDTH: 1080,
    HEIGHT: 1920,
  },
};

/**
 *
 * @param lottieElement
 * @returns {JSX.Element}
 * @constructor
 */

export const LottiePlayer = ({ lottieElement }) => {
  const uniqKey = useRef(new Date().getTime());
  const prevLength = useRef(null);

  const { isPaused } = useEditor();

  const isFetchingAudio = useSelector(getAudioFetchingStatus);
  const isChangingDraft = useSelector(getChangingDraftPending);
  const isLoadingDataOfAnimation = useSelector(getAnimationDataRequestPending);
  const isLoadingDataOfDraft = useSelector(getSelectedDraftDataRequestStatus);
  const animationData = useSelector(animationDataSelector);
  const options = useSelector(getEditorSettings);
  const imagesGroupsList = useSelector(imageLayerGroupsSelector);
  const isReady = useSelector(getEditorIsReady);
  const animationLength = useSelector(animationLengthSelector);
  const areReadyImages = useSelector(selectReadinessImages);
  const imagesRefsCount = useSelector(selectAmountImages);
  const imagesReadyCount = useSelector(selectAmountReadyImages);

  const [isLottieReady, setIsLottieReady] = useState(imagesRefsCount);

  const { tryRecalculateKeyFrames } = useKeyFrames({ lottieElement });
  const { handleOpenFullView } = useFullView({
    uniqKey,
    lottieElement,
    animationData,
  });

  const handleAnimationFrame = useCallback(
    () => {
      if (lottieElement.current && lottieElement.current.anim) {
        const { currentFrame, isPaused: isPausedStatus } = lottieElement.current.anim;
        const action = isPausedStatus ? ANIMATION_ACTIONS.STOP : ANIMATION_ACTIONS.PLAY;
        if (currentFrame < 1) lottieElement.current.anim[action](1, true);
      }
    },
    [lottieElement],
  );

  const eventListeners = [{
    eventName: LOTTIE_LISTENERS.DOM_LOADED,
    callback: tryRecalculateKeyFrames,
  }, {
    eventName: LOTTIE_LISTENERS.ENTER_FRAME,
    callback: () => requestAnimationFrame(handleAnimationFrame),
  }];

  const noImages = !imagesGroupsList.length ? false : (!isReady || !isLottieReady);
  const isLoadingAnimation = isLoadingDataOfAnimation && isLoadingDataOfDraft;

  const isPending = noImages || isChangingDraft || isLoadingAnimation || !areReadyImages || !isReady;

  const superLottieOptions = useMemo(
    () => ({ ...options, animationData }),
    [options, animationData],
  );

  const handleReady = useCallback(() => {
    setIsLottieReady(true);
  }, [lottieElement, isLottieReady]);

  // Setting reference to Lottie element to global lottie service
  useEffect(() => {
    LottieInstance.set(lottieElement);
    return () => {
      LottieInstance.delete();
    };
  }, [uniqKey]);

  // Lottie ready watcher
  // 1) Go to 1 frame and play after lottie dispatching event superReady
  // 2) Set locally isReady flag as true after calling event superReady

  // Setting isReady lottie status from storage to local state
  useEffect(() => {
    if (!isReady) {
      setIsLottieReady(false);
    } else {
      debounceFunc(() => setIsLottieReady(isReady), DEBOUNCE_DELAY, TEMP_LOTTIE_READY);
    }
  }, [isReady]);

  useEffect(() => {
    if (lottieElement.current) {
      lottieElement.current.el.addEventListener(LOTTIE_LISTENERS.SUPER_READY, handleReady, { once: true });
    }
    // eslint-disable-next-line
    }, [lottieElement.current, handleReady]);

  // Go to and play from start if animation length was changed
  useEffect(() => {
    const handleRebuildEnd = () => {
      prevLength.current = animationLength;
    };
    const lottie = lottieElement.current;
    if (lottie && lottie.el) {
      lottie.el.addEventListener(LOTTIE_LISTENERS.SUPER_REBUILD_END,
        handleRebuildEnd);
      return () => {
        if (lottie && lottie.el) {
          lottie.el.removeEventListener(LOTTIE_LISTENERS.SUPER_REBUILD_END,
            handleRebuildEnd);
        }
      };
    }
  }, [animationLength, lottieElement.current]);

  const animWidth = useMemo(() => {
    if (DIMENSIONS.INSTAGRAM.WIDTH === animationData.w
        && DIMENSIONS.INSTAGRAM.HEIGHT === animationData.h) {
      return '320px';
    }
    if (DIMENSIONS.SQUARE.WIDTH === animationData.w
        && DIMENSIONS.SQUARE.HEIGHT === animationData.h) {
      return '45%';
    }
    return '100%';
  }, [animationData]);

  return (
    <Container
      isPending={isPending}
      id="lottiePlayer"
    >
      <Header>
        <PlayerHeader
          isLottieReady={isLottieReady}
          onOpenFullView={handleOpenFullView}
        />
        <MobileToolbar />
      </Header>
      <Preloader
        type={PRELOADER_TYPES.CERTAIN}
        size={PRELOADER_SIZES.SMALL}
        isLoading={isPending}
        isDestroyChildren={!isReady}
        minDuration={UPLOADER_DURATION}
        preloaderChildren={(
          <PreloaderContainer>
            <List>
              {!!imagesRefsCount && (
                <Item>
                  {(`Fetched images: ${imagesReadyCount} of ${imagesRefsCount}`)}
                </Item>
              )}
              {!isNil(isFetchingAudio) && (
                <Item>
                  {`Fetched audio: of ${Number(!isFetchingAudio)} of 1`}
                </Item>
              )}
              <Item>
                {!isLottieReady || !areReadyImages ? 'Preparing player...' : 'Player is ready'}
              </Item>
            </List>
          </PreloaderContainer>
                )}
      >
        <SuperLottie
          key="lottie"
          isNotAssets={!imagesRefsCount}
          isForceUpdate={isLoadingDataOfAnimation || isChangingDraft}
          refTo={lottieElement}
          options={superLottieOptions}
          updateBy={[animationLength]}
          speed={LOTTIE_SPEED}
          height="auto"
          width={animWidth}
          eventListeners={eventListeners}
          isPaused={isPaused}
        />
        <PlayerControls
          lottieElement={lottieElement}
        />
      </Preloader>
    </Container>
  );
};
