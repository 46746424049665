export default {
  ADD_COLOR_REQUEST: 'ADD_COLOR_REQUEST',
  SET_COLOR: 'SET_COLOR',

  DELETE_COLOR_REQUEST: 'DELETE_COLOR_REQUEST',
  DELETE_COLOR: 'DELETE_COLOR',

  GET_COLOR_REQUEST: 'GET_COLOR_REQUEST',

  GET_COLORS_REQUEST: 'GET_COLORS_REQUEST',
  SET_COLORS: 'SET_COLORS',

  SET_COLORS_FRESH_STATUS: 'SET_COLORS_FRESH_STATUS',
};
