import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';

export const {
  getAnimationsRequest,
  setAnimations,
  changeSelectedAnimation,
  setSelectAnimation,
  getAnimationsSuccess,
  updateAnimationSuccess,
  updateAnimation,
  setAnimationsFreshStatus,
  setAnimationStatus,
  fetchAllAnimationsRequest,
  fetchAllAnimationsSuccess,
  fetchAllAnimationsError,
} = createActions({
  [types.GET_ANIMATIONS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/animations',
      selectorName: 'getAnimationsRequest',
      method: 'GET',
      params,
      actions: {
        success: getAnimationsSuccess,
      },
      ...meta,
    }),
  ],
  [types.UPDATE_ANIMATION]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/animations',
      selectorName: 'updateAnimationRequest',
      method: 'PUT',
      params,
      actions: {
        success: updateAnimationSuccess,
      },
      ...meta,
    }),
  ],

}, types.SET_ANIMATIONS,
types.CHANGE_SELECTED_ANIMATION,
types.SET_SELECT_ANIMATION,
types.UPDATE_ANIMATION_SUCCESS,
types.GET_ANIMATIONS_SUCCESS,
types.SET_ANIMATIONS_FRESH_STATUS,
types.SET_ANIMATION_STATUS,
types.FETCH_ALL_ANIMATIONS_REQUEST,
types.FETCH_ALL_ANIMATIONS_SUCCESS,
types.FETCH_ALL_ANIMATIONS_ERROR);
