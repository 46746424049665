import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFileUpload } from 'hooks';
import './style.sass';
import { cancelIcon } from 'assets/images';
import { notify } from 'utils/helpers/notificationHelpers';
import { resizeImage, validateFileSize } from 'utils/helpers/filesHelper';
import { IMAGES_MAX_DIMENSION, LOGO_MAX_SIZE, LOGO_MAX_SIZE_BYTES } from 'constants/fileSizes';
import { Icon } from 'ui-kit';

const LogoUpload = ({
  label, children, onUpload, onDelete, initialImage,
}) => {
  const {
    onUploadFile, value, onDeleteFile, fileUrl, fileModel,
  } = useFileUpload();

  useEffect(() => {
    if (fileModel) onUpload(fileModel);
    // eslint-disable-next-line
  }, [fileModel]);

  const onDeleteImage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onDeleteFile();
    onDelete();
  };

  const previewUrl = fileUrl || initialImage;

  const resizeLogo = (e) => {
    const file = e.currentTarget.files[0];
    resizeImage(file, IMAGES_MAX_DIMENSION.WIDTH, IMAGES_MAX_DIMENSION.HEIGHT, (fileBlob) => {
      onUploadFile({
        currentTarget: { files: { 0: new File([fileBlob], file.name, { type: file.type }) } },
      });
    });
  };

  return (
    <div className={`select-logo__row ${previewUrl ? 'select-logo__row--has-file' : ''}`}>
      {label && (<label htmlFor="logoCompany" className="design-presets__brand-logo">{label}</label>)}
      <label htmlFor="logoCompany" className="select-logo__field">
        {previewUrl && <img src={previewUrl} className="select-logo__logo" alt="" /> }
        {previewUrl && <div className="select-logo__cover" /> }
        <Icon name="upload-picture" width={22} height={22} mt="20px" />
        <span className="select-logo__title">upload your logo</span>
        <small className="select-logo__params">
          Min. 500px x 500px
          <br />
          Max. 2000px x 2000px
          <br />
          PNG with transparency
        </small>
        <input
          type="file"
          id="logoCompany"
          value={value}
          className="select-logo__input"
          onChange={validateFileSize({
            onSuccess: resizeLogo,
            onError: () => notify.error(`Maximum allowed file size is ${LOGO_MAX_SIZE}Mb`),
            maxSize: LOGO_MAX_SIZE_BYTES,
          })}
        />
        {
          previewUrl && (
          <button
            type="button"
            className="select-logo__reset-button"
            onClick={onDeleteImage}
            title="Reset this image"
          >
            <img src={cancelIcon} alt="X" />
          </button>
          )
        }
      </label>
      {
        children
      }
    </div>
  );
};

LogoUpload.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.element,
  onUpload: PropTypes.func,
  initialImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDelete: PropTypes.func.isRequired,
};

LogoUpload.defaultProps = {
  label: '',
  children: <></>,
  onUpload: () => {},
  initialImage: '',
};

export default LogoUpload;
