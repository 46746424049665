import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { equals, isEmpty } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'utils/helpers/commonHelpers';
import { setImageZoom } from 'state/editor/actions';
import { getSelectedDraft } from 'state/drafts/selectors';
import { SCALE_IMAGES } from 'constants/editor';

const normalizerZoomValue = (value) => Number(parseFloat(value).toFixed(2));

/**
 *
 * @param cropperRef {object}
 * @param refId {string}
 * @param fileUrl {string}
 * @param onCrop {function}
 * @returns {{onZoom: ((function(*): void)|*), setZoomValue: (function(*): void), maxZoom: (number), minZoom: (number), zoom: (number)}}
 */

export const useScale = ({
  cropperRef, refId, fileUrl, onCrop,
}) => {
  const [zoom, setZoom] = useState([SCALE_IMAGES.DEFAULT, SCALE_IMAGES.MIN, SCALE_IMAGES.MAX]);
  const prefZoomData = useRef({});
  const dispatch = useDispatch();
  const selectedDraft = useSelector(getSelectedDraft);
  const onZoom = useCallback((z) => {
    setZoom([normalizerZoomValue(z), ...zoom.slice(1)]);
  }, [zoom]);

  const sendImageZoom = useMemo(() => debounce(500,
    (data) => {
      if (!equals(prefZoomData.current, data) && !isEmpty(prefZoomData.current)) {
        onCrop(true, true, true);
        dispatch(setImageZoom(data));
      }
      prefZoomData.current = data;
    }),
  // eslint-disable-next-line
  [dispatch, prefZoomData]);

  const handleSetZoomByRanger = (ratio) => setZoom([normalizerZoomValue(ratio), zoom[1], zoom[2]]);

  useEffect(() => {
    if (!selectedDraft) {
      setZoom([SCALE_IMAGES.DEFAULT, ...zoom.slice(1)]);
    }
    // eslint-disable-next-line
  }, [fileUrl]);

  useEffect(() => {
    sendImageZoom([refId, zoom]);
    // eslint-disable-next-line
  }, [zoom, sendImageZoom]);

  useEffect(() => {
    if (cropperRef.current) {
      const { cropper } = cropperRef.current;
      if (cropper && !isEmpty(cropper.getImageData())) {
        cropper.getCropBoxData();
        cropper.scale(zoom[0], zoom[0]);
      }
    }
    // eslint-disable-next-line
  }, [zoom[0], cropperRef]);

  return {
    onZoom,
    zoom: zoom[0],
    setZoomValue: handleSetZoomByRanger,
    minZoom: zoom[1],
    maxZoom: zoom[2],
  };
};
