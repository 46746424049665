export default {
  REWRITE_ANIMATION_DATA: 'REWRITE_ANIMATION_DATA',
  UPDATE_ANIMATION_DATA: 'UPDATE_ANIMATION_DATA',
  SET_DEEP_ANIMATION_DATA: 'SET_DEEP_ANIMATION_DATA',

  GET_ANIMATION_DATA_REQUEST: 'GET_ANIMATION_DATA_REQUEST',
  GET_ANIMATION_DATA_SUCCESS: 'GET_ANIMATION_DATA_SUCCESS',
  SET_ANIMATION_DATA: 'SET_ANIMATION_DATA',

  CHANGE_ASSET_URL: 'CHANGE_ASSET_URL',
  CHANGE_TINT_AMOUNT: 'CHANGE_TINT_AMOUNT',
  CHANGE_TINT_COLOR: 'CHANGE_TINT_COLOR',

  SET_LENGTH: 'SET_LENGTH',
  ANIMATION_HARD_RESET: 'ANIMATION_HARD_RESET',

  DOWNLOAD_CSV: 'DOWNLOAD_CSV',

  GET_ANIMATION_CSV_REQUEST: 'GET_ANIMATION_CSV_REQUEST',
  GET_ANIMATION_CSV_SUCCESS: 'GET_ANIMATION_CSV_SUCCESS',

  UPLOAD_ANIMATION_CSV_REQUEST: 'UPLOAD_ANIMATION_CSV_REQUEST',
  UPLOAD_ANIMATION_CSV_SUCCESS: 'UPLOAD_ANIMATION_CSV_SUCCESS',

  CREATE_VIDEO_BY_CSV_REQUEST: 'CREATE_VIDEO_BY_CSV_REQUEST',

  NEW_IMAGE_WAS_UPLOADED: 'NEW_IMAGE_WAS_UPLOADED',

  CONVERT_CSV_TO_DRAFTS_SUCCESS: 'CONVERT_CSV_TO_DRAFTS_SUCCESS',
  SET_ANIMATION_FONTS: 'SET_ANIMATION_FONTS',

  SET_CSV_STATUS: 'SET_CSV_STATUS',

  SET_CUSTOM_LENGTH_IN_ANIMATION: 'SET_CUSTOM_LENGTH_IN_ANIMATION',

  SET_ANIMATION_DIMENSIONS: 'SET_ANIMATION_DIMENSIONS',
};
