import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Icon, Select } from 'ui-kit';
import { DEFAULT_INDEX } from 'constants/common';
import { Root, Text } from './PickerSelect.styled';

/**
 *
 * @param id {string}
 * @param name {string}
 * @param defaultValue {number | string}
 * @param onChange {function}
 * @param options {array}
 * @param label {string}
 * @param isShowedValue {boolean}
 * @param isDefaultSelectedOnOpen {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const PickerSelect = ({
  id,
  name,
  defaultValue,
  onChange,
  options,
  label = '',
  isShowedValue = true,
  isDefaultSelectedOnOpen = false,
}) => {
  const { t } = useTranslation('common');
  const form = useFormContext();

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={defaultValue}
      render={({ field: { value } }) => (
        <Select
          isDefaultSelectedOnOpen={isDefaultSelectedOnOpen}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onMouseDown={(e) => {
            if (isDefaultSelectedOnOpen) {
              // reset select
              e.target.selectedIndex = DEFAULT_INDEX;
            }
          }}
          id={id}
          name={name}
          value={value}
          label={(
            <Root>
              <Text>
                {isShowedValue && (value || defaultValue)} {t(label)}
              </Text>
              <Icon name="dropdown-icon" style={{ marginBottom: '5px', marginLeft: '3px' }} width={10} height={10} />
            </Root>
           )}
          options={options}
        />
      )}
    />
  );
};
