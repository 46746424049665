import React from 'react';
import PropTypes from 'prop-types';
import { tumplateLoadAnimator } from 'assets/images';

import {
  Container,
  Status,
  Loader,
  Filename,
  BottomContainer,
  CancelButton,
} from './LoadingState.styled';

/**
 *
 * @param fileName {string}
 * @param onDismiss {function(any=): function(): void}
 * @returns {JSX.Element}
 * @constructor
 */

export const LoadingState = ({ fileName, onDismiss }) => (
  <>
    <Container>
      <Status>Decoding video, please wait.</Status>
      <Loader
        src={tumplateLoadAnimator}
        alt="Tumplate load animation"
      />
      <Filename>{fileName}</Filename>
    </Container>
    <BottomContainer>
      <CancelButton
        aria-hidden="true"
        onClick={onDismiss}
      >
        Close
      </CancelButton>
    </BottomContainer>
  </>
);

LoadingState.propTypes = {
  fileName: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
};

LoadingState.defaultProps = {
  fileName: '',
};
