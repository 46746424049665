import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 36px;
  &:last-child {
    margin-bottom: 24px
  }
`;

export const Field = styled.textarea`
  width: 100%;
  height: 103px;
  border-radius: 5px;
  padding: 24px 17px 14px 21px;
  margin-bottom: 20px;
  border: none;
  box-shadow: 0 2px 5px 0 rgba(224,224,223,1);
  font-size: 14px;
  line-height: 17px;
  box-sizing: border-box;
  resize: vertical;
  :disabled {
    background-color: ${({ theme }) => theme.colors.WHITE};
    box-shadow: inset 0 0 6px #eee;
    opacity: .8;
  }
  @media(${({ theme }) => theme.devices.SM}) {
    margin-right: 15px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const ScaleLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 71px;
  height: 32px;
  margin-right: 10px;
  margin-left: 3px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  background-color: #000;
  color: ${({ theme }) => theme.colors.WHITE};
  white-space: nowrap;
`;

export const ScaleText = styled.span`
  display: inline-block;
  max-width: 40px;
  margin-left: 11px;
  font-size: 10px;
  font-weight: 700;
  margin-top: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const VariableLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 133px;
  max-height: 32px;
  padding: 0;
  margin-left: auto;
  box-sizing: border-box;
  border-radius: 7px;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.MAIN};
  border: 1px solid ${({ theme }) => theme.colors.MAIN};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 14px;
  line-height: 32px;
  font-weight: 500;
  text-decoration: none;
  transition-duration: .25s;
  cursor: pointer;
`;
