export const FILE_TYPES = {
  GIF: 'image/gif',
};

export const BEGIN_FILE = {
  DATA: 'data',
  BLOB: 'blob',
  HTTP: 'http',
};

export const VIDEO_FILE_TYPES = ['video/quicktime', 'video/mp4'];
