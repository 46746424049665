import { createSelector } from 'reselect';
import {
  pathOr, identity,
} from 'ramda';

const getColorsSelector = createSelector(
  pathOr([], ['colors', 'data']),
  identity,
);

const getColorsRequestPending = createSelector(
  pathOr(false, ['pending', 'getColorsRequest']),
  identity,
);

const getAddColorRequestPending = createSelector(
  pathOr(false, ['pending', 'addColorRequest']),
  identity,
);

const getDeleteColorRequestPending = createSelector(
  pathOr(false, ['pending', 'deleteColorRequest']),
  identity,
);

const getColorsFreshStatus = createSelector(
  pathOr(false, ['colors', 'isFresh']),
  identity,
);

export {
  getColorsSelector,
  getColorsRequestPending,
  getAddColorRequestPending,
  getDeleteColorRequestPending,
  getColorsFreshStatus,
};
