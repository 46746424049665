import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { RENDER_TYPES_LOCAL } from 'constants/rendering';
import { CheckboxGroup } from 'ui-kit';
import { filterOrSet } from 'utils/helpers/commonHelpers';
import { getCompanyDisabledVideoTypes } from 'state/company/selectors';
import { AdvancedList } from '../AdvancedList';
import { List, Item } from './RenderOptions.styled';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const RenderOptions = () => {
  const form = useFormContext();
  const formats = form.watch('formats');

  const disabledVideoTypes = useSelector(getCompanyDisabledVideoTypes);

  const handleChangeFormat = useCallback(({ target: { name } }) => {
    form.setValue('formats', filterOrSet(parseInt(name, 10), formats));
  }, [formats]);

  const isCheckedOption = useCallback((value) => formats.includes(value), [formats]);

  const checkDisabledVideoType = useCallback((videoType) => {
    return disabledVideoTypes.find((type) => type === videoType);
  }, [disabledVideoTypes]);

  return (
    <List className="formats">
      {
        RENDER_TYPES_LOCAL.map(({
          id, title, nativeRenderValue, advanced = [],
        }) => (
          <Item key={id}>
            <Controller
              name="formats"
              control={form.control}
              render={() => (
                <CheckboxGroup
                  id={id}
                  label={title}
                  name={nativeRenderValue}
                  isDisabled={checkDisabledVideoType(nativeRenderValue)}
                  isChecked={isCheckedOption(nativeRenderValue)}
                  onChange={handleChangeFormat}
                  isAppendedChild={advanced && isCheckedOption(nativeRenderValue)}
                  size="middle"
                >
                  <AdvancedList id={id} advanced={advanced} />
                </CheckboxGroup>
              )}
            />
          </Item>
        ))

      }
    </List>
  );
};
