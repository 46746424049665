import styled from 'styled-components';
import { color, typography } from 'styled-system';

export const Wrapper = styled.div`
  display: inline-block;
  max-width: 46px;
  min-width: 42px;
  max-height: 23px;
  border-radius: 30px;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.MAIN};
  line-height: 23px;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${typography};
  ${color};
`;
