import styled from 'styled-components';
import { layout, flexbox } from 'styled-system';
import { space } from '@styled-system/space';
import { Modal } from 'ui-kit';

export const Root = styled(Modal)`
  .modal-window__container {
    margin-top: 60px;
    margin-bottom: 40px;
    padding: 23px 33px 23px 12px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${flexbox};
`;

export const Image = styled.img`
  width: 32px;
  height: auto;
`;

export const Title = styled.h3`
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  ${space};
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  ${flexbox};
  ${layout};
  ${space};
`;

export const IconButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 0;
  border: none;
  font-size: 12px;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  margin: 2px 5px 0;
`;

export const Body = styled.div`;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding-top: 10px;
  ${flexbox};
`;

export const Form = styled.form`
  width: 100%;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ToggleLabel = styled.label`
  font-size: 12px;
`;
