export const TAB_IDS = {
  PARAMS: 1,
  HEADERS: 2,
  BODY: 3,
};

export const TAB_PANELS = [{
  id: TAB_IDS.PARAMS,
  label: 'Params',
},
{
  id: TAB_IDS.HEADERS,
  label: 'Headers',
},
{
  id: TAB_IDS.BODY,
  label: 'Body',
}];

export const TAB_VIEWS = [{
  id: TAB_IDS.PARAMS,
  fieldName: 'get_fields',
},
{
  id: TAB_IDS.HEADERS,
  fieldName: 'headers',
},
{
  id: TAB_IDS.BODY,
  fieldName: 'body_fields',
}];
