/* eslint-disable */
// todo: needed to refactoring
import React, { PureComponent } from 'react';
import Lottie from 'react-lottie';
import { equals, last, propOr, identity } from 'ramda';
import { callOnlyLatest, debounceFunc } from "utils/helpers/commonHelpers";
import { preloaderAnim } from 'assets/images';
import { animationInstance } from 'utils/helpers/animationInstance';
import './style.sass';

const initialConfig = {
    latestFrame: null,
    isRebuilding: false,
    isReady: false,
    stackGoTo: [], // { action,  params }
};

let config = {...initialConfig};

const GO_TO_ACTIONS = {
    PLAY: 'goToAndPlay',
    STOP: 'goToAndStop',
};

const goToLatestAndClearStack = (props) => () => {
    if(props.refTo.current) {
        const lastGoTo = last(config.stackGoTo);
        if (lastGoTo) {
            const { action, params: { frame, type }} = lastGoTo;
            props.refTo.current.anim[action](frame, type);
            config.stackGoTo = [];
        }
    }
};

export class SuperLottie extends PureComponent {

    constructor(props) {
        super(props);
        this.goToAndPlaySuper = this.goToAndPlaySuper.bind(this);
        this.goToAndStopSuper = this.goToAndStopSuper.bind(this);
        this.setRef = this.setRef.bind(this);
        this.resetFakeElement = this.resetFakeElement.bind(this);
        this.setFakeElement = this.setFakeElement.bind(this);
        this.setReady = this.setReady.bind(this);
    }

    resetFakeElement(){
        const fakeLottieElement = document.querySelector('.super-lottie__fake');
        if (fakeLottieElement) {
            fakeLottieElement.removeAttribute('data-use');
            fakeLottieElement.innerHTML = '';
        }

        callOnlyLatest(() => {
            if( this.props && this.props.refTo.current) {
                this.props.refTo.current.el.dispatchEvent(new CustomEvent('superRebuildEnd', { detail: { config } }));
            }
            config.isRebuilding = false;
        }, 100, 'superLottieResetFake')
    }

    goToAndPlaySuper(frame, type = false){
        config.stackGoTo.push({ action: GO_TO_ACTIONS.PLAY, params: { frame, type }});
        debounceFunc(goToLatestAndClearStack(this.props), 100,'goToSuperLottie')
    }

    goToAndStopSuper(frame, type = false){
        config.stackGoTo.push({ action: GO_TO_ACTIONS.STOP, params: { frame, type }});
        debounceFunc(goToLatestAndClearStack(this.props), 100,'goToSuperLottie')
    }

    setFakeElement(){
        if (!config.isRebuilding && config.isReady) {
        this.props.refTo.current.el.dispatchEvent(new CustomEvent('superRebuildStart', { detail: { config } }));
        config.isRebuilding = true;
        const lottieElement = this.props.refTo.current.el;
        const fakeLottieElement = document.querySelector('.super-lottie__fake');
        fakeLottieElement.style.width = '100%';
        fakeLottieElement.style.maxHeight = `${lottieElement.clientHeight}px`;
        const tempFakeLottieElement = document.createElement('div');
        tempFakeLottieElement.innerHTML = lottieElement.innerHTML
        if (!fakeLottieElement.innerHTML  && this.props.refTo.current.anim.currentFrame !== 0) {
            fakeLottieElement.setAttribute('data-use', '1');
            config.latestFrame = this.props.refTo.current.anim.currentFrame;
            fakeLottieElement.innerHTML = tempFakeLottieElement.innerHTML;
        }
        }
    }

    setReady(){
        if (!config.isReady) { /// checking on isRebuilding
        this.resetFakeElement();
        if(this.props.refTo.current && this.props.refTo.current.anim){
            this.props.refTo.current.el.dispatchEvent(new CustomEvent('superReady', { detail: { config } }));
            config.isReady = true;
        }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isForceUpdate) {
            this.resetFakeElement();
        }
    }

    componentWillUpdate(prevProps, prevState, snapshot) {
        config.currentFrame = this.props.refTo.current.anim.currentFrame;
        if (this.props.isForceUpdate) {
            this.resetFakeElement();
        } else if (equals(prevProps.options.animationData, this.props.options.animationData)
            || !equals(this.props.updateBy, prevProps.updateBy)) {
            // Component props will changing
            this.setFakeElement();
        }
        if (!equals(this.props.updateBy, prevProps.updateBy)) {
            config.isReady = false;
        }
        if(!equals(this.props.updateBy, prevProps.updateBy)) {
            this.props.refTo.current.anim.goToAndStop(1, true);
        }
    }

    setRef(e){
        const refElement = e;
        if(refElement){
        refElement.goToAndPlaySuper = this.goToAndPlaySuper;
        refElement.goToAndStopSuper = this.goToAndStopSuper;
        }
        this.props.refTo.current = refElement;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        callOnlyLatest(() => {
        this.resetFakeElement();
        }, 50, 'resetFakeElementByUpdate');

        if (this.props.isForceUpdate) this.resetFakeElement();
        if (this.props.refTo.current.anim.isPaused) {
            this.props.refTo.current.anim.goToAndStop(config.currentFrame, true);
        } else {
            this.props.refTo.current.anim.goToAndPlay(config.currentFrame, true);
        }
    }

    componentDidMount() {
        this.props.refTo.current.anim.config =  config;
    }

    componentWillUnmount() {
        config.isReady = false;
        config.isRebuilding = false;
        this.resetFakeElement();
        config = {...initialConfig}
    }

  render() {
    let animation = animationInstance.get();
    return (
        <div className="frame">
            <div className="super-lottie">
                <div className="super-lottie__fake" />
                <img src={preloaderAnim} className="super-lottie__lottie-loader" alt=""/>
                {this.props.beforeChild || <></>}
                <Lottie
                    ref={this.setRef}
                    {...this.props}
                    options={{
                    ...this.props.options,
                    animationData: {
                        ...this.props.options.animationData,
                        w: this.props.options.animationData.w || animation.w,
                        h: this.props.options.animationData.h || animation.h
                    }
                    }}
                    eventListeners={[
                    {
                        eventName: 'DOMLoaded',
                        callback: (e) =>  callOnlyLatest(() => {
                        propOr(identity, 'DOMLoaded', this.props.eventListeners)();
                        this.setReady(true);
                        }, 100, 'setReady'),
                    },
                    ...this.props.eventListeners]
                    }
                />
            </div>
        </div>
    );
  }
}
