import { handleActions } from 'redux-actions';
import { prop } from 'ramda';
import { mergeIn } from 'utils/helpers/ramdaStateHelpers';
import types from './types';

const errorRequestReducer = handleActions({
  [types.API_REQUEST]: mergeIn(prop('payload')),
}, null);

export default errorRequestReducer;
