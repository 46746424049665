import React, { memo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useSwitchers } from '../hooks';
import { SwitchersItem } from './SwitchersItem';
import { RootStyle } from './Switchers.styled';

export const Switchers = memo(({ data }) => {
  const { handleChange, switchers } = useSwitchers({ data });
  return switchers.length > 0 ? (
    <RootStyle>
      {
        switchers.map(({
          name, values, selectedControlValue, index,
        }) => (
          <SwitchersItem
            key={`image-switcher-${uuidV4()}`}
            name={name}
            values={values}
            selectedControlValue={selectedControlValue}
            index={index}
            handleChange={handleChange}
          />
        ))
       }
    </RootStyle>
  ) : (<></>);
});
