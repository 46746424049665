import {
  put, take, fork, select,
} from 'redux-saga/effects';
import {
  propEq, prop, compose, find,
} from 'ramda';
import { createFileByUrl, draftsFilesStorage } from 'utils/helpers/filesHelper';
import { getFileUrl } from 'utils/helpers/requestHelpers';
import { notify } from 'utils/helpers/notificationHelpers';
import { DRAFTS_ERRORS, DRAFTS_STATUS } from 'constants/drafts';
import sagasManager from 'utils/sagasManager';
import { AUDIO_PLAYER_STATE } from 'constants/editor';
import AudioInstance from 'utils/helpers/audioHelper';
import draftsTypes from './types';
import * as draftsActions from './actions';
import * as editorActions from '../editor/actions';
import * as animationActions from '../animation/actions';
import * as animationsActions from '../animations/actions';
import * as animationsSelectors from '../animations/selectors';
import { setVideoDraft } from './actions';

const DEFAULT = {
  NAME: 'Untitled project',
  DESCRIPTION: '',
};

function* getDraftFile(fileUrl, fileName, fileType, callback) {
  yield put(draftsActions.fetchDraftFile({ fileName }));
  yield createFileByUrl(fileUrl,
    fileName,
    fileType).then((resultFile) => callback(resultFile, fileName))
    .catch(() => notify.error(DRAFTS_ERRORS.FETCHING_DRAFT_FILE_FAILED));
  yield put(draftsActions.fetchDraftFileSuccess({ fileName }));
}

function* selectDraftFlow() {
  while (true) {
    const {
      payload: {
        target: { value: id }, status = DRAFTS_STATUS.CHANGING_DRAFT, isControls = false, isDuplicate = false,
      },
    } = yield take(draftsTypes.CHANGE_DRAFT_TEMPLATE);
    AudioInstance.delete();

    yield put(draftsActions.setStatusDraft(status));
    yield put(draftsActions.selectDraft({ id: parseInt(id, 10) }));

    if (Number(id)) { // If selected draft id is null or NONE_DRAFT.id
      yield put(editorActions.setIsReady(false));
      if (isDuplicate) {
        yield put(draftsActions.duplicateVideoDraftRequest({ draftId: id }));
      } else {
        yield put(draftsActions.getVideoDraftRequest({ id, withFiles: 1 }, { withoutControls: isControls ? 1 : 0 }));
        yield put(editorActions.editorHardReset({}));
        yield put(editorActions.resetImagesData({}));
        yield put(animationActions.animationHardReset());
      }
    } else {
      yield put(animationActions.updateAnimationData({ withHardReset: true, draftId: id }));
      yield put(editorActions.setProjectName(DEFAULT.NAME));
      yield put(editorActions.setDescription(DEFAULT.DESCRIPTION));
    }
  }
}

function* createVideDraftFromAnimationSuccessFlow() {
  while (true) {
    const { payload: { data } } = yield take([draftsTypes.CREATE_VIDEO_DRAFT_FROM_ANIMATION_SUCCESS, draftsTypes.DUPLICATE_VIDEO_DRAFT_SUCCESS]);
    AudioInstance.delete();

    yield put(draftsActions.selectDraft({ id: parseInt(data.id, 10) }));
    yield put(editorActions.setIsReady(false));
    yield put(editorActions.editorHardReset({}));
    yield put(editorActions.resetImagesData({}));
    yield put(animationActions.animationHardReset());
  }
}

function* getVideoDraftSuccessFlow() {
  while (true) {
    const { payload: { data, meta } } = yield take([draftsTypes.GET_VIDEO_DRAFT_SUCCESS, draftsTypes.DUPLICATE_VIDEO_DRAFT_SUCCESS, draftsTypes.CREATE_VIDEO_DRAFT_FROM_ANIMATION_SUCCESS]);
    const {
      original_audio_file_id,
      original_audio_file_id_copy,
      audio_start_at,
      audio_fade: audioFade,
      custom_length: customLength,
    } = data;
    // eslint-disable-next-line camelcase
    const audioData = original_audio_file_id_copy || original_audio_file_id;
    const animations = yield select(animationsSelectors.getAnimations);

    const animationId = compose(
      prop('index'),
      find(propEq('name', prop('animation_name', data))),
    )(animations);
    yield put(animationsActions.changeSelectedAnimation({
      id: animationId,
      isFetching: false,
      isResetSelectedDraft: false,
    }));
    yield put(draftsActions.getSelectedDraftDataRequest({
      draftId: data.id,
      salt: localStorage.getItem('salt'),
      withoutControls: meta.withoutControls,
    }, {
      ...meta,
      withHardReset: true,
      audio_start_at,
      audioData,
      callbacks: {
        success: () => {
          sagasManager.addSagaToRoot(function* watcherSuccess() {
            if (audioData) {
              const { id: originalAudioFileId, original } = audioData;
              yield put(editorActions.setAudioFetchStatus(true));
              yield getDraftFile(
                getFileUrl({ fileUrl: `files/${originalAudioFileId}`, isUseSalt: true }),
                original,
                'audio/mp3',
                (fileData) => {
                  sagasManager.addSagaToRoot(function* watcher() {
                    yield put(editorActions.setAudioFetchStatus(false));
                  });
                  draftsFilesStorage.addFile(fileData,
                    'AUDIO',
                    { startPoint: audio_start_at, fade: audioFade });
                },
              );
            }
          });
        },
      },
    }));
    yield put(editorActions.setCustomLength(customLength));
    yield put(editorActions.setAudioFade(!!audioFade));
    yield put(editorActions.setPlayerState(AUDIO_PLAYER_STATE.PAUSE));
    yield put(setVideoDraft({ data: { model: data } }));
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(getVideoDraftSuccessFlow);
  yield fork(createVideDraftFromAnimationSuccessFlow);
});

export default selectDraftFlow;
