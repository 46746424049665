import React, { useCallback, memo } from 'react';
import { useMyFiles } from 'hooks';
import { Icon } from '../Icon';
import { Button } from './Buttons.styled';

/**
 *
 * @param id {number}
 * @param title {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const RemoveButton = memo(({ id, title }) => {
  const { toggleDeleteFilesModal, setSelectedFileIdsList } = useMyFiles();
  const handleDeleteFile = useCallback((videoId) => () => {
    setSelectedFileIdsList([videoId]);
    toggleDeleteFilesModal(true)();
  }, []);

  return (
    <Button
      type="button"
      onClick={handleDeleteFile(id)}
      title={`Delete ${title}`}
    >
      <Icon name="delete" height={20} width={17} />
    </Button>
  );
});
