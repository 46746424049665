import { API_METHODS } from 'constants/common';
import { ROUTES } from 'constants/api.routes';
import { catchError } from 'utils/helpers';
import { apiService } from './api.service';

export const authService = {
  login: async (data = {}) => {
    return catchError(apiService({
      method: API_METHODS.POST,
      subUrl: ROUTES.AUTH.LOGIN,
      data,
    }));
  },
  googleLogin: async (params = {}) => {
    return catchError(apiService({
      method: API_METHODS.GET,
      subUrl: ROUTES.AUTH.LOGIN_GOOGLE,
      config: {
        params,
      },
    }));
  },
  checkCodeForgotPassword: async (data = {}) => {
    return catchError(apiService({
      method: API_METHODS.POST,
      subUrl: ROUTES.AUTH.CHECK_CODE_FORGOT_PASSWORD,
      data,
    }));
  },
  resetPassword: async (data = {}) => {
    return catchError(apiService({
      method: API_METHODS.POST,
      subUrl: ROUTES.AUTH.RESET_PASSWORD,
      data,
    }));
  },
  forgotPassword: async (data = {}) => {
    return catchError(apiService({
      method: API_METHODS.POST,
      subUrl: ROUTES.AUTH.FORGOT_PASSWORD,
      data,
    }));
  },
  register: async (data = {}) => {
    return catchError(apiService({
      method: API_METHODS.POST,
      subUrl: ROUTES.AUTH.REGISTER,
      data,
    }));
  },
  registerSecondStep: async (data = {}) => {
    return catchError(apiService({
      method: API_METHODS.POST,
      subUrl: ROUTES.AUTH.REGISTER_SECOND_STEP,
      data,
    }));
  },
  checkActivationCode: async (params = {}) => {
    return catchError(apiService({
      method: API_METHODS.GET,
      subUrl: ROUTES.AUTH.CHECK_ACTIVATION_CODE,
      config: {
        params,
      },
    }));
  },
};
