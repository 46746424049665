import React, {
  useCallback, useEffect, useRef,
} from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Portal from 'components/Portal/Portal';
import { useClickOutside } from 'hooks';
import { COLORS } from 'constants/styles';
import {
  Body, Container, Question, Row,
} from './Modal.styled';
import { Button } from '../Button';

const DIMENSIONS = {
  WIDTH: 71,
  HEIGHT: 37,
};

/**
 *
 * @param isOpen {boolean}
 * @param isPrompt {boolean}
 * @param question {string}
 * @param className {string}
 * @param children {React.Node}
 * @param afterClickingOutsideClose {boolean}
 * @param onClose {function}
 * @param onChange {function}
 * @param props {object} style props
 * @returns {JSX.Element}
 * @constructor
 */

export const Modal = ({
  isOpen,
  isPrompt,
  question,
  className,
  children,
  afterClickingOutsideClose,
  onClose,
  onChange,
  ...props
}) => {
  const { t } = useTranslation('common');

  const modalRef = useRef(null);

  const onChangeHandler = useCallback((value) => () => {
    onChange(value);
    onClose();
  }, [onChange, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useClickOutside({
    ref: modalRef,
    callback: onClose,
    condition: isOpen && afterClickingOutsideClose,
  });

  return isOpen && (
    <Portal>
      <Container
        className={cn('modal-window', {
          [className]: className,
        })}
      >
        <Body className="modal-window__container" ref={modalRef} isPrompt={isPrompt} {...props}>
          {question && (<Question>{question}</Question>)}
          {children && (children)}
          {isPrompt && (
            <Row>
              <Button
                type="button"
                onClick={onChangeHandler(true)}
                width={DIMENSIONS.WIDTH}
                height={DIMENSIONS.HEIGHT}
                color={COLORS.GREEN}
                label={t('Yes')}
                title={t('Yes')}
              />
              <Button
                type="button"
                onClick={onClose}
                width={DIMENSIONS.WIDTH}
                height={DIMENSIONS.HEIGHT}
                color={COLORS.LIGHT_GRAY}
                label={t('No')}
                title={t('No')}
              />
            </Row>
          )}
        </Body>
      </Container>
    </Portal>
  );
};

Modal.defaultProps = {
  afterClickingOutsideClose: true,
  isPrompt: false,
  question: '',
  onChange: (a) => a,
  children: <></>,
  className: '',
  onClose: () => {},
  isOpen: false,
};
