import React, { useMemo } from 'react';
import { Select } from 'components/forms';
import { Title } from './AdvancedList.styled';

/**
 *
 * @param title {string}
 * @param options {array}
 * @param name {string}
 * @param id {number | string}
 * @param onChange {function}
 * @param value {number | string}
 * @returns {JSX.Element}
 * @constructor
 */

export const AdvancedItem = ({
  title, options, name, id, onChange, value,
}) => {
  const makeLabel = useMemo(() => {
    const neededOption = options.find((option) => option.value === parseFloat(value));
    return (
      <div className="select">{neededOption.label}</div>
    );
  }, [value, options]);
  return (
    <>
      <Title htmlFor={`advanced-${id}-${name}`}>{title}</Title>
      <Select
        onChange={onChange}
        id={`advanced-${id}-${name}`}
        label={makeLabel}
        name={name}
        value={value}
        options={options}
      />
    </>
  );
};
