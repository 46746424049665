import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsAuthenticated } from 'state/user/selectors';
import SignIn from 'pages/Auth/SignIn/SignIn';
import { resetConfig } from 'utils/mixpanel';

// For routes that can only be accessed by authenticated users

/**
 *
 * @param children {React.ReactNode}
 * @returns {JSX.Element}
 * @constructor
 */
export const AuthGuard = ({ children }) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const accessToken = window.localStorage.getItem('accessToken');
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  if (!isAuthenticated && !accessToken && pathname !== requestedLocation) {
    resetConfig();
    return (
      <SignIn />
    );
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};
