import React from 'react';
import InputRange from 'react-input-range';
import { Container } from './PlayerTrack.styled';

/**
 *
 * @param maxValue {number}
 * @param value {number}
 * @param onChange {function}
 * @param formatLabel {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const PlayerTrack = ({
  maxValue,
  value,
  onChange,
  formatLabel = '',
}) => (
  <Container>
    <InputRange
      maxValue={maxValue}
      minValue={0}
      value={value || 0}
      onChange={onChange}
      formatLabel={formatLabel}
    />
  </Container>
);
