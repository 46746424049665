import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';

export const {
  getTopDrafts,
  toggleTopDraftStatus,
  createVideoDraftRequest,
  deleteVideoDraftRequest,
  getVideoDraftsRequest,
  sendDataFileDraftRequest,
  sendAudioFileDraftRequest,
  getSelectedDraftDataRequest,
  sendFileDraftRequest,
  getDraftFilesRequest,
  updateDraftFileRequest,
  getVideoDraftRequest,
  getDraftJsonRequest,
  getCsvByDraftRequest,
  setVideoDrafts,
  deleteVideoDraft,
  setVideoDraft,
  createVideoDraft,
  sendDataFileDraftSuccess,
  sendAudioFileDraftSuccess,
  setStatusDraft,
  changeDraftTemplate,
  getSelectedDraftDataSuccess,
  setSelectedDraft,
  fetchDraftFile,
  fetchDraftFileSuccess,
  setSavingDraftId,
  setDraftFiles,
  setDraftFile,
  getDraftFilesSuccess,
  getVideoDraftSuccess,
  deleteVideoDraftSuccess,
  setDraftOptions,
  getDraftJsonSuccess,
  getCsvByDraftSuccess,
  selectDraft,
  updateListOfTopDrafts,
  updateVideoDrafts,
  toggleOriginalAnimationStatus,
  duplicateVideoDraftRequest,
  duplicateVideoDraftSuccess,
  createVideoDraftFromAnimationRequest,
  createVideoDraftFromAnimationSuccess,
} = createActions({
  [types.GET_TOP_DRAFTS]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/video-drafts/top',
      selectorName: 'getTopDrafts',
      method: 'GET',
      params,
      actions: {
        success: (payload) => updateListOfTopDrafts(payload.data),
      },
      ...meta,
    }),
  ],
  [types.TOGGLE_TOP_DRAFT_STATUS]: [
    identity,
    ({ id, params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${id}/toggle-top`,
      selectorName: 'toggleTopDraftStatus',
      method: 'PUT',
      params,
      actions: {
        success: (payload) => updateListOfTopDrafts(payload.data),
      },
      ...meta,
    }),
  ],
  [types.CREATE_VIDEO_DRAFT_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/video-drafts',
      selectorName: 'createVideoDraftRequest',
      method: 'POST',
      params,
      actions: {
        success: setVideoDraft,
      },
      ...meta,
    }),
  ],
  [types.DELETE_VIDEO_DRAFT_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${id}`,
      selectorName: 'deleteVideoDraftRequest',
      method: 'DELETE',
      params,
      actions: {
        success: () => deleteVideoDraftSuccess(id),
      },
      ...meta,
    }),
  ],
  [types.GET_VIDEO_DRAFTS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/video-drafts',
      selectorName: 'getVideoDraftsRequest',
      method: 'GET',
      params,
      actions: {
        success: setVideoDrafts,
      },
      ...meta,
    }),
  ],
  [types.SEND_DATA_FILE_DRAFT_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/temp-files',
      selectorName: 'sendAudioFileRequest',
      method: 'POST',
      params,
      actions: {
        success: (payload) => sendDataFileDraftSuccess({ ...payload, meta }),
      },
      ...meta,
    }),
  ],
  [types.SEND_AUDIO_FILE_DRAFT_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/temp-files',
      selectorName: 'sendAudioFileRequest',
      method: 'POST',
      params,
      actions: {
        success: (payload) => sendAudioFileDraftSuccess({ ...payload, meta }),
      },
      ...meta,
    }),
  ],
  [types.GET_SELECTED_DRAFT_DATA_REQUEST]: [
    identity,
    ({ draftId, salt, ...params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${draftId}/json?salt=${salt}`,
      selectorName: 'getSelectedDataRequest',
      method: 'GET',
      params,
      actions: {
        success: (payload) => getSelectedDraftDataSuccess({
          ...payload,
          meta: { ...meta, draftId },
        }),
      },
      ...meta,
    }),
  ],
  [types.SEND_FILE_DRAFT_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${id}/files`,
      selectorName: 'sendFileDraftRequest',
      method: 'POST',
      params,
      ...meta,
    }),
  ],
  [types.GET_DRAFT_FILES_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${id}/files`,
      selectorName: 'getDraftFilesRequest',
      method: 'GET',
      params,
      actions: {
        meta,
        success: getDraftFilesSuccess,
      },
      ...meta,
    }),
  ],
  [types.UPDATE_DRAFT_FILE_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${id}/files`,
      selectorName: 'updateDraftFileRequest',
      method: 'PUT',
      params,
      actions: {
        success: setDraftFile,
      },
      ...meta,
    }),
  ],
  [types.GET_VIDEO_DRAFT_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${id}`,
      selectorName: 'getVideoDraftRequest',
      method: 'GET',
      params,
      actions: {
        meta,
        success: getVideoDraftSuccess,
      },
      ...meta,
    }),
  ],
  [types.GET_DRAFT_JSON_REQUEST]: [
    identity,
    ({ draftId, ...params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${draftId}/json`,
      selectorName: 'getDraftJsonRequest',
      method: 'GET',
      params,
      actions: {
        meta,
        success: getVideoDraftSuccess,
      },
      ...meta,
    }),
  ],
  [types.GET_CSV_BY_DRAFT_REQUEST]: [
    identity,
    ({ draftId, ...params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${draftId}/csv`,
      selectorName: 'getDraftJsonRequest',
      method: 'GET',
      params,
      actions: {
        meta,
        success: getCsvByDraftSuccess,
      },
      ...meta,
    }),
  ],
  [types.CREATE_VIDEO_DRAFT_FROM_ANIMATION_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/video-drafts/animation?withFiles=1',
      selectorName: 'createVideoDraftFromAnimationRequest',
      method: 'POST',
      params,
      actions: {
        meta,
        success: createVideoDraftFromAnimationSuccess,
      },
      ...meta,
    }),
  ],
  [types.DUPLICATE_VIDEO_DRAFT_REQUEST]: [
    identity,
    ({ draftId, ...params }, meta) => ({
      async: true,
      route: `/api/video-drafts/${draftId}/duplicate?withFiles=1`,
      selectorName: 'duplicateVideoDraftRequest',
      method: 'POST',
      params,
      actions: {
        meta,
        success: duplicateVideoDraftSuccess,
      },
      ...meta,
    }),
  ],
},
types.SET_VIDEO_DRAFTS,
types.DELETE_VIDEO_DRAFT,
types.SET_VIDEO_DRAFT,
types.CREATE_VIDEO_DRAFT,
types.SEND_DATA_FILE_DRAFT_SUCCESS,
types.SEND_AUDIO_FILE_DRAFT_SUCCESS,
types.SET_STATUS_DRAFT,
types.CHANGE_DRAFT_TEMPLATE,
types.GET_SELECTED_DRAFT_DATA_SUCCESS,
types.SET_SELECTED_DRAFT,
types.FETCH_DRAFT_FILE,
types.FETCH_DRAFT_FILE_SUCCESS,
types.SET_SAVING_DRAFT_ID,
types.SET_DRAFT_FILES,
types.SET_DRAFT_FILE,
types.GET_DRAFT_FILES_SUCCESS,
types.GET_VIDEO_DRAFT_SUCCESS,
types.DELETE_VIDEO_DRAFT_SUCCESS,
types.SET_DRAFT_OPTIONS,
types.GET_DRAFT_JSON_SUCCESS,
types.GET_CSV_BY_DRAFT_SUCCESS,
types.SELECT_DRAFT,
types.UPDATE_LIST_OF_TOP_DRAFTS,
types.UPDATE_VIDEO_DRAFTS,
types.TOGGLE_ORIGINAL_ANIMATION_STATUS,
types.DUPLICATE_VIDEO_DRAFT_SUCCESS,
types.CREATE_VIDEO_DRAFT_FROM_ANIMATION_SUCCESS);
