import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: ${({ isShowed }) => (isShowed ? '40px' : '-200px')};
  left: 40px;
  z-index: 999;
  width: 300px;
  height: 80px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: 1px 1px 3px #333;
  box-sizing: border-box;
  padding: 4px 8px;
  transition-duration: .5s;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  width: 50px;
  height: auto;
`;

export const Title = styled.div`
  margin: 8px 0;
  font-weight: 500;
  font-size: 21px;
`;

export const Content = styled.div`
  font-size: 14px;
  font-weight: 300;
`;
