import styled from 'styled-components';
import { layout, color, border } from 'styled-system';
import { space } from '@styled-system/space';

export const Button = styled.button`
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  ${layout};
  ${space};
  ${color};
  ${border};
  &:disabled {
    opacity: .2;
  }
`;

export const Image = styled.img`
  width:100%;
  height: initial;
  object-fit: cover;
`;
