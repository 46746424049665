import React, { memo } from 'react';
import {
  Badge, Cell, Row, BadgeFormat, EditButton, ShareButton, RemoveButton, DownloadButton, StatusLabel,
} from 'ui-kit';
import { COLORS } from 'constants/styles';
import {
  getRenderTypeByFileType,
  makeDate,
} from 'utils/helpers';
import { StatusFile } from '../StatusFile';
import { ItemPreview } from '../ItemPreview';
import { Favorite } from '../Favorite';

const CELLS = [
  {
    name: 'preview_file_id',
    render: (data) => (
      <ItemPreview id={data.id} previewFileId={data.preview_file_id} status={data.status} />
    ),
    textAlign: 'center',
    height: '75px',
    width: '75px',
  },
  {
    name: 'title',
    render: (data) => (<span style={{ wordBreak: 'break-all' }}>{data.title}</span>),
    paddingLeft: '5px',
    width: '254px',
  },
  {
    name: 'favorite',
    render: (data) => (
      <Favorite
        id={data.id}
        animationExists={data.animation_exists}
        isTop={data?.draft?.is_top}
        draftId={data.draft_id}

      />
    ),
    textAlign: 'center',
    width: '70px',
  },
  {
    name: 'clientStatus',
    render: (data) => (
      <StatusFile
        id={data.id}
        clientStatus={data.client_status}
      />
    ),
    textAlign: 'center',
    width: '70px',
  },
  {
    name: 'ratio',
    render: (data) => (
      <Badge label={data.animation_ratio || '-'} bg={COLORS.MAIN} />
    ),
    textAlign: 'center',
    width: '60px',
  },
  {
    name: 'duration',
    render: (data) => (
      <Badge label={data.duration ? `${data.duration}sec` : '-'} bg={COLORS.MAIN} />
    ),
    textAlign: 'center',
    width: '60px',
  },
  {
    name: 'format',
    render: (data) => (
      <BadgeFormat label={getRenderTypeByFileType(data.type)?.label} type={data.type} />
    ),
    textAlign: 'center',
    width: '60px',
  },
  {
    name: 'audio',
    render: (data) => (
      <StatusLabel isApproved={!!data?.draft?.original_audio_file_id} />
    ),
    textAlign: 'center',
    width: '60px',
  },
  {
    name: 'createdAt',
    render: (data) => (<span>{makeDate(data.created_at)}</span>),
    width: '80px',
  },
  {
    name: 'edit',
    render: (data) => (
      <EditButton
        animationExists={data.animation_exists}
        draftId={data.draft_id}
        animationRatio={data.animation_ratio}
      />
    ),
    textAlign: 'center',
    width: '90px',
  },
  {
    name: 'share',
    render: (data) => (
      <ShareButton
        id={data.id}
        draftId={data.draft_id}
        status={data.status}
      />
    ),
    textAlign: 'center',
    width: '60px',
  },
  {
    name: 'download',
    render: (data) => (
      <DownloadButton
        status={data.status}
        title={data.title}
        videoFileId={data.video_file_id}
        type={data.type}
      />
    ),
    textAlign: 'center',
    width: '70px',
  },
  {
    name: 'remove',
    render: (data) => (
      <RemoveButton
        id={data.id}
        title={data.title}
      />
    ),
    textAlign: 'center',
    width: '50px',
  },
];

export const FileItem = memo(({ data }) => {
  return (
    <Row>
      {CELLS.map(({ name, render, ...props }) => (
        <Cell
          key={name}
          {...props}
        >
          {render ? render(data) : data[name]}
        </Cell>
      ))}
    </Row>
  );
});
