import { createActions } from 'redux-actions';

import {
  identity, map, compose, values, toUpper,
} from 'ramda';
import { notify } from 'utils/helpers/notificationHelpers';
import { CSV_ERRORS, ANIMATIONS_ERRORS } from 'constants/errors';

import types from './types';

export const {
  getAnimationDataRequest,
  getAnimationCsvRequest,
  uploadAnimationCsvRequest,
  createVideoByCsvRequest,
  setAnimationData,
  rewriteAnimationData,
  updateAnimationData,
  getAnimationDataSuccess,
  setDeepAnimationData,
  changeAssetUrl,
  changeTintColor,
  changeTintAmount,
  setLength,
  animationHardReset,
  downloadCsv,
  getAnimationCsvSuccess,
  uploadAnimationCsvSuccess,
  newImageWasUploaded,
  convertCsvToDraftsSuccess,
  setAnimationFonts,
  setCsvStatus,
  setCustomLengthInAnimation,
  setAnimationDimensions,
} = createActions({
  [types.GET_ANIMATION_DATA_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/animations/json',
      selectorName: 'getAnimationDataRequest',
      method: 'GET',
      params,
      actions: {
        meta,
        success: getAnimationDataSuccess,
      },
      callbacks: {
        error: () => notify.error(ANIMATIONS_ERRORS.FETCH_FAILED),
      },
      ...meta,
    }),
  ],
  [types.GET_ANIMATION_CSV_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/animations/csv',
      selectorName: 'getAnimationCsvRequest',
      method: 'GET',
      params,
      actions: {
        success: (action) => getAnimationCsvSuccess({ ...action, meta: params }),
      },
      ...meta,
    }),
  ],
  [types.UPLOAD_ANIMATION_CSV_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/temp-files',
      selectorName: 'uploadAnimationCsvRequest',
      method: 'POST',
      params,
      actions: {
        success: uploadAnimationCsvSuccess,
      },
      callbacks: {
        error: () => notify.error(CSV_ERRORS.CSV_UPLOAD_FAILED),
      },
      ...meta,
    }),
  ],
  [types.CREATE_VIDEO_BY_CSV_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/videos/create-from-csv',
      selectorName: 'createVideoByCsvRequest',
      method: 'POST',
      params,
      callbacks: {
        error: compose(
          map(({
            field,
            message,
          }) => notify.error(`${field.replace(/^./, toUpper)}: ${message}`)), values,
        ),
        success: ({ data: { model } }) => {
          if (model.error && model.error.length > 0) {
            notify.error(model.error);
          }
        },
      },
      ...meta,
    }),
  ],
},
types.SET_ANIMATION_DATA,
types.REWRITE_ANIMATION_DATA,
types.UPDATE_ANIMATION_DATA,
types.GET_ANIMATION_DATA_SUCCESS,
types.SET_DEEP_ANIMATION_DATA,
types.CHANGE_ASSET_URL,
types.CHANGE_TINT_COLOR,
types.CHANGE_TINT_AMOUNT,
types.SET_LENGTH,
types.ANIMATION_HARD_RESET,
types.DOWNLOAD_CSV,
types.GET_ANIMATION_CSV_SUCCESS,
types.UPLOAD_ANIMATION_CSV_SUCCESS,
types.NEW_IMAGE_WAS_UPLOADED,
types.CONVERT_CSV_TO_DRAFTS_SUCCESS,
types.SET_ANIMATION_FONTS,
types.SET_CSV_STATUS,
types.SET_CUSTOM_LENGTH_IN_ANIMATION,
types.SET_ANIMATION_DIMENSIONS);
