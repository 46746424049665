import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Preloader } from 'ui-kit';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { getAnimationDataRequestPending } from 'state/animation/selectors';
import { getSelectedDraftDataRequestStatus } from 'state/drafts/selectors';
import { TAB_PANELS, TAB_VIEWS } from './constants';
import {
  Container, Header, NavItem, NavList, Tab, Wrapper, TabWrapper,
} from './RightToolBar.styled';

import './style.sass';

const DEFAULT_TAB = 'CONTROLS';
const PRELOADER_DURATION = 400;

export const RightToolBar = ({ lottieElement }) => {
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  const isLoadingDataOfAnimation = useSelector(getAnimationDataRequestPending);
  const isLoadingDataOfDraft = useSelector(getSelectedDraftDataRequestStatus);

  const onOpenTab = (tabName) => () => {
    setActiveTab(tabName);
  };

  return (
    <Container className="design" id="rightToolBar">
      <Wrapper>
        <Header>
          <NavList>
            {TAB_PANELS.map(({ tabName, content }) => (
              <NavItem
                key={`panel-${tabName}`}
                onClick={onOpenTab(tabName)}
                isActive={tabName === activeTab}
              >
                {content}
              </NavItem>
            ))}
          </NavList>
        </Header>
        <TabWrapper>
          <Preloader
            type={PRELOADER_TYPES.CONTAINER}
            size={PRELOADER_SIZES.MIDDLE}
            minDuration={PRELOADER_DURATION}
            isLoading={isLoadingDataOfAnimation && isLoadingDataOfDraft}
          >
            {TAB_VIEWS.map(({ tabName, content }) => (
              <Tab
                key={`view-${tabName}`}
                isActive={tabName === activeTab}
              >
                {content({ lottieElement })}
              </Tab>
            ))}
          </Preloader>
        </TabWrapper>
      </Wrapper>
    </Container>
  );
};
