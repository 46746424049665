import React, {
  useState,
  useCallback,
  useEffect,
  useRef, useMemo,
} from 'react';
import workerClient from 'ffmpeg-webworker';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import WebVideo from 'utils/helpers/webVideoHelper';
import { Select } from 'components/forms';
import { smallArrowDownIcon } from 'assets/images';
import { animationActions, animationSelectors } from 'state/animation';
import { useEditor, useTrackEvent } from 'hooks';
import AudioInstance from 'utils/helpers/audioHelper';
import { editorActions } from 'state/editor';
import { DEFAULT_SYNCHRONIZE_OPTIONS, FIRST_IMAGE, CUSTOM_LENGTH } from 'constants/trimmer';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import { TrimmerCore } from './TrimmerCore';
import { LoadingState } from './LoadingState';
import {
  BackDropOverlay,
  MainContainer,
  TrimmerControls,
  CancelButton,
  TrimButton,
  LabelSelect,
  MaxLengthWrapper,
  MaxLengthLabel,
} from './VideoTrimmer.styled';

const getSynchronizeLabel = (value) => DEFAULT_SYNCHRONIZE_OPTIONS.find((option) => option.value === Number(value)).label;

/**
 * @param file
 * @param timeLimit
 * @param refId
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
export const VideoTrimmer = ({
  file,
  refId,
  onClose,
}) => {
  const webVideoRef = useRef(new WebVideo(null, workerClient));
  const dispatch = useDispatch();
  const lengthOptions = useSelector(animationSelectors.animationLengthOptionsSelector);
  const animationLength = useSelector(animationSelectors.animationLengthSelector);
  const form = useFormContext();
  const { handleTrack } = useTrackEvent();

  const length = form.watch('length');
  const { isCustomLength, setIsCustomLength } = useEditor();
  const [videoDataURL, setVideoDataURL] = useState('');
  const [trimmerImages, setTrimmerImages] = useState([]);
  const [timeLimit, setTimeLimit] = useState(animationLength);
  const [timeRange, setTimeRange] = useState({ start: 0, end: timeLimit || 15 });
  const [isDecoding, setIsDecoding] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [syncToSlide, setSyncToSlide] = useState(0);

  const isFirstImageLayer = FIRST_IMAGE === refId;

  const handleDismiss = useCallback((forceClose = false) => () => {
    if (!isUploading || forceClose) {
      onClose(false);
    }
  }, [isUploading]);

  const handleChange = useCallback((value) => {
    if (value === CUSTOM_LENGTH.value) {
      setIsCustomLength(true);
      setTimeLimit(300);
    } else {
      form.setValue('length', value);
      const currentDuration = timeRange.end - timeRange.start;
      if (value < timeLimit && value < currentDuration) {
        const { duration } = webVideoRef.current.videoData;
        setTimeRange((prevState) => {
          const newValue = value > duration ? duration : value;
          const endValue = parseFloat(prevState.start) + parseInt(newValue, 10) > duration ? duration : parseFloat(prevState.start) + parseInt(newValue, 10);
          return {
            start: prevState.start,
            end: endValue,
          };
        });
      }
      setIsCustomLength(false);
      setTimeLimit(parseInt(value, 10));
    }
  }, [timeLimit, length, webVideoRef.current.videoData, timeRange]);

  /**
   * onSubmit.
   * @type {function(): (undefined)}
   */
  const handleSubmitTrimData = useCallback(() => {
    const duration = timeRange.end - timeRange.start;
    const isInvalidEdges = duration.toFixed(2) > timeLimit || duration < 0;

    if (isInvalidEdges) {
      console.log({
        type: 'manual',
        text: '{duration: end - start} must have been greater than 0 and less than {timeLimit}',
      });
    } else {
      if (isFirstImageLayer) {
        if (isCustomLength) {
          dispatch(editorActions.setCustomLength(duration));
          dispatch(animationActions.setCustomLengthInAnimation({ customLength: duration }));
        } else {
          dispatch(animationActions.setLength(parseInt(length.toString(), 10)));
          dispatch(editorActions.setCustomLength(null));
        }
      }
      AudioInstance.delete();
      dispatch(editorActions.uploadVideoFileAsGif({
        file,
        refId,
        start: timeRange.start,
        end: duration,
      }));

      dispatch(editorActions.setVideoTypeSynchronization({
        refId,
        syncToSlide: !!syncToSlide,
      }));

      setIsUploading(true);
      handleTrack(MIXPANEL_EVENTS.UPLOAD_VIDEO);
      onClose(false); // close window immediately after start trimming
      dispatch(editorActions.setAudioPoints({
        startPoint: +timeRange.start,
        endPoint: +timeRange.end,
      }));
    }
  }, [timeRange, file, refId, timeLimit, length, isCustomLength]);

  const decodeVideoFile = useCallback(async () => {
    try {
      setIsDecoding(true);
      webVideoRef.current.videoFile = file;

      const { dataURL } = await webVideoRef.current.decode(file);
      setVideoDataURL(dataURL);

      const timeRangeStart = timeRange.start;
      const { duration } = webVideoRef.current.videoData;
      const newTimeLimit = timeRangeStart + (timeLimit || 10);
      const timeRangeEnd = duration > newTimeLimit ? newTimeLimit : duration;

      setTimeRange({ start: timeRangeStart, end: timeRangeEnd });
      setPlayedSeconds((timeRangeEnd - timeRangeStart) / 2 + timeRangeStart);

      const extractedValue = await webVideoRef.current.extractFramesFromVideo();

      setTrimmerImages(extractedValue);

      setIsDecoding(false);
    } catch (e) {
      console.log(e, 'thrown while decoding uploaded file to trimmer');
    }
  }, [webVideoRef, file, timeRange, timeLimit]);

  const options = useMemo(() => [
    ...lengthOptions.map((option) => ({ label: `${option} seconds`, value: option })),
    CUSTOM_LENGTH,
  ],
  [lengthOptions]);

  const handleChangeSynchronization = (value) => {
    setSyncToSlide(value);
  };

  useEffect(() => {
    if (webVideoRef.current) {
      decodeVideoFile();
    }
  }, [webVideoRef]);

  return (
    <>
      <BackDropOverlay onClick={handleDismiss()} aria-hidden="true" />
      <MainContainer>
        {isDecoding || isUploading ? (
          <LoadingState
            isDecoding={isDecoding}
            onDismiss={handleDismiss(true)}
            fileName={file.name}
          />
        ) : (
          <TrimmerCore
            webVideoRef={webVideoRef.current}
            timeRange={timeRange}
            onTrim={setTimeRange}
            playedSeconds={playedSeconds}
            onChangePlayback={setPlayedSeconds}
            timeLimit={timeLimit}
            videoDataURL={videoDataURL}
            trimmerImages={trimmerImages}
          >
            {() => (
              <TrimmerControls>
                <MaxLengthWrapper>
                  <MaxLengthLabel>Synchronize timing</MaxLengthLabel>
                  <Select
                    id="timing-select"
                    onChange={(e) => handleChangeSynchronization(e.target.value)}
                    value={syncToSlide}
                    label={(
                      <LabelSelect className="active">
                        <span className="text-cut">
                          {getSynchronizeLabel(syncToSlide)}
                        </span>
                        <img className="dropdown-icon" src={smallArrowDownIcon} alt="icon" />
                      </LabelSelect>
                    )}
                    options={DEFAULT_SYNCHRONIZE_OPTIONS}
                  />
                </MaxLengthWrapper>
                {isFirstImageLayer && (
                  <MaxLengthWrapper>
                    <MaxLengthLabel>Max Length</MaxLengthLabel>
                    <Controller
                      name="length"
                      control={form.control}
                      defaultValue={lengthOptions[0].value}
                      render={() => (
                        <Select
                          id="lengthListSelect"
                          onChange={(e) => handleChange(e.target.value)}
                          value={length}
                          label={(
                            <LabelSelect className="active">
                              <span className="text-cut">
                                {isCustomLength ? 'Custom length' : `${length} seconds`}
                              </span>
                              <img className="dropdown-icon" src={smallArrowDownIcon} alt="icon" />
                            </LabelSelect>
                      )}
                          options={options}
                        />
                      )}
                    />
                  </MaxLengthWrapper>
                )}
                <CancelButton
                  type="button"
                  onClick={handleDismiss(true)}
                >
                  Cancel
                </CancelButton>
                <TrimButton
                  type="button"
                  onClick={handleSubmitTrimData}
                >
                  Trim
                </TrimButton>
              </TrimmerControls>
            )}
          </TrimmerCore>
        )}
      </MainContainer>
    </>
  );
};
