import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  compose, pathOr, not, isNil,
} from 'ramda';
import { Editor, ThemesSelector } from 'shared';
import { NotificationBar, Header } from 'ui-kit';
import { fontsActions } from 'state/fonts';
import { getCompanyRequest } from 'state/company/actions';
import { getCurrentUserRequest } from 'state/user/actions';
import { selectIsMadeExport } from 'state/videos/selectors';
import { useLocationChange, useTrackEvent } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import './style.sass';

/**
 *
 * @param children {React.ReactNode}
 * @returns {JSX.Element}
 * @constructor
 */

export const Main = ({ children }) => {
  const isExistOpenedModals = useSelector(compose(not, isNil, pathOr(false, ['ui', 'modals'])));
  const isMadeExport = useSelector(selectIsMadeExport);
  const dispatch = useDispatch();
  const { handleTrack } = useTrackEvent();
  useLocationChange();

  useEffect(() => {
    dispatch(fontsActions.getFontsRequest());
    dispatch(getCompanyRequest());
    dispatch(getCurrentUserRequest());
  }, []);

  const handleUnload = () => {
    if (!isMadeExport) {
      handleTrack(MIXPANEL_EVENTS.EXIT_NO_EXPORT);
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  return (
    <div aria-hidden={isExistOpenedModals} className="page-wrapper page-wrapper--main">
      <NotificationBar />
      <Header />
      <ThemesSelector />
      <Editor />
      {children}
      <Outlet />
    </div>
  );
};
