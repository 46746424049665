import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLinksCount, getLinksEntities, getLinksResult } from 'state/links/selectors';
import { useListSearch, usePagination, useSorting } from 'hooks';
import { deleteLinkRequest, getLinksRequest } from 'state/links/actions';
import {
  Body, Modal, Pagination, SearchGroup, Table,
} from 'ui-kit';
import {
  Root,
  Wrapper,
  Controls,
  CustomScrollbars,
  LeftPart,
  PaginationWrapper,
  RightPart,
  TableContainer,
  Title,
  Logo,
} from './SharedLinks.styled';
import { HeadTable } from './HeadTable';
import { LinkItem } from './LinkItem';
import { editIcon } from '../../assets/images';

const DELETE_QUESTION = 'Are you sure you want to delete this link?';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const SharedLinks = () => {
  const dispatch = useDispatch();
  const linksResult = useSelector(getLinksResult);
  const linksEntities = useSelector(getLinksEntities);
  const linksCount = useSelector(getLinksCount);
  const { t } = useTranslation(['account', 'common', 'forms']);

  const [currentLinkId, setCurrentLinkId] = useState(null);
  const [isOpenedDeleteLinkModal, setIsOpenedDeleteLinkModal] = useState(false);

  const toggleDeleteLinkModal = useCallback((value) => () => setIsOpenedDeleteLinkModal(value), []);

  const { orderBy, sortBy, handleSort } = useSorting();

  const {
    page, pages, setPage, lastPage, limit,
  } = usePagination({ count: linksCount });

  const { debouncedSearchValue, searchValue, setSearchValue } = useListSearch(setPage);

  const onDelete = useCallback((confirm) => confirm
    && dispatch(deleteLinkRequest({ id: currentLinkId }, {
      callbacks: {
        success: () => {
          dispatch(getLinksRequest({
            order: orderBy,
            sortBy,
            limit,
            page: page - 1,
            title: debouncedSearchValue,
          }));
          setCurrentLinkId(null);
        },
      },
    })), [page, sortBy, orderBy, page, debouncedSearchValue, currentLinkId]);

  useEffect(() => {
    dispatch(getLinksRequest({
      order: orderBy,
      sortBy,
      limit,
      page: page - 1,
      title: debouncedSearchValue,
    }));
  }, [sortBy, orderBy, page, limit, debouncedSearchValue]);

  return (
    <>
      <Root>
        <Wrapper>
          <Controls>
            <LeftPart>
              <Logo src={editIcon} alt="logo" />
              <Title>{t('Shared links')}</Title>
            </LeftPart>
            <RightPart>
              <SearchGroup value={searchValue} onSearch={setSearchValue} />
            </RightPart>
          </Controls>
          <TableContainer>
            <Table>
              <HeadTable sortBy={sortBy} handleSort={handleSort} orderBy={orderBy} />
              <CustomScrollbars>
                <Body>
                  { linksResult.length ? linksResult.map((id) => (
                    <LinkItem
                      key={id}
                      data={linksEntities[id]}
                      toggleDeleteLinkModal={toggleDeleteLinkModal}
                      setCurrentLinkId={setCurrentLinkId}
                    />
                  )) : (<></>)}
                </Body>
              </CustomScrollbars>
            </Table>
          </TableContainer>
          {linksResult.length ? (
            <PaginationWrapper>
              <Pagination pages={pages} page={page} setPage={setPage} lastPage={lastPage} />
            </PaginationWrapper>
          ) : (<></>)}
        </Wrapper>
      </Root>
      <Modal
        isPrompt
        isOpen={isOpenedDeleteLinkModal}
        onChange={onDelete}
        onClose={toggleDeleteLinkModal(false)}
        question={t(DELETE_QUESTION)}
      />
    </>
  );
};
