import styled from 'styled-components';
import { flexbox, layout, typography } from 'styled-system';
import { space } from '@styled-system/space';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  ${layout};
  ${space};
  ${flexbox};
  ${typography};
`;

export const TabPanel = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
  border: ${({ isActive, theme }) => `1px solid ${isActive ? theme.colors.BLACK : theme.colors.WHITE}`}
`;

export const Tab = styled.div`
  width: 100%;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.WHITE};
`;
