import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ScrollBars from 'react-custom-scrollbars';
import { is, isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { draftsActions, draftsSelectors } from 'state/drafts';
import { NONE_DRAFT } from 'constants/drafts';
import { cancelIcon } from 'assets/images';
import { getDraftTitleByDraftModel } from 'utils/helpers/renderHelpers';
import {
  Select, Input, Button, Modal, Preloader,
} from 'ui-kit';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { COLORS } from 'constants/styles';
import './styles.sass';
import { useEditor } from 'hooks';
import {
  Container, Header, Title,
  Body, Item, ItemTitle,
  ItemWrapper, SelectLabel,
  Label, CancelButton, RootForm,
} from './DraftForm.styled';

const PRELOADER_DELAY = 250;

const DIMENSIONS = {
  WIDTH: 55,
  HEIGHT: 32,
};

const FONT_SIZE = 12;

function mapDraftToOption(draft) {
  return {
    value: draft.id,
    label: is(Object, draft) ? getDraftTitleByDraftModel(draft) : draft.title,
  };
}

/**
 *
 * @param onClose {function}
 * @param preventClose {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const DraftForm = ({ onClose, preventClose }) => {
  const dispatch = useDispatch();
  const drafts = useSelector(draftsSelectors.getDrafts);
  const selectedDraft = useSelector(draftsSelectors.getSelectedDraft);
  const isPending = useSelector(draftsSelectors.getDraftsRequestPending);
  const isPreparing = useSelector(draftsSelectors.getPrepareStatus);

  const { t } = useTranslation('common');

  const [deletingId, setDeletingId] = useState(null);
  const [isOpenDraftModal, setIsOpenDraftModal] = useState(false);
  const { setIsCustomLength } = useEditor();
  const isCurrentDraft = selectedDraft === deletingId;

  const form = useFormContext();

  const [title, length, version] = form.getValues(['projectName', 'length', 'version']);

  const handleSubmit = useCallback(({ projectName }) => {
    dispatch(draftsActions.createVideoDraft({
      title: projectName,
      version,
      length,
      createDraftEndCb: () => {
        onClose();
        setIsOpenDraftModal(false);
      },
    }));
  }, [version, length]);

  const filteredDrafts = useMemo(() => drafts.filter(({ id }) => id !== NONE_DRAFT.id), [drafts]);

  const handleDeleteDraft = useCallback((draftId) => () => {
    setDeletingId(draftId);
  }, []);

  const handleChangeTargetDraft = (e) => {
    const { value } = e.target;
    form.setValue('selectedDraft', value);
    setIsCustomLength(false);
    // we need to pass here event instead of value
    dispatch(draftsActions.changeDraftTemplate({ target: { value: Number(value) }, isDuplicate: true }));
  };

  const draftsOptions = useMemo(() => drafts.map(mapDraftToOption), [drafts]);

  const draftByValue = useMemo(() => {
    if (!isEmpty(draftsOptions) && selectedDraft) {
      return draftsOptions.find(({ value }) => value === parseInt(selectedDraft, 10));
    }
    return {
      id: 0,
      label: 'None',
    };
  }, [selectedDraft, draftsOptions]);

  const onDeleteModal = useCallback((confirm) => {
    if (confirm && !isCurrentDraft) {
      dispatch(draftsActions.deleteVideoDraftRequest({ id: deletingId }));
    }
    setDeletingId(null);
  }, [deletingId, selectedDraft]);

  return (
    <>
      {!isOpenDraftModal && (
        <>
          <Label
            isChecked
            onClick={() => {
              setIsOpenDraftModal(true);
              preventClose(true);
            }}
            role="presentation"
          >
            {t('Save')}
          </Label>
          <Select
            onChange={handleChangeTargetDraft}
            id="draft-select"
            name="selectedDraft"
            value={selectedDraft}
            label={(
              <SelectLabel>
                <span className="text-cut">{draftByValue?.label}</span>
              </SelectLabel>
            )}
            options={draftsOptions}
          />
        </>
      )}

      {!!deletingId && !isCurrentDraft && (
        <Modal
          isPrompt
          isOpen
          onChange={onDeleteModal}
          question={t('Are you sure you want to delete this draft?')}
        />
      )}

      {isOpenDraftModal && (
        <RootForm>
          <Preloader
            isLoading={isPreparing || isPending}
            size={PRELOADER_SIZES.MIDDLE}
            type={PRELOADER_TYPES.CONTAINER}
            isDestroyChildren={false}
            delay={PRELOADER_DELAY}
          >
            <Container
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <Header>
                <Title>{t('Save new Draft?')}</Title>
                <Button
                  type="submit"
                  width={DIMENSIONS.WIDTH}
                  height={DIMENSIONS.HEIGHT}
                  fontSize={FONT_SIZE}
                  color={COLORS.GREEN}
                  label={t('Yes')}
                  title={t('Yes')}
                />
                <Button
                  onClick={() => {
                    onClose();
                    setIsOpenDraftModal(false);
                    preventClose(false);
                  }}
                  width={DIMENSIONS.WIDTH}
                  height={DIMENSIONS.HEIGHT}
                  fontSize={FONT_SIZE}
                  color={COLORS.LIGHT_GRAY}
                  label={t('No')}
                  title={t('No')}
                />
              </Header>
              <Controller
                name="projectName"
                control={form.control}
                defaultValue={title}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <Input
                    className="draft__field"
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    onBlur={onBlur}
                    error={error?.message || ''}
                  />
                )}
              />
              <Body>
                <ScrollBars style={{ height: 295, backgroundColor: 'white' }}>
                  {filteredDrafts.map((itemDraft) => (
                    <Item key={itemDraft.id}>
                      <ItemWrapper>
                        <CancelButton
                          type="button"
                          onClick={handleDeleteDraft(itemDraft.id)}
                          title="Delete"
                        >
                          <img src={cancelIcon} alt="Delete" />
                        </CancelButton>
                        <ItemTitle>
                          {getDraftTitleByDraftModel(itemDraft)}
                        </ItemTitle>
                      </ItemWrapper>
                    </Item>
                  ))}
                </ScrollBars>
              </Body>
            </Container>
          </Preloader>
        </RootForm>
      )}
    </>
  );
};
