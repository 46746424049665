import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { editIcon } from 'assets/images';
import { changeDraftTemplate } from 'state/drafts/actions';
import { getSelectedDraft } from 'state/drafts/selectors';
import { COLORS } from 'constants/styles';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import { Button, Image } from './Buttons.styled';
import { useTrackEvent } from '../../hooks';

/**
 *
 * @param draftId {number}
 * @param animationRatio {string}
 * @param animationExists {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const EditButton = ({ draftId, animationRatio, animationExists }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedDraft = useSelector(getSelectedDraft);

  const { handleTrack } = useTrackEvent();

  const isEditingEnabled = draftId && animationExists && draftId !== selectedDraft;

  const handleEditFile = useCallback((id, version) => () => {
    dispatch(changeDraftTemplate({ target: { value: id, version } }));
    navigate('/');
    handleTrack(MIXPANEL_EVENTS.CHOOSE_DRAFT);
  }, []);

  return (
    <Button
      onClick={handleEditFile(draftId, animationRatio)}
      type="button"
      disabled={!isEditingEnabled}
      borderRadius="100%"
      backgroundColor={COLORS.WHITE}
    >
      <Image src={editIcon} alt="Edit this file" />
    </Button>
  );
};
