import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';

export const {
  addColorRequest,
  deleteColorRequest,
  getColorRequest,
  getColorsRequest,
  setColor,
  deleteColor,
  setColors,
  setColorsFreshStatus,
} = createActions({
  [types.ADD_COLOR_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/company-colors',
      selectorName: 'addColorRequest',
      method: 'POST',
      params,
      actions: {
        success: setColor,
      },
      ...meta,
    }),
  ],
  [types.DELETE_COLOR_REQUEST]: [
    identity,
    ({ colorId, ...params }, meta) => ({
      async: true,
      route: `/api/company-colors/${colorId}`,
      selectorName: 'deleteColorRequest',
      method: 'DELETE',
      params,
      actions: {
        success: () => deleteColor({ colorId }),
      },
      ...meta,
    }),
  ],
  [types.GET_COLOR_REQUEST]: [
    identity,
    ({ colorId, ...params }, meta) => ({
      async: true,
      route: `/api/company-colors/${colorId}`,
      selectorName: 'getColorRequest',
      method: 'GET',
      params,
      ...meta,
    }),
  ],
  [types.GET_COLORS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/company-colors/',
      selectorName: 'getColorsRequest',
      method: 'GET',
      params,
      actions: {
        success: setColors,
      },
      ...meta,
    }),
  ],
}, types.SET_COLOR,
types.DELETE_COLOR,
types.SET_COLORS,
types.SET_COLORS_FRESH_STATUS);
