import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { is } from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  useForm, Controller, FormProvider, useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'rc-time-picker/assets/index.css';
import { normalizedCommand } from 'utils/helpers/sharingHelpers';
import { validation } from './validation';
import {
  DatePickerInput, Error, FieldGroup, IconsGroup,
  Label, Row, SocialItem, SocialList, SubTitle,
  TextArea, TimePickerContainer, Title, Wrapper,
} from './ZapierModal.styled';
import { Modal } from '../Modal';
import { Preloader } from '../Preloader';
import { Icon } from '../Icon';
import { CheckboxGroup } from '../CheckboxGroup';
import { Button } from '../Button';

const SOCIALS = [
  {
    id: 1,
    name: 'cloud',
  },
  {
    id: 2,
    name: 'twitter',
  },
  {
    id: 3,
    name: 'linkedin',
  },
  {
    id: 4,
    name: 'instagram',
  },
  {
    id: 5,
    name: 'facebook',
  },
];

const DEFAULT_VALUES = {
  text: '',
  fullDate: null,
  time: '',
  socials: {
    instagram: false,
    facebook: false,
    linkedin: false,
    twitter: false,
    cloud: false,
  },
};

/**
 *
 * @param isOpen {boolean}
 * @param onClose {function}
 * @param isPending {boolean}
 * @param onSubmitCallback {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const ZapierModal = ({
  isOpen, onClose, isPending = false, onSubmitCallback = null,
}) => {
  const form = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validation),
  });

  const [isOpenedTimePicker, setIsOpenedTimePicker] = useState(false);

  const toggleTimePicker = useCallback((value) => setIsOpenedTimePicker(value.open), []);

  const { t } = useTranslation(['account', 'common', 'forms']);

  const {
    control, setValue,
  } = form;

  const mainForm = useFormContext();

  const onSubmit = (data) => {
    if (!is(Function, onSubmitCallback)) {
      mainForm.setValue('outputCommand', normalizedCommand(data));
      onClose();
    } else {
      onSubmitCallback(normalizedCommand(data));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      afterClickingOutsideClose={!isOpenedTimePicker}
    >
      <FormProvider {...form}>
        <Preloader isLoading={isPending}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Title>Share content via Zapier</Title>
            <Wrapper>
              <FieldGroup>
                <IconsGroup>
                  <Icon name="message" width={30} height={30} mb="10px" />
                  <Icon name="zapier" width={30} height={30} />
                </IconsGroup>
                <Label htmlFor="text">{t('Post text (if applicable)')}:</Label>
                <Controller
                  name="text"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextArea
                      name="text"
                      id="text"
                      cols="30"
                      rows="5"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </FieldGroup>
              <SubTitle>{t('Schedule (if applicable)')}</SubTitle>
              <FieldGroup isDate>
                <Label htmlFor="fullDate" isDate>{t('Date')}:</Label>
                <Controller
                  name="fullDate"
                  control={control}
                  render={({
                    field: {
                      onChange, onBlur, value,
                    },
                    fieldState: {
                      error,
                    },
                  }) => (
                    <>
                      <DatePickerInput
                        id="fullDate"
                        selected={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                      <Label htmlFor="fullDate" isClickable isDate>
                        <Icon name="calendar" width={28} height={28} />
                      </Label>
                      <Error>{t(error?.message)}</Error>
                    </>
                  )}
                />
              </FieldGroup>
              <FieldGroup isDate>
                <Label htmlFor="time" isDate>{t('Time')}:</Label>
                <Controller
                  name="time"
                  control={control}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <>
                      <TimePickerContainer
                        id="time"
                        showSecond={false}
                        minuteStep={15}
                        use12Hours
                        defaultValue=""
                        onChange={(e) => onChange(moment(e).format('hh:mmA'))}
                        popupClassName="zapier-modal__time-picker"
                        onOpen={toggleTimePicker}
                        onClose={toggleTimePicker}
                      />
                      <Error>{t(error?.message)}</Error>
                    </>
                  )}
                />
              </FieldGroup>
              <FieldGroup>
                <Label>{t('Destination (if applicable)')}:</Label>
                <SocialList>
                  {SOCIALS.map(({ id, name }) => (
                    <SocialItem key={id}>
                      <Controller
                        name={`socials.${name}`}
                        control={control}
                        defaultValue={false}
                        render={({ field: { value } }) => (
                          <CheckboxGroup
                            name={`socials.${name}`}
                            id={`zapier-${id}`}
                            onChange={() => setValue(`socials.${name}`, !value)}
                            value={value}
                            isChecked={value}
                            hasLabel={false}
                            label={() => (
                              <Icon
                                name={name}
                                width={36}
                                height={36}
                                position="absolute"
                                top="0px"
                                left="0px"
                                zIndex={0}
                              />
                            )}
                          />
                        )}
                      />
                    </SocialItem>
                  ))}
                </SocialList>
              </FieldGroup>
              <span>
                {t('Please contact us if you wish to connect')}
              </span>
              <span>
                {t('your account to scheduling services,')}
              </span>
              <span>
                {t('cloud storages or directly post')}
              </span>
              <span>
                {t('to different platform')}
              </span>
            </Wrapper>
            <Row>
              <Link
                to="/"
                onClick={onClose}
              >
                {t('Cancel')}
              </Link>
              <Button
                buttonTheme="submit"
                type="submit"
                label={t('Share')}
                width={102}
                height={36}
                marginLeft="8px"
              />
            </Row>
          </form>
        </Preloader>
      </FormProvider>
    </Modal>
  );
};
