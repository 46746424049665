import styled from 'styled-components';
import { flexbox, layout } from 'styled-system';
import { space } from '@styled-system/space';
import { Link } from 'react-router-dom';

export const RootStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @media(${({ theme }) => theme.devices.LG}) {
    height: 100px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${space};
  ${layout};
  ${flexbox};
`;

export const Close = styled(Link)`
  padding: 10px 35px;
  background-color: ${({ theme }) => theme.colors.DARK_GRAY};
  color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.DARK_GRAY};
  border-radius: 8px;
  font-size: 14px;
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.WHITE};
    color: ${({ theme }) => theme.colors.DARK_GRAY};
  }
`;

export const Title = styled.h4`
  margin: 0 0 18px;
  font-size: 14px;
  font-weight: 300;
`;

export const Integration = styled.div`
  width: 579px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 5px 16px 5px 8px;
  border: 1px solid ${({ theme }) => theme.colors.BORDER_COLOR};
  overflow: hidden;
  ${space};
  @media(${({ theme }) => theme.devices.MD}) {
    margin-bottom: 10px;
  }
`;

export const Image = styled.img`
  max-height: 56px;
  flex-shrink: 0;
  object-fit: cover;
`;

export const Text = styled.span`
  display: inline-block;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  padding-right: 10px;
  cursor: pointer;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  ${layout};
`;

export const ZapierLink = styled.a`
  padding: 9px 20px;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.colors.MAIN};
  color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.MAIN};
  border-radius: 8px;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.WHITE};
    color: ${({ theme }) => theme.colors.MAIN};
  }
`;

export const IconButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
