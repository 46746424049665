import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { prop, propOr } from 'ramda';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'components/forms/Input/Input';
import { updateUserProfileRequest } from 'state/user/actions';
import { getUser, getUpdateUserRequestPending } from 'state/user/selectors';
import './style.sass';
import { Preloader } from 'ui-kit';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { getCompany, getCompanyLogoUrl } from 'state/company/selectors';
import { LogoUpload } from 'components';
import { updateCompanyRequest } from 'state/company/actions';
import { DEFAULT_INDEX } from 'constants/common';
import { rules } from './rules';

export const Overview = () => {
  const dispatch = useDispatch();
  const {
    user, company, isPendingUpdate, logoUrl,
  } = useSelector((store) => ({
    user: getUser(store),
    company: getCompany(store),
    isPendingUpdate: getUpdateUserRequestPending(store),
    logoUrl: getCompanyLogoUrl(store),
  }));
  const { t } = useTranslation(['account', 'common']);

  const [isLogoDeleted, setIsLogoDeleted] = useState(false);

  const {
    values, errors, handleChange, handleSubmit, setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: propOr('', 'first_name', user),
      last_name: propOr('', 'last_name', user),
      company_name: propOr('', 'company_name', user),
      email: propOr('', 'email', user),
      password: propOr('', 'password', user),
      repeat: propOr('', 'repeatPassword', user),
      logo: DEFAULT_INDEX,
    },
    onSubmit: (formValues) => {
      dispatch(updateUserProfileRequest(formValues));
      if (formValues.logo !== DEFAULT_INDEX) {
        dispatch(updateCompanyRequest({
          brand_title: company.brand_title,
          logo: formValues.logo,
        }));
      }
      setFieldValue('logo', DEFAULT_INDEX);
    },
    validationSchema: Yup.object().shape(rules),
  });

  return (
    <div className="overview">
      <Preloader
        type={PRELOADER_TYPES.CONTAINER}
        size={PRELOADER_SIZES.MIDDLE}
        isLoading={isPendingUpdate}
      >
        <form className="overview__form" onSubmit={handleSubmit}>
          <div className="overview__left-col">
            <Input
              label="Company name:"
              placeholder="Enter the company name"
              name="company_name"
              onChange={handleChange}
              value={prop('company_name', values)}
              error={prop('company_name', errors)}
            />
            <Input
              label="User first name:"
              placeholder="Your first name"
              name="first_name"
              onChange={handleChange}
              value={prop('first_name', values)}
              error={prop('first_name', errors)}
            />
            <Input
              label="User last name:"
              placeholder="Your last name"
              name="last_name"
              onChange={handleChange}
              value={prop('last_name', values)}
              error={prop('last_name', errors)}
            />
            <Input
              label="Contact email:"
              placeholder="Contact email:"
              name="email"
              type="email"
              onChange={handleChange}
              value={prop('email', values)}
              error={prop('email', errors)}
            />
            <Input
              label="Password:"
              name="password"
              type="password"
              onChange={handleChange}
              value={prop('password', values)}
              error={prop('password', errors)}
            />
            <Input
              label="Repeat password:"
              name="repeat"
              type="password"
              onChange={handleChange}
              value={prop('repeat', values)}
              error={prop('repeat', errors)}
            />
          </div>
          <div className="overview__right-col">
            <div className="overview__logo">
              <div
                className="overview__logo-input"
              />
              <LogoUpload
                onUpload={({ id }) => {
                  setFieldValue('logo', id);
                  setIsLogoDeleted(false);
                }}
                onDelete={() => {
                  setFieldValue('logo', null);
                  setIsLogoDeleted(true);
                }}
                initialImage={isLogoDeleted ? '' : logoUrl}
              />
            </div>
          </div>
          <div className="account-information__buttons-row">
            <Link to="/" className="button button--grey">{t('Close')}</Link>
            <button type="submit" className="button">{t('Save')}</button>
          </div>
        </form>
      </Preloader>
    </div>
  );
};
