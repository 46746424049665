import React from 'react';
import { TOOL_TYPES } from 'constants/editor';
import { PickerField } from './PickerField';
import { VersionTool } from './VersionTool';
import { LengthTool } from './LengthTool';
import { DraftForm } from './DraftForm';
import { ExportTool } from './ExportTool';
import { CsvTool } from './CsvTool';
import { ResetTool } from './ResetTool';

const TOOLS_CONFIG = [{
  id: 1,
  label: 'Version',
  element: <VersionTool />,
},
{
  id: 2,
  label: 'Length',
  element: <LengthTool />,
},
{
  id: 3,
  label: 'Drafts',
  element: ({ onClose, preventClose }) => <DraftForm onClose={onClose} preventClose={preventClose} />,
},
{
  id: 4,
  label: 'Export',
  element: <ExportTool />,
},
{
  id: 5,
  label: 'Csv',
  element: <CsvTool />,
  type: TOOL_TYPES.CSV,
},
{
  id: 6,
  label: 'Reset',
  element: <ResetTool />,
},
];

/**
 *
 * @returns {unknown[]}
 * @constructor
 */

export const Tools = () => TOOLS_CONFIG.map(({
  id, label, type, element,
}) => (
  <PickerField
    key={id}
    label={label}
    {...(type && { type })}
  >
    {element}
  </PickerField>
));
