import React, { useState } from 'react';
import {
  RootStyle, Label, Field, Error, IconButton, Wrapper,
} from './Input.styled';
import { Icon } from '../Icon';

const INPUT_TYPES = {
  PASSWORD: 'password',
  TEXT: 'text',
};

/**
 *
 * @param id {string | number}
 * @param name {string}
 * @param value {string | number}
 * @param onChange {function}
 * @param disabled {boolean}
 * @param label {string}
 * @param error {string}
 * @param type {string}
 * @param placeholder {string}
 * @param inputStyleProps {object} style props for the input
 * @param labelStyleProps {object} style props for the label
 * @param errorStyleProps {object} style props for the error
 * @param props {object} style props for the root
 * @returns {JSX.Element}
 * @constructor
 */

export const Input = ({
  id,
  name,
  value,
  onChange = () => {},
  disabled,
  label,
  error,
  type,
  placeholder,
  inputStyleProps = {},
  labelStyleProps = {},
  errorStyleProps = {},
  ...props
}) => {
  const [fieldType, setFieldType] = useState(type);

  const handleChangeFieldType = () => {
    if (fieldType === INPUT_TYPES.PASSWORD) {
      setFieldType(INPUT_TYPES.TEXT);
    } else {
      setFieldType(INPUT_TYPES.PASSWORD);
    }
  };

  return (
    <RootStyle {...props}>
      {label && (
        <Label {...labelStyleProps} htmlFor={id}>
          {label}
        </Label>
      )}
      <Wrapper>
        <Field
          id={id}
          name={name}
          type={fieldType}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          {...inputStyleProps}
          {...(type === INPUT_TYPES.PASSWORD && {
            paddingRight: '46px',
          })}
        />
        {type === INPUT_TYPES.PASSWORD && (
        <IconButton type="button" onClick={handleChangeFieldType}>
          {fieldType === INPUT_TYPES.TEXT ? <Icon name="eye" /> : <Icon name="crossed-eye" />}
        </IconButton>
        )}
      </Wrapper>
      {error && (<Error {...errorStyleProps}>{error}</Error>)}
    </RootStyle>
  );
};
