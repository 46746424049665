import * as uiActions from './actions';
import * as uiSelectors from './selectors';
import * as uiOperations from './operations';
import uiTypes from './types';
import reducer from './reducers';

export {
  uiActions,
  uiTypes,
  uiSelectors,
  uiOperations,
};

export default reducer;
