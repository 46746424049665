import styled from 'styled-components';
import { Icon } from 'ui-kit';

export const StyledIcon = styled(Icon)`
`;

export const StyledDropZone = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(0,0,0,0.1);
  border-radius: 6px;
  cursor: pointer;
  &:hover ${StyledIcon} {
    fill: ${({ theme }) => theme.colors.MAIN};
  }
`;

export const MainText = styled.p`
  margin: 0;
  padding: 10px 0 0;
  font-size: 20px;
  font-weight: 500;
`;

export const SecondaryText = styled.p`
  margin: 0;
  padding: 10px 0 0;
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.DRAG_DROP_TEXT};
`;
