import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion, Button, Input, Modal, Select, Toggle,
} from 'ui-kit';
import { BODY_TYPES_OPTIONS, WEBHOOK_METHODS_OPTIONS } from 'constants/webhook';
import { COLORS } from 'constants/styles';
import { selectWebhookById } from 'state/webhooks/selectors';
import { webhookCreateRequest, webhookUpdateRequest } from 'state/webhooks/actions';
import { findLabelByValue } from 'utils/helpers';
import { Form, Row, Label } from './WebhookModal.styled';
import rules from './rules';
import {
  BUTTON_STYLES, DEFAULT_VALUES, INPUT_STYLES, LABEL_STYLES,
} from '../constants';
import { AdvancedOptions } from './AdvancedOptions';

/**
 *
 * @param id {number | null}
 * @param isOpen {boolean}
 * @param onClose {function}
 * @param setSelectedWebhookId {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const WebhookModal = ({
  isOpen, onClose, id, setSelectedWebhookId,
}) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const webhook = useSelector((state) => selectWebhookById(state, id));

  const form = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(rules),
  });

  const {
    control, handleSubmit, setValue, reset,
  } = form;

  useEffect(() => {
    if (webhook) {
      reset({
        url: webhook.url,
        type: webhook.type,
        status: webhook.status,
        get_fields: webhook.get_fields,
        body_fields: webhook.body_fields,
        headers: webhook.headers,
        body_type: webhook.body_type,
      });
    }
  }, [webhook]);

  const onSubmit = useCallback((values) => {
    if (webhook) {
      dispatch(webhookUpdateRequest({ id: webhook.id, data: values }));
      setSelectedWebhookId(null);
    } else {
      dispatch(webhookCreateRequest(values));
    }
    reset(DEFAULT_VALUES);
    onClose();
  }, [webhook]);

  return (
    <Modal isOpen={isOpen} width="95%" maxWidth="550px">
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Controller
              control={control}
              name="url"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  label={t('Url')}
                  type="text"
                  name="url"
                  id="url"
                  value={value}
                  error={t(error?.message)}
                  onChange={onChange}
                  width="100%"
                  inputStyleProps={INPUT_STYLES}
                  labelStyleProps={LABEL_STYLES}
                />
              )}
            />
          </Row>
          <Row>
            <Row width="30%">
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    id="method"
                    label={() => (
                      <Label>
                        {findLabelByValue(WEBHOOK_METHODS_OPTIONS, value)}
                      </Label>
                    )}
                    labelText={t('Method type')}
                    labelStyleProps={LABEL_STYLES}
                    value={value}
                    onChange={onChange}
                    options={WEBHOOK_METHODS_OPTIONS}
                    error={t(error?.message)}
                  />
                )}
              />
            </Row>
            <Row width="30%">
              <Controller
                control={control}
                name="body_type"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    id="body_type"
                    label={() => (
                      <Label>
                        {findLabelByValue(BODY_TYPES_OPTIONS, Number(value))}
                      </Label>
                    )}
                    labelText={t('Body type')}
                    labelStyleProps={LABEL_STYLES}
                    value={value}
                    onChange={onChange}
                    options={BODY_TYPES_OPTIONS}
                    error={t(error?.message)}
                    width="100%"
                  />
                )}
              />
            </Row>
            <Row width="25%">
              <Controller
                control={control}
                name="status"
                render={({ field: { value }, fieldState: { error } }) => (
                  <Toggle
                    id="webhook_status"
                    onChange={() => setValue('status', Number(!value))}
                    name="status"
                    value={value}
                    label={t('Status')}
                    error={t(error?.message)}
                    labelStyleProps={LABEL_STYLES}
                  />
                )}
              />
            </Row>
          </Row>
          <Accordion title={t('Advanced options')}>
            <AdvancedOptions />
          </Accordion>
          <Row justifyContent="flex-end">
            <Button
              type="button"
              {...BUTTON_STYLES}
              color={COLORS.CANCEL}
              onClick={onClose}
              label={t('Close')}
              title={t('Close')}
              mr="10px"
            />
            <Button
              type="submit"
              buttonTheme="submit"
              {...BUTTON_STYLES}
              label={t('Submit')}
              title={t('Submit')}
            />
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};
