import { useSelector } from 'react-redux';
import { getUser } from 'state/user/selectors';
import { trackEvent } from 'utils/mixpanel';

export const useTrackEvent = () => {
  const user = useSelector(getUser);

  const handleTrack = (eventType, props = {}) => {
    trackEvent(eventType, {
      Company: user?.company_name ?? '-',
      Name: `${user?.first_name ?? '-'} ${user?.last_name ?? '-'}`,
      'Company Id': user?.company_id ?? '-',
      Email: user?.email ?? '-',
      ...props,
    });
  };

  return {
    handleTrack,
  };
};
