import styled from 'styled-components';
import { layout, typography, border } from 'styled-system';
import { space } from '@styled-system/space';

const THEME = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  RESET: 'reset',
  CANCEL: 'cancel',
  SUBMIT: 'submit',
  PLAYER: 'player',
};

export const Container = styled.button`
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  ${layout};
  ${space};
  font-weight: 500;
  font-size: 14px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  transition-duration: .25s;
  outline: none;
  border-radius: 8px;
  ${typography};

  ${({
    theme, buttonTheme, isDisabled, color,
  }) => buttonTheme === THEME.PRIMARY && !isDisabled && `
  background-color: ${color || theme.colors.MAIN};
  color: ${theme.colors.WHITE};
  &:hover {
    background-color: ${theme.colors.WHITE};
    color: ${color || theme.colors.MAIN};
    border: 1px solid ${color || theme.colors.MAIN};
  }
  `}

  ${({ buttonTheme }) => buttonTheme === THEME.SECONDARY && `
    border-radius: 0;
  `}
  
  ${({ theme, buttonTheme, isDisabled }) => buttonTheme === THEME.SECONDARY && !isDisabled && `
  padding: 2px 30px;
  background-color: ${theme.colors.WHITE};
  &:hover {
    background-color: ${theme.colors.LIGHT_BLUE};
    color: ${theme.colors.WHITE};
  }
  @media(${theme.devices.XS_X}) {
    min-width: 42px;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    font-size: 12px;
  }
  `}

  ${({ buttonTheme }) => buttonTheme === THEME.RESET && `
    border-radius: 0;
  `}
  
  ${({ theme, buttonTheme, isDisabled }) => buttonTheme === THEME.RESET && !isDisabled && `
    padding: 2px 30px;
    background-color: ${theme.colors.RESET};
    color: ${theme.colors.WHITE};
    &:hover {
      opacity: 0.9;
      background-color: ${theme.colors.RESET};
    }
  `}
  
  ${({
    theme, buttonTheme, isDisabled, color,
  }) => buttonTheme === THEME.SUBMIT && !isDisabled && `
    background-color: ${color || theme.colors.SUCCESS};
    color: ${theme.colors.WHITE};
    border-radius: 8px;
    &:hover {
      opacity: 0.7;
    }
  `}
  
  ${({ theme, buttonTheme }) => buttonTheme === THEME.PLAYER && `
    background-color: ${theme.colors.SUCCESS};
    color: ${theme.colors.WHITE};
    border-radius: 50%;
  `}
  ${border};
`;

export const Label = styled.span``;

Container.defaultProps = {

};
