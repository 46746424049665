import React, { useEffect, useRef, useState } from 'react';
import Waveform from 'react-audio-waveform';
import InputRange from 'react-input-range';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, isEmpty } from 'ramda';

import {
  cancelIcon, smallArrowDownIcon, customUploadIcon,
} from 'assets/images';
import './style.sass';
import {
  generateAcceptStingByArray,
  getTimeStringBySeconds,
} from 'utils/helpers/commonHelpers';
import { editorActions, editorSelectors } from 'state/editor';
import { AVAILABLE_FORMATS } from 'constants/rendering';
import { validateFileSize } from 'utils/helpers/filesHelper';
import { draftsSelectors } from 'state/drafts';
import { PRELOADER_TYPES } from 'constants/ui';
import { notify } from 'utils/helpers/notificationHelpers';
import { AUDIO_MAX_SIZE, AUDIO_MAX_SIZE_BYTES } from 'constants/fileSizes';

import Select from 'components/forms/Select/Select';
import { animationSelectors } from 'state/animation';
import { SWITCHER_STATE } from 'constants/editor';
import { PlayerButtons, Preloader } from 'ui-kit';
import {
  useVolume,
  usePlayControls,
  usePlayHandlers,
  useVisualize,
  useControlPlayback,
  useAudioFileHandle,
  useAutoPlayAudio,
} from './hooks';

const SWITCHER_OPTIONS = [
  { label: SWITCHER_STATE.ON, value: SWITCHER_STATE.ON },
  { label: SWITCHER_STATE.OFF, value: SWITCHER_STATE.OFF },
];

export const AudioTab = ({ lottieElement }) => {
  const dispatch = useDispatch();

  const {
    startPoint,
    isFade,
    isMute,
    trackDuration,
    audioFileStorage,
    isPendingDraftFile,
    convertAudio: { isPreparing = false, data: convertedAudioData },
    selectedDraft,
    isFetching,
    playerState,
    editorIsReady,
    percentOfPlayer,
    animationLength,
  } = useSelector((store) => ({
    isFetching: editorSelectors.getAudioFetchingStatus(store),
    startPoint: editorSelectors.getAudioStartPoint(store),
    isFade: editorSelectors.getAudioFade(store),
    isMute: editorSelectors.getAudioMute(store),
    trackDuration: editorSelectors.getAudioDuration(store),
    audioFileStorage: editorSelectors.getAudioFile(store),
    isPendingDraftFile: draftsSelectors.getDraftFilePending(store)('audio-file.mp3'),
    convertAudio: editorSelectors.getConvertAudio(store),
    selectedDraft: draftsSelectors.getSelectedDraft(store),
    playerState: editorSelectors.getPlayerState(store),
    editorIsReady: editorSelectors.getEditorIsReady(store),
    percentOfPlayer: editorSelectors.getPercentOfPlayer(store),
    animationLength: animationSelectors.animationLengthSelector(store),
  }));

  const setTrackDuration = compose(dispatch, editorActions.setAudioDuration);
  const setFade = compose(dispatch, editorActions.setAudioFade);
  const setMute = compose(dispatch, editorActions.setAudioMute);

  const startPointInputElement = useRef(null);
  const audioRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const audioInputRef = useRef(null);
  const [audioFile, setAudioFile] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [waveFormData, setWaveFormData] = useState([]);
  const endPoint = startPoint + animationLength;
  const rangeValue = { min: startPoint, max: endPoint };
  const muteState = isMute ? SWITCHER_STATE.ON : SWITCHER_STATE.OFF;
  const fadeState = isFade ? SWITCHER_STATE.ON : SWITCHER_STATE.OFF;

  const { onResetPlayerPlay, onTogglePlayerPlay } = usePlayControls({
    audioPlayerRef,
    audioRef,
    lottieElement,
    startPoint,
  });

  const {
    handleToggleFade,
    handleToggleMute,
    handleChangeAudio,
    handleDeleteAudio,
    handleChangeStartPointField,
    handleChangeRangeValue,
  } = usePlayHandlers({
    audioRef,
    audioPlayerRef,
    lottieElement,
    setAudioFile,
    audioInputRef,
    setFade,
    setMute,
    startPoint,
    animationLength,
    audioFile,
    playerState,
    editorIsReady,
  });

  useAudioFileHandle({
    audioRef,
    audioPlayerRef,
    lottieElement,
    audioFile,
    setAudioDuration,
    setTrackDuration,
    setAudioFile,
    handleChangeStartPointField,
    convertedAudioData,
    audioInputRef,
    selectedDraft,
    startPoint,
    animationLength,
  });

  useVolume({
    audioElement: audioPlayerRef.current, animationLength, startPoint, isFade, isMute,
  });
  useVolume({
    audioElement: audioRef.current, animationLength, startPoint, isFade, isMute,
  });

  useControlPlayback({
    startPoint,
    audioPlayerRef,
    audioFile,
    audioFileStorage,
    startPointInputElement,
    trackDuration,
    setAudioFile,
    audioRef,
    animationLength,
    lottieElement,
  });

  useAutoPlayAudio({
    audioRef,
    audioPlayerRef,
    lottieElement,
    editorIsReady,
    playerState,
    percentOfPlayer,
    animationLength,
    startPoint,
    audioFile,
  });

  useVisualize({ setWaveFormData, audioFile, audioRef });

  useEffect(() => {
    if (isEmpty(audioFileStorage)) {
      handleDeleteAudio();
    }
  }, [audioFileStorage]);

  return (
    <div className="design__tab">
      <Preloader
        isLoading={!!(isPendingDraftFile || isPreparing || isFetching)}
        type={PRELOADER_TYPES.CONTAINER}
        isDestroyChildren={false}
      >
        <div className="audio-editor__wrap-group">
          <div className="audio-editor__wrap">
            { audioFile ? (
              <div className="design__tab__title-text">
                <h5 className="design__audio-name">
                  <div className="text-cut">{audioFile.name}</div>
                </h5>
                <button
                  onClick={handleDeleteAudio}
                  type="button"
                  className="design__audio-delete"
                >
                  <img src={cancelIcon} alt="Delete audio" />
                </button>
              </div>
            ) : (<div className="design__tab__title-text">Click for select audio file &nbsp;</div>)}
            <div className="design__audio-label">
              <input
                onChange={validateFileSize({
                  onSuccess: handleChangeAudio,
                  onError: () => notify.error(`Maximum allowed file size is ${AUDIO_MAX_SIZE}Mb`),
                  maxSize: AUDIO_MAX_SIZE_BYTES,
                })}
                type="file"
                id="uploadNewAudio"
                className="design__audio-input"
                ref={audioInputRef}
                accept={generateAcceptStingByArray('audio', AVAILABLE_FORMATS.AUDIO)}
              />
              <label htmlFor="uploadNewAudio" className="design__audio-label-pseudo">
                <img src={customUploadIcon} alt="" />
              </label>
            </div>
          </div>
        </div>
        {
          audioFile && (

          <div className="audio-editor">
            <div className="audio-editor__duration-row">
              <div className="audio-editor__duration-col">
                <div className="audio-editor__duration-title">In Point</div>
                <input
                  type="text"
                  className="audio-editor__duration-input"
                  ref={startPointInputElement}
                  onChange={handleChangeStartPointField}
                  defaultValue={getTimeStringBySeconds(startPoint)}
                />
              </div>
              <div className="audio-editor__duration-col">
                <div className="audio-editor__duration-title">Duration</div>
                <div className="audio-editor__duration-value">{getTimeStringBySeconds(animationLength)}</div>
              </div>
              <div className="audio-editor__duration-col">
                <div className="audio-editor__duration-title">OutPoint</div>
                <div className="audio-editor__duration-value">{getTimeStringBySeconds(audioDuration)}</div>
              </div>
            </div>
            <div className="audio-editor__wrap-group">
              <div className="audio-editor__wrap audio-wrap">
                <Waveform
                  barWidth={0.1}
                  peaks={waveFormData}
                  height={46}
                  duration={audioFile.duration}
                  color="#1369A5"
                  pixelRatio={1}
                />
                <div className="audio-editor__time">
                  <InputRange
                    maxValue={trackDuration}
                    minValue={0}
                    value={rangeValue}
                    draggableTrack
                    onChange={handleChangeRangeValue}
                  />
                </div>
              </div>
              <div className="design__text-outside-row-wrap-specific">
                <div className="design__tab__title-text">Fade</div>
                <Select
                  id="fadeInOut"
                  value={fadeState}
                  onChange={handleToggleFade}
                  label={(
                    <div className="design__select active">
                      <span className="text-normalizer">
                        {fadeState}
                        <img className="dropdown-icon" src={smallArrowDownIcon} alt="dropdown-icon" />
                      </span>
                    </div>
                      )}
                  options={SWITCHER_OPTIONS}
                />
              </div>
              <div className="design__text-outside-row-wrap-specific">
                <div className="design__tab__title-text">Mute for playback</div>
                <Select
                  id="mutePlayback"
                  value={muteState}
                  onChange={handleToggleMute}
                  label={(
                    <div className="design__select active">
                      <span className="text-normalizer">
                        {muteState}
                        <img className="dropdown-icon" src={smallArrowDownIcon} alt="select" />
                      </span>
                    </div>
                      )}
                  options={SWITCHER_OPTIONS}
                />
              </div>
              <PlayerButtons
                onTogglePlayer={onTogglePlayerPlay}
                onPlayFromStart={onResetPlayerPlay}
              />
            </div>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio ref={audioRef} preload="metadata" loop />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio ref={audioPlayerRef} preload="metadata" loop />
          </div>
          )
        }
      </Preloader>
    </div>
  );
};

AudioTab.propTypes = {
  lottieElement: PropTypes.instanceOf(Object).isRequired,
};
