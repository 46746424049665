import styled from 'styled-components';

export const RootStyle = styled.div`
  min-width: 46%;
  max-width: 46%;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const Label = styled.div`
  display: inline-block;
  max-height: 32px;
  width: 98%;
  padding: 10px 5px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.MAIN};
  color: ${({ theme }) => theme.colors.WHITE};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
 `;
