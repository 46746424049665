import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Input, TextArea, Toggle,
} from 'ui-kit';
import { COLORS } from 'constants/styles';
import { useDispatch } from 'react-redux';
import { linksActions } from 'state/links';
import { showErrorAfterCreatingLink } from 'utils/helpers';
import { useMyFiles } from 'hooks';
import {
  Box, FieldWrapper, Form, ToggleLabel,
} from './ShareLinksModal.styled';
import { BUTTON_SIZES } from './constants';

/**
 *
 * @param setSharedCode {function}
 * @param onCloseModal {function}
 * @param isUsedPassword {boolean}
 * @param setIsUsedPassword {function}
 * @constructor
 */

export const ShareLinksForm = ({
  setSharedCode, onCloseModal, isUsedPassword, setIsUsedPassword,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['account', 'common', 'forms']);
  const { selectedFileIdsList } = useMyFiles();

  const { control, handleSubmit, reset } = useFormContext();

  const onSubmit = useCallback((data) => {
    dispatch(linksActions.createLinkRequest({
      is_secure: Number(isUsedPassword),
      ...data,
      video_ids: selectedFileIdsList,
    }, {
      callbacks: {
        success: ({ data: { model: { code } } }) => {
          setSharedCode(code);
          reset();
        },
        error: (e) => showErrorAfterCreatingLink(e),
      },
    }));
  }, [isUsedPassword, selectedFileIdsList]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper>
        <Controller
          name="emails"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextArea
              id="emails"
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              label={t('List emails here (separated by a comma)')}
              error={t(error?.message)}
            />
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Input
              id="title"
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              label={t('Title')}
              error={t(error?.message)}
            />
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Controller
          name="comment"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextArea
              id="comment"
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              label={t('Comments')}
              error={t(error?.message)}
            />
          )}
        />
      </FieldWrapper>
      <Box pt="10px" pb="10px" justifyContent="flex-start">
        <Toggle
          onChange={({ target: { checked } }) => setIsUsedPassword(checked)}
          value={isUsedPassword}
          name="requiredPassword"
          toggleSize="small"
        />
        <ToggleLabel
          htmlFor="requiredPassword"
        >{t('Require password')}
        </ToggleLabel>

      </Box>
      {isUsedPassword && (
        <>
          <FieldWrapper>
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  id="password"
                  type="password"
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  label={t('Password')}
                  error={t(error?.message)}
                />
              )}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Controller
              name="repeatPassword"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  id="repeatPassword"
                  type="password"
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  label={t('Repeat password')}
                  error={t(error?.message)}
                />
              )}
            />
          </FieldWrapper>
        </>
      )}
      <Box justifyContent="flex-end">
        <Box width="85%">
          <Button
            width={BUTTON_SIZES.WIDTH}
            height={BUTTON_SIZES.HEIGHT}
            label={t('Close')}
            color={COLORS.RED}
            onClick={onCloseModal}
          />
          <Button
            buttonTheme="submit"
            type="submit"
            width={BUTTON_SIZES.WIDTH}
            height={BUTTON_SIZES.HEIGHT}
            label={t('Send')}
          />
        </Box>
      </Box>
    </Form>
  );
};
