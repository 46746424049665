import React from 'react';
import { MyFilesProvider } from 'contexts';
import { DownloadFilesModal } from './DownloadFilesModal';
import { Root, Wrapper } from './MyFiles.styled';
import { List } from './List';
import { ShareLinksModal } from './ShareLinksModal';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const MyFiles = () => {
  return (
    <MyFilesProvider>
      <Root>
        <Wrapper>
          <List />
        </Wrapper>
      </Root>
      <DownloadFilesModal />
      <ShareLinksModal />
    </MyFilesProvider>
  );
};
