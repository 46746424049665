import { useEffect, useState } from 'react';
import { changeAssetUrl } from 'state/animation/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getAudioFile } from 'state/editor/selectors';
import { setAudioMute } from 'state/editor/actions';

/**
 *
 * @param cropperRef {object}
 * @param firstRender {object}
 * @param asset {object}
 * @param imageRef {object}
 * @returns {{setDisabled: (value: (((prevState: boolean) => boolean) | boolean)) => void, isDisabled: boolean}}
 */

export const useDisabled = ({
  cropperRef, firstRender, asset, imageRef,
}) => {
  const dispatch = useDispatch();
  const audioFile = useSelector(getAudioFile);
  const [isDisabled, setDisabled] = useState(false);
  const isAudio = JSON.stringify(audioFile) !== JSON.stringify({});

  useEffect(() => {
    const fileRefId = asset?.ref?.id || '';
    if (!firstRender.current) {
      if (isDisabled) {
        if (cropperRef.current) cropperRef.current.cropper.disable();
        if (isAudio) dispatch(setAudioMute(true));
        dispatch(changeAssetUrl([fileRefId, '']));
      } else {
        if (cropperRef.current) cropperRef.current.cropper.enable();
        if (isAudio) dispatch(setAudioMute(false));
        dispatch(changeAssetUrl([fileRefId, imageRef.value]));
      }
    }
    // eslint-disable-next-line
    }, [isDisabled, isAudio]);

  return {
    setDisabled, isDisabled,
  };
};
