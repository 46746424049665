import { handleActions } from 'redux-actions';
import {
  compose, objOf, pathOr, prop, map,
} from 'ramda';
import { mergeIn } from 'utils/helpers/ramdaStateHelpers';
import types from './types';

const reducer = handleActions({
  [types.SET_ANIMATIONS]: mergeIn(compose(
    objOf('animations'),
    pathOr({}, ['payload', 'data', 'animations']),
  )),
  [types.SET_SELECT_ANIMATION]: mergeIn(compose(objOf('selectAnimation'), pathOr({}, ['payload']))),

  [types.SET_ANIMATION_STATUS]: mergeIn(
    ({ payload: { name, status } },
      { animations }) => compose(objOf('animations'),
      map((item) => (item.name === name ? ({ ...item, status }) : item)))(animations),
  ),
  [types.SET_ANIMATIONS_FRESH_STATUS]: mergeIn(compose(objOf('isFresh'), prop('payload'))),
  [types.FETCH_ALL_ANIMATIONS_REQUEST]: (state) => ({
    ...state,
  }),
  [types.FETCH_ALL_ANIMATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: [...payload],
  }),
  [types.FETCH_ALL_ANIMATIONS_ERROR]: (state) => ({
    ...state,
  }),
}, {
});

export default reducer;
