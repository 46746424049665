import React, {
  useState, useCallback,
} from 'react';
import { is } from 'ramda';
import { useTranslation } from 'react-i18next';
import { TOOL_TYPES } from 'constants/editor';
import { ACTIONS, MODULES } from 'constants/permissions';
import { PermissionWrapper } from 'wrappers';
import {
  Row, Body, Title,
} from './PickerField.styled';

/**
 *
 * @param label {string}
 * @param children {function | JSX.Element}
 * @param type {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const PickerField = ({
  label = '',
  children,
  type = '',
}) => {
  const { t } = useTranslation('common');

  const [isFocused, setIsFocused] = useState(false);
  const [isPreventClose, setIsPreventClose] = useState(false);

  const handleToggle = useCallback((value) => () => {
    setIsFocused(value);
  }, []);

  const isCsv = type === TOOL_TYPES.CSV;

  return (
    <PermissionWrapper action={ACTIONS.READ} module={MODULES.CSV} forceShow={!type}>
      <Row
        isOpened={isFocused}
        onMouseEnter={handleToggle(true)}
        onFocus={handleToggle(true)}
        onBlur={handleToggle(isPreventClose)}
        onMouseLeave={handleToggle(isPreventClose)}
      >
        <Title>{t(label)}</Title>
        <Body
          isOpened={isFocused}
          isCsv={isCsv}
        >
          {is(Function, children) ? children({
            onClose: () => setIsFocused(false),
            preventClose: (value) => setIsPreventClose(value),
          }) : children}
        </Body>
      </Row>
    </PermissionWrapper>
  );
};
