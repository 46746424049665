import { useFileUpload } from 'hooks';
import { AVAILABLE_FORMATS } from 'constants/rendering';
import { useDispatch } from 'react-redux';
import { draftsFilesStorage, resizeImage } from 'utils/helpers/filesHelper';
import { setImageData } from 'state/editor/actions';
import { IMAGES_MAX_DIMENSION } from 'constants/fileSizes';

/**
 *
 * @param refId {string}
 * @returns {{
 * onUploadFile: ((function(*): void)|*),
 * fileUrl,
 * tempId: string,
 * isPending: (*),
 * value: (*|string|string)
 * }}
 */

export const useFileUploading = ({ refId }) => {
  const {
    fileUrl, onUploadFile, value, tempId, isPending,
  } = useFileUpload({ refId });
  const dispatch = useDispatch();

  const onUploadFileMiddleware = (func) => (file) => {
    if (AVAILABLE_FORMATS.VIDEOS.includes(file.type)) {
      draftsFilesStorage.subscribe(({ file: storageFile }) => {
        if (storageFile) {
          if (tempId) dispatch(setImageData({ [refId]: { options: { tempId } } }));
          func({ currentTarget: { files: [storageFile] } });
        }
      }, `GIF:${refId}`);
    } else {
      resizeImage(file, IMAGES_MAX_DIMENSION.WIDTH, IMAGES_MAX_DIMENSION.HEIGHT, (fileBlob) => {
        func({
          currentTarget: { files: { 0: new File([fileBlob], file.name, { type: file.type }) } },
        });
      });
    }
  };

  return {
    fileUrl,
    onUploadFile: onUploadFileMiddleware(onUploadFile),
    value,
    tempId,
    isPending,
  };
};
