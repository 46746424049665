export const AVAILABLE_FORMATS = {
  AUDIO: ['mp3', 'wav', 'x-aiff'],
  CONVERTED_AUDIO: ['x-aiff'],
  IMAGES: ['image/png', 'image/jpg', 'image/gif', 'image/jpeg', 'image/svg'],
  VIDEOS: ['video/mp4', 'video/mov', 'video/quicktime'],
};

export const AUDIO_FADE_DURATION = {
  IN: 1.5,
  OUT: 1.5,
}; // seconds

export const IMAGE_TYPES_BY_ID = {
  JPG: 1,
  PNG: 2,
};
export const RENDER_STATUS = {
  RENDERING: 1,
  PROCESSING: 2,
  SUCCESS: 3,
  ERROR: 4,
};

export const RENDER_STATUSES_LISTS = {
  NOT_READY: [RENDER_STATUS.ERROR, RENDER_STATUS.PROCESSING, RENDER_STATUS.RENDERING],
};

export const RENDER_TYPES_FOR_API = {
  mp4: 1,
  gif: 2,
  html5: 3,
  image: 4,
  oneFrame: 5,
  mov: 6,
  keyframesZip: 7,
  keyframesGif: 8,
  templates: 9,
  htmlBanner: 10,
  html5Adform: 11,
  keyFramesPdf: 12,
};

export const HTML_PREVIEW_TYPES = [
  RENDER_TYPES_FOR_API.htmlBanner,
  RENDER_TYPES_FOR_API.html5,
  RENDER_TYPES_FOR_API.html5Adform,
];

export const SPECIFIC_PREVIEW_TYPES = [
  ...HTML_PREVIEW_TYPES,
  RENDER_TYPES_FOR_API.keyframesZip,
  RENDER_TYPES_FOR_API.keyFramesPdf,
];

export const RENDER_TYPES = [
  {
    label: 'Video',
    type: 1,
    format: 'mp4',
    fileTypes: [RENDER_TYPES_FOR_API.mp4],
    typeForApi: 1,
  },
  {
    label: 'GIF',
    type: 2,
    format: 'gif',
    fileTypes: [RENDER_TYPES_FOR_API.gif, RENDER_TYPES_FOR_API.keyframesGif],
    typeForApi: 2,
  },
  {
    label: 'HTML5',
    type: 3,
    format: 'zip',
    fileTypes: HTML_PREVIEW_TYPES,
    typeForApi: 10,
  },
  {
    label: 'IMAGE',
    type: 4,
    format: 'png',
    fileTypes: [RENDER_TYPES_FOR_API.oneFrame, RENDER_TYPES_FOR_API.image, RENDER_TYPES_FOR_API.keyframesZip],
    typeForApi: 4,
  },
  {
    label: 'PDF',
    type: 12,
    format: 'pdf',
    fileTypes: [RENDER_TYPES_FOR_API.keyFramesPdf],
    typeForApi: 12,
  },
];

export const MAP_RENDER_TYPE_WHEN_USE_KEYFRAMES = {
  [RENDER_TYPES_FOR_API.gif]: RENDER_TYPES_FOR_API.keyframesGif,
  [RENDER_TYPES_FOR_API.image]: RENDER_TYPES_FOR_API.keyframesZip,
};

export const RENDER_IMAGE_FORMAT = [
  IMAGE_TYPES_BY_ID.JPG,
  IMAGE_TYPES_BY_ID.PNG,
];

export const RENDER_IMAGE_FORMATS = {
  0: {
    id: 0,
    label: 'JPG',
    value: 2,
  },
  1: {
    id: 1,
    label: 'PNG',
    value: 1,
  },
};

export const VIDEO_BITRATE = {
  0: {
    id: 0,
    label: 'Low',
    valueForApi: 2560,
    value: 0,
  },
  1: {
    id: 1,
    label: 'Normal',
    valueForApi: 5120,
    value: 1,
  },
  2: {
    id: 2,
    label: 'High ~10 Mb/sec',
    valueForApi: 10124,
    value: 2,
  },
  3: {
    id: 3,
    label: 'High ~20 Mb/sec',
    valueForApi: 20248,
    value: 3,
  },
  4: {
    id: 4,
    label: 'High ~25 Mb/sec',
    valueForApi: 25310,
    value: 4,
  },
  5: {
    id: 5,
    label: 'High ~30 Mb/sec',
    valueForApi: 30372,
    value: 5,
  },
};

export const VIDEO_FRAMERATE = {
  0: { id: 0, label: '0.5 fps', gif_fps: 0.5 },
  1: { id: 1, label: '1.0 fps', gif_fps: 1 },
  2: { id: 2, label: '1.5 fps', gif_fps: 1.5 },
};

export const RENDER_STATUSES = {
  0: {
    label: 'Csv Template',
    id: 0,
    color: 'black',
  },
  1: {
    label: 'Ready for Render',
    id: 1,
    color: 'black',
  },
  2: {
    label: 'In Progress',
    id: 2,
    color: 'darkcyan',
  },
  3: {
    label: 'Done',
    id: 3,
    color: 'green',
  },
  4: {
    label: 'Error',
    id: 4,
    color: 'red',
  },
};

export const RENDER_TYPES_LOCAL = [
  {
    id: 1,
    title: 'MP4 Video',
    nativeRenderValue: RENDER_TYPES_FOR_API.mp4,
  },
  {
    id: 2,
    title: 'GIF Full Animation',
    nativeRenderValue: RENDER_TYPES_FOR_API.gif,
  },
  {
    id: 3,
    type: 1,
    title: 'GIF keyframes',
    nativeRenderValue: RENDER_TYPES_FOR_API.keyframesGif,
    advanced: [
      {
        title: 'Hold keyframes',
        name: 'gif_fps',
        type: 1, // select
        options: [
          {
            value: 2,
            label: '0.5s',
          },
          {
            value: 1,
            label: '1s',
          },
          {
            value: 0.5,
            label: '2s',
          },
          {
            value: 0.333,
            label: '3s',
          },
          {
            value: 0.25,
            label: '4s',
          },
          {
            value: 0.2,
            label: '5s',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'Still image keyframes',
    nativeRenderValue: RENDER_TYPES_FOR_API.keyframesZip,
  },
  {
    id: 5,
    title: 'Still image current frame',
    nativeRenderValue: RENDER_TYPES_FOR_API.oneFrame,
  },
  {
    id: 6,
    title: 'HTML package',
    nativeRenderValue: RENDER_TYPES_FOR_API.html5,
  },
  {
    id: 7,
    title: 'HTML banner',
    nativeRenderValue: RENDER_TYPES_FOR_API.htmlBanner,
  },
  {
    id: 8,
    title: 'Adform banner',
    nativeRenderValue: RENDER_TYPES_FOR_API.html5Adform,
  },
  {
    id: 9,
    title: 'Animation template',
    nativeRenderValue: RENDER_TYPES_FOR_API.templates,
  },
  {
    id: 10,
    title: 'PDF keyframes',
    nativeRenderValue: RENDER_TYPES_FOR_API.keyFramesPdf,
  },
];

export const TYPES_WITH_FFMPEG_SETTINGS = [
  RENDER_TYPES_FOR_API.gif,
  RENDER_TYPES_FOR_API.mp4,
  RENDER_TYPES_FOR_API.keyframesGif,
];

export const DEFAULT_VIDEO_TYPES = RENDER_TYPES.reduce(
  (acc, { type }) => ({ ...acc, [type]: true }),
  {},
);
