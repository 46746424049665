import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { BaseButton, PageItem, Root } from './Pagination.styled';

const FIRST_PAGE = 1;
const PAGES_FOR_SKIPPING = 3;
const DOTS = '...';

/**
 *
 * @param pages {array<object>}
 * @param page {number}
 * @param setPage {function}
 * @param lastPage {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const Pagination = ({
  pages, page, setPage, lastPage,
}) => {
  const { t } = useTranslation(['account', 'common', 'forms']);
  const indexOfCurrentPage = pages.findIndex((item) => item === page);

  const handleChangePageByArrowClick = (newPage) => () => {
    const res = page + newPage;
    if (res < FIRST_PAGE || res > lastPage) return;
    setPage(res);
  };

  const handleChangePage = (newPage, index) => () => {
    // if click on usual page, then set number
    if (newPage !== DOTS) setPage(newPage);
    else if (indexOfCurrentPage < index) {
      // if click on right dots, skip pages to the end
      setPage((prev) => prev + PAGES_FOR_SKIPPING);
    } else {
      // if click on left dots, skip pages to the start
      setPage((prev) => prev - PAGES_FOR_SKIPPING);
    }
  };

  return (
    <Root>
      {t('Page')}
      <BaseButton
        type="button"
        onClick={handleChangePageByArrowClick(-1)}
      >
        &#60;
      </BaseButton>
      {pages.map((item, index) => (
        <PageItem
          type="button"
          key={`my-files-page-${uuidV4()}`}
          isActive={item === page}
          onClick={handleChangePage(item, index)}
        >
          {item}
        </PageItem>
      ))}
      <BaseButton type="button" onClick={handleChangePageByArrowClick(1)}>
        &#62;
      </BaseButton>
    </Root>
  );
};
