import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  AdditionalLink, AdditionalText, AuthLayout,
  Wrapper, ExternalLink, Form, INPUT_STYLES, LABEL_STYLES,
} from 'layouts/Auth';
import { authService } from 'api';
import { Button, Input } from 'ui-kit';
import { authActions } from 'state/user';
import { COLORS } from 'constants/styles';
import { ROUTES } from 'constants/routes';
import { editorActions } from 'state/editor';
import { animationActions } from 'state/animation';
import { identifyMixPanelUser, setPropertiesToTheUser } from 'utils/mixpanel';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import rules from './rules';
import { Divider } from './SignIn.styled';
import {
  BLOG_LINK,
  DEFAULT_VALUES, FIELDS_CONFIG,
} from './constants';
import { useTrackEvent } from '../../../hooks';

const SignIn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { handleTrack } = useTrackEvent();

  const {
    control, handleSubmit, formState: { isSubmitting }, setError, clearErrors,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(rules),
  });

  const onSubmit = useCallback(async (values) => {
    try {
      clearErrors();
      const data = await authService.login(values);
      if (data && data.token) {
        localStorage.setItem('authToken', data.token);
        dispatch(editorActions.editorHardReset());
        dispatch(animationActions.animationHardReset());
        dispatch(authActions.setAuthenticated(true));
        dispatch(authActions.setUser(data));
        identifyMixPanelUser(data.user.id);
        handleTrack(MIXPANEL_EVENTS.SIGN_IN, {
          Company: data?.user?.company_name ?? '-',
          Name: `${data?.user?.first_name ?? '-'} ${data?.user?.last_name ?? '-'}`,
          'Company Id': data?.user?.company_id ?? '-',
          Email: data?.user?.email ?? '-',
        });
        setPropertiesToTheUser({
          company: data?.user?.company_name ?? '-',
          name: `${data?.user?.first_name ?? '-'} ${data?.user?.last_name ?? '-'}`,
          email: data?.user?.email ?? '-',
        });
      }
    } catch (e) {
      const { errors } = e;
      if (errors?.username) {
        setError('username', {
          message: errors.username,
        });
      }
      if (errors?.password) {
        setError('password', {
          message: errors.password,
        });
      }
    }
  }, []);

  const loginByGoogle = useCallback(async () => {
    const code = searchParams.get('code');
    if (code) {
      const data = await authService.googleLogin({ code });
      if (data && data.token) {
        localStorage.setItem('authToken', data.token);
        dispatch(authActions.setAuthenticated(true));
        dispatch(authActions.setUser(data));
        identifyMixPanelUser(data.user.id);
        handleTrack(MIXPANEL_EVENTS.SIGN_IN, {
          Company: data?.user?.company_name ?? '-',
          Name: `${data?.user?.first_name ?? '-'} ${data?.user?.last_name ?? '-'}`,
          'Company Id': data?.user?.company_id ?? '-',
          Email: data?.user?.email ?? '-',
        });
        setPropertiesToTheUser({
          company: data?.user?.company_name ?? '-',
          name: `${data?.user?.first_name ?? '-'} ${data?.user?.last_name ?? '-'}`,
          email: data?.user?.email ?? '-',
        });
      } else {
        navigate(ROUTES.AUTH.SIGN_UP_SUCCESS);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    loginByGoogle();
  }, []);

  return (
    <AuthLayout
      title={(
        <>
          {t('Welcome to Tumplate')}
          <br />
          {t('Sign in and get creating')}
        </>
      )}
    >
      <Form onSubmit={handleSubmit(onSubmit)} mt={3}>
        {FIELDS_CONFIG.map(({
          id, name, label, type,
        }) => (
          <Controller
            key={id}
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label={label}
                type={type}
                name={name}
                id={id}
                value={value}
                error={t(error?.message)}
                onChange={onChange}
                width="80%"
                mb="32px"
                inputStyleProps={INPUT_STYLES}
                labelStyleProps={LABEL_STYLES}
              />
            )}
          />
        ))}
        <AdditionalText>
          {t('Forgot your password?')}
          {' '}
          <AdditionalLink
            to={ROUTES.AUTH.FORGOT_PASSWORD}
          >{t('Click here for reset')}
          </AdditionalLink>
        </AdditionalText>
        <Wrapper pt="30px">
          <Button
            type="submit"
            buttonTheme="submit"
            isDisabled={isSubmitting}
            label={t('Sign In')}
            title={t('Sign In')}
            width={182}
            height={46}
          />
        </Wrapper>
        <Divider>
          <span>{t('or')}</span>
        </Divider>
        <Wrapper>
          <Button
            type="button"
            width={182}
            height={46}
            onClick={() => {
              window.location.assign(`${process.env.REACT_APP_API_URL}/google`);
            }}
            label={t('Sign in with Google')}
            title={t('Sign in with Google')}
            color={COLORS.WARNING}
          />
        </Wrapper>
        <AdditionalText>
          {t('Not yet a happy account holder?')}
          {' '}
          <ExternalLink href={BLOG_LINK}>{t('Contact us')}</ExternalLink>
        </AdditionalText>
      </Form>
    </AuthLayout>
  );
};

export default SignIn;
