export const RATIOS = {
  WIDE: '16:9',
  TALL: '9:16',
  SQUARE: '1:1',
};

const RATIOS_BY_FILENAME = {
  [RATIOS.WIDE]: '16:9',
  [RATIOS.TALL]: '9:16',
  [RATIOS.SQUARE]: '1:1',
};

export { RATIOS_BY_FILENAME };

export const DEFAULT_COLORS = [
  { id: 0, color: '#fff', isDefault: true },
  { id: 1, color: '#000', isDefault: true },
];

export const CONTROLS_THEME_NONE = 'None';

export const AUDIO_PLAYER_STATE = {
  NOT_USED: 0,
  PLAY: 1,
  PAUSE: 2,
  RESET: 3,
  USE_ONLY_PLAYER: 4,
};

export const SWITCHER_STATE = {
  ON: 'On',
  OFF: 'Off',
};

export const AUDIO_METHOD = {
  PLAY: 'play',
  PAUSE: 'pause',
};

export const TOOL_TYPES = {
  CSV: 'CSV',
  LENGTH: 'LENGTH',
  DRAFTS: 'DRAFTS',
  VERSION: 'VERSION',
  EXPORT: 'EXPORT',
  RESET: 'RESET',
};

export const TABS = {
  TEXT: 'TEXT',
  IMAGES: 'IMAGES',
  CONTROLS: 'CONTROLS',
  AUDIO: 'AUDIO',
};

export const LOTTIE_LISTENERS = {
  SUPER_REBUILD_END: 'superRebuildEnd',
  SUPER_READY: 'superReady',
  DOM_LOADED: 'DOMLoaded',
  ENTER_FRAME: 'enterFrame',
};

export const ANIMATION_ACTIONS = {
  STOP: 'goToAndStop',
  PLAY: 'goToAndPlay',
  STOP_SUPER: 'goToAndStopSuper',
  PLAY_SUPER: 'goToAndPlaySuper',
};

export const SCALE_IMAGES = {
  MIN: 0.1,
  DEFAULT: 1.05,
  MAX: 2.1,
};

export const MAIN_COLORS = [
  'MAIN TEXT COLOR',
  'ACCENT TEXT COLOR',
  'ACCENT COLOR',
  'AUXCOLOR1',
  'AUXCOLOR2',
  'AUXCOLOR3',
  'AUXCOLOR4',
];

export const PRESET_COLOR_INDEXES = {
  FIRST: 44,
  LAST: 59,
};

export const GLOBAL_COLOR_INDEXES = {
  FIRST: 59,
  LAST: 66,
};
