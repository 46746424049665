import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-width: 100%;
  margin-left: 20px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: none;
  &:first-child {
    margin-top: 42px;
  }
  @media (${({ theme }) => theme.devices.MD}) {
    border-radius: 23px
  }
  @media (${({ theme }) => theme.devices.XS}) {
    border-radius: 0 23px 23px 0;
  }
`;

export const Text = styled.span`
  display: inline-block;
  min-width: 70px;
  max-width: 110px;
  margin: 0 auto 0 16px;
  padding: 2px 16px 0 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
