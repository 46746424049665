const getMaxWidthForSize = (size) => `max-width: ${size}`;

export const COLORS = {
  MAIN: '#00A0FF',
  ACTIVE_MAIN: '#1369a5',
  MAIN_BACKGROUND: '#E3F3FF',
  ORANGE: '#FF9F1A',
  ERROR: '#b91b17',
  RED: '#ff1d32',
  WARNING: '#ffcc00',
  GRAY: '#cecece',
  WHITE: '#ffffff',
  BLACK: '#363648',
  SUCCESS: '#00D7B4',
  SECONDARY: '#BD47DA',
  LIGHT_GREEN: '#ccfdf8',
  DARK_GRAY: '#898989',
  LIGHT_BLUE: '#6fbfff',
  RESET: '#FF0000',
  BORDER_COLOR: '#707070',
  VIOLET: '#889FFF',
  CANCEL: '#F03D3E',
  LIGHT_GRAY: '#D1D1D1',
  TAB_BACKGROUND: '#EBEBEB',
  EXTRA_LIGHT_GREY: '#DDE2E5',
  GREEN: '#05AF00',
  TRACK_BORDER: '#EAEAEA',
  PLATINUM: '#DDE2E5',
  LIGHT: '#F9F9F9',
  EXTRA_LIGHT: '#F9FAFC',
  PURPLE: '#7048E8',
  BLUE: '#374FC7',
  EXTRA_LIGHT_BLUE: '#EDF8FF',
  LABEL_COLOR: '#ACB5BD',
  CROPPER: '#3399FF',
  MODAL_SIDEBAR: '#EEEEEE',
  DRAG_DROP_TEXT: '#9E9E9E',
};

const SIZE = {
  H_FULL_HD: '(min-width: 1080px) and (max-height: 1200px)',
  XS_X: '350px',
  XS: '480px',
  SM: '767px',
  MD: '980px',
  LG: '1240px',
  LGX: '1380px',
  STANDARD: '1660px',
  LARGE: '2100px',
};

const DEVICES = {
  H_FULL_HD: SIZE.H_FULL_HD,
  XS_X: getMaxWidthForSize(SIZE.XS_X),
  XS: getMaxWidthForSize(SIZE.XS),
  SM: getMaxWidthForSize(SIZE.SM),
  MD: getMaxWidthForSize(SIZE.MD),
  LG: getMaxWidthForSize(SIZE.LG),
  LGX: getMaxWidthForSize(SIZE.LGX),
  STANDARD: getMaxWidthForSize(SIZE.STANDARD),
  LARGE: getMaxWidthForSize(SIZE.LARGE),
};

export const MAIN_THEME = {
  colors: { ...COLORS },
  devices: { ...DEVICES },
  fontFamily: 'Helvetica Neue, sans-serif',
};
