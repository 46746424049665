import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 100%;
  margin-top: 55px;
  @media(${({ theme }) => theme.devices.LG}) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  @keyframes showRight {
    0% {
      transform: translateX(-100%)
    }
    100% {
      transform: translateX(0)
    }
  }
  @media(${({ theme }) => theme.devices.LG}) {
    display: block;
    animation: .25s showRight;
  }
`;
