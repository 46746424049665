import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { editIcon } from 'assets/images';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, ZapierModal } from 'ui-kit';
import { useMyFiles } from 'hooks';
import { linksActions } from 'state/links';
import { generateLinkForDiscussion, showErrorAfterCreatingLink, notify } from 'utils/helpers';
import { getPendingSendingVideosToZapier } from 'state/videos/selectors';
import { sendVideosToZapierRequest } from 'state/videos/actions';
import { DEFAULT_VALUES, ICON_DIMENSION } from './constants';
import { SuccessSharedLinks } from './SuccessSharedLinks';
import {
  ButtonText, Header, IconButton,
  Image, Root, Title, Box, Body,
} from './ShareLinksModal.styled';
import { ShareLinksForm } from './ShareLinksForm';
import getRules from './rules';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const ShareLinksModal = () => {
  const { t } = useTranslation(['account', 'common', 'forms']);
  const dispatch = useDispatch();
  const isPendingSendingToZapier = useSelector(getPendingSendingVideosToZapier);

  const { isOpenedShareLinksModal, toggleShareLinksModal, selectedFileIdsList } = useMyFiles();

  const [shareableLink, setShareableLink] = useState(false);
  const [sharedCode, setSharedCode] = useState(null);
  const [isOpenedZapierModal, setIsOpenedZapierModal] = useState(false);
  const [isUsedPassword, setIsUsedPassword] = useState(false);

  const form = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(getRules(isUsedPassword)),
    mode: 'onChange',
  });

  const [title] = form.watch(['title']);

  const toggleZapierModal = useCallback((value) => () => setIsOpenedZapierModal(value), []);

  const buttonText = shareableLink ? 'Copy Shareable link' : 'Generate Shareable link';

  const handleCloseModal = useCallback(() => {
    toggleShareLinksModal(false)();
    setSharedCode(null);
    setShareableLink(null);
  }, []);

  const handleClick = useCallback(() => {
    if (!title && title === '' && !sharedCode) {
      form.setError('title', {
        message: 'Please enter a title',
      });
      return;
    }
    if (!shareableLink && !sharedCode) {
      dispatch(linksActions.createLinkRequest({
        video_ids: selectedFileIdsList,
        title,
      }, {
        callbacks: {
          success: ({ data: { model: { code } } }) => {
            setShareableLink(code);
            notify.success(t('Link was generated!'));
          },
          error: (e) => showErrorAfterCreatingLink(e),
        },
      }));
      form.clearErrors();
    } else {
      const link = generateLinkForDiscussion(sharedCode || shareableLink);
      navigator.clipboard.writeText(link);
      notify.success(t('Link was copied to clipboard!'));
      form.reset();
      handleCloseModal();
    }
  }, [shareableLink, sharedCode, selectedFileIdsList, title]);

  const handleShareViaZapier = useCallback((command) => {
    dispatch(sendVideosToZapierRequest({ command, ids: selectedFileIdsList }, {
      callbacks: {
        success: () => {
          notify.success(t('Sending to zapier was succeed'));
        },
        error: () => {
          notify.error(t('Sending to zapier was failed'));
        },
        finally: () => {
          toggleZapierModal(false)();
          toggleShareLinksModal(false)();
        },
      },
    }));
  }, [selectedFileIdsList]);

  return (
    <>
      <Root isOpen={isOpenedShareLinksModal}>
        {!sharedCode ? (
          <>
            <Header>
              <Box width="40%">
                <Image src={editIcon} alt="Logo" />
                <Title>{t('Share content')}</Title>
              </Box>
              <Box width="55%" justifyContent="flex-end">
                <IconButton onClick={handleClick}>
                  <ButtonText>{t(buttonText)}</ButtonText>
                  <Icon name="link" />
                </IconButton>
              </Box>
            </Header>
            <Body>
              <Box
                flexDirection="column"
                width="20%"
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Icon name="email" width={ICON_DIMENSION} height={ICON_DIMENSION} marginBottom="5px" />
                <IconButton onClick={toggleZapierModal(true)}>
                  <Icon name="zapier" width={ICON_DIMENSION} height={ICON_DIMENSION} />
                </IconButton>
              </Box>
              <Box width="80%" pt="5px" pl="10px">
                <FormProvider {...form}>
                  <ShareLinksForm
                    setSharedCode={setSharedCode}
                    onCloseModal={handleCloseModal}
                    isUsedPassword={isUsedPassword}
                    setIsUsedPassword={setIsUsedPassword}
                  />
                </FormProvider>
              </Box>
            </Body>
          </>
        ) : (
          <SuccessSharedLinks onClick={handleClick} onCloseModal={handleCloseModal} />
        )}
      </Root>
      <ZapierModal
        isPending={isPendingSendingToZapier}
        isOpen={isOpenedZapierModal}
        onSubmitCallback={handleShareViaZapier}
        onClose={toggleZapierModal(false)}
      />
    </>
  );
};
