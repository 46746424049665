export default {
  CREATE_LINK_REQUEST: 'CREATE_LINK_REQUEST',
  UPDATE_LINK_REQUEST: 'UPDATE_LINK_REQUEST',
  SET_LINK: 'SET_LINK',

  GET_LINKS_REQUEST: 'GET_LINKS_REQUEST',
  SET_LINKS: 'SET_LINKS',

  DELETE_LINK_REQUEST: 'DELETE_LINK_REQUEST',
  DELETE_LINK: 'DELETE_LINK',

  GET_LINK_VIEW_REQUEST: 'GET_LINK_VIEW_REQUEST',
  GET_LINK_VIEW_SUCCESS: 'GET_LINK_VIEW_SUCCESS',
  GET_LINK_VIEW_FAILURE: 'GET_LINK_VIEW_FAILURE',

  GET_FILES_OF_LINK_REQUEST: 'GET_FILES_OF_LINK_REQUEST',
  GET_FILES_OF_LINK_SUCCESS: 'GET_FILES_OF_LINK_SUCCESS',
  GET_FILES_OF_LINK_FAILURE: 'GET_FILES_OF_LINK_FAILURE',

  UPDATE_FILE_OF_LINK_REQUEST: 'UPDATE_FILE_OF_LINK_REQUEST',
  UPDATE_FILE_OF_LINK_SUCCESS: 'UPDATE_FILE_OF_LINK_SUCCESS',
  UPDATE_FILE_OF_LINK_FAILURE: 'UPDATE_FILE_OF_LINK_FAILURE',
};
