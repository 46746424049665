import React from 'react';
import { Tools } from '../Tools';
import { RootStyle } from './MobileToolbar.styled';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const MobileToolbar = () => {
  return (
    <RootStyle>
      <Tools />
    </RootStyle>
  );
};
