import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
  box-sizing: border-box;
  @media(${({ theme }) => theme.devices.SM}) {
    padding: 6px 15px
  }
`;

export const Title = styled.h3`
  display: flex;
  margin-left: 2px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
`;

export const Label = styled.div`
  display: inline-block;
  max-height: 32px;
  box-sizing: border-box;
  padding: 0 12px;
  color: #000;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.WHITE};
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 32px;
  text-decoration: none;
  transition-duration: .25s;
  cursor: pointer;
`;
