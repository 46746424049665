import {
  call,
  fork, put, select, take,
} from 'redux-saga/effects';
import { sagasManager } from 'utils';
import { linkService } from 'api';
import { notify, setItemToStorage } from 'utils/helpers';
import { SHARING_ERRORS } from 'constants/errors';
import { STORAGE_KEYS } from 'constants/links';
import types from './types';
import {
  getLinkViewSuccess,
  getFilesOfLinkSuccess,
  getLinkViewFailure,
  getFilesOfLinkFailure,
  updateFileOfLinkSuccess,
} from './actions';
import { selectFilesOfLink } from './selectors';

function* getLinkView() {
  while (true) {
    const {
      payload: {
        code, password, isNotifyError,
      },
    } = yield take(types.GET_LINK_VIEW_REQUEST);
    try {
      const data = yield call(() => linkService.getLinkView(code, { password }));
      yield put(getLinkViewSuccess(data));
      setItemToStorage(STORAGE_KEYS.PASSWORD, password);
    } catch (e) {
      yield put(getLinkViewFailure());
      if (isNotifyError) {
        notify.error(SHARING_ERRORS.PASSWORD_IS_INCORRECT);
      }
    }
  }
}

function* getFilesOfLink() {
  while (true) {
    const {
      payload: {
        code, ...params
      },
    } = yield take(types.GET_FILES_OF_LINK_REQUEST);
    try {
      const data = yield call(() => linkService.getFilesOfLink(code, params));
      yield put(getFilesOfLinkSuccess(data));
    } catch (e) {
      yield put(getFilesOfLinkFailure());
    }
  }
}

function* updateFileOfLink() {
  while (true) {
    const {
      payload: {
        code, ...data
      },
    } = yield take(types.UPDATE_FILE_OF_LINK_REQUEST);
    try {
      const list = yield select(selectFilesOfLink);
      const { video } = yield call(() => linkService.updateFileOfLink(code, data));
      const result = list.map((item) => (item.id === video.id ? video : item));
      yield put(updateFileOfLinkSuccess(result));
    } catch (e) {
      notify.error('Issue with changing status');
    }
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(getLinkView);
  yield fork(getFilesOfLink);
  yield fork(updateFileOfLink);
});
