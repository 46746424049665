import React, { useCallback, useRef, useState } from 'react';
import { PlayerButtons } from '../PlayerButtons';
import { Track } from './Track';
import {
  Container, Frame, TimeLine, Video,
} from './VideoPlayer.styled';

/**
 *
 * @param src {string}
 * @param frameStyleProps {object} style for frame
 * @param videoStyleProps {object} style for video
 * @returns {JSX.Element}
 * @constructor
 */

export const VideoPlayer = ({ src, frameStyleProps, videoStyleProps }) => {
  const playerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(true);
  const [progress, setProgress] = useState(0);

  const handleTogglePlay = useCallback(() => {
    if (playerRef.current.paused) {
      playerRef.current.currentTime = progress;
      playerRef.current.play();
    } else {
      playerRef.current.currentTime = progress;
      playerRef.current.pause();
    }
    setIsPaused(playerRef.current.paused);
  }, [playerRef, setIsPaused, progress]);

  const handlePlayFromStart = useCallback(() => {
    playerRef.current.currentTime = 0;
    playerRef.current.play();
    setIsPaused(false);
  }, [playerRef, setIsPaused]);

  return (
    <Container>
      <Frame {...frameStyleProps}>
        <Video {...videoStyleProps} src={src} ref={playerRef} loop><track kind="captions" /></Video>
      </Frame>
      <TimeLine>
        <Track
          playerRef={playerRef}
          onTogglePlaying={setIsPaused}
          isPaused={isPaused}
          setProgress={setProgress}
          progress={progress}
        />
      </TimeLine>
      <PlayerButtons
        onTogglePlayer={handleTogglePlay}
        onPlayFromStart={handlePlayFromStart}
        isPaused={isPaused}
      />
    </Container>
  );
};
