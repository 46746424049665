import styled from 'styled-components';
import { typography, layout, position } from 'styled-system';
import { space } from '@styled-system/space';

export const Root = styled.td`
  ${layout}
  ${typography}
  ${space}
  ${position}
  box-sizing: border-box;
`;
