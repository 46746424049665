const READ_AS = 'readAs';

const DATA_TYPES = {
  ARRAY_BUFFER: `${READ_AS}ArrayBuffer`,
  DATA_URL: `${READ_AS}DataURL`,
};

const VIDEO_TYPE = 'video/webm';

const ZEROS = '000000';

/**
 * FileReader via promise
 * @param {File} file
 * @param {string} dataType
 * @return {Promise<ArrayBuffer | string>}
 */
export const readFile = (file, dataType = DATA_TYPES.ARRAY_BUFFER) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader[dataType](file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (err) => reject(err);
});

/**
 * Read File/Blob to ArrayBuffer
 * @param {File} file
 * @return {Promise<ArrayBuffer>}
 */
export const readArrayBuffer = (file) => readFile(file, DATA_TYPES.ARRAY_BUFFER);

/**
 * Read File/Blob to Base64
 * @param {File} file
 * @return {Promise<string>}
 */
export const readDataURL = (file) => readFile(file, DATA_TYPES.DATA_URL);

/**
 * format seconds to [minutes, integer, decimal(2)]
 * @param {number} seconds
 */
export const formatSeconds = (seconds) => [
  Math.floor(seconds / 60),
  Math.floor(seconds % 60),
  Math.round((seconds % 1) * 100),
];

/**
 *
 * @param num
 * @param zeros
 * @returns {string|number}
 */

export const addZeroBeforeNumber = (num, zeros = 1) => (num.toString().length < 2 ? `${ZEROS}${num}`.slice(-zeros) : num);

/**
 *
 * @param buffer
 * @returns {Blob}
 */

export const arrayBufferToBlob = (buffer) => new Blob([new Uint8Array(buffer, 0, buffer.byteLength)], {
  type: VIDEO_TYPE,
  name: VIDEO_TYPE,
});
