import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { draftsSelectors } from 'state/drafts';
import { draftsFilesStorage, getFileTypeByUrl } from 'utils/helpers/filesHelper';
import { normalizeDraftsFileParamsToFront } from 'utils/helpers/renderHelpers';
import { GIF_VIDEO_REGEXP } from 'constants/regexp';

/**
 *
 * @param refId {string}
 * @param inputElementRef {object}
 * @param onUploadFile {function}
 * @param cropperRef {object}
 * @param setCropData {function}
 * @param onZoom {function}
 * @param setDisabled {function}
 * @param setIsTouched {function}
 */

export const useDraftsImages = ({
  refId,
  onUploadFile,
  cropperRef,
  setCropData,
  onZoom,
  setDisabled,
  setIsTouched,
}) => {
  const prevSelectedDraft = useRef(null);
  const selectedDraft = useSelector(draftsSelectors.getSelectedDraft);
  const isChangingDraft = prevSelectedDraft.current || selectedDraft;

  const filesHandler = (file) => {
    // eslint-disable-next-line no-shadow
    const callUpdateInputField = (file) => {
      onUploadFile(file);
    };

    if (isNil(file)) {
      callUpdateInputField(file);
    } else {
      requestAnimationFrame(() => callUpdateInputField(file));
    }
  };

  // eslint-disable-next-line
    useEffect(() => {
    const fileData = draftsFilesStorage.getFile(refId);
    if (fileData?.file) {
      filesHandler(fileData.file, fileData.meta);
    }
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    const handleReady = (e) => {
      const fileData = draftsFilesStorage.getFile(refId);
      if (fileData) {
        const {
          isDisabled = false,
          isTouched = false,
          scale,
          ...cropData
        } = normalizeDraftsFileParamsToFront(fileData?.meta || {});
        setTimeout(() => {
          if (cropData) {
            setTimeout(() => {
              getFileTypeByUrl(e.target.src, (fileType) => {
                setIsTouched(isTouched);
                setTimeout(() => {
                  if ((scale || scale === 0) && !isDisabled) onZoom(scale);
                  setCropData({
                    ...cropData,
                    isForceUpdate: !new RegExp(GIF_VIDEO_REGEXP).test(fileType) || isTouched,
                  });
                });
                queueMicrotask(setDisabled(isDisabled));
              }, () => {
                setCropData({ ...cropData, isForceUpdate: isTouched });
              });
            });
          }
        });
      }
    };
    if (cropperRef.current && isChangingDraft) {
      cropperRef.current.img.addEventListener('ready', handleReady);
      return () => {
        // eslint-disable-next-line
          cropperRef.current && cropperRef.current.img.removeEventListener('ready', handleReady);
      };
    }
    // eslint-disable-next-line
  }, [cropperRef, isChangingDraft]);

  useEffect(() => {
    draftsFilesStorage.subscribe((result) => {
      if (result) {
        filesHandler(result.file);
      } else {
        filesHandler(null);
      }
    }, refId);
    // eslint-disable-next-line
    }, [selectedDraft]);

  useEffect(() => {
    prevSelectedDraft.current = selectedDraft;
  }, [selectedDraft]);
};
