import { createSelector } from 'reselect';
import {
  identity, pathOr, propEq, filter, anyPass,
} from 'ramda';

export const getFontsRequestPending = createSelector(
  pathOr(true, ['pending', 'getFontsRequest']),
  identity,
);

export const getFonts = createSelector(
  pathOr([], ['fonts', 'data']),
  identity,
);

export const getSelectedFonts = createSelector(
  getFonts,
  filter(propEq('selected', 1)),
);

export const getFontsFreshStatus = createSelector(
  pathOr(false, ['fonts', 'isFresh']),
  identity,
);

export const getChangeFontsPending = createSelector(
  anyPass([
    pathOr(false, ['pending', 'unselectFontRequest']),
    pathOr(false, ['pending', 'selectFontRequest']),
  ]),
  identity,
);
