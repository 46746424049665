import { handleActions } from 'redux-actions';
import {
  compose, prop, objOf, path,
} from 'ramda';

import { mergeIn } from 'utils/helpers/ramdaStateHelpers';

import types from './types';

const reducer = handleActions({
  [types.SET_USER]: mergeIn(prop('payload')),
  [types.UPDATE_USER]: mergeIn(compose(path(['payload', 'data']))),
  [types.SET_AUTHENTICATED]: mergeIn(compose(
    objOf('isAuthenticated'),
    prop('payload'),
  )),
}, {
  user: null,
  isAuthenticated: !!localStorage.getItem('authToken'),
});

export default reducer;
