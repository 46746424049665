import React from 'react';
import { Cell, Row, Icon } from 'ui-kit';
import { getFileUrl } from 'utils/helpers';
import { notify } from 'utils/helpers/notificationHelpers';
import { CopyButton, Title, Url } from './DownloadItem.styled';
import { DEFAULT_PADDING, WIDTHS } from './constants';

const handleCopyLink = (videoFileId) => () => {
  navigator.clipboard.writeText(getFileUrl({ fileUrl: `files/${videoFileId}`, isUseSalt: true }));
  notify.success('Link was copied to clipboard!');
};

const CELLS = [
  {
    name: 'title',
    render: (data) => (<Title>{data.title}</Title>),
    textAlign: 'center',
    padding: DEFAULT_PADDING,
    width: WIDTHS.TITLE,
  },
  {
    name: 'video_file_id',
    render: (data) => (<Url>{getFileUrl({ fileUrl: `files/${data.video_file_id}`, isUseSalt: true })}</Url>),
    padding: DEFAULT_PADDING,
    width: WIDTHS.URL,
  },
  {
    name: 'copy',
    render: (data) => (
      <CopyButton onClick={handleCopyLink(data.video_file_id)}>
        <Icon name="link" />
      </CopyButton>
    ),
    textAlign: 'center',
    padding: DEFAULT_PADDING,
    width: WIDTHS.LINK,
  },
];

export const DownloadItem = ({ data }) => {
  return (
    <Row>
      {CELLS.map(({ name, render, ...props }) => (
        <Cell
          key={name}
          {...props}
        >
          {render ? render(data) : data[name]}
        </Cell>
      ))}
    </Row>
  );
};
