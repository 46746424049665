import styled from 'styled-components';

export const Navigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 92px;
  padding: 12px 0 21px;
  box-sizing: border-box;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.LIGHT};
  box-shadow: 0px -0.5px 0px ${({ theme }) => theme.colors.BLACK};
  display: block;
`;

export const List = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    svg {
      .cls-1 {
        fill: rgba(255, 255, 255, 0);
        stroke: rgba(0, 0, 0, 0);
      }

      .cls-2 {
        fill: ${({ theme }) => theme.colors.DARK_GRAY};
      }

      .cls-3 {
        stroke: none;
      }

      .cls-4 {
        fill: none;
      }
    }
    
    &.active {
      svg .cls-2 {
        stroke: ${({ theme }) => theme.colors.ACTIVE_MAIN};
      }
      span {
        color: ${({ theme }) => theme.colors.ACTIVE_MAIN};
      }
    }
    
    span {
      width: 100%;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: -0.24px;
      color: ${({ theme }) => theme.colors.DARK_GRAY};
      word-break: break-word;
    }
  }
`;
