import React from 'react';
import { formatSeconds, addZeroBeforeNumber } from 'utils/helpers/videoTrimmerHelpers';
import { Container } from './TimeStamp.styled';

/**
 *
 * @param time
 * @param current
 * @param noMicroSeconds
 * @returns {JSX.Element}
 * @constructor
 */

export const TimeStamp = ({ time, isCurrent, noMicroSeconds }) => {
  const [minutes, seconds, milliseconds] = formatSeconds(time);
  return (
    <Container isCurrent={isCurrent}>
      <span>{minutes}</span>
      <span>
        :
        {addZeroBeforeNumber(seconds, 2)}
      </span>
      {!noMicroSeconds && (
        <span>
          :
          {addZeroBeforeNumber(milliseconds, 2)}
        </span>
      )}
    </Container>
  );
};
