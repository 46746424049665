export const ERROR_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

const ERRORS = {
  [ERROR_CODES.NOT_FOUND]: {
    title: 'Page Not Found',
    subTitle: 'The page you are looking for doesn\'t exist or has been moved',
  },
  [ERROR_CODES.FORBIDDEN]: {
    title: 'Access Forbidden',
    subTitle: 'You do not have permission for the page you are looking for',
  },
};

export const SHARING_ERRORS = {
  PASSWORD_IS_INCORRECT: 'Incorrect password',
};

export const CSV_ERRORS = {
  CSV_UPLOAD_FAILED: 'CSV File upload FAILED',
  CSV_TO_DRAFT_ERROR: 'CSV to drafts conversion FAILED',
};

export const RENDERING_ERRORS = {
  RENDERING_FAILED: 'Rendering FAILED',
};

export const ANIMATIONS_ERRORS = {
  FETCH_FAILED: 'Fetching of the animation config FAILED',
};

export const SET_FILES_ERRORS = {
  AUDIO_FAILED: 'There was an issue with your request.',
  IMAGE_FAILED: 'There was an issue with your request.',
  VIDEO_FAILED: 'There was an issue with your request.',
};

export default ERRORS;
