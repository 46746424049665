import styled from 'styled-components';

export const Url = styled.span`
  width: 350px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CopyButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Title = styled.span`
  display: inline-block;
  width: 190px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
