export default {
  USER_LOGOUT: 'USER_LOGOUT',
  SET_USER: 'SET_USER',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',

  GET_CURRENT_USER_REQUEST: 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_ERROR: 'GET_CURRENT_USER_ERROR',

  UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER: 'UPDATE_USER',
  GENERATE_ZAPIER_KEY_REQUEST: 'GENERATE_ZAPIER_KEY_REQUEST',
};
