import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { features, core } from 'utils/middlewares';
import { sagasManager } from 'utils';
import requestApi from 'utils/middlewares/sagaRequestApi';
import reducers from 'state';
import { getApiUrl } from 'utils/helpers/requestHelpers';
import { authTypes } from './state/user';

const sagaMiddleware = createSagaMiddleware();

const resetEnhancer = (rootReducer) => (state, action) => {
  if (action.type === authTypes.SET_AUTHENTICATED && !action.payload) {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const store = createStore(
  resetEnhancer(reducers()),
  composeWithDevTools(
    applyMiddleware(
      sagaMiddleware,
      ...core,
      ...features,
      requestApi({
        baseUrl: getApiUrl(),
      }),
    ),
  ),
);

sagaMiddleware.run(sagasManager.getRootSaga());

export default store;
