import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getImageRef } from 'state/editor/selectors';
import { FILE_TYPES } from 'constants/files';
import { changeAssetUrl } from 'state/animation/actions';
import { setImageFileType } from 'state/editor/actions';
import { usePrevValue } from 'hooks';

export const useGif = ({ asset, refId }) => {
  const dispatch = useDispatch();
  const imageRef = useSelector((store) => getImageRef(store)(refId));
  const prevValue = usePrevValue(imageRef?.value);
  const isGif = imageRef?.fileType === FILE_TYPES.GIF;

  useEffect(() => {
    if (isGif && imageRef.isPreparingGif && prevValue !== imageRef?.value) {
      dispatch(setImageFileType({ refId, fileType: imageRef.fileType, isPreparingGif: false }));
      dispatch(changeAssetUrl([asset.ref.id, imageRef.value]));
    }
  }, [imageRef, asset]);
};
