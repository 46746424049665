import * as yup from 'yup';
import {
  keyValueArrayFieldSchema, numberSchema, stringSchema, urlSchema,
} from 'utils/validation';

const rules = yup.object().shape({
  type: stringSchema({ label: 'Type' }),
  status: numberSchema('Status'),
  url: urlSchema(),
  get_fields: keyValueArrayFieldSchema(),
  headers: keyValueArrayFieldSchema(),
  body_fields: keyValueArrayFieldSchema(),
});

export default rules;
