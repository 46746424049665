import React, {
  useCallback, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '@react-hook/window-size';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'ui-kit';
import { convertBytesToSize, notify, validateFileSize } from 'utils/helpers';
import { useClickOutside, useTrackEvent } from 'hooks';
import { camera } from 'assets/images';
import { VIDEO_FILE_TYPES } from 'constants/files';
import { getImageRef } from 'state/editor/selectors';
import { editorActions } from 'state/editor';
import { SCALE_IMAGES } from 'constants/editor';
import { IMAGE_MAX_SIZE, IMAGE_MAX_SIZE_BYTES } from 'constants/fileSizes';
import { IMAGE_TYPES } from 'constants/animationsPath';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import {
  Body,
  Container,
  FileImage,
  FileInfoBlock,
  FileItem,
  FileName,
  FileSize,
  IconButton,
  Sidebar,
  SidebarItem,
  SidebarWrapper,
  StyledIcon,
  TextBlock, Wrapper,
} from './FileModal.styled';
import { DropZone } from './DropZone';
import { UrlUploading } from './UrlUploading';
import { VideoTrimmer } from '../VideoTrimmer';

const MODAL_TABS = {
  DRAG_AND_DROP: 1,
  URL: 2,
};

const SIDEBAR_ITEMS = [
  {
    id: MODAL_TABS.DRAG_AND_DROP,
    iconName: 'paperclip',
    iconDimensions: {
      width: 20,
      height: 20,
    },
    text: 'My device',
  },
  {
    id: MODAL_TABS.URL,
    iconName: 'link-line',
    iconDimensions: {
      width: 22,
      height: 22,
    },
    text: 'Link (URL)',
  },
];

const PHONE_SCREEN_RESOLUTION = 767;

/**
 *
 * @param isOpened {boolean}
 * @param onClose {function}
 * @param file {File | null}
 * @param setFile {function}
 * @param onUploadFile {function}
 * @param refId {string}
 * @param cropperRef {object}
 * @param setCropData {function}
 * @param onZoom {function}
 * @returns {JSX.Element}
 * @constructor
 */

const TIMEOUT = 4000;

export const FileModal = ({
  isOpened, onClose, file, setFile,
  onUploadFile, refId, cropperRef, setCropData, onZoom,
}) => {
  const screenWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const imageRef = useSelector((store) => getImageRef(store)(refId));
  const { handleTrack } = useTrackEvent();

  const [activeTab, setActiveTab] = useState(MODAL_TABS.DRAG_AND_DROP);
  const [isOpenedVideoTrimmer, setIsOpenedVideoTrimmer] = useState(false);
  const ref = useRef(null);

  const handleChangeTab = useCallback((newTabId) => () => {
    setActiveTab(newTabId);
  }, []);

  const handleRemoveFile = () => {
    setFile(null);
  };

  const isVideoFile = VIDEO_FILE_TYPES.includes(file?.type);

  const handleUpload = useCallback(() => {
    if (isVideoFile && screenWidth <= PHONE_SCREEN_RESOLUTION) {
      return;
    }

    if (file?.type.startsWith('image')) {
      // todo: make one action for it in the future
      dispatch(editorActions.setImageVideoFileId({ refId, fileId: null }));
      dispatch(editorActions.setImageVideoTempId({ refId, tempId: null }));
      dispatch(editorActions.setImageFileType({ refId, fileType: null }));
    }

    if (isVideoFile) {
      setIsOpenedVideoTrimmer(true);
    } else if (imageRef.layerType === IMAGE_TYPES.BACKGROUND) {
      dispatch(editorActions.setBackgroundImageToDefault({
        refId,
        resetOptions: {
          zoom: [SCALE_IMAGES.DEFAULT, SCALE_IMAGES.MIN, SCALE_IMAGES.MAX],
        },
      }));
    }

    if (imageRef.hasAudio) {
      dispatch(editorActions.setAudioFileInfo(null));
      dispatch(editorActions.setHasAudioStatus({ refId, hasAudio: false }));
    }
    dispatch(editorActions.setOriginalImageId({ refId, fileId: null }));
    return validateFileSize({
      onSuccess: (newFile) => {
        onUploadFile(newFile);
        if (cropperRef.current?.getData()) {
          // todo: hotfix, should be refactored with cropper component
          setTimeout(() => {
            cropperRef.current?.cropper.reset();
            setCropData(cropperRef.current?.getData());
            onZoom(1.05);
          }, TIMEOUT);
        }
        if (newFile?.type.startsWith('image')) {
          handleTrack(MIXPANEL_EVENTS.ADD_IMAGE);
        }
        onClose();
      },
      onError: () => notify.error(t(`Maximum allowed file size is ${IMAGE_MAX_SIZE}Mb`)),
      maxSize: IMAGE_MAX_SIZE_BYTES,
    })(file);
  }, [file, refId, isVideoFile]);

  useClickOutside({
    ref,
    callback: onClose,
  });

  return (
    <>
      <Modal
        height="450px"
        width="100%"
        maxWidth="700px"
        padding="0"
        isOpen={isOpened}
      >
        <Container ref={ref}>
          <SidebarWrapper>
            <Sidebar>
              {SIDEBAR_ITEMS.map(({
                id, iconName, iconDimensions, text,
              }) => (
                <SidebarItem onClick={handleChangeTab(id)} key={id} isActive={activeTab === id}>
                  <StyledIcon name={iconName} {...iconDimensions} isActive={activeTab === id} />
                  <TextBlock>
                    <span>{t(text)}</span>
                  </TextBlock>
                </SidebarItem>
              ))}
            </Sidebar>
          </SidebarWrapper>
          <Body>
            <Wrapper>
              {activeTab === MODAL_TABS.DRAG_AND_DROP && (<DropZone setFile={setFile} />)}
              {activeTab === MODAL_TABS.URL && (<UrlUploading setFile={setFile} />)}
            </Wrapper>
            {file && (
              <>
                <FileItem>
                  <FileImage
                    src={VIDEO_FILE_TYPES.includes(file.type) ? camera : URL.createObjectURL(file)}
                    alt="Preview"
                  />
                  <FileInfoBlock>
                    <FileName>{file.name}</FileName>
                    <FileSize>{convertBytesToSize(file.size || 0)}</FileSize>
                  </FileInfoBlock>
                  <IconButton type="button" onClick={handleRemoveFile}>
                    <StyledIcon name="close" width={20} height={20} />
                  </IconButton>
                </FileItem>
                <Button
                  type="button"
                  title={t('Upload')}
                  label={t('Upload')}
                  width={102}
                  height={46}
                  onClick={handleUpload}
                />
              </>
            )}
          </Body>
        </Container>
      </Modal>
      {isOpenedVideoTrimmer && (
      <VideoTrimmer
        file={file}
        refId={refId}
        onClose={setIsOpenedVideoTrimmer}
      />
      )}
    </>
  );
};
