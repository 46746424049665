import styled from 'styled-components';
import { flexbox, layout } from 'styled-system';
import { space } from '@styled-system/space';
import { Link } from 'react-router-dom';

export const RootStyle = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.EXTRA_LIGHT};
`;

export const Container = styled.div`
  width: 435px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  ${layout};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
`;

export const StyledLink = styled(Link)`
  margin-bottom: 10px;
  text-align: center;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow: 0 2px 4px rgba(222, 222, 222, 1);
  padding: 30px 5px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  ${space};
`;

export const AdditionalText = styled.span`
  margin: 20px 0;
  text-align: center;
  font-size: 15px;
  ${space};
`;

export const AdditionalLink = styled(Link)`
  color: ${({ theme }) => theme.colors.MAIN};
  text-decoration: none;
  transition-duration: .25s;
  &:hover {
    text-decoration: underline
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media(${({ theme }) => theme.devices.SM}) {
    padding-top: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
  }
  ${space}
  ${layout}
  ${flexbox}
`;

export const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.MAIN};
  text-decoration: none;
  transition-duration: .25s;
  &:hover {
    text-decoration: underline
  }
`;

export const SignUpInfo = styled.span`
  width: 100%;
  display: inline-block;
  margin: 21px 0 21px;
  text-align: center;
  color: ${({ theme }) => theme.colors.MAIN};
  font-size: 21px;
  font-weight: 600;
  @media(${({ theme }) => theme.devices.SM}) {
    margin: 24px 0 50px;
    font-size: 32px;
  }
`;
