import React from 'react';

import { icons } from 'constants/icons';
import { Wrapper } from './Icon.styled';

/**
 *
 * @param name {string}
 * @param color {string}
 * @param height {number}
 * @param width {number}
 * @param stroke {string}
 * @param fill {string}
 * @param rest {object}
 * @returns {JSX.Element}
 * @constructor
 */
export const Icon = ({
  name, color, height, width, stroke, fill, ...rest
}) => {
  const Svg = icons[name];

  return (
    <Wrapper color={color} height={height} width={width} {...rest}>
      <Svg
        height={height}
        width={width}
        color={color}
        {...(stroke ? { stroke } : {})}
        {...(fill ? { fill } : {})}
      />
    </Wrapper>
  );
};

Icon.defaultProps = {
  width: 24,
  height: 24,
  color: '#000',
};
