import { API_METHODS } from 'constants/common';
import { ROUTES } from 'constants/api.routes';
import { catchError } from 'utils/helpers';
import { apiService } from './api.service';

export const webhookService = {
  getWebhooks: async (params = {}) => catchError(apiService({
    method: API_METHODS.GET,
    subUrl: ROUTES.WEBHOOKS.ROOT,
    config: { params },
  })),
  createWebhook: async (data = {}) => catchError(apiService({
    method: API_METHODS.POST,
    subUrl: ROUTES.WEBHOOKS.ROOT,
    data,
  })),
  updateWebhook: async (id, data = {}) => catchError(apiService({
    method: API_METHODS.PUT,
    subUrl: `${ROUTES.WEBHOOKS.ROOT}/${id}`,
    data,
  })),
  deleteWebhook: async (id) => catchError(apiService({
    method: API_METHODS.DELETE,
    subUrl: `${ROUTES.WEBHOOKS.ROOT}/${id}`,
  })),
  testWebhook: async (data) => catchError(apiService({
    method: API_METHODS.POST,
    subUrl: ROUTES.WEBHOOKS.TEST,
    data,
  })),
  fetchWebhookParams: async () => catchError(apiService({
    method: API_METHODS.GET,
    subUrl: ROUTES.WEBHOOKS.PARAMS,
  })),
};
