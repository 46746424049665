export const MIXPANEL_EVENTS = {
  SIGN_IN: 'Sign in',
  EDIT_TEXT: 'Edit text',
  ADD_IMAGE: 'Add image',
  CHANGE_ASPECT: 'Try versions',
  UPLOAD_VIDEO: 'Upload video',
  CHOOSE_DRAFT: 'Choose other Tumplates',
  EXIT_NO_EXPORT: 'Goes away from the site without exports',
  EXPORT: 'Export',
};
