import React, { useCallback, useState } from 'react';
import {
  RootStyle, Header, Body, Triangle, Title,
} from './Accordion.styled';
/**
 * @param title {string}
 * @param children {React.ReactNode}
 * @param bodyStyles {object} styles for body
 * @returns {JSX.Element}
 * @constructor
 */

export const Accordion = ({
  children, title, bodyStyles = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTab = useCallback(() => setIsOpen((prevState) => !prevState), []);

  return (
    <RootStyle>
      <Header
        role="presentation"
        onClick={toggleTab}
      >
        <Triangle isOpened={isOpen} />
        <Title>{title}</Title>
      </Header>
      <Body isOpened={isOpen} {...bodyStyles}>
        {children}
      </Body>
    </RootStyle>
  );
};
