import {
  delay, fork, put, select, take, takeLatest,
} from 'redux-saga/effects';
import sagasManager from 'utils/sagasManager';
import { editorActions, editorSelectors } from 'state/editor';
import {
  compose,
  equals,
  filter,
  find,
  forEach,
  head,
  isEmpty,
  path,
  pathOr,
  pickBy,
  prop,
  propEq,
  propOr,
  set,
  test,
  view,
  reduce,
} from 'ramda';
import * as animationsSelectors from 'state/animations/selectors';
import {
  ANIMATION_PATH_NAME, GROUP_LAYER, IMAGE_LAYER, TEXT_LAYER,
} from 'constants/animationsPath';
import { animationFramerate, animationOP, dataLayer } from 'utils/animationHelpers';
import { blobAutoRevoker, getApiUrl } from 'utils/helpers/requestHelpers';
import * as draftsActions from 'state/drafts/actions';
import * as draftsSelectors from 'state/drafts/selectors';
import draftsTypes from 'state/drafts/types';
import { CSV_STATUS, DRAFTS_STATUS, NONE_DRAFT } from 'constants/drafts';
import { notify } from 'utils/helpers/notificationHelpers';
import { cleanLatestWordOfString } from 'utils/helpers/commonHelpers';
import { prettierTheUrlPathToImages } from 'utils/helpers/filesHelper';
import { LottieInstance } from 'utils/helpers/lottieHelpers';
import { getCurrentRatioTitleByDimensions, getDimensionsBuCurrentRatio } from 'utils/animationHelpers/ratio';
import * as animationActions from './actions';
import * as animationSelectors from './selectors';
import animationTypes from './types';
import reorderAnimationData from './reorderAnimationDataFlow';
import { getLastParams } from '../videos/selectors';
import { getVideosRequest } from '../videos/actions';
import { fontsSelectors } from '../fonts';
import { companySelectors } from '../company';
import { colorsSelectors } from '../colors';
import * as colorsActions from '../colors/actions';
import * as fontsActions from '../fonts/actions';
import * as animationsActions from '../animations/actions';
import { getCustomLength } from '../editor/selectors';
import { imageLayerGroupsSelector } from './selectors';
import { getTopDrafts } from '../drafts/selectors';
import { changeDraftTemplate } from '../drafts/actions';

const verificationLayer = (substr) => compose(test(new RegExp(substr, 'g')), prop('nm'));

function* rewriteAnimationDataFlow() {
  yield takeLatest([animationTypes.REWRITE_ANIMATION_DATA], reorderAnimationData);
}

function* initAnimationDataFlow() {
  while (true) {
    const action = yield take(animationTypes.UPDATE_ANIMATION_DATA);
    const withHardReset = path(['payload', 'withHardReset'], action);
    const draftId = pathOr(false, ['payload', 'draftId'], action);
    const draftStatus = yield select(draftsSelectors.getPrepareStatus);
    const topDrafts = yield select(getTopDrafts);

    if (withHardReset) {
      yield put(editorActions.editorHardReset(withHardReset));
      yield put(animationActions.animationHardReset());
    }

    const version = yield select(editorSelectors.getEditorVersion);
    const selectedAnimation = yield select(animationsSelectors.getSelectedAnimation);
    const listOfTemplates = propOr([], 'template', selectedAnimation);
    let data = find(propEq('aspectratio', version), listOfTemplates);
    if (!data) {
      data = head(listOfTemplates);
      if (data) {
        yield put(editorActions.setVersion({
          version: data.aspectratio,
          isUpdateAnimation: false,
        }));
      }
    }

    if (!data && topDrafts.length > 0) {
      yield put(changeDraftTemplate({ target: { value: topDrafts[0].id } }));
    }

    const isNeededToGetAnimation = (data && !draftStatus) || parseInt(draftId, 10) === NONE_DRAFT.id;
    if (isNeededToGetAnimation) {
      if (!data) return;
      const { aspectratio } = data;
      yield put(
        animationActions.getAnimationDataRequest({
          name: selectedAnimation.name,
          ratio: aspectratio,
        }, { withHardReset }),
      );
    }
  }
}

function* filterFonts({ fonts }) {
  const fontsSelected = yield select(fontsSelectors.getFonts);
  const isEnabledFontPresets = yield select(companySelectors.getCompanyIsEnabledBrandFonts);
  const filterSelectedFonts = (item) => {
    if (isEnabledFontPresets) return true;
    let isSelected = false;
    forEach(({ description }) => {
      isSelected = false;
      forEach((font) => {
        if (equals(font.fName, item.fName)) {
          isSelected = true;
        }
      }, description);
    }, fontsSelected);
    return !isSelected;
  };
  const reduceUniqFonts = (accum, font) => (
    find(
      propEq('fName', font.fName),
      accum,
    )
      ? accum : [...accum, font]);

  const fontsAfterFiltered = compose(
    reduce(reduceUniqFonts, []),
    filter(filterSelectedFonts),
  )(fonts.list);

  yield put(editorActions.saveFonts(fontsAfterFiltered));
}

const assetsUrlNormalizer = (assets) => assets.map((asset) => {
  const assetUrl = asset.u;
  const resultAsset = asset;
  if (assetUrl && !assetUrl.startsWith('http') && !assetUrl.startsWith('data:')) {
    resultAsset.u = `${getApiUrl()}/dist/${assetUrl}`;
    if (!/path=/g.test(resultAsset.u)) {
      const url = /dist/.test(resultAsset.u) ? resultAsset.u : `${getApiUrl()}/dist/${resultAsset.u}`;
      resultAsset.u = prettierTheUrlPathToImages(`${url}${resultAsset.p}`);
      resultAsset.p = '';
    }
  }
  return resultAsset;
});

function* setAnimationDataFlow() {
  while (true) {
    const action = yield take([
      animationTypes.GET_ANIMATION_DATA_SUCCESS,
      draftsTypes.GET_SELECTED_DRAFT_DATA_SUCCESS,
    ]);
    const {
      payload: {
        data: result,
        meta,
      },
    } = action;
    const withHardReset = prop('withHardReset', meta);
    yield put(editorActions.resetImagesData({}));

    const animationData = result.data || result;
    const baseLength = animationSelectors.animationLengthSelector({ animation: animationData });
    const framerate = view(animationFramerate, animationData);
    const {
      selectedDraft, drafts,
      draftStatus, oldAnimationData, length,
    } = yield select((store) => ({
      selectedDraft: draftsSelectors.getSelectedDraft(store),
      drafts: draftsSelectors.getDrafts(store),
      draftStatus: draftsSelectors.getPrepareStatus(store),
      oldAnimationData: animationSelectors.animationDataSelector(store),
      length: animationSelectors.animationLengthSelector(store),
    }));

    if (withHardReset) {
      yield put(animationActions.animationHardReset());
    }

    yield filterFonts(animationData);

    const draft = drafts.find(({ id }) => selectedDraft && id === Number(selectedDraft));
    if (draft) {
      yield put(editorActions.setProjectName(draft.title));
      yield put(editorActions.setDescription(draft.description));
      yield put(editorActions.setCustomLength(draft.custom_length));
      yield put(editorActions.setVersion({ version: draft.animation_ratio }));
    }

    const customLength = yield select(getCustomLength);

    const data = compose(
      set(animationOP, baseLength * framerate),
      (anim) => ({
        ...anim,
        assets: assetsUrlNormalizer(anim.assets),
      }),
    )(animationData);
    const getNameAnimation = (animation) => cleanLatestWordOfString(pathOr('', ANIMATION_PATH_NAME, animation));
    const layers = prop('layers', data);
    const groups = pickBy(compose(verificationLayer(GROUP_LAYER)), layers);
    const texts = pickBy(compose(verificationLayer(TEXT_LAYER)), layers);
    const images = pickBy(compose(verificationLayer(IMAGE_LAYER)), layers);
    const tumplateData = view(dataLayer, data);
    const nameNewAnimation = getNameAnimation(animationData);
    const nameOldAnimation = getNameAnimation(oldAnimationData);

    const isColorsFresh = yield select(colorsSelectors.getColorsFreshStatus);
    const isFontsFresh = yield select(fontsSelectors.getFontsFreshStatus);
    const isAnimationsFresh = yield select(animationsSelectors.getAnimationsFreshStatus);
    if (!draft) {
      const currentRatioTitle = getCurrentRatioTitleByDimensions({ anim: animationData });
      yield put(editorActions.setVersion({
        version: currentRatioTitle,
        isUpdateAnimation: false,
      }));
    }

    if (!isEmpty(groups)) {
      // Suggest that only actual animations has text groups
      if ((!oldAnimationData || nameNewAnimation !== nameOldAnimation)
          || (!isColorsFresh || !isFontsFresh || !isAnimationsFresh)
          || prop('withHardReset', meta)
          || draftStatus === DRAFTS_STATUS.DELETING_DRAFT) {
        // If we change animation version we must not reset text fields and switchers ( except drafts )
        yield put(editorActions.saveData(tumplateData));
        yield put(editorActions.saveFonts(data.fonts.list));
        yield put(editorActions.saveTextLayers({ texts }));
        yield put(editorActions.saveTextGroups({ groups, texts }));
        yield put(editorActions.saveVisibleTextGroups(groups));
        yield put(editorActions.prepareImageEditFrames(images));
        yield put(draftsActions.setStatusDraft(null));
      } else {
        yield put(animationActions.rewriteAnimationData());
      }
    } else {
      notify.warning('The animation has old version');
    }
    yield put(animationActions.setAnimationData(data));
    if (draft) {
      const dimensions = getDimensionsBuCurrentRatio({ anim: animationData, ratio: draft.animation_ratio });
      if (dimensions) {
        yield put(animationActions.setAnimationDimensions({
          width: dimensions.width,
          height: dimensions.height,
        }));
      }
    }
    const groupsList = yield select(imageLayerGroupsSelector);
    if (!groupsList.length) {
      yield put(editorActions.setIsReady(true));
    }

    if (!isEmpty(groups)) {
      if (customLength || (selectedDraft && customLength)) {
        const immediatelySet = customLength === length || (selectedDraft && customLength);
        yield put(animationActions.setCustomLengthInAnimation({ customLength, immediatelySet }));
        if (!immediatelySet) {
          yield put(animationActions.setLength(baseLength));
        }
      } else {
        yield put(animationActions.setLength(baseLength));
      }
    }
    yield filterFonts(animationData);
    yield put(draftsActions.setStatusDraft(null));
    yield put(colorsActions.setColorsFreshStatus(true));
    yield put(fontsActions.setFontsFreshStatus(true));
    yield put(animationsActions.setAnimationsFreshStatus(true));
  }
}

function* changeAssetFlow() {
  while (true) {
    yield take([animationTypes.CHANGE_ASSET_URL,
      animationTypes.CHANGE_TINT_AMOUNT,
      animationTypes.CHANGE_TINT_COLOR]);
    // todo: always index usder lastEditFrame must be
    const { listEditFrames, lastEditFrame } = yield select((store) => ({
      listEditFrames: editorSelectors.getEditFramesList(store),
      lastEditFrame: editorSelectors.getLastEditFrame(store),
    }));
    yield delay();
    if (lastEditFrame || lastEditFrame === 0) {
      LottieInstance.goToAndStop(listEditFrames[lastEditFrame] || lastEditFrame);
    }
  }
}

function* downloadCsvFlow() {
  // todo: combine with getAnimationJsonRequest
  while (true) {
    const {
      payload: {
        version, projectName, outputCommand, outputUrl,
      },
    } = yield take(animationTypes.DOWNLOAD_CSV);
    const selectedAnimation = yield select(animationsSelectors.getSelectedAnimation);

    const data = yield find(
      propEq('aspectratio', version),
      propOr([], 'template', selectedAnimation),
    );

    yield put(draftsActions.createVideoDraft({
      title: `${projectName} - csv`,
      version: data?.aspectratio || version,
      length: data?.length || null,
      outputCommand,
      outputUrl,
      createDraftEndCb: ({ data: { model: { id } } }) => {
        sagasManager.addSagaToRoot(function* watcher() {
          yield put(animationActions.setCsvStatus(CSV_STATUS.PREPARING));
          yield put(draftsActions.getCsvByDraftRequest({ draftId: id },
            {
              name: selectedAnimation.name,
              ratio: data?.aspectratio || version,
            }));
        });
      },
    }));
  }
}

function* getAnimationCsvSuccessFlow() {
  while (true) {
    const {
      payload: { data, meta: { name, ratio } },
    } = yield take([animationTypes.GET_ANIMATION_CSV_SUCCESS,
      draftsTypes.GET_CSV_BY_DRAFT_SUCCESS]);

    const hiddenElement = document.createElement('a');
    const blob = new Blob([data], { type: 'data:application/octet-stream;base64' });
    const urlToBlob = URL.createObjectURL(blob);
    hiddenElement.href = urlToBlob;
    blobAutoRevoker.add('csvFile', urlToBlob);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${name}_ratio${ratio}_created-at${
      new Date().toLocaleDateString('en-US')}.csv`;
    hiddenElement.click();

    yield put(animationActions.setCsvStatus(null));
  }
}

function* uploadAnimationCsvSuccessFlow() {
  while (true) {
    const { payload: { data: { id } } } = yield take(animationTypes.UPLOAD_ANIMATION_CSV_SUCCESS);

    yield put(animationActions.createVideoByCsvRequest({ csv_file_id: id }));
    notify.success('Start converting csv to drafts');
  }
}

function* convertCSVToDraftsFlow() {
  while (true) {
    const { payload: { success } } = yield take(animationTypes.CONVERT_CSV_TO_DRAFTS_SUCCESS);
    if (success) {
      const selectedAnimation = yield select(animationsSelectors.getSelectedAnimation);
      const params = yield select(getLastParams);

      yield put(getVideosRequest(params));
      yield put(draftsActions.getVideoDraftsRequest({
        limit: 100,
        offset: 0,
        animation_name: prop('name', selectedAnimation),
      }));
    }
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(rewriteAnimationDataFlow);
  yield fork(setAnimationDataFlow);
  yield fork(initAnimationDataFlow);
  yield fork(changeAssetFlow);
  yield fork(downloadCsvFlow);
  yield fork(getAnimationCsvSuccessFlow);
  yield fork(uploadAnimationCsvSuccessFlow);
  yield fork(convertCSVToDraftsFlow);
});
