const DOTS = '...';
const FIRST_PAGE = 1;

/**
 *
 * @param start {number}
 * @param end {number}
 * @returns {unknown[]}
 */
export const makeRange = (start, end) => {
  // get length of array
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

/**
 *
 * @param totalCount {number}
 * @param itemsPerPage {number}
 * @param siblingCount {number}
 * @param currentPage {number}
 */

export const generatePagesArrayWithDots = ({
  totalCount,
  itemsPerPage,
  siblingCount,
  currentPage,
}) => {
  const totalPagesCount = Math.ceil(totalCount / itemsPerPage);

  // number of pages, which should be displayed (firstPage+lastPage+currentPage+2*dots+siblingCount)
  const totalPageNumbers = siblingCount + 5;

  /*
    If the number of pages is less than the page numbers we want to show in our
    paginationComponent, we return the range [1..totalPageCount]
  */
  if (totalPageNumbers >= totalPagesCount) {
    return makeRange(FIRST_PAGE, totalPagesCount);
  }

  // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount

  const leftSiblingIndex = Math.max(currentPage - siblingCount, FIRST_PAGE);
  const rightSiblingIndex = Math.min(
    currentPage + siblingCount,
    totalPagesCount,
  );

  /* We do not show dots just when there is just one page number to be inserted between
    the extremes of sibling and the page limits i.e 1 and totalPageCount.
    Hence, we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
  */
  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPagesCount - 2;
  // calculate count of the items, for show be
  const itemCountForShow = 3 + 2 * siblingCount;

  // Case 2: No left dots to show, but rights dots to be shown
  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftRange = makeRange(FIRST_PAGE, itemCountForShow);
    return [...leftRange, DOTS, totalPagesCount];
  }

  // Case 3: No right dots to show, but left dots to be shown
  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightRange = makeRange(
      totalPagesCount - itemCountForShow + 1,
      totalPagesCount,
    );
    return [FIRST_PAGE, DOTS, ...rightRange];
  }

  // Case 4: Both left and right dots to be shown
  if (shouldShowLeftDots && shouldShowRightDots) {
    const middleRange = makeRange(leftSiblingIndex, rightSiblingIndex);
    return [FIRST_PAGE, DOTS, ...middleRange, DOTS, totalPagesCount];
  }
  return [];
};
