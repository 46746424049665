import {
  fork, take, put, select,
} from 'redux-saga/effects';
import { contains } from 'ramda';

import sagasManager from 'utils/sagasManager';
import { notify } from 'utils/helpers/notificationHelpers';
import { getVideosResult, getLastParams } from 'state/videos/selectors';
import { getVideosRequest } from 'state/videos/actions';
import types from './types';
import { videosActions, videosSelectors } from '../videos';

function* videoReadyFlow() {
  while (true) {
    const { payload: video } = yield take(types.VIDEO_READY);
    const countOfRendering = yield select(videosSelectors.getCountRenderingFiles);
    yield put(videosActions.setCountOfRenderingFiles(countOfRendering - 1));
    switch (video.status) {
      case 3:
        notify.success(`${video.title} was rendered successfully`);
        break;
      case 4:
        notify.error(`Rendering of ${video.title} video FAILED`);
        break;
      default:
        yield put(videosActions.setCountOfRenderingFiles(countOfRendering + 1));
        break;
    }
    const videosIds = yield select(getVideosResult);
    if (contains(video.id, videosIds)) {
      const params = yield select(getLastParams);
      yield put(getVideosRequest(params));
    }
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(videoReadyFlow);
});
