import {
  take,
  fork,
  put,
} from 'redux-saga/effects';

import sagasManager from 'utils/sagasManager';
import requestTypes from './types';

function* apiRequestSuccessFlow() {
  while (true) {
    const {
      payload:
      { action: featureActionSuccess, data, meta },
    } = yield take(requestTypes.API_REQUEST_SUCCESS);
    yield put(featureActionSuccess({ ...data, meta }));
  }
}

function* apiRequestErrorFlow() {
  while (true) {
    const {
      payload:
        { action: featureActionError, error },
    } = yield take(requestTypes.API_REQUEST_ERROR);
    yield put(featureActionError(error));
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(apiRequestSuccessFlow);
  yield fork(apiRequestErrorFlow);
});
