import styled from 'styled-components';

export const FormContainer = styled.form`
  position: relative;
  width: 100%;
  min-height: 130px;
`;

export const InfoProgress = styled.div`
  width: 100%;
  transform: translateY(-35px);
  font-size: 14px;
  font-weight: 300;
  text-align: center;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  margin-top: auto;
  a {
    min-width: 102px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.CANCEL};
    border: 1px solid ${({ theme }) => theme.colors.CANCEL};
    color: ${({ theme }) => theme.colors.WHITE};
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    text-decoration: none;
    :hover {
      opacity: 0.7;
    }
  }
`;
