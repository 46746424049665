import { createActions } from 'redux-actions';

import types from './types';

export const {
  webhookCreateRequest,
  webhookCreateSuccess,
  webhooksGetRequest,
  webhooksGetSuccess,
  webhookUpdateRequest,
  webhookUpdateSuccess,
  webhookDeleteRequest,
  webhookDeleteSuccess,
  webhookRequestFailure,
  getWebhookParamsRequest,
  getWebhookParamsSuccess,
} = createActions(
  types.WEBHOOK_CREATE_REQUEST,
  types.WEBHOOK_CREATE_SUCCESS,
  types.WEBHOOKS_GET_REQUEST,
  types.WEBHOOKS_GET_SUCCESS,
  types.WEBHOOK_UPDATE_REQUEST,
  types.WEBHOOK_UPDATE_SUCCESS,
  types.WEBHOOK_DELETE_REQUEST,
  types.WEBHOOK_DELETE_SUCCESS,
  types.WEBHOOK_REQUEST_FAILURE,
  types.GET_WEBHOOK_PARAMS_REQUEST,
  types.GET_WEBHOOK_PARAMS_SUCCESS,
);
