import styled from 'styled-components';

export const DraggerShell = styled.div`
  position: absolute;
  height: 40px;
  width: 9px;
  top: -2px;
  left: -4px;
  border-radius: 4px;
  z-index: 6;
  cursor: col-resize;

  &.startpoint {
    background: ${({ theme }) => theme.colors.SUCCESS};
  }
  
  &.endpoint {
    background: ${({ theme }) => theme.colors.RED};
  }

  &:hover {
    background: #333;
  }
  
  &:focus {
    outline: none
  }

  &.trimmer_currentTime{
    z-index: 5;
    width: 6px;
    transform: translate(-4px, -2px);
    background: ${({ theme }) => theme.colors.MAIN};
  
    &::after {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      transform: translate(-4px, -11px);
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.MAIN};
    }
  }
`;
