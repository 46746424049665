import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Controller, FormProvider, useForm, useFormContext, useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import 'rc-time-picker/assets/index.css';
import { PRELOADER_TYPES } from 'constants/ui';
import {
  Button, Modal, Preloader, Accordion, Input,
} from 'ui-kit';
import {
  getPrepareStatus as getDraftPrepareStatus, getDraft,
} from 'state/drafts/selectors';
import { getPrepareStatus as getVideoPrepareStatus } from 'state/videos/selectors';
import { RENDER_TYPES_FOR_API, TYPES_WITH_FFMPEG_SETTINGS } from 'constants/rendering';
import { editorActions } from 'state/editor';
import { changeIsMakingExport, createVideo } from 'state/videos/actions';
import { makeFileNameEnd } from 'utils/helpers/renderHelpers';
import { useEditor, useTrackEvent } from 'hooks';
import { COLORS } from 'constants/styles';
import { videoService } from 'api';
import { selectCompanyImageType } from 'state/company/selectors';
import { selectSelectedAnimationName } from 'state/animations/selectors';
import { yupResolver } from '@hookform/resolvers/yup';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import { RenderOptions } from './RenderOptions';
import { TopFields } from './TopFields';
import { ConfirmRenderModal } from './ConfirmRenderModal';
import { FormContainer, InfoProgress, Row } from './SaveFileModal.styled';
import { rules } from './rules';

const PRELOADER_DELAY = 400;

const LABEL_STYLE_PROPS = {
  fontSize: '14px',
};

const INPUT_STYLE_PROPS = {
  borderRadius: '21px',
  fontSize: '14px',
  height: '40px',
  borderColor: COLORS.BORDER_COLOR,
  paddingLeft: '28px',
  paddingRight: '28px',
};

const DEFAULT_VALUE = {
  projectName: '',
  description: '',
  formats: [],
  gifFps: null,
  outputCommand: '',
  outputUrl: '',
  ffmpegSettings: '',
};

/**
 * @param onOpenZapierModal {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const SaveFileModal = ({ onOpenZapierModal }) => {
  const {
    isOpenFileModal,
    setIsOpenFileModal,
  } = useEditor();

  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const { t } = useTranslation(['account', 'common', 'forms']);

  const draftPreparingStatus = useSelector(getDraftPrepareStatus);
  const videoPreparingStatus = useSelector(getVideoPrepareStatus);
  const animationName = useSelector(selectSelectedAnimationName);
  const imageFormat = useSelector(selectCompanyImageType);
  const selectedDraft = useSelector(getDraft);

  const [isPending, setIsPending] = useState(false);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [isValidated, setIsValidated] = useState(true); // it is ok, if we have empty ffmpeg_settings
  const [isOpenedConfirmRenderModal, setIsOpenedConfirmRenderModal] = useState(false);
  const [isRenderingConfirmed, setIsRenderingConfirmed] = useState(false);
  const [idsForDelete, setIdsForDelete] = useState([]);
  const { handleTrack } = useTrackEvent();

  const onClose = () => setIsOpenFileModal(false);

  const [
    projectName, description, renderType, length, version,
  ] = getValues([
    'projectName', 'description', 'renderType', 'length', 'version',
  ]);

  const form = useForm({
    defaultValues: DEFAULT_VALUE,
    resolver: yupResolver(rules),
  });

  const formats = useWatch({
    control: form.control,
    name: 'formats',
    defaultValue: [],
  });

  const [
    internalProjectName, outputCommand, ffmpegSettings, gifFps, outputUrl,
  ] = form.getValues([
    'projectName', 'outputCommand', 'ffmpegSettings', 'gifFps', 'outputUrl',
  ]);

  const renderConfigsList = useMemo(() => [...new Set(formats)]
    .map((format) => ({
      type: format,
      name: internalProjectName,
      output_command: outputCommand,
      ffmpeg_settings: ffmpegSettings,
      version,
      length,
      createVideoEndCb: () => {
        onClose();
        setIsPending(false);
      },
      ...(format === RENDER_TYPES_FOR_API.keyframesGif
        ? { gif_fps: parseFloat(gifFps) }
        : {}
      ),
      ...(format === RENDER_TYPES_FOR_API.htmlBanner
        ? { output_url: outputUrl }
        : {}
      ),
    })), [formats, internalProjectName, outputCommand, ffmpegSettings, version, gifFps, outputUrl]);

  const onRenderRun = useCallback(async (formValues) => {
    try {
      const { data } = await videoService.getVideos({
        title: formValues.projectName,
        type: formats,
        duration: length,
        duplicates: 1,
        animation_name: animationName,
        animation_ratio: version,
        image_format: imageFormat,
      });
      setIsPending(true);
      if (data.count === 0) {
        setIsRenderingConfirmed(true);
      } else {
        setIsOpenedConfirmRenderModal(true);
        const ids = data.data.map((video) => video.id);
        setIdsForDelete(ids);
      }
    } catch (e) {
      console.log(e, 'Issue with getting the same videos');
    }
  }, [length, version, internalProjectName, renderConfigsList]);

  const isSendingForm = videoPreparingStatus && !draftPreparingStatus;
  const isClickingOutsideClose = isSendingForm || !isOpenedConfirmRenderModal;

  useEffect(() => {
    const values = {
      projectName,
      description,
      formats: [renderType],
      gifFps: 2,
      outputCommand: selectedDraft?.output_command || '',
      outputUrl: selectedDraft?.output_url || '',
      ffmpegSettings: '',
    };
    form.reset(values);
    return () => form.reset(DEFAULT_VALUE);
  }, [projectName, description, renderType, selectedDraft]);

  useEffect(() => {
    const areTypesForRendering = formats?.some((
      format,
    ) => TYPES_WITH_FFMPEG_SETTINGS.includes(format));
    if (areTypesForRendering && !isValidated) {
      setIsDisabledSubmit(true);
    } else {
      setIsDisabledSubmit(false);
    }
  }, [formats, isValidated]);

  useEffect(() => {
    if (isRenderingConfirmed) {
      setIsPending(true);
      dispatch(editorActions.setProjectName(internalProjectName));
      dispatch(createVideo({
        name: internalProjectName,
        length,
        version,
        renderConfigsList,
        outputCommand,
        outputUrl,
      }));
      setIsRenderingConfirmed(false);
      handleTrack(MIXPANEL_EVENTS.EXPORT, {
        aspectRatio: version,
        formats: [...new Set(formats)].join(', '),
      });
      dispatch(changeIsMakingExport(true));
    }
  }, [internalProjectName, length, version, renderConfigsList, isRenderingConfirmed]);

  useEffect(() => {
    reset({
      ...getValues(),
      outputCommand,
      outputUrl,
    });
  }, [outputCommand, outputUrl]);

  return (
    <>
      <FormProvider {...form}>
        <Modal
          isOpen={isOpenFileModal}
          onClose={onClose}
          afterClickingOutsideClose={isClickingOutsideClose}
          marginBottom="75px"
        >
          <FormContainer
            onSubmit={form.handleSubmit(onRenderRun)}
          >
            <Preloader
              isLoading={videoPreparingStatus}
              height="100%"
              preloaderChildren={(
                <InfoProgress>
                  {draftPreparingStatus ? t('Just a moment preparing export') : t('Finishing up...')}
                </InfoProgress>
                  )}
              type={PRELOADER_TYPES.CONTAINER}
              delay={PRELOADER_DELAY}
            >
              <TopFields
                endFileName={makeFileNameEnd(version, length)}
                setIsValidated={setIsValidated}
              />
              <Accordion
                title={t('More formats')}
                bodyStyles={{
                  paddingLeft: '90px',
                }}
              >
                <RenderOptions />
              </Accordion>
              <Accordion
                title={t('Advanced options')}
                bodyStyles={{
                  alignItems: 'center',
                }}
              >
                <Controller
                  name="outputCommand"
                  control={form.control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label={t('Add command...')}
                      type="text"
                      name="outputCommand"
                      id="zapierCommand"
                      value={value}
                      onChange={onChange}
                      width="80%"
                      labelStyleProps={LABEL_STYLE_PROPS}
                      inputStyleProps={INPUT_STYLE_PROPS}
                    />
                  )}
                />
                <Button
                  buttonTheme="primary"
                  onClick={onOpenZapierModal}
                  title="Generate new zapier key"
                  label="New"
                  width={50}
                  height={40}
                  marginLeft="8px"
                  marginTop="20px"
                >
                  {t('New')}
                </Button>
              </Accordion>
              <Row>
                <Button
                  buttonTheme="submit"
                  width={102}
                  height={36}
                  color={COLORS.CANCEL}
                  label={t('Cancel')}
                  onClick={onClose}
                />
                <Button
                  type="submit"
                  buttonTheme="submit"
                  isDisabled={isPending || isDisabledSubmit}
                  width={102}
                  height={36}
                  marginLeft="20px"
                  label={t('Save')}
                />
              </Row>
            </Preloader>
          </FormContainer>
        </Modal>
      </FormProvider>
      <ConfirmRenderModal
        isOpen={isOpenedConfirmRenderModal}
        setIsOpen={setIsOpenedConfirmRenderModal}
        confirmRendering={setIsRenderingConfirmed}
        idsForDelete={idsForDelete}
      />
    </>
  );
};
