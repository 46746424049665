import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toBlobUrl } from 'utils/helpers/requestHelpers';
import { changeAssetUrl } from 'state/animation/actions';
import { BEGIN_FILE } from 'constants/files';

/**
 *
 * @param value {string}
 * @param cropperRef {object}
 * @param asset {object}
 * @param isDisabled {boolean}
 * @param refId {string}
 */

export const useFetchRefs = ({
  value, cropperRef, asset, isDisabled, refId,
}) => {
  const dispatch = useDispatch();

  const handleChangeAssetUrl = useCallback((imageUrl) => {
    toBlobUrl(
      imageUrl,
      (url) => {
        dispatch(changeAssetUrl([asset.ref.id || '', isDisabled ? '' : url]));
        if (cropperRef.current?.cropper) {
          cropperRef.current.cropper.replace(url);
        }
      },
      `fetching_${refId}`,
    );
  }, [refId, asset, isDisabled, cropperRef.current]);

  // need to ask do we need to restart anim after upload gif
  useEffect(() => {
    if (value && cropperRef.current) {
      if (value.startsWith(BEGIN_FILE.DATA)) cropperRef.current.cropper.replace(value);
      else handleChangeAssetUrl(value); // blob and other
    }
  }, [value]);
};
