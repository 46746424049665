import React, { useCallback, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'ui-kit';
import { RENDER_TYPES_FOR_API, TYPES_WITH_FFMPEG_SETTINGS } from 'constants/rendering';
import { ffmpegValidationRequest } from 'state/videos/actions';
import { notify } from 'utils/helpers/notificationHelpers';
import { isEmpty } from 'ramda';
import { debounceFunc } from 'utils/helpers/commonHelpers';
import { EndFileName, Wrapper } from './TopFields.styled';
import { COLORS } from '../../../../constants/styles';

const TIMEOUT = 500;
const FFMPEG_VALIDATION = 'FFMPEG_VALIDATION';

const LABEL_STYLE_PROPS = {
  fontSize: '14px',
};

const INPUT_STYLE_PROPS = {
  borderRadius: '21px',
  fontSize: '14px',
  height: '45px',
  borderColor: COLORS.BORDER_COLOR,
  paddingLeft: '28px',
  paddingRight: '28px',
};

/**
 *
 * @param endFileName {string}
 * @param setIsValidated {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const TopFields = ({ endFileName, setIsValidated }) => {
  const { t } = useTranslation(['account', 'common', 'forms']);
  const form = useFormContext();
  const dispatch = useDispatch();

  const formats = useWatch({
    control: form.control,
    name: 'formats',
    defaultValue: [],
  });

  const handleChangeFfmpegSettings = useCallback((value) => {
    if (isEmpty(value)) setIsValidated(true);
    else debounceFunc(() => setIsValidated(false), TIMEOUT, FFMPEG_VALIDATION);
  }, [setIsValidated]);

  const isFfmpegSettings = useMemo(() => formats.some((format) => TYPES_WITH_FFMPEG_SETTINGS.includes(format)), [formats]);
  const isBanner = useMemo(() => formats.some((format) => format === RENDER_TYPES_FOR_API.htmlBanner), [formats]);

  const firstNeededTypeForValidation = useMemo(() => formats.find((format) => TYPES_WITH_FFMPEG_SETTINGS.includes(format)), [formats]);

  const handleValidateFfmpegSettings = useCallback((value) => {
    if (!isEmpty(value)) {
      dispatch(ffmpegValidationRequest({
        type: firstNeededTypeForValidation,
        ffmpeg_settings: value,
      }, {
        callbacks: {
          success: (result) => {
            if (result?.data?.success) {
              setIsValidated(true);
              notify.success(t('Validation was successful'));
            } else if (!result?.data?.success) {
              setIsValidated(false);
              notify.error(t('Validation was not successful'));
            }
          },
          error: () => {
            setIsValidated(false);
            notify.error(t('Validation was not successful'));
          },
        },
      }));
    } else setIsValidated(true);
  }, [formats, firstNeededTypeForValidation, setIsValidated]);

  return (
    <Wrapper>
      <Controller
        name="projectName"
        control={form.control}
        defaultValue="Untitled project"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            label={t('Export as')}
            type="text"
            name="projectName"
            id="projectName"
            value={value}
            error={t(error?.message)}
            onChange={onChange}
            width="100%"
            marginBottom="10px"
            labelStyleProps={LABEL_STYLE_PROPS}
            inputStyleProps={{ ...INPUT_STYLE_PROPS, paddingRight: '100px' }}
          />
        )}
      />
      <EndFileName>{endFileName}</EndFileName>
      <Controller
        name="description"
        control={form.control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            label={t('Notes')}
            type="text"
            name="description"
            id="description"
            value={value}
            error={t(error?.message)}
            onChange={onChange}
            width="100%"
            marginBottom="10px"
            labelStyleProps={LABEL_STYLE_PROPS}
            inputStyleProps={INPUT_STYLE_PROPS}
          />
        )}
      />
      {isFfmpegSettings && (
        <>
          <Controller
            name="ffmpegSettings"
            control={form.control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Input
                  label={t('FFmpeg settings')}
                  type="text"
                  name="ffmpegSettings"
                  id="ffmpegSettings"
                  value={value}
                  error={t(error?.message)}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleChangeFfmpegSettings(e.target.value);
                  }}
                  width="100%"
                  marginBottom="10px"
                  labelStyleProps={LABEL_STYLE_PROPS}
                  inputStyleProps={INPUT_STYLE_PROPS}
                />
                <Button
                  title={t('Validate')}
                  onClick={() => handleValidateFfmpegSettings(value)}
                  buttonTheme="primary"
                  type="button"
                  label={t('Validate')}
                  width={143}
                  height={35}
                  marginLeft="57%"
                />
              </>
            )}
          />
        </>
      )}
      {isBanner && (
      <Controller
        name="outputUrl"
        control={form.control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            label={t('Output url')}
            type="text"
            name="outputUrl"
            id="outputUrl"
            value={value}
            error={t(error?.message)}
            onChange={onChange}
            width="100%"
            marginBottom="10px"
            labelStyleProps={LABEL_STYLE_PROPS}
            inputStyleProps={INPUT_STYLE_PROPS}
          />
        )}
      />
      )}
    </Wrapper>
  );
};
