import { useCallback, useEffect, useRef } from 'react';
import { equals } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { changeAssetUrl } from 'state/animation/actions';
import { getFileTypeByUrl } from 'utils/helpers/filesHelper';
import { blobAutoRevoker, toBlobUrl } from 'utils/helpers/requestHelpers';
import { debounceFunc } from 'utils/helpers/commonHelpers';
import { getImageRef } from 'state/editor/selectors';
import { GIF_VIDEO_REGEXP } from 'constants/regexp';
import { setImageFileType } from 'state/editor/actions';

/**
 *
 * @param cropperRef {object}
 * @param asset {object}
 * @param setIsTouched {function}
 * @param refId {string}
 * @param isTouched {boolean}
 * @param setCropData {function}
 * @returns {{onRotate: onRotate, onCrop: ((function(*, *, *=): void)|*)}}
 */

export const useCropper = ({
  cropperRef,
  asset,
  setIsTouched,
  refId,
  isTouched,
  setCropData,
}) => {
  const dispatch = useDispatch();
  const imageRef = useSelector((store) => getImageRef(store)(refId));
  const prevData = useRef({});
  const handleCrop = useCallback((isCheckTouched, isForce, skipDisabling) => () => {
    if (cropperRef.current) {
      const cropData = cropperRef.current.props.data ? cropperRef.current.getData() : null;
      const currentData = { u: asset.ref.u, cropData };
      if ((isForce || !equals(prevData.current, currentData))) {
        const image = cropperRef.current.cropper
          .getCroppedCanvas({
            width: asset.ref.w || 0,
            height: asset.ref.h || 0,
          });
        const setAssetUrl = (url) => {
          dispatch(changeAssetUrl([asset.ref.id, url]));
        };
        if (((imageRef && imageRef.options && imageRef.options.isDisabled)
            || !asset.ref.u) && !skipDisabling
        ) {
          setAssetUrl('');
        } else if (image) {
          const handler = (fileType) => {
            if (!isCheckTouched || !new RegExp(GIF_VIDEO_REGEXP).test(fileType) || isTouched) {
              setCropData(cropData || (cropperRef.current && cropperRef.current.cropper.getData()));
              image.toBlob((blob) => {
                const url = URL.createObjectURL(blob, blob.type);
                blobAutoRevoker.add(`cropperImage_${refId}`, url);
                setAssetUrl(url);
              });
            } else if (cropperRef.current) {
              toBlobUrl(
                cropperRef.current.cropper.url,
                setAssetUrl,
                `cropperImage_${refId}`,
              );
            }
          };
          getFileTypeByUrl(cropperRef.current.cropper.url, handler, handler);
        }
      }
      prevData.current = currentData;
    }
    return null;
  }, [asset, cropperRef, dispatch, isTouched, refId, setIsTouched, setCropData, imageRef]);
  const onCrop = useCallback((isCheckTouched, isForce, skipDisabling = false) => {
    try {
      debounceFunc(handleCrop(isCheckTouched, isForce, skipDisabling),
        500, `onCrop${refId}`);
    } catch {
      // sometimes cropper return error
      console.error('Cropping throw error');
    }
  },
  [refId, handleCrop]);

  const onRotate = () => {
    onCrop(false, false);
    cropperRef.current.cropper.rotate(90);
  };

  // eslint-disable-next-line
    useEffect(() => {
    const cropperElement = cropperRef.current;
    const imageInCropper = cropperElement ? cropperRef.current.img : null;
    const handleCropByImageReady = (e) => getFileTypeByUrl(e.target.src, (fileType) => {
      dispatch(setImageFileType({ refId, fileType, isPreparingGif: false }));
      if (!new RegExp(GIF_VIDEO_REGEXP).test(fileType)) {
        onCrop(true, true);
      }
    }, onCrop);
    if (cropperElement) {
      imageInCropper.addEventListener('ready', handleCropByImageReady);
      return () => {
        imageInCropper.removeEventListener('ready', handleCropByImageReady);
      };
    }
  }, [cropperRef, onCrop, dispatch, refId]);

  return {
    onCrop,
    onRotate,
  };
};
