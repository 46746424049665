import * as R from 'ramda';

const NOT_VALID_ERROR_PATH = 'NOT_VALID_ERROR_PATH';

const getErrors = (errors, errorPath) => {
  const resultError = errorPath
    ? errorPath(errors)
    : R.pathOr(NOT_VALID_ERROR_PATH, ['response', 'data', 'errors'], errors);

  if (resultError === NOT_VALID_ERROR_PATH) {
    // eslint-disable-next-line no-console
    console.error('API get not valid errors path, you must use custom errors path for this');
  }
  return resultError;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getErrors,
};
