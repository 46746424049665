import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'ui-kit';
import { webhookDeleteRequest, webhooksGetRequest } from 'state/webhooks/actions';
import { selectWebhookList } from 'state/webhooks/selectors';
import { WebhookModal } from '../WebhookModal';
import { Row, Title } from '../Integrations.styled';
import { WebhookItem } from './WebhookItem';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const Webhooks = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const webhooks = useSelector(selectWebhookList);

  const [isOpenedWebhookModal, setIsOpenedWebhookModal] = useState(false);
  const [isOpenedDeleteWebhookModal, setIsOpenedDeleteWebhookModal] = useState(false);
  const [selectedWebhookId, setSelectedWebhookId] = useState(null);

  const handleCloseDeleteWebhookModal = useCallback(() => {
    setIsOpenedDeleteWebhookModal(false);
    setSelectedWebhookId(null);
  }, []);

  const handleDeleteWebhook = useCallback(() => {
    dispatch(webhookDeleteRequest(selectedWebhookId));
  }, [selectedWebhookId]);

  const handleCloseWebhookModal = useCallback(() => {
    setIsOpenedWebhookModal(false);
    setSelectedWebhookId(null);
  }, []);

  useEffect(() => {
    dispatch(webhooksGetRequest());
  }, []);

  return (
    <>
      <Title>{t('Webhook - trigger this webhooks on every export or share')}</Title>
      {webhooks.map((webhook) => (
        <WebhookItem
          key={`webhook-id-${webhook.id}`}
          webhook={webhook}
          setSelectedWebhookId={setSelectedWebhookId}
          setIsOpenedDeleteWebhookModal={setIsOpenedDeleteWebhookModal}
          setIsOpenedWebhookModal={setIsOpenedWebhookModal}
        />
      ))}
      <Row maxWidth="582px" width="100%" justifyContent="flex-end">
        <Button
          type="button"
          width={102}
          minWidth={102}
          height={39}
          fontSize={16}
          onClick={() => setIsOpenedWebhookModal(true)}
          label={t('Add')}
          title={t('Add')}
        />
      </Row>
      <WebhookModal
        id={selectedWebhookId}
        isOpen={isOpenedWebhookModal}
        setSelectedWebhookId={setSelectedWebhookId}
        onClose={handleCloseWebhookModal}
      />
      <Modal
        isPrompt
        isOpen={isOpenedDeleteWebhookModal}
        onChange={handleDeleteWebhook}
        onClose={handleCloseDeleteWebhookModal}
        question={t('Are you sure you want to delete this webhook')}
      />
    </>
  );
};
