import React, { useCallback } from 'react';
import { Checkbox } from 'ui-kit';
import { useDispatch } from 'react-redux';
import { toggleTopDraftStatus } from 'state/drafts/actions';
import { toggleTopVideo } from 'state/videos/actions';

/**
 *
 * @param id {number}
 * @param animationExists {number}
 * @param draftId {number}
 * @param isTop {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const Favorite = ({
  id, animationExists, draftId, isTop,
}) => {
  const dispatch = useDispatch();

  const handleChange = useCallback(({ target: { checked } }) => {
    const params = { id: draftId, params: { is_top: Number(checked) } };

    const action = toggleTopDraftStatus(params, {
      callbacks: {
        success: () => {
          dispatch(toggleTopVideo(id));
        },
      },
    });
    dispatch(action);
  }, [draftId, id]);

  return (
    <Checkbox
      id={`isFavorite${id}`}
      disabled={!animationExists}
      checked={!!isTop}
      onChange={handleChange}
      size="small"
    />
  );
};
