import React from 'react';
import { is } from 'ramda';
import { Label } from './CheckboxGroup.styled';
import { Checkbox } from '../Checkbox';

/**
 *
 * @param onChange {function}
 * @param id {string | number}
 * @param name {string}
 * @param isDisabled {boolean}
 * @param isChecked {boolean}
 * @param label {string | function | null}
 * @param isAppendedChild
 * @param children {JSX.Element}
 * @param value {string | number | boolean}
 * @param size {string}
 * @param hasLabel {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const CheckboxGroup = ({
  onChange,
  id,
  name,
  isDisabled,
  isChecked,
  label = null,
  isAppendedChild = true,
  children,
  value,
  size = '',
  hasLabel = true,
}) => {
  return (
    <>
      <Checkbox
        className="checked-icon"
        id={`checkbox-group-${id}`}
        name={name}
        value={value}
        disabled={isDisabled}
        checked={isChecked}
        onChange={onChange}
        title={is(Function, label) ? null : label}
        size={size}
        hasLabel={hasLabel}
      />
      {label && (
      <Label
        htmlFor={`checkbox-group-${id}`}
        isDisabled={isDisabled}
        isChecked={isChecked}
      >
        {is(Function, label) ? label() : label}
      </Label>
      )}
      {isAppendedChild && children}
    </>
  );
};
