import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFileUrl } from 'utils/helpers/requestHelpers';
import { getEditorIsReady } from 'state/editor/selectors';
import { changeDraftTemplate, createVideoDraftFromAnimationRequest } from 'state/drafts/actions';
import { getActiveTheme } from 'state/animations/selectors';
import { animationsActions } from 'state/animations';
import { editorActions } from 'state/editor';
import {
  Item, Container, Preview, Image,
  PreviewText, Body, Content, Title,
  Text, List, Theme,
} from './ThemeItem.styled';

/**
 * get url for image
 * @param image {string|null}
 * @param isAnim {boolean}
 * @returns {string|string|null}
 */
const makeImgUrl = (image = null, isAnim) => {
  if (isAnim) {
    return getFileUrl({ fileUrl: `dist/images/${image}` });
  }
  return image ? getFileUrl({ fileUrl: `files/${image}` }) : null;
};

/**
 *
 * @param theme {{
 * id: number,
 * title: string,
 * name: string,
 * description: string,
 * version: string,
 * isAnim: boolean,
 * image: string
 * }}
 * @returns {JSX.Element}
 * @constructor
 */

export const ThemeItem = ({ theme }) => {
  const dispatch = useDispatch();
  const isReady = useSelector(getEditorIsReady);
  const selectedTheme = useSelector(getActiveTheme);

  // changing theme, we have different actions for changing usual animation or draft
  const handleChangeTheme = useCallback((id, name, isAnim, ratio) => () => {
    if (isReady) {
      if (isAnim) {
        dispatch(createVideoDraftFromAnimationRequest({ name, ratio }));
        dispatch(animationsActions.setSelectAnimation(name));
      } else {
        dispatch(changeDraftTemplate({ target: { value: id }, isDuplicate: true }));
      }
      dispatch(editorActions.setIsReady(false));
    }
  }, [isReady]);

  // animations don't have id, only uniq name, so if we don't have equal by id
  // then we check name and non-existence of the id
  const isActive = theme.id === selectedTheme || (theme.name === selectedTheme && !theme.id);

  return (
    <Theme>
      <Container
        isActive={isActive}
        onDoubleClick={handleChangeTheme(theme.id, theme.name, theme.isAnim, theme.version)}
        onMouseDown={handleChangeTheme(theme.id, theme.name, theme.isAnim, theme.version)}
      >
        <Preview>
          <Image
            src={makeImgUrl(theme.image, theme.isAnim)}
            alt="preview_image"
          />
        </Preview>
        <PreviewText>{theme.title}</PreviewText>
        <Body>
          <Content>
            <Title>{theme.title}</Title>
            <Text>{theme.description}</Text>
            <List>
              <Item>
                Version:
                {' '}
                {theme.version}
              </Item>
              <Item>
                Path:
                {' '}
                {theme.name}
              </Item>
              {!theme.isAnim && (
              <Item>
                Draft ID:
                {' '}
                {theme.id}
              </Item>
              )}
            </List>
          </Content>
        </Body>
      </Container>
    </Theme>
  );
};
