import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { preloaderAnim } from 'assets/images';
import {
  MIN_DURATION_PRELOADER,
  PRELOADER_SIZES,
  PRELOADER_TYPES,
} from 'constants/ui';
import {
  Root,
  PreloaderItem,
  PreloaderImage,
} from './Preloader.styled';

/**
 *
 * @param isLoading {boolean}
 * @param minDuration {number}
 * @param type {string}
 * @param size {string}
 * @param isDestroyChildren {boolean}
 * @param className {string}
 * @param children {JSX.Element}
 * @param preloaderChildren {JSX.Element}
 * @param preloaderSrc
 * @param preloaderItemProps {object} style props for preloader item
 * @param props {object} style props for root
 * @returns {JSX.Element}
 * @constructor
 */

export const Preloader = ({
  isLoading,
  minDuration = MIN_DURATION_PRELOADER,
  type = PRELOADER_TYPES.CERTAIN,
  size = PRELOADER_SIZES.SMALL,
  isDestroyChildren = true,
  className = '',
  children,
  preloaderChildren = <></>,
  preloaderSrc,
  preloaderItemProps = {},
  ...props
}) => {
  const [isPending, setIsPending] = useState(isLoading);

  useEffect(() => {
    if (isPending !== isLoading) {
      const timer = setTimeout(() => {
        setIsPending(isLoading);
      }, minDuration);
      return () => clearTimeout(timer);
    }
  }, [isLoading, minDuration, isPending]);

  return (
    <Root {...props} className="preloader-parent" type={type}>
      {isPending ? (
        <PreloaderItem
          className={cn('preloader', className)}
          type={type}
          {...preloaderItemProps}
        >
          <PreloaderImage
            size={size}
            className="preloader__image"
            src={preloaderSrc || preloaderAnim}
            alt="Preloader"
          />
          {preloaderChildren}
        </PreloaderItem>
      ) : (
        <>
          {
            isDestroyChildren ? (children) : ''
          }
        </>
      )}
      {
        !isDestroyChildren ? (children) : ''
      }
    </Root>
  );
};
