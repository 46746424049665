import themePrev from './theme-image.png';
import loadIcon from './load-icon.svg';
import customCheckedIcon from './customCheck-icon.svg';
import zapierLogo from './zappierLogo.png';
import downloadIcon from './download.svg';
import newDownloadIcon from './download-new.svg';
import rotateIcon from './rotate.svg';
import cropIcon from './crop.svg';
import cancelIcon from './cancel.svg';
import preloaderAnim from './preloader.gif';
import processingFile from './processing.gif';
import renderingFile from './rendering.gif';
import errorFile from './error.png';
import lockIcon from './lock.png';
import editIcon from './edit.png';
import shareIcon from './share-icon.svg';
import linkIcon from './link.svg';
import emailIcon from './email.svg';
import arrowDownIcon from './arrow-down.svg';
import largeCustomCheckIcon from './largeCustomCheck.svg';
import customResetIcon from './customReset.svg';
import customPlayIcon from './customPlay.svg';
import playerArrow from './player-arrow.svg';
import smallStarIcon from './small-star.svg';
import smallArrowDownIcon from './small-arrow-down.svg';
import cameraIcon from './camera-icon.svg';
import customUploadIcon from './custom-upload.svg';
import deleteIconRed from './delete-icon-red.svg';
import customFullView from './custom-full-view.svg';
import customLogo from './custom-logo.svg';
import transparentLogo from './transparent-logo.svg';
import sidebarIcon from './sidebar.svg';
import tumplateLoadAnimator from './tumplate-load-animator1.gif';
import pauseIcon from './pause.svg';
import sharedLinksIcon from './shared-links.svg';
import myFilesIcon from './my-files.svg';
import accountIcon from './account.svg';
import logoutIcon from './logout-icon.svg';
import filesIcon from './files-icon.svg';
import accountIconMobile from './account-icon.svg';
import designPresetsIcon from './design-presets.svg';
import sharedIcon from './shared-icon.svg';
import logoutIconMobile from './logout-icon-mobile.svg';
import checkedMarker from './checked-marker.png';
import camera from './video-camera.png';

const logo = editIcon;
export {
  logoutIconMobile,
  sharedIcon,
  designPresetsIcon,
  accountIconMobile,
  filesIcon,
  logoutIcon,
  accountIcon,
  myFilesIcon,
  sharedLinksIcon,
  linkIcon,
  emailIcon,
  editIcon,
  shareIcon,
  logo,
  smallStarIcon,
  smallArrowDownIcon,
  customUploadIcon,
  cameraIcon,
  customLogo,
  transparentLogo,
  playerArrow,
  themePrev,
  deleteIconRed,
  loadIcon,
  customCheckedIcon,
  zapierLogo,
  customResetIcon,
  customPlayIcon,
  downloadIcon,
  newDownloadIcon,
  rotateIcon,
  cropIcon,
  cancelIcon,
  preloaderAnim,
  processingFile,
  renderingFile,
  errorFile,
  lockIcon,
  customFullView,
  arrowDownIcon,
  largeCustomCheckIcon,
  sidebarIcon,
  tumplateLoadAnimator,
  pauseIcon,
  checkedMarker,
  camera,
};
