import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/styles';
import { PaletteModal, Picker, Row } from './ColorPalette.styled';
import { Button } from '../Button';

/**
 *
 * @param isOpen {boolean}
 * @param handleClose {function}
 * @param color {string | object}
 * @param handleChange {function}
 * @param handleAdd {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const ColorPalette = ({
  isOpen, handleClose, color, handleChange, handleAdd,
}) => {
  const { t } = useTranslation(['account', 'common', 'forms']);

  return (
    <PaletteModal
      afterClickingOutsideClose={false}
      isOpen={isOpen}
    >
      <Picker
        color={color}
        disableAlpha
        onChange={handleChange}
      />
      <Row>
        <Button
          type="button"
          buttonTheme="COLORS."
          width={70}
          color={COLORS.GRAY}
          onClick={handleClose}
          label={t('Cancel')}
          title={t('Cancel')}
        />
        <Button
          type="button"
          buttonTheme="primary"
          width={51}
          color={COLORS.GREEN}
          onClick={handleAdd}
          label={t('Add')}
          title={t('Add')}
        />
      </Row>
    </PaletteModal>
  );
};
