export const notifyStore = (() => {
  const handlers = {};

  return {
    subscribe: (fn, key) => {
      handlers[key] = fn;
    },
    set: (data, key) => {
      const handler = handlers[key];
      if (handler) {
        handler(data);
      }
    },
    revoke: (key) => {
      delete handlers[key];
    },
  };
})();
