import React, { useRef, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';
import { PlayerContainer } from './Player.styled';

const SECONDS = 'seconds';

/**
 *
 * @param isPlaying
 * @param timeRange
 * @param onPlayerProgress
 * @param src
 * @param setIsPlaying
 * @param currentTime
 * @returns {JSX.Element}
 * @constructor
 */

export const Player = ({
  isPlaying,
  timeRange,
  onPlayerProgress,
  src,
  setIsPlaying,
  currentTime,
}) => {
  const player = useRef(null);
  const prevIsPlaying = useRef(isPlaying);
  const isSeekedFirstTime = useRef(false);

  const seekPlayer = ({ time }) => { player.current.seekTo(time, SECONDS); };

  //  setting start point when player on pause
  useEffect(() => {
    if (!isPlaying && !prevIsPlaying.current) {
      const playerCurrentTime = player.current.getCurrentTime();
      seekPlayer({ time: playerCurrentTime });
    } else if (!isPlaying) {
      seekPlayer({ time: currentTime });
    }
    prevIsPlaying.current = !isPlaying;
  }, [isPlaying, currentTime]);

  // setting start point when we change timeRange (skip first render, because there is no time range on first render);
  useEffect(() => {
    if (player.current && isPlaying) {
      onPlayerProgress(timeRange.start);
      setIsPlaying(false);
      seekPlayer({ time: timeRange.start });
    }
  }, [timeRange]);

  const handlePlayerProgress = (data) => {
    // I don't use useCallback, because this function will be using each seconds when Player will be active (play)
    if (data.loaded) {
      const { playedSeconds } = data;
      const { start, end } = timeRange;
      const playedSecondsIsOutOfRange = playedSeconds <= start
          || playedSeconds >= end;

      if (playedSecondsIsOutOfRange) {
        player.current.seekTo(start, SECONDS);
      }
      onPlayerProgress(playedSeconds);
    }
  };

  // onReady is used, each time when we change something on player but we need to update currentTime only after first render (ready) player
  const handlePlayerReady = useCallback(() => {
    if (!isSeekedFirstTime.current) {
      seekPlayer({ time: currentTime });
      isSeekedFirstTime.current = true;
    }
  }, [isSeekedFirstTime.current]);

  return (
    <PlayerContainer>
      <ReactPlayer
        onProgress={handlePlayerProgress}
        url={src}
        ref={player}
        onReady={handlePlayerReady}
        playing={isPlaying}
        className="rvt__player"
      />
    </PlayerContainer>
  );
};
