import * as animationsActions from './actions';
import * as animationsSelectors from './selectors';
import * as animationsOperations from './operations';
import animationsTypes from './types';
import reducer from './reducers';

export {
  animationsActions,
  animationsTypes,
  animationsSelectors,
  animationsOperations,
};

export default reducer;
