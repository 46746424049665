import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';

export const {
  sendDataFileRequest,
  getVideosRequest,
  runRenderVideoRequest,
  sendDataFileSuccess,
  sendAudioFileRequest,
  createVideo,
  runRenderVideoSuccess,
  setVideos,
  sendAudioFileSuccess,
  setPrepareToRender,
  deleteVideoRequest,
  deleteVideosRequest,
  updateVideoClientStatusRequest,
  updateVideoClientStatusSuccess,
  getCountOfRenderingFilesRequest,
  getCountOfRenderingFilesSuccess,
  setCountOfRenderingFiles,
  sendVideosToZapierRequest,
  ffmpegValidationRequest,
  toggleTopVideo,
  changeVideosIdsForDelete,
  changeIsMakingExport,
} = createActions({
  [types.SEND_DATA_FILE_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/temp-files',
      selectorName: 'sendDataFileRequest',
      method: 'POST',
      params,
      actions: {
        success: (payload) => sendDataFileSuccess({ ...payload, meta, ...params }),
        finally: () => setPrepareToRender(false),
      },
      ...meta,
    }),
  ],
  [types.GET_VIDEOS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/videos',
      selectorName: 'getVideosRequest',
      method: 'GET',
      params,
      actions: {
        success: setVideos,
      },
      ...meta,
    }),
  ],
  [types.DELETE_VIDEO_REQUEST]: [
    identity,
    (id, meta) => ({
      async: true,
      route: `/api/videos/${id}`,
      selectorName: 'deleteVideoRequest',
      method: 'DELETE',
      ...meta,
    }),
  ],
  [types.DELETE_VIDEOS_REQUEST]: [
    identity,
    (ids, meta) => ({
      async: true,
      route: '/api/videos',
      selectorName: 'deleteVideosRequest',
      method: 'DELETE',
      params: { ids },
      ...meta,
    }),
  ],
  [types.RUN_RENDER_VIDEO_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/videos',
      selectorName: 'runRenderVideoRequest',
      method: 'POST',
      params,
      ...meta,
    }),
  ],
  [types.SEND_AUDIO_FILE_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/temp-files',
      selectorName: 'sendAudioFileRequest',
      method: 'POST',
      params,
      actions: {
        success: (payload) => sendAudioFileSuccess({ ...payload, meta }),
      },
      ...meta,
    }),
  ],
  [types.UPDATE_VIDEO_CLIENT_STATUS_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/api/videos/${id}/client-status`,
      selectorName: 'updateVideoRequest',
      method: 'PUT',
      params,
      actions: {
        success: updateVideoClientStatusSuccess,
      },
      ...meta,
    }),
  ],
  [types.GET_COUNT_OF_RENDERING_FILES_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: '/api/videos',
      selectorName: 'getCountRenderingFiles',
      method: 'GET',
      params,
      actions: {
        success: getCountOfRenderingFilesSuccess,
      },
      ...meta,
    }),
  ],
  [types.SEND_VIDEOS_TO_ZAPIER_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: '/api/videos/send-to-zapier',
      selectorName: 'sendVideoToZapierRequest',
      method: 'POST',
      params,
      ...meta,
    }),
  ],
  [types.FFMPEG_VALIDATION_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/videos/validate-ffmpeg-settings',
      selectorName: 'ffmpegValidationRequest',
      method: 'POST',
      params,
      ...meta,
    }),
  ],
},
types.SEND_DATA_FILE_SUCCESS,
types.CREATE_VIDEO,
types.RUN_RENDER_VIDEO_SUCCESS,
types.SET_VIDEOS,
types.SEND_AUDIO_FILE_SUCCESS,
types.SET_PREPARE_TO_RENDER,
types.UPDATE_VIDEO_CLIENT_STATUS_SUCCESS,
types.SET_COUNT_OF_RENDERING_FILES,
types.GET_COUNT_OF_RENDERING_FILES_SUCCESS,
types.TOGGLE_TOP_VIDEO,
types.CHANGE_VIDEOS_IDS_FOR_DELETE,
types.CHANGE_IS_MAKING_EXPORT);
