import * as videosActions from './actions';
import * as videosSelectors from './selectors';
import * as videosOperations from './operations';
import videosTypes from './types';
import reducer from './reducers';

export {
  videosActions,
  videosTypes,
  videosSelectors,
  videosOperations,
};

export default reducer;
