import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';

export const {
  getCurrentUserRequest,
  updateUserProfileRequest,
  setUser,
  setAuthenticated,
  userLogout,
  getCurrentUserSuccess,
  getCurrentUserError,
  updateUser,
  generateZapierKeyRequest,
} = createActions(
  {
    [types.GET_CURRENT_USER_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/api/auth/current-user',
        selectorName: 'getCurrentUserRequest',
        method: 'GET',
        actions: {
          error: getCurrentUserError,
          success: getCurrentUserSuccess,
        },
        params,
        ...meta,
      }),
    ],
    [types.UPDATE_USER_PROFILE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/api/profile',
        selectorName: 'updateUserProfileRequest',
        method: 'PUT',
        params,
        actions: {
          success: updateUser,
        },
        ...meta,
      }),
    ],
    [types.GENERATE_ZAPIER_KEY_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/api/profile/generate-zapier-api-key',
        selectorName: 'generateZapierKey',
        method: 'POST',
        params,
        actions: {
          success: getCurrentUserSuccess,
        },
        ...meta,
      }),
    ],
  },
  types.SET_USER,
  types.SET_AUTHENTICATED,
  types.USER_LOGOUT,
  types.GET_CURRENT_USER_SUCCESS,
  types.GET_CURRENT_USER_ERROR,
  types.UPDATE_USER,
);
