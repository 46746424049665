import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  always, ifElse, prop, identity,
} from 'ramda';
import { useTranslation } from 'react-i18next';

import { setPageError } from 'state/ui/actions';
import './style.sass';

export const PageError = ({ pageError }) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const {
    forwardTitle = t('Go Back to Safety'),
    title = t('Page Not Found'),
    subTitle = t('The page you are looking for doesn\'t exist or has\n  been moved.'),
  } = pageError;
  const dispatch = useDispatch();
  const backHref = ifElse(prop('forwardHref'), identity, always('/'))(pageError);
  const goToForwardPage = () => {
    dispatch(setPageError({}));
    navigate(backHref);
  };
  return (
    <div className="page-error">
      <div className="page-error__wrapper">
        <div className="page-error__container">
          <h1 className="page-error__title">{title}</h1>
          <span className="page-error__sub-title pre-wrap">
            {subTitle}
          </span>
          <div className="page-error__row">
            <button
              type="button"
              onClick={goToForwardPage}
              className="page-error__go-back"
            >
              {forwardTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

PageError.propTypes = {
  pageError: PropTypes.instanceOf(Object),
};

PageError.defaultProps = {
  pageError: {},
};
