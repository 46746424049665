import styled from 'styled-components';

export const RootStyle = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 6px;
  margin-bottom: 20px; 
  img {
    max-width: 100%;
    max-height: 400px;
  }
`;

export const Title = styled.h3`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
`;

export const InputRangeContainer = styled.div`
  .input-range {
    margin: 10px 0
  }
  .input-range__label {
    display: none;
  }
  .input-range__slider {
    background: ${({ theme }) => theme.colors.MAIN};
    margin-top: -0.4rem;
    background: ${({ theme }) => theme.colors.WHITE};
    border: 1px solid #9ca5aa
  }
  .input-range__track--background {
    height: 5px;
    background: ${({ theme }) => theme.colors.PLATINUM};
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 23px;
  }
  .input-range__track--active {
    display: none
  }
`;

export const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.MAIN_BACKGROUND};;
`;
