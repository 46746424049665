import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import InputColor from 'components/forms/InputColor/InputColor';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { useDispatch, useSelector } from 'react-redux';
import { getColorsRequest } from 'state/colors/actions';
import { Modal, Preloader } from 'ui-kit';
import {
  getAddColorRequestPending,
  getColorsRequestPending,
  getColorsSelector,
  getDeleteColorRequestPending,
} from '../../state/colors/selectors';
import { colorsActions } from '../../state/colors';

const REQUEST_COLORS_PARAMS = {
  limit: 100,
  offset: 0,
  sortBy: 'id',
  order: 'asc',
};

const ColorsSelector = ({
  children, initial,
}) => {
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const dispatch = useDispatch();
  const colors = useSelector(getColorsSelector);
  const isGetColorsRequestPending = useSelector(getColorsRequestPending);
  const isAddColorRequestPending = useSelector(getAddColorRequestPending);
  const isDeleteColorRequestPending = useSelector(getDeleteColorRequestPending);

  const onDeleteColor = useCallback((id) => () => {
    setCurrentItemId(id);
    setIsOpenedModal(true);
  }, []);

  const handleCloseModal = () => setIsOpenedModal(false);

  const handleAddColor = useCallback((color) => {
    dispatch(colorsActions.setColorsFreshStatus(false));
    dispatch(colorsActions.addColorRequest({ color }));
  },
  [dispatch]);

  const handleDeleteColor = useCallback((colorId) => {
    dispatch(colorsActions.setColorsFreshStatus(false));
    dispatch(colorsActions.deleteColorRequest({ colorId }));
  },
  [dispatch]);

  const isLoading = isGetColorsRequestPending
      || isAddColorRequestPending
      || isDeleteColorRequestPending;

  useEffect(() => {
    if (initial) {
      dispatch(getColorsRequest(REQUEST_COLORS_PARAMS));
    }
  }, []);

  return (
    <div className="select-colors__row">
      <Preloader
        type={PRELOADER_TYPES.CONTAINER}
        size={PRELOADER_SIZES.SMALL}
        isLoading={isLoading}
        minDuration={800}
        isDestroyChildren={false}
      >
        <>
          {colors.map(({ color, id }, index) => (
            <div
              key={`${color}${id}-color`}
              className="select-colors__color-box"
              style={{ backgroundColor: color }}
            >
              {
               index > 1 && (
                 <button
                   type="button"
                   title={`Delete color ${color}`}
                   onClick={onDeleteColor(id)}
                   className="select-colors__delete-button"
                 >
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="15.653"
                     height="15.653"
                     viewBox="0 0 15.653 15.653"
                   >
                     <path
                       className="a"
                       d="M13,5.21a7.826,7.826,0,1,1-7.813,7.8A7.826,7.826,0,0,1,13,5.21ZM14.6,13.081c.172-.172.269-.287.374-.391.747-.748,1.5-1.488,2.231-2.248a1.031,1.031,0,0,0,.24-1.279,1.109,1.109,0,0,0-1.174-.647,1.687,1.687,0,0,0-.755.434c-.839.8-1.651,1.637-2.506,2.494l-1.267-1.291c-.455-.458-.9-.929-1.373-1.373a1.054,1.054,0,0,0-1.545.055,1.076,1.076,0,0,0-.05,1.545c.125.144.268.275.4.412l2.207,2.231c-.094.1-.182.2-.276.3-.759.777-1.522,1.533-2.271,2.3a1.065,1.065,0,0,0-.046,1.576,1.077,1.077,0,0,0,1.6.057c.386-.343.745-.729,1.114-1.1L13,14.651c.132.124.233.211.33.305.74.735,1.478,1.473,2.221,2.205a1.083,1.083,0,0,0,1.183.369,1.118,1.118,0,0,0,.436-1.934C16.337,14.752,15.479,13.942,14.6,13.081Z"
                       transform="translate(-5.19 -5.21)"
                     />
                   </svg>
                 </button>
               )
             }
            </div>
          ))}
          {colors.length < 15 && <InputColor onChange={handleAddColor} />}
          {children}
        </>
      </Preloader>
      <Modal
        isOpen={isOpenedModal}
        onChange={(confirm) => {
          if (confirm) handleDeleteColor(currentItemId);
        }}
        onClose={handleCloseModal}
        isPrompt
        question="Are you sure you want to delete this color?"
      />
    </div>
  );
};

ColorsSelector.propTypes = {
  children: PropTypes.element,
  initial: PropTypes.bool,
};
ColorsSelector.defaultProps = {
  children: <></>,
  initial: false,
};

export default ColorsSelector;
