import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media(${({ theme }) => theme.devices.STANDARD}) {
    margin-top: 32px;
    height: 32px;
  };
  @media(${({ theme }) => theme.devices.LG}) {
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 0;
  };
`;

export const Title = styled.h5`
  margin-right: 20px;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  @media(${({ theme }) => theme.devices.LG}) {
    margin: 0;
  }
  @media(${({ theme }) => theme.devices.XS_X}) {
    zoom: .8;
  }
`;

export const Body = styled.div`
  display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};
  max-width: 410px;
  width: ${({ isOpened }) => (isOpened ? 'auto' : '100%')};
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 3px 6px #00000029;
  overflow: hidden;

  @media(${({ theme }) => theme.devices.LG}) {
    flex-direction: column;
    position: absolute;
    margin-top: 30px;
    z-index: 100;
    border-radius: 5px;
  }
  
  @media(${({ theme }) => theme.devices.XS_X}) {
    zoom: .85
  }
  ${({ isCsv }) => isCsv && `
  .preloader{
    background-color: transparent;
    }
  img {
    max-height: 28px;
    }`}
`;
