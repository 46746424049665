import styled from 'styled-components';

export const Title = styled.label`
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 40px;
  width: 100%;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-top: 10px;
  .select__label {
    min-width: 40px;
    max-width: 100px;
    height: 32px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.MAIN};
    color: ${({ theme }) => theme.colors.WHITE};
    padding: 0 8px;
    font-size: 14px;
    line-height: 14px;
    border-radius: 30px;
  }
`;
