import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';
import { getActiveAnimations } from 'state/animations/selectors';
import './style.sass';
import { getAnimationsRequest } from 'state/animations/actions';
import SelectThemesModal from '../SelectThemesModal/SelectThemesModal';

const ThemesSelector = ({
  isOpenSelectThemes, setIsOpenSelectThemes, value, setFieldValue, initial,
}) => {
  const animationList = useSelector(getActiveAnimations);
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  useEffect(() => {
    if (initial) dispatch(getAnimationsRequest());
  }, []);

  return (
    <div className="select-theme__row">
      <span className="select-theme__title">
        Account tumplates
      </span>
      <ul className="select-theme__list">
        <ScrollBars style={{ height: 300, width: '100%' }} autoHide>
          {animationList.map(({ name }) => (
            <li className="text-cut" key={name}>{name}</li>
          ))}
        </ScrollBars>
      </ul>
      <div className="select-theme__button-row">
        {
        isOpenSelectThemes && (
        <>
          {
                screenWidth > 1200 ? (
                  <SelectThemesModal
                    value={value}
                    screenWidth
                    onChange={(fonts) => {
                      setFieldValue('colors', fonts);
                      setIsOpenSelectThemes(false);
                    }}
                  />
                ) : (
                  <>
                    <SelectThemesModal
                      isPortal
                      value={value}
                      screenWidth
                      onChange={(fonts) => {
                        setFieldValue('colors', fonts);
                        setIsOpenSelectThemes(false);
                      }}
                    />
                  </>
                )

            }
        </>
        )
      }
        <button
          onClick={() => setIsOpenSelectThemes(true)}
          type="button"
          className="button button--black select-theme__button"
        >
          Edit list
        </button>
      </div>
    </div>
  );
};

ThemesSelector.propTypes = {
  value: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func.isRequired,
  isOpenSelectThemes: PropTypes.bool.isRequired,
  setIsOpenSelectThemes: PropTypes.func.isRequired,
  initial: PropTypes.bool,
};
ThemesSelector.defaultProps = {
  value: [],
  initial: false,
};

export default ThemesSelector;
