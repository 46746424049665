import {
  assocPath,
  compose,
  curry,
  defaultTo,
  findIndex,
  is,
  lens,
  lensPath,
  map,
  path as Rpath,
  pathEq,
  prop,
  propEq,
  view,
} from 'ramda';
import { DATA_CONST, GROUP_PATH } from '../../constants/animationsPath';
import {
  HIDE_EVERYWHERE_REGEXP,
  HIDE_EVERYWHERE_WITH_SPACES_REGEXP,
  HIDE_REGEXP,
  HIDE_WITH_SPACES_REGEXP,
} from '../../constants/regexp';

const STANDARD_FRAME_RATE = 24;
const TUMPLATE_MASTER_DATA = 'TumplateMasterData';
const MASTER_DATA_PATH = ['ef', 0, 'nm'];
const CUSTOM_LENGTH_OPTION = 3;
const CUSTOM_LENGTH_OPTION_INDEX = 1;
const CUSTOM_LENGTH_DURATION_INDEX = 6;

export const layersPath = ['layers'];
export const layers = lensPath(layersPath);

export const getMasterDataLayerIndex = compose(
  findIndex(pathEq(['ef', 0, 'nm'], 'TumplateMasterData')),
  defaultTo([]),
  prop('layers'),
);

export const pathBuilder = curry((path, data) => map((part) => (is(Function, part) ? part(data) : part), path));
export const lensBuilder = (path) => lens(
  (data) => Rpath(pathBuilder(path)(data), data),
  curry((val, data) => assocPath(pathBuilder(path)(data), val, data)),
);

export const dataLayerPath = ['layers', getMasterDataLayerIndex];
export const dataLayer = lensBuilder(dataLayerPath);

export const dataLayerEffectsPath = ['layers', getMasterDataLayerIndex, 'ef', 0, 'ef'];
export const dataLayerEffects = lensBuilder(dataLayerEffectsPath);

export const getLayerIndex = (name) => findIndex(propEq('nm', name));

export const layer = curry((name, anim) => lensPath([...layersPath, getLayerIndex(name)(view(layers, anim))]));

/**
 *
 * @param animationLayers {array<object>}
 * @param path {array<string | number>}
 * @param name {string}
 * @returns {number}
 */

export const getLayerIndexByName = ({ animationLayers = [], path, name }) => {
  return animationLayers.findIndex((animationLayer) => Rpath(path, animationLayer) === name);
};

const setLengthProps = (sublayer, param) => ({
  ...sublayer,
  v: {
    ...sublayer.v,
    k: param,
  },
});

const mapSubLayers = (subLayers, mapper) => {
  return subLayers.map((subLayer, subLayerIndex) => {
    if (is(Function, mapper)) {
      return {
        ...subLayer,
        ...mapper(subLayer, subLayerIndex),
      };
    }
    return { ...subLayer };
  });
};

export const getAnimationPayload = (anim, customLength, immediatelySet) => {
  if (anim) {
    const masterDataIndex = getLayerIndexByName({
      animationLayers: anim.layers,
      path: MASTER_DATA_PATH,
      name: TUMPLATE_MASTER_DATA,
    });
    return {
      ...anim,
      op: immediatelySet ? Math.floor(customLength * STANDARD_FRAME_RATE) : anim.op,
      layers: anim.layers.map((animationLayer, index) => {
        if (index === masterDataIndex) {
          return {
            ...animationLayer,
            ef: [...animationLayer.ef.map((subLayer) => ({
              ...subLayer,
              ef: [...mapSubLayers(subLayer.ef, (item, itemIndex) => {
                if (itemIndex === CUSTOM_LENGTH_OPTION_INDEX && immediatelySet) {
                  return setLengthProps(item, CUSTOM_LENGTH_OPTION);
                }
                if (itemIndex === CUSTOM_LENGTH_DURATION_INDEX) {
                  return setLengthProps(item, customLength);
                }
                return { ...item };
              })],
            }))],
          };
        }
        return animationLayer;
      }),
    };
  }
  return {};
};

/**
 *
 * @param group {object}
 * @param name {string} name of connected layer
 * @returns {boolean}
 */

export const testHide = (group, name = DATA_CONST.FORCE_HIDE) => {
  const connectedLayers = Rpath(GROUP_PATH.CONNECTED_LAYERS, group);
  const hideForceLayer = connectedLayers.find((connectedLayer) => connectedLayer.nm === name);
  if (hideForceLayer) {
    const values = hideForceLayer?.v?.x;
    if (values) {
      return !(new RegExp(HIDE_REGEXP).test(values))
        && !(new RegExp(HIDE_WITH_SPACES_REGEXP).test(values))
        && !(new RegExp(HIDE_EVERYWHERE_REGEXP).test(values))
        && !(new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(values));
    }
    return true;
  }
  return true;
};
