import styled from 'styled-components';

export const Header = styled.div`
  padding: 18px 0 5px 42px;
  font-size: 24px;
  font-weight: 700;
`;

export const Description = styled.div`
  padding-left: 40px;
  font-size: 12px;
  opacity: 0.5;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 70px 440px 70px;
  width: 580px;
  margin: 30px auto 20px;

  @media(${({ theme }) => theme.devices.SM}){
    grid-template-columns: 50px 360px 50px;
    width: 460px;
  }

  @media(${({ theme }) => theme.devices.SM}){
    grid-template-columns: 45px 240px 45px;
    width: 330px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

export const ToggleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 6px;
  padding: 0;
  border: 0;
  background-color: ${({ theme }) => theme.colors.SUCCESS};
  border-radius: 100%;
  cursor: pointer;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.03), 0 6px 10px 2px rgba(0, 0, 0, 0.08);
  :hover {
    opacity: .86
  }
  :active {
    box-shadow: 0 0 3px #000
  }
  ::after, ::before {
    margin: 0 2px;
    width: 32px;
    height: 32px;
    padding-left: 4px;
    outline: none;
  }
  }`;

export const PauseIcon = styled.div`
  display: flex;
  justify-content: center;
  &::after, &::before {
    content: '';
    width: 6px;
    height: 18px;
    background-color: #fff;
  }
  &::before, &::after {
    margin: 0 2px;
  }
`;

export const PauseImg = styled.img`
    width: 11px;
    height: 14px;
`;

export const VideoDuration = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.BLACK};
  font-size: 12px;
`;

export const TrimmerEdge = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  width: 374px;
  margin: 25px auto 0;

  @media(${({ theme }) => theme.devices.XS}){
    width: 340px;
  }
`;

export const TrimmerPointContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(0,0,0, 0.5);
  font-size: 12px;
`;

export const TrimmerPoint = styled.input`
  margin-left: 5px;
  max-width: 45px;
  border-radius: 4px;
  border: none;
  padding-left: 8px;
  box-shadow: 0 0 2px ${({ theme, warning }) => (warning ? theme.colors.RED : theme.colors.BLACK)};
  ${({ theme }) => theme.devices.SM && 'max-width: 46px;'}
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 2px gray;
  }
  &:disabled {
    color: black;
  }
`;
