import { EventEmitter } from 'events';
import { arrayBufferToBlob, readArrayBuffer, readDataURL } from 'utils/helpers/videoTrimmerHelpers';

const COMMAND_OPTIMIZE_VIDEO = '-strict -2 -vcodec libx264 -crf 23 output.mp4';
const TOTAL_MEMORY = 253554432;

const VIDEO_READY_STATES = {
  HAVE_NOTHING: 0,
  HAVE_METADATA: 1,
  HAVE_CURRENT_DATA: 2,
  HAVE_FUTURE_DATA: 3,
  HAVE_ENOUGH_DATA: 4,
};

class WebVideo extends EventEmitter {
  constructor(videoFile, workerClient) {
    super();
    this.videoFile = videoFile;

    this.workerClient = workerClient;
    this.workerClient.setMaxListeners(35); // I increased value of listeners, because ffmpeg-webworker doesn't give opportunity to clear listeners
    this.workerClient.on('onReady', () => {
      this.emit('FFMPEGReady');
    });
    this.workerClient.on('onStdout', (msg) => this.emit('FFMPEGStdout', msg));
    this.workerClient.on('onFileReceived', () => this.emit('FFMPEGFileReceived'));
    this.workerClient.on('onDone', this.onDone);
  }

  _videoData = {};

  _videoFile = null;

  _videoBuffer = {};

  onDone = (result) => {
    this.emit('FFMPEGDone', result);
  };

  optimizeVideo = () => {
    this.workerClient.runCommand(
      COMMAND_OPTIMIZE_VIDEO,
      TOTAL_MEMORY,
    );
  };

  readAsArrayBuffer = async () => {
    this._videoBuffer = await readArrayBuffer(this._videoFile);
    return this.videoBuffer;
  };

    /**
     * @returns {Blob}
     * @returns {String}
     */
    convertBufferToBlob = (buffer) => {
      const newBuffer = buffer || this.videoBuffer;
      return newBuffer.byteLength ? arrayBufferToBlob(newBuffer) : null;
    };

    /**
     * @returns {File}
     */
    readAsDataURL = async (buffer, blob) => {
      const newBuffer = buffer || this.videoBuffer;
      const newBlob = blob || this.convertBufferToBlob(newBuffer);
      if (newBlob) {
        return readDataURL(newBlob);
      }
      return null;
    };

    set videoFile(file) {
      if (file && file.type) {
        this.workerClient.inputFile = file;
      }
      this._videoFile = file;
    }

    get videoFile() {
      return this._videoFile;
    }

    get duration() {
      return this._videoData.duration || 0;
    }

    get videoData() {
      return this._videoData;
    }

    get videoBuffer() {
      return this._videoBuffer;
    }

    /**
   *
   * @param file
   * @returns {Promise<{dataURL: *, arrayBuffer: {}, blob: String}>}
   */

  decode = async (file) => {
    this.videoFile = file;
    this.emit('processingFile');
    // Read File As ArrayBuffer
    const arrayBuffer = await this.readAsArrayBuffer();
    // convert to dataURL
    const dataURL = await this.readAsDataURL(arrayBuffer);

    const videoObjectUrl = URL.createObjectURL(this.videoFile);
    const video = document.createElement('video');
    video.src = videoObjectUrl;

    const promise = () => new Promise((r) => setTimeout(r, 1000));

    while (
      (video.duration === Infinity || Number.isNaN(video.duration))
            && video.readyState < VIDEO_READY_STATES.HAVE_ENOUGH_DATA
    ) {
      // eslint-disable-next-line no-await-in-loop
      await promise();
      video.currentTime = 10000000 * Math.random();
    }

    this._videoData = video;
    this.emit('processedFile');
    return { dataURL, arrayBuffer, blob: this.convertBufferToBlob() };
  };

  /**
   *
   * @returns {Promise<[]>}
   */

  extractFramesFromVideo = async () => {
    try {
      this.emit('extractingFrames');
      const video = this._videoData;

      let seekResolve = null;
      video.addEventListener('seeked', async () => {
        if (seekResolve) seekResolve();
      });
      const { duration } = video;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const [w, h] = [video.videoWidth, video.videoHeight];
      canvas.width = w;
      canvas.height = h;
      const frames = [];
      let currentTime = duration / 20;
      const interval = duration / 10;

      // eslint-disable-next-line no-return-assign
      const promise = () => new Promise((r) => (seekResolve = r));

      while (currentTime < duration) {
        video.currentTime = currentTime;
        // eslint-disable-next-line no-await-in-loop
        await promise();

        context.drawImage(video, 0, 0, w, h);
        const base64ImageData = canvas.toDataURL();
        frames.push(base64ImageData);

        currentTime += interval;
      }
      this.emit('extractedFrames');
      return frames;
    } catch (e) {
      console.log(e, 'Error with frames sequence');
    }
  };
}

export default WebVideo;
