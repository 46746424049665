import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';
import { userLogout } from 'state/user/actions';
import { getUser } from 'state/user/selectors';
import { getUserName } from 'utils/helpers/userHepers';
import { logo, preloaderAnim } from 'assets/images';
import { getCountOfRenderingFilesRequest } from 'state/videos/actions';
import { getCountRenderingFiles, selectIsMadeExport } from 'state/videos/selectors';
import { ROUTES } from 'constants/routes';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import { MobilesNavigation } from '../MobilesNavigation';
import {
  HeaderContainer,
  Logo,
  Welcome,
  Navigation,
  List,
  ListItem,
  PerformingRenders,
  BurgerButton,
} from './Header.styled';
import { Icon } from '../Icon';
import { useTrackEvent } from '../../hooks';

const SCREEN_SIZE_SM = 768;

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenWidth = useWindowWidth();
  const user = useSelector(getUser);
  const { handleTrack } = useTrackEvent();

  const isMadeExport = useSelector(selectIsMadeExport);
  const isExistedPerformingRenders = useSelector(getCountRenderingFiles);
  const { t } = useTranslation('common');
  const [isAppearedNavigation, setIsAppearedNavigation] = useState(false);

  const handleLogout = () => {
    if (!isMadeExport) {
      handleTrack(MIXPANEL_EVENTS.EXIT_NO_EXPORT);
    }
    dispatch(userLogout({}));
  };
  const handleToggleNavigation = () => setIsAppearedNavigation((prevState) => !prevState);

  useEffect(() => {
    if (!isAppearedNavigation && screenWidth <= SCREEN_SIZE_SM) {
      navigate('/');
    }
  }, [isAppearedNavigation, screenWidth]);

  useEffect(() => {
    dispatch(getCountOfRenderingFilesRequest({ limit: 1, offset: 0, status: [2] }));
  }, []);

  return (
    <HeaderContainer>
      <Logo src={logo} alt="Tumplate" />
      <Welcome>
        {t('Welcome')}
        ,
        {' '}
        {getUserName({
          username: user.username,
          firstName: user.first_name,
          lastName: user.last_name,
        })}
      </Welcome>
      <Navigation>
        <BurgerButton
          type="button"
          onClick={handleToggleNavigation}
          isAppearNavigation={isAppearedNavigation}
        >
          <span />
          <span />
          <span />
        </BurgerButton>
        <List>
          <ListItem>
            <Link to={ROUTES.SHARED_LINKS}>
              <Icon name="shared-links" width={26} height={26} marginBottom="10px" />
            </Link>
            <Link to={ROUTES.SHARED_LINKS}>{t('Shared links')}</Link>
          </ListItem>
          <ListItem>
            <Link to={ROUTES.MY_FILES}>
              <Icon name="my-files" width={26} height={24} marginBottom="10px" />
            </Link>
            <Link to={ROUTES.MY_FILES}>{t('My files')}</Link>
            {!!isExistedPerformingRenders && (
              <PerformingRenders>
                <img
                  src={preloaderAnim}
                  alt="Rendering..."
                  title={t('You have performing renders')}
                />
              </PerformingRenders>
            )}
          </ListItem>
          <ListItem>
            <Link to={ROUTES.ACCOUNT.OVERVIEW}>
              <Icon name="account" width={26} height={26} marginBottom="10px" />
            </Link>
            <Link to={ROUTES.ACCOUNT.OVERVIEW}>{t('Account')}</Link>
          </ListItem>
          <ListItem>
            <button type="button" onClick={handleLogout}>
              <Icon name="logout-icon" width={26} height={24} marginBottom="10px" />
            </button>
            <button type="button" onClick={handleLogout}>
              {t('Log Out')}
            </button>
          </ListItem>
        </List>
      </Navigation>
      {isAppearedNavigation && screenWidth <= SCREEN_SIZE_SM && (
        <MobilesNavigation handleLogout={handleLogout} />
      )}
    </HeaderContainer>
  );
};
