import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { editorSelectors } from 'state/editor';
import { animationSelectors } from 'state/animation';
import { draftsSelectors } from 'state/drafts';
import { animationsSelectors } from 'state/animations';
import { DEFAULT_INDEX } from 'constants/common';
import { EditorContext } from './EditorContext';
import { rules } from './rules';

/**
 * textTab, imagesTab are dynamically defined.
 *
 * @type {{
 * previousVersionData: null, audioTab: {isFade: boolean, isMute: boolean, playerState: number, percentOfPlayer: number}, textTab: {textGroups: {}, textLayers: {}}, isReady: boolean, length: string, description: string, isLoadingDataOfAnimation: boolean, imagesTab: {imagesControls: {}, imageEditFrames: {}, imageRefs: {}}, projectName: string, version: string, isChangingDraft: boolean, controlsTab: {}}}
 */
const DEFAULT_VALUES = {
  length: '',
  version: '',
  projectName: '',
  description: '',
  previousVersionData: null,
  isReady: false,
  isChangingDraft: false,
  isLoadingDataOfAnimation: false,
  selectedDraft: null,
  videoPreparingStatus: false,
  draftPreparingStatus: false,
  renderType: DEFAULT_INDEX,
  outputCommand: null,
  outputUrl: null,
};

export const EditorProvider = ({ children }) => {
  const projectName = useSelector(editorSelectors.getProjectName);
  const description = useSelector(editorSelectors.getDescription);
  const length = useSelector(animationSelectors.animationLengthSelector);
  const version = useSelector(editorSelectors.getEditorVersion);
  const animation = useSelector(animationSelectors.animationDataSelector);
  const selectedDraft = useSelector(draftsSelectors.getSelectedDraft);
  const customLength = useSelector(editorSelectors.getCustomLength);
  const selectAnimationName = useSelector(animationsSelectors.getSelectAnimationName);

  const [activeTab, setActiveTab] = useState(-1);

  const [isCustomLength, setIsCustomLength] = useState(false);

  const [isPaused, setIsPaused] = useState(true);

  const [isOpenFileModal, setIsOpenFileModal] = useState(false); // save file modal

  const [isOpenedConfirmRenderModal, setIsOpenedConfirmRenderModal] = useState(false);

  const [isChangedText, setIsChangedText] = useState(false);

  const form = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(rules),
  });

  const onChangeTab = useCallback((tabValue) => {
    setActiveTab(tabValue);
  }, []);

  const context = useMemo(() => ({
    activeTab,
    onChangeTab,
    setIsCustomLength,
    isCustomLength,
    isOpenFileModal,
    setIsOpenFileModal,
    isPaused,
    setIsPaused,
    isOpenedConfirmRenderModal,
    setIsOpenedConfirmRenderModal,
    isChangedText,
    setIsChangedText,
  }), [
    activeTab, isCustomLength, isOpenFileModal,
    isPaused, isOpenedConfirmRenderModal, isChangedText,
  ]);

  useEffect(() => {
    if (animation) {
      form.reset({
        ...form.getValues(),
        projectName,
        description,
        selectedDraft,
      });
    }
  }, [selectedDraft, projectName, description]);

  useEffect(() => {
    if (animation) {
      form.reset({
        ...form.getValues(),
        length,
      });
    }
  }, [length]);

  useEffect(() => {
    form.reset({ ...form.getValues(), version });
  }, [version, selectAnimationName]);

  useEffect(() => { setIsCustomLength(false); }, [selectAnimationName]);

  useEffect(() => {
    if (selectedDraft && customLength) {
      setIsCustomLength(true);
    }
  }, [selectedDraft, customLength]);

  return (
    <FormProvider {...form}>
      <EditorContext.Provider value={context}>
        {children}
      </EditorContext.Provider>
    </FormProvider>
  );
};
