import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { notifyStore } from '../../utils/helpers/notifyStore';

export const Notification = ({
  children, iconClass, title, options,
}) => {
  const [{ content, head }, setData] = useState({ content: children, head: title });
  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (options.key) {
      // eslint-disable-next-line react/prop-types
      notifyStore.subscribe(({ title: t, content: c }) => setData({ content: c, head: t }), options.key);
    }
  }, [options]);
  return (
    <div className="notify">
      <div className="notify__icon">
        <span className={`${iconClass || 'icon-success-icon'}`} />
      </div>
      <div className="notify__content">
        {title && <b className="notify__title">{head}</b>}
        <span className="notify__text text-cut">{content}</span>
      </div>
    </div>
  );
};

Notification.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  iconClass: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.instanceOf(Object).isRequired,
};

Notification.defaultProps = {
  iconClass: '',
  title: '',
};
