import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { customFullView } from 'assets/images';
import {
  Title, FullViewBtn, FullViewImg,
  NotesWrapper, NotesLabel, Notes,
  Container, Wrapper,
} from './PlayerHeader.styled';

/**
 *
 * @param isLottieReady {boolean}
 * @param onOpenFullView {function}
 * @returns {JSX.Element}
 * @constructor
 */
export const PlayerHeader = ({ isLottieReady, onOpenFullView }) => {
  const form = useFormContext();
  const { t } = useTranslation('common');
  return (
    <Container>
      <Wrapper>
        <Controller
          name="projectName"
          control={form.control}
          defaultValue="Untitled project"
          render={({ field: { onChange, value, onBlur } }) => (
            <Title
              type="text"
              onBlur={onBlur}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={t('Project name')}
            />
          )}
        />
        <Controller
          name="description"
          defaultValue=""
          control={form.control}
          render={({ field: { onChange, value, onBlur } }) => (
            <NotesWrapper>
              <NotesLabel>Notes:</NotesLabel>
              <Notes
                value={value}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.value)}
                placeholder={t('Project description')}
              />
            </NotesWrapper>
          )}
        />
      </Wrapper>
      {isLottieReady && (
        <FullViewBtn
          type="button"
          onClick={onOpenFullView}
        >
          <FullViewImg
            src={customFullView}
            alt={t('Open in new window')}
            title={t('Open in new window')}
          />
        </FullViewBtn>
      )}
    </Container>
  );
};
