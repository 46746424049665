import { createSelector } from 'reselect';
import {
  prop, identity, path, compose, sort, memoizeWith, find, propEq,
  propOr, pathOr, view, map, nth, defaultTo, anyPass, last, slice,
} from 'ramda';

import {
  decRGB2Hex,
  getImageLayersByGroup,
  getImageLayersGroups,
  assetsWithRefs,
  animationLength,
  getLengthOptions,
  dataLayerEffects, getFilteredImageLayersGroups,
} from 'utils/animationHelpers';
import { findValueAndIndexByPropValue } from 'utils/helpers/animationsPath';
import { IMAGE_LAYER } from '../../constants/animationsPath';

export const animationDataSelector = createSelector(path(['animation']), identity);

const globalColorSlotsView = compose(slice(59, 66), defaultTo([]), view(dataLayerEffects));

export const globalColorsSelector = createSelector(animationDataSelector,
  (anim) => globalColorSlotsView(anim).map((color) => ({
    color: decRGB2Hex(path(['v', 'k'], color)),
    name: prop('nm', color),
  })));

export const imageLayerGroupsSelector = createSelector(
  animationDataSelector,
  compose(
    sort((a, b) => a - b),
    getImageLayersGroups,
  ),
);

export const filteredImageLayerGroupsSelector = createSelector(
  animationDataSelector,
  compose(
    sort((a, b) => a - b),
    getFilteredImageLayersGroups,
  ),
);

export const imageLayerByGroupSelector = createSelector(animationDataSelector,
  (anim) => memoizeWith(identity, (id) => getImageLayersByGroup(id)(anim)));

export const assetByIdSelector = createSelector(
  animationDataSelector,
  (anim) => memoizeWith(identity, (id) => {
    const { refId } = last(
      findValueAndIndexByPropValue('nm', `IMAGE LAYER ${id.split(/\D[^0-9]*/gm)[1]}`,
        prop('layers', anim)),
    );
    return find(propEq('id', refId), assetsWithRefs(anim));
  }),
);

export const animationAspectRatioSelector = createSelector(animationDataSelector,
  (anim) => propOr(1, 'w', anim) / propOr(1, 'h', anim));

export const getAnimationDataRequestPending = createSelector(pathOr(true,
  ['pending', 'getAnimationDataRequest']), identity);

export const animationLengthOptionsSelector = createSelector(
  animationDataSelector,
  compose(
    map(path(['v', 'k'])),
    getLengthOptions,
    defaultTo([]),
    view(dataLayerEffects),
  ),
);

export const animationLengthSelector = createSelector(
  animationDataSelector,
  animationLengthOptionsSelector,
  (anim, options) => compose(
    nth,
    (efs) => view(animationLength(efs))(efs),
    defaultTo([]),
    view(dataLayerEffects),
  )(anim)(options),
);

export const getPendingUploadingCsv = createSelector(
  anyPass([
    pathOr(false, ['pending', 'createVideoByCsvRequest']),
    pathOr(false, ['pending', 'uploadAnimationCsvRequest']),
  ]),
  identity,
);

export const getPendingDownloadingCsv = createSelector(
  pathOr(false, ['pending', 'getAnimationCsvRequest']),
  identity,
);

export const getCsvStatus = createSelector(
  pathOr(null, ['animation', 'csvStatus']),
  identity,
);

export const selectImageLayers = createSelector(
  [animationDataSelector],
  (anim) => anim.layers.filter((layer) => layer.nm.includes(IMAGE_LAYER)),
);

export const selectTemplateName = createSelector(path(['animation', 'nm']), identity);
