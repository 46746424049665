import { COLORS } from '../../constants/styles';

export const INPUT_STYLES = {
  height: '52px',
  borderRadius: '5px',
  fontSize: '18px',
};
export const LABEL_STYLES = {
  fontSize: '12px',
  textTransform: 'uppercase',
  color: COLORS.LABEL_COLOR,
  letterSpacing: '.08em',
};
