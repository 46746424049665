import {
  take, fork, call, put, select,
} from 'redux-saga/effects';
import { webhookService } from 'api';
import { sagasManager } from 'utils';
import { notify } from 'utils/helpers';
import types from './types';
import {
  getWebhookParamsSuccess,
  webhookCreateSuccess,
  webhookDeleteSuccess,
  webhookRequestFailure,
  webhooksGetSuccess,
  webhookUpdateSuccess,
} from './actions';
import { selectWebhookList } from './selectors';

function* createWebhook() {
  while (true) {
    try {
      const action = yield take(types.WEBHOOK_CREATE_REQUEST);
      const { model } = yield call(() => webhookService.createWebhook(action.payload));
      yield put(webhookCreateSuccess(model));
      notify.success('Webhook was added successfully');
    } catch (e) {
      yield put(webhookRequestFailure());
      notify.error('Webhook was not added');
    }
  }
}

function* getWebhookList() {
  while (true) {
    try {
      yield take(types.WEBHOOKS_GET_REQUEST);
      const { data } = yield call(() => webhookService.getWebhooks());
      yield put(webhooksGetSuccess(data));
    } catch (e) {
      yield put(webhookRequestFailure());
      notify.error('Webhooks were not loaded');
    }
  }
}

function* updateWebhook() {
  while (true) {
    try {
      const {
        payload: {
          id, data,
        },
      } = yield take(types.WEBHOOK_UPDATE_REQUEST);
      const { model } = yield call(() => webhookService.updateWebhook(id, data));
      const list = yield select(selectWebhookList);
      const result = list.map((item) => (item.id === model.id ? model : item));
      yield put(webhookUpdateSuccess(result));
      notify.success('Webhook was updated successfully');
    } catch (e) {
      yield put(webhookRequestFailure());
      notify.error('Webhook was not updated');
    }
  }
}

function* deleteWebhook() {
  while (true) {
    try {
      const { payload: id } = yield take(types.WEBHOOK_DELETE_REQUEST);
      const { success } = yield call(() => webhookService.deleteWebhook(id));
      if (success) {
        const list = yield select(selectWebhookList);
        const data = list.filter((item) => item.id !== id);
        yield put(webhookDeleteSuccess(data));
        notify.success('Webhook was removed successfully');
      } else {
        yield put(webhookRequestFailure());
        notify.error('Webhook was not removed');
      }
    } catch (e) {
      yield put(webhookRequestFailure());
      notify.error('Webhook was not removed');
    }
  }
}

function* getWebhookParams() {
  try {
    yield take(types.GET_WEBHOOK_PARAMS_REQUEST);
    const res = yield call(() => webhookService.fetchWebhookParams());
    const producedResult = res.map((item) => ({ label: item.title, value: `%${item.key}%` }));
    yield put(getWebhookParamsSuccess(producedResult));
  } catch (e) {
    yield put(webhookRequestFailure());
    notify.error('Webhook params wasn\'t received');
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(createWebhook);
  yield fork(getWebhookList);
  yield fork(updateWebhook);
  yield fork(deleteWebhook);
  yield fork(getWebhookParams);
});
