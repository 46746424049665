import React, { useCallback } from 'react';
import { RENDER_STATUS } from 'constants/rendering';
import { useMyFiles } from 'hooks';
import { Icon } from '../Icon';
import { Button } from './Buttons.styled';

/**
 *
 * @param id {number}
 * @param draftId {number}
 * @param status {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const ShareButton = ({ id, draftId, status }) => {
  const { setSelectedFileIdsList, toggleShareLinksModal } = useMyFiles();

  const isSharingAllowed = draftId && status === RENDER_STATUS.SUCCESS;

  const handleShare = useCallback(() => {
    toggleShareLinksModal(true)();
    setSelectedFileIdsList([id]);
  }, [id]);

  return (
    <Button
      type="button"
      onClick={handleShare}
      disabled={!isSharingAllowed}
    >
      <Icon name="share" />
    </Button>
  );
};
