import React from 'react';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Row } from './PlayerButtons.styled';

const BUTTON_DIMENSION = 43;

const PAUSE = 'pause';
const PLAY = 'play';

/**
 *
 * @param onTogglePlayer {function}
 * @param onPlayFromStart {function}
 * @param isPaused {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const PlayerButtons = ({ onTogglePlayer, onPlayFromStart, isPaused = true }) => {
  return (
    <Row>
      <Button
        onClick={onPlayFromStart}
        type="button"
        buttonTheme="player"
        width={BUTTON_DIMENSION}
        height={BUTTON_DIMENSION}
      >
        <Icon name="reset" height={26} width={26} />
      </Button>
      <Button
        onClick={onTogglePlayer}
        type="button"
        buttonTheme="player"
        width={BUTTON_DIMENSION}
        height={BUTTON_DIMENSION}
      >
        <Icon name={isPaused ? PLAY : PAUSE} height={18} width={18} />
      </Button>
    </Row>
  );
};
