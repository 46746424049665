import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-left: -20px;
  margin-bottom: 11px;
  ${({ isColorOption, theme }) => (isColorOption ? `
    min-width: 45%;
    margin-right: 10px;
  ` : `
    z-index: 1;
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    @media(${theme.devices.SM}){
        margin-left: -16px;
        width: 93%;
    }
  `)}
`;

export const ColorOptionWrapper = styled.div`
  min-width: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  margin-left: 20px;
  padding: 3.6px 0 3.5px 0;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 23px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
`;

export const ColorOptionLabel = styled.span`
  display: flex;
  margin-left: 15px;
  margin-right: auto;
  padding-right: 16px;
  padding-top: 2px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const LabelContainer = styled.div`
  min-width: 55px;
  max-width: 171px;
  height: 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.MAIN};
  color: ${({ theme }) => theme.colors.WHITE};
  white-space: nowrap;
  @media(${({ theme }) => theme.devices.XS}){
    padding: 0 13px;
  }
`;

export const Label = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Row = styled.div`
  width: 100%;
`;
