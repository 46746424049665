import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({
  children, rootId,
}) => {
  const rootElement = document.getElementById(rootId);
  useEffect(() => {
    const element = document.createElement('div');

    rootElement.appendChild(element);
    return () => {
      rootElement.removeChild(element);
    };
    // eslint-disable-next-line
  }, [rootId]);

  return createPortal(
    children,
    rootElement,
  );
};

Portal.propTypes = {
  children: PropTypes.element,
  rootId: PropTypes.string,
};

Portal.defaultProps = {
  rootId: 'modal-root',
};

export default Portal;
