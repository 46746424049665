import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 96%;
  max-width: 96%;
  margin: 14px auto 0;
  @media(${({ theme }) => theme.devices.SM}) {
    order: 2;
    max-width: 100%;
  }
  .input-range {
    z-index: 2;
  }
`;

export const TimeLine = styled.div`
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 43px);
  margin-right: 43px;
  @media(${({ theme }) => theme.devices.XS}) {
    width: calc(100% - 69px);
    margin-right: 55px;
    margin-left: 12px;
  }
`;

export const KeyFrame = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  left: 5px;
  margin-top: -11px;
  margin-left: ${({ marginLeft }) => marginLeft}%;
  z-index: 3;
  border-radius: 100%;
  cursor: pointer;
`;
