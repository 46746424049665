import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { path } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { AUDIO_PLAYER_STATE } from 'constants/editor';
import { PlayerButtons } from 'ui-kit';
import { LottieInstance } from 'utils/helpers/lottieHelpers';
import { getPlayerState, selectReadinessImages } from 'state/editor/selectors';
import { setPlayerState } from 'state/editor/actions';
import { useEditor } from 'hooks';
import { Track } from './Track';
import { Frames } from './Frames';
import { TimeLine, Wrapper } from './PlayerControls.styled';

const PLAY = 'play';
const PAUSE = 'pause';

const DEFAULT_LOTTIE_FRAME = 0;

/**
 *
 * @param lottieElement {object}
 * @returns {JSX.Element}
 * @constructor
 */

export const PlayerControls = ({ lottieElement }) => {
  const dispatch = useDispatch();
  const playerState = useSelector(getPlayerState);

  const { isPaused, setIsPaused } = useEditor();

  const areReadyImages = useSelector(selectReadinessImages);

  const setFrameToLottie = useCallback((frameIndex, action = 'goToAndPlaySuper') => {
    lottieElement
      .current[action](frameIndex, true);
  },
  [lottieElement]);

  // todo: get rid of playerState in Redux, when audio tab will be refactored
  const onPlayFromStart = useCallback(() => {
    setIsPaused(true);
    if (playerState !== AUDIO_PLAYER_STATE.NOT_USED) dispatch(setPlayerState(AUDIO_PLAYER_STATE.RESET));
    setFrameToLottie(DEFAULT_LOTTIE_FRAME);
    requestAnimationFrame(() => { setIsPaused(false); });
  }, [playerState]);

  const calculatePlayerState = useMemo(() => {
    switch (playerState) {
      case AUDIO_PLAYER_STATE.USE_ONLY_PLAYER: return AUDIO_PLAYER_STATE.RESET;
      case AUDIO_PLAYER_STATE.PLAY: return AUDIO_PLAYER_STATE.PAUSE;
      case AUDIO_PLAYER_STATE.RESET: return AUDIO_PLAYER_STATE.PAUSE;
      default: return AUDIO_PLAYER_STATE.RESET;
    }
  }, [playerState]);

  const onTogglePlayer = useCallback(() => {
    setIsPaused((prevState) => !prevState);
    if (playerState !== AUDIO_PLAYER_STATE.NOT_USED) {
      dispatch(setPlayerState(calculatePlayerState));
    }
  }, [playerState, calculatePlayerState]);

  // listeners for adding audio
  useEffect(() => {
    const lottieNativeElement = lottieElement.current.el;
    const handlePlayFromStart = () => {
      setIsPaused(true);
    };
    if (lottieElement.current) {
      lottieNativeElement.addEventListener('addAudio', handlePlayFromStart);
      lottieNativeElement.addEventListener('deleteAudio', handlePlayFromStart);
      return () => {
        lottieNativeElement.removeEventListener('addAudio', handlePlayFromStart);
        lottieNativeElement.removeEventListener('deleteAudio', handlePlayFromStart);
      };
    }
  }, [lottieElement]);

  // play or pause lottie, if isPaused was changed
  useEffect(() => {
    if (!isPaused && areReadyImages) {
      requestAnimationFrame(() => {
        if (lottieElement.current) {
          const { currentFrame, totalFrames } = lottieElement.current.anim;
          const progress = (currentFrame / totalFrames) * 100;

          lottieElement.current.el.dispatchEvent(new CustomEvent(PLAY,
            { detail: { progress } }));
        }
      });
    } else if (isPaused && selectReadinessImages) {
      requestAnimationFrame(() => {
        if (lottieElement.current?.el) {
          lottieElement.current.el.dispatchEvent(new CustomEvent(PAUSE));
        }
      });
    }
  }, [isPaused, lottieElement, areReadyImages]);

  // stop lottie when we add subscriber to the lottie instance
  useEffect(() => {
    LottieInstance.subscribe(() => {
      const lottiePlayerElement = path(['current', 'el'], lottieElement);

      if (lottiePlayerElement) {
        lottiePlayerElement.dispatchEvent(new CustomEvent(PAUSE));
      }

      setIsPaused(true);
    }, PAUSE);
  }, [lottieElement, setIsPaused]);

  return (
    <Wrapper>
      <TimeLine>
        <Frames lottieElement={lottieElement} />
        <Track
          lottieElement={lottieElement}
          setFrameToLottie={setFrameToLottie}
          onTogglePlayer={onTogglePlayer}
        />
      </TimeLine>
      <PlayerButtons
        onTogglePlayer={onTogglePlayer}
        onPlayFromStart={onPlayFromStart}
        isPaused={isPaused}
      />
    </Wrapper>
  );
};
