import { path } from 'ramda';

export const generateDraftTitle = (animationName, date) => `${animationName}${date}`;

export const getDraftTitleByDraftModel = ({
  title, created_at,
  animation_name,
}) => title || generateDraftTitle(animation_name, created_at);

export const normalizeDraftsFileParamsToApi = (params) => {
  const {
    scaleX, scaleY, isTouched, isDisabled, ...newParams
  } = params.options;
  const scale = (scaleX + scaleY) / 2 || path(['zoom', 0], params.options);

  return {
    ...newParams, scale, use_original: Number(params.fileType === 'image/gif' ? 1 : isTouched), is_disabled: Number(isDisabled), tempId: null,
  };
};

export const normalizeDraftsFileParamsToFront = (params) => {
  const {
    scale, use_original: isTouched, is_disabled: isDisabled, ...newParams
  } = params;

  return {
    ...newParams,
    scale,
    isTouched: false,
    isDisabled: !!isDisabled,
    scaleX: scale,
    scaleY: scale,
  };
};

export const makeFileNameEnd = (version, length) => {
  const normalizedVersion = version ? version.replace(':', 'x').toLowerCase() : '';
  return `${normalizedVersion}_${length}s`;
};
