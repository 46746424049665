import styled from 'styled-components';

export const Divider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin: 30px auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  @media(${({ theme }) => theme.devices.SM}) {
    margin-top: 16px;
  }
  &::before, &::after {
    content: '';
    width: calc(50% - 26px);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.EXTRA_LIGHT_GREY};
  }
`;
