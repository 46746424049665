import {
  compose,
  view,
  filter,
  propSatisfies,
  includes,
  uniq,
  map,
  defaultTo,
  lensPath,
  drop,
  splitWhen,
  equals,
  head, last,
  path,
} from 'ramda';
import {
  IMAGE_PATH, IMAGE_LAYER, IMAGES_PROP, IMAGE_TYPES, DATA_CONST,
} from 'constants/animationsPath';
import {
  CUSTOM_NAMES_REGEXP, IMAGE_REF_ID_REGEXP, SPLIT_WORDS_REGEXP,
} from 'constants/regexp';
import { layers, testHide } from './core';

export const getImageLayers = compose(
  filter(propSatisfies((name) => name && includes(IMAGE_LAYER, name), 'nm')),
  defaultTo([]),
  view(layers),
);

export const imageLayerName = lensPath(IMAGE_PATH.NAME);

export const getImageLayerGroupAndName = compose(
  splitWhen(equals(' ')),
  drop(IMAGE_LAYER.length + 1),
  view(imageLayerName),
);

const toInt = (x) => parseInt(x.join(''), 10);

export const getImageLayerGroup = compose(
  toInt,
  head,
  getImageLayerGroupAndName,
);

export const getImageLayersGroups = compose(uniq, map(getImageLayerGroup), getImageLayers);

export const getFilteredImageLayersGroups = compose(
  uniq,
  map(getImageLayerGroup),
  (imageLayers) => imageLayers.filter((imageLayer) => testHide(imageLayer, DATA_CONST.EDITFRAME)),
  getImageLayers,
);

export const getImageLayersByGroup = (groupId) => compose(
  filter(compose(
    equals(groupId),
    getImageLayerGroup,
  )),
  getImageLayers,
);

export const tintColor = lensPath(IMAGE_PATH.TINT_COLOR);

export const chosenColor = lensPath(IMAGE_PATH.CHOSEN_COLOR);

export const tintAmount = lensPath(IMAGE_PATH.TINT_AMOUNT);

/**
 *
 * @param data {object}
 * @returns {*|string}
 */

export const getImageLayerTitle = (data) => {
  const layerCustomTitles = path(IMAGE_PATH.CUSTOM_NAME, data);
  if (layerCustomTitles && layerCustomTitles.match(new RegExp(CUSTOM_NAMES_REGEXP)) && layerCustomTitles.match(new RegExp(CUSTOM_NAMES_REGEXP))[1]) {
    return layerCustomTitles.match(CUSTOM_NAMES_REGEXP)[1];
  }
  const imageLayer = data[IMAGES_PROP.NAME];
  return last(imageLayer.split(SPLIT_WORDS_REGEXP)) || 'Image';
};

/**
 *
 * @param nm {string}
 * @returns {string}
 */

export const getRefId = (nm) => {
  return `image_${nm.split(IMAGE_REF_ID_REGEXP)[1]}`;
};

/**
 *
 * @param animationAspectRatio {number}
 * @param layerType {number}
 * @param data {object}
 * @returns {number|*}
 */
const getRatio = (animationAspectRatio, layerType, data) => {
  if (layerType === IMAGE_TYPES.BACKGROUND) return animationAspectRatio;
  if (layerType === IMAGE_TYPES.PRODUCT) return data.w / data.h;
  return 0;
};

/**
 *
 * @param data {object}
 * @param animationAspectRatio {number}
 * @returns {{layerType: (*|(function(*): (*))), aspectRatio: (number|*)}}
 */

export const getRatioAndLayerType = (data, animationAspectRatio) => {
  const layerType = path(IMAGE_PATH.TYPE, data);
  return {
    aspectRatio: getRatio(animationAspectRatio, layerType, data),
    layerType,
  };
};
