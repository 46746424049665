import React from 'react';
import PropTypes from 'prop-types';
import { identity, is } from 'ramda';

import './style.sass';

const Select = ({
  label, options, id, name, onChange, value,
}) => (
  <div className="select">
    <label
      className="select__label"
      htmlFor={id}
    >
      {is(Function, label) ? label(value) : label}
    </label>
    <select
      value={value || ''}
      onChange={onChange}
      className="select__select"
      name={name}
      id={id.toString()}
    >
      {
        options.map((option, index) => (
          <option key={option.key || `select-${option.value}-${index}`} value={option.value}>{option.label}</option>
        ))
      }
    </select>
  </div>
);

Select.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
};

Select.defaultProps = {
  name: '',
  value: '',
  onChange: identity,
};

export default Select;
