import { handleActions } from 'redux-actions';
import { normalize } from 'normalizr';
import { mergeIn } from 'utils/helpers/ramdaStateHelpers';
import { linksSchema } from './schema';
import types from './types';

const DEFAULT_STATE = {
  linkView: {
    isLoading: true,
  },
  filesOfLink: {
    isLoading: true,
  },
};

const reducer = handleActions(
  {
    [types.SET_LINKS]: mergeIn(({ payload: { data } }) => ({
      count: data.count,
      ...normalize(data.data, linksSchema),
    })),
    [types.GET_LINKS_REQUEST]: mergeIn(({ payload }) => ({ params: payload })),
    [types.GET_LINK_VIEW_REQUEST]: (state) => ({
      ...state,
      linkView: { isLoading: true },
    }),
    [types.GET_LINK_VIEW_SUCCESS]: (state, { payload }) => ({
      ...state,
      linkView: { ...payload, isLoading: false },
    }),
    [types.GET_LINK_VIEW_FAILURE]: (state) => ({
      ...state,
      linkView: {
        isLoading: false,
      },
    }),
    [types.GET_FILES_OF_LINK_REQUEST]: (state) => ({
      ...state,
      filesOfLink: {
        isLoading: true,
      },
    }),
    [types.GET_FILES_OF_LINK_SUCCESS]: (state, { payload }) => ({
      ...state,
      filesOfLink: {
        ...payload,
        isLoading: false,
      },
    }),
    [types.GET_FILES_OF_LINK_FAILURE]: (state) => ({
      ...state,
      filesOfLink: {
        isLoading: false,
      },
    }),
    [types.UPDATE_FILE_OF_LINK_SUCCESS]: (state, { payload }) => ({
      ...state,
      filesOfLink: {
        ...state.filesOfLink,
        data: [...payload],
      },
    }),
  },
  DEFAULT_STATE,
);

export default reducer;
