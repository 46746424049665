export const ICON_DIMENSION = 32;
export const BUTTON_SIZES = {
  HEIGHT: 34,
  WIDTH: 102,
};

export const DEFAULT_VALUES = {
  emails: '',
  title: '',
  comment: '',
  password: '',
  repeatPassword: '',
};
