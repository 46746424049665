import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';

export const {
  uploadTempFileRequest,
  setPageError,
} = createActions({
  [types.UPLOAD_TEMP_FILE_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/temp-files',
      selectorName: 'uploadTempFileRequest',
      method: 'POST',
      params,
      ...meta,
    }),
  ],
}, types.SET_PAGE_ERROR);
