import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  changeTextField,
  changeTextScale,
  changeTextStatus,
  changeTextVariable,
} from 'state/editor/actions';
import { Select } from 'components/forms';
import { selectTextLayer } from 'state/editor/selectors';
import { Icon, Toggle } from 'ui-kit';
import { mapArrayToOptions, mapArrayToOptionsWithIndex } from 'utils/mappers';
import { debounceFunc } from 'utils/helpers/commonHelpers';
import { useEditor, useTrackEvent } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import { Header } from './Header';
import { ColorControl } from './ColorControl';
import {
  Container, Field, Row, ScaleLabel, ScaleText, VariableLabel,
} from './TextField.styled';

const CHANGE_TEXT_VALUE = 'CHANGE_TEXT_VALUE';
const TIMEOUT = 500;

/**
 *
 * @param id {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const TextField = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const layer = useSelector((state) => selectTextLayer(state)(id));
  const { isChangedText, setIsChangedText } = useEditor();
  const { handleTrack } = useTrackEvent();

  const handleChange = useCallback(({ target: { name, value } }) => debounceFunc(() => {
    dispatch(changeTextField([name, value]));
    if (!isChangedText) {
      setIsChangedText(true);
      handleTrack(MIXPANEL_EVENTS.EDIT_TEXT);
    }
  }, TIMEOUT, CHANGE_TEXT_VALUE), [debounceFunc]);

  const handleChangeScale = useCallback(({ target: { name, value } }) => {
    dispatch(changeTextScale([name, value]));
  }, []);
  const handleChangeVariable = useCallback(({ target: { name, value } }) => {
    dispatch(changeTextVariable([name, Number(value)]));
  }, []);

  const handleTextStatus = useCallback(({ target: { name, checked } }) => {
    dispatch(changeTextStatus([name, !checked]));
  }, []);

  return layer.isTextLayer ? (
    <Container>
      <Header id={id} />
      <Field
        title={t(layer.isDisabled ? 'The field is disabled' : '')}
        disabled={layer.isDisabled}
        defaultValue={layer.value}
        onChange={handleChange}
        name={id}
      />
      <Row>
        <Toggle
          value={!layer.isDisabled}
          name={id}
          onChange={handleTextStatus}
          marginRight="10px"
        />
        {layer.isAllowColorPicker && (
        <ColorControl
          id={id}
          isGlobalColor={layer.isGlobalColor}
          relatedToColor={layer.relatedToColor}
          selectedColor={layer.selectedColor}
        />
        )}
        {layer.scaleValues && (
        <Select
          onChange={handleChangeScale}
          id={`select-${id}`}
          name={id}
          value={layer.selectedScale}
          label={(
            <ScaleLabel>
              {t('Tt')}
              <ScaleText>{layer.selectedScale}</ScaleText>
            </ScaleLabel>
          )}
          options={mapArrayToOptions(layer.scaleValues)}
        />
        )}
        {layer.variables && (
        <Select
          id={`select-${id}`}
          name={id}
          value={layer.selectedVariable}
          onChange={handleChangeVariable}
          label={(
            <VariableLabel>
              <Icon name="star" width={10} height={10} />
              {layer.variables[layer.selectedVariable]}
              <Icon name="arrow-down" width={10} height={10} />
            </VariableLabel>
          )}
          options={mapArrayToOptionsWithIndex(layer.variables)}
        />
        )}
      </Row>
    </Container>
  ) : (<></>);
};
