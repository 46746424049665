import styled from 'styled-components';
import { typography, layout } from 'styled-system';
import { space } from '@styled-system/space';
import { SORTING_ORDER } from 'constants/common';

export const Root = styled.th`
  ${layout}
  ${typography}
  ${space}
  box-sizing: border-box;
  cursor: pointer;
  ${({ isLabel, order }) => isLabel && `
    ::after {
      margin-left: 5px;
      content: ${order === SORTING_ORDER.ASC ? '\'▲\'' : '\'▼\''}
    }
  `}
`;
