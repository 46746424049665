import styled from 'styled-components';
import { layout } from 'styled-system';
import { space } from '@styled-system/space';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(255,255,255,.83);
  overflow-y: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 398px;
  padding: 32px 27px 22px 33px;
  margin-top: 150px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: 0 3px 6px #00000029;
  ${({ isPrompt }) => isPrompt && 'height: 210px;'}
  ${layout};
  ${space};
  @media (${({ theme }) => theme.devices.MD}) {
    margin-top: 140px;
  }
`;

export const Question = styled.b`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: pre-line;
`;

export const Row = styled.div`
  width: 155px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-left: auto;
  margin-right: 0;
`;
