export const DEFAULT_VALUES = {
  username: '',
  password: '',
};

export const FIELDS_CONFIG = [{
  id: 'username',
  name: 'username',
  label: 'Username or email:',
  type: 'text',
},
{
  id: 'password',
  name: 'password',
  label: 'Password:',
  type: 'password',
}];

export const BLOG_LINK = 'https://tumplate.com/contact-us';
