const AudioInstance = (() => {
  const listeners = [];
  return {
    subscribe(cb) {
      listeners.push(cb);
    },
    delete() {
      listeners.forEach((listener) => listener('delete'));
    },
  };
})();

export default AudioInstance;
