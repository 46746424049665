import React, { useCallback } from 'react';
import { RENDER_TYPES } from 'constants/rendering';
import { resetTypesFilters } from 'utils/helpers';
import { List } from './Types.styled';
import { TypesItem } from './TypesItem';

const FIRST_PAGE = 1;

/**
 *
 * @param types {object} type as a key and boolean as a value
 * @param setTypes {function}
 * @param setPage {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const Types = ({ types, setTypes, setPage }) => {
  const handleChangeFilters = useCallback((type) => () => {
    setTypes((prev) => {
      const allTypesSelected = Object.values(prev).every((value) => value);
      if (allTypesSelected) {
        const resetFilters = resetTypesFilters(prev);
        return { ...resetFilters, [type]: true };
      }
      return { ...prev, [type]: !prev[type] };
    });
    setPage(FIRST_PAGE);
  }, []);
  return (
    <List>
      {RENDER_TYPES.map(({ label, type }) => (
        <TypesItem
          key={`filters-item-${type}`}
          type={type}
          label={label}
          onChangeFilter={handleChangeFilters(type)}
          isDefaultBackground={!types[type]}
        />
      ))}
    </List>
  );
};
