import {
  call,
  fork,
  cancelled,
  take,
  cancel,
  put,
} from 'redux-saga/effects';

import { normalize } from 'normalizr';
import {
  toLower, path, propOr,
} from 'ramda';

import sagasManager from 'utils/sagasManager';

import {
  pendingActions, requestTypes, errorActions, requestActions,
} from './state';
import requestBuilder from './methods';
import { getErrors } from './utils';

const { apiRequestSuccess, apiRequestError } = requestActions;

function* callApi({
  config, method, featureActions, selector, callbacks, schema, params, route, errorPath,
}) {
  const api = requestBuilder(config);
  const apiRequest = api[toLower(method)];
  const featureActionFinally = propOr(null, 'finally', featureActions);
  const featureActionSuccess = propOr(null, 'success', featureActions);
  const featureActionError = propOr(null, 'error', featureActions);
  const callbackFunctionsSuccess = propOr(null, 'success', callbacks);
  const callbackFunctionsError = propOr(null, 'error', callbacks);
  const callbackFunctionsFinally = propOr(null, 'finally', callbacks);
  const meta = propOr({}, 'meta', featureActions);
  yield put(pendingActions.setPendingStatus({ [selector]: true }));
  try {
    const response = yield call(apiRequest, { params, route });
    const result = response;
    let data = response;
    if (schema) {
      let normalizeData = normalize(path(['data', ...schema.pathData], result), schema.rules);
      if (schema.flat) {
        const flatProp = Object.keys(normalizeData.entities)[0];
        normalizeData = {
          ...normalizeData,
          entities: normalizeData.entities[flatProp],
        };
      }
      data = { ...result, data: normalizeData };
    }
    if (callbackFunctionsSuccess) {
      yield call(callbackFunctionsSuccess, data);
    }

    if (featureActionFinally) yield put(featureActionFinally(data));
    if (callbackFunctionsFinally) yield call(callbackFunctionsFinally, data);

    if (featureActionSuccess) {
      return yield put(apiRequestSuccess({
        data,
        action: featureActionSuccess,
        meta,
      }));
    }

    return data;
  } catch (error) {
    const errorMessages = meta.isOriginError ? getErrors(error, errorPath) : error.response;
    yield put(errorActions.setRequestError({ [selector]: errorMessages }));
    if (callbackFunctionsError) {
      yield call(callbackFunctionsError, errorMessages);
    }
    if (featureActionError) {
      return yield put(apiRequestError({
        action: () => featureActionError({ error }),
        error: errorMessages,
      }));
    }
    if (featureActionFinally) yield put(featureActionFinally(error));
    if (callbackFunctionsFinally) yield call(callbackFunctionsFinally, error);
    return error;
  } finally {
    if (yield cancelled()) {
      yield put(pendingActions.setPendingStatus({ [selector]: false }));
    }
    yield put(pendingActions.setPendingStatus({ [selector]: false }));
  }
}

function requestFlow(data) {
  return function* startRequest() {
    const task = yield fork(callApi, data);
    if (data.cancelActions) {
      yield take([data.cancelActions]);
      yield cancel(task);
    }
    return task;
  };
}

const sagaRequestApi = (config) => () => (next) => (action) => {
  next(action);
  try {
    if (action.type === requestTypes.API_REQUEST) {
      const {
        payload: {
          method, params, route, actions: featureActions, selectorName,
          schema, callbacks, cancelActions, errorPath,
        },
        type,
      } = action;
      sagasManager.addSagaToRoot(function* watcher() {
        const selector = selectorName || type;
        yield fork(requestFlow({
          method,
          params,
          route,
          config,
          selector,
          featureActions,
          schema,
          errorPath,
          callbacks,
          cancelActions,
        }));
      });
    }
  } catch (e) {
    throw new Error(e);
  }
};

export default sagaRequestApi;
