import * as yup from 'yup';

const URL_REG_EXP = /^(http|https):\/\/[^ "]+$/;

export function emailSchema() {
  return yup.string().required('Please enter an email address').email('Email is not valid');
}

export function usernameSchema() {
  return yup.string()
    .min(3, 'Username is too short')
    .max(60, 'Username is too long')
    .required('Please enter an email address or username');
}

export function passwordSchema() {
  return yup.string()
    .required('Password is required')
    .min(6, 'Password is too short')
    .max(30, 'Password is too long');
}

export function repeatPasswordSchema() {
  return yup.string()
    .when('password', {
      is: (val) => val && val.length > 0,
      then: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
    }).required('Password confirm is required');
}

/**
 *
 * @param label {string}
 * @param min {null | number}
 * @param max {null | number}
 * @returns {*}
 */

export function stringSchema({ label = '', min = null, max = null }) {
  if (min && max) {
    return yup.string()
      .required(`${label} is required`)
      .min(min, `${label} must be at least ${min} characters`)
      .max(max, `${label} must be at less than ${max} characters`);
  }
  if (min) {
    return yup.string()
      .required(`${label} is required`)
      .min(min, `${label} must be at least ${min} characters`);
  }
  if (max) {
    return yup.string()
      .required(`${label} is required`)
      .max(max, `${label} must be at less than ${max} characters`);
  }
  return yup.string().required(`${label} is required`);
}

export function numberSchema(label = '') {
  return yup.number().required(`${label} is required`);
}

export function urlSchema() {
  return yup
    .string()
    .required('Url is required')
    .matches(URL_REG_EXP, 'Incorrect url. Example: http://test.com');
}

export function keyValueArrayFieldSchema() {
  return yup.array().of(
    yup.object().shape({
      key: yup.string().required('Key is required'),
      value: yup.string().required('Value is required'),
    }),
  );
}
