import React, { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import { AuthGuard, GuestGuard } from 'guards';
import { ACCOUNT, AUTH, ROUTES } from 'constants/routes';
import { AccountLayout } from 'layouts/Account';
import {
  Main, PageError, SharedLinks,
  BillingInformation, Changelog,
  DesignPresets, Overview, MyFiles, Integrations,
} from '../pages';

const FullView = lazy(() => import('../pages/FullView/FullView'));
const DiscusPage = lazy(() => import('../pages/DiscusPage/DiscusPage'));
const SignUp = lazy(() => import('../pages/Auth/SignUp/SignUp'));
const SignUpSecondStepSuccess = lazy(() => import('../pages/Auth/SignUpSecondStepSuccess/SignUpSecondStepSuccess'));
const SignUpSuccess = lazy(() => import('../pages/Auth/SignUpSuccess/SignUpSuccess'));
const SignUpSecondStep = lazy(() => import('../pages/Auth/SignUpSecondStep/SignUpSecondStep'));
const SignIn = lazy(() => import('../pages/Auth/SignIn/SignIn'));
const ForgotPassword = lazy(() => import('../pages/Auth/ForgotPassword/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('../pages/Auth/ForgotPasswordSuccess/ForgotPasswordSuccess'));
const ResetPassword = lazy(() => import('../pages/Auth/ResetPassword/ResetPassword'));
const ResetPasswordSuccess = lazy(() => import('../pages/Auth/ResetPasswordSuccess/ResetPasswordSuccess'));

export const Router = () => useRoutes([
  {
    path: AUTH,
    element: (
      <GuestGuard />
    ),
    children: [
      {
        path: ROUTES.AUTH.LOGIN,
        element: <SignIn />,
      },
      {
        path: ROUTES.AUTH.SIGN_UP,
        element: <SignUp />,
      },
      {
        path: ROUTES.AUTH.SIGN_UP_SUCCESS,
        element: <SignUpSuccess />,
      },
      {
        path: `${ROUTES.AUTH.SIGN_UP_CONFIRM}/:userId/:code`,
        element: <SignUpSecondStep />,
      },
      {
        path: ROUTES.AUTH.SIGN_UP_CONFIRM_SUCCESS,
        element: <SignUpSecondStepSuccess />,
      },
      {
        path: `${ROUTES.AUTH.RESET_PASSWORD}/:userId/:code/`,
        element: <ResetPassword />,
      },
      {
        path: ROUTES.AUTH.RESET_PASSWORD_SUCCESS,
        element: <ResetPasswordSuccess />,
      },
      {
        path: ROUTES.AUTH.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: ROUTES.AUTH.FORGOT_PASSWORD_SUCCESS,
        element: <ForgotPasswordSuccess />,
      },
    ],
  },
  {
    path: ROUTES.ROOT,
    element: (
      <AuthGuard>
        <Main />
      </AuthGuard>
    ),
    children: [
      {
        path: ROUTES.SHARED_LINKS,
        element: <SharedLinks />,
      },
      {
        path: ROUTES.MY_FILES,
        element: <MyFiles />,
      },
      {
        path: ACCOUNT,
        element: <AccountLayout />,
        children: [
          {
            path: ROUTES.ACCOUNT.OVERVIEW,
            element: <Overview />,
          },
          {
            path: ROUTES.ACCOUNT.DESIGN_PRESETS,
            element: <DesignPresets />,
          },
          {
            path: ROUTES.ACCOUNT.BILLING_INFORMATION,
            element: <BillingInformation />,
          },
          {
            path: ROUTES.ACCOUNT.INTEGRATIONS,
            element: <Integrations />,
          },
          {
            path: ROUTES.ACCOUNT.CHANGELOG,
            element: <Changelog />,
          },
        ],
      },
    ],
  },
  {
    path: `${ROUTES.DISCUS_VIDEOS}/:code`,
    element: <DiscusPage />,
  },
  {
    path: `${ROUTES.FULL_VIEW}/:code`,
    element: <FullView />,
  },
  { path: '*', element: <PageError /> },
]);
