import React from 'react';
import { Iframe } from './IframePreview.styled';

/**
 *
 * @param link {string}
 * @param onLoad {function}
 * @param props {object} style props
 * @returns {JSX.Element}
 * @constructor
 */

export const IframePreview = ({ link, onLoad = () => {}, ...props }) => {
  return (
    <Iframe
      onLoad={onLoad}
      width="40vh"
      height="40vw"
      src={link}
      title="iframe_preview"
      {...props}
    />
  );
};
