import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constants/routes';
import { getIsAuthenticated } from 'state/user/selectors';

// For routes that can only be accessed by unauthenticated users

/**
 *
 * @param children {React.ReactNode}
 * @returns {JSX.Element}
 * @constructor
 */
export const GuestGuard = ({ children }) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  if (isAuthenticated) {
    return <Navigate to={ROUTES.ACCOUNT.OVERVIEW} />;
  }

  return (
    <>
      <Outlet />
      {children}
    </>
  );
};
