import { omit } from 'ramda';
import { blobAutoRevoker, revokeObjectUrlWithDelay } from './requestHelpers';
import { createFileByUrl } from './filesHelper';

export const fullViewInstance = (() => {
  let storage = JSON.parse(localStorage.getItem('fullViewStorage') || '{}');

  return {
    get: (key, callback) => {
      const reader = new FileReader();
      const urlBlob = storage[key];

      reader.onload = ({ target: { result: data } }) => {
        callback(JSON.parse(data));
      };
      reader.onerror = () => {
        callback(null);
      };
      createFileByUrl(urlBlob, 'data.json').then((result) => {
        reader.readAsText(new Blob([result]));
      }).catch(() => callback(null));
    },
    set: (key, data) => {
      const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
      const urlToBlob = URL.createObjectURL(blob, blob.type);

      blobAutoRevoker.add(key, urlToBlob);
      storage[key] = urlToBlob;
      localStorage.setItem('fullViewStorage', JSON.stringify(storage));
    },
    destroy: (key) => {
      const blobUrl = storage[key];
      storage = omit([key], storage);
      if (blobUrl) revokeObjectUrlWithDelay(blobUrl);
      localStorage.setItem('fullViewStorage', JSON.stringify(omit([key], storage)));
    },
  };
})();
