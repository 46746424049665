import React from 'react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { SwiperSlide } from 'swiper/react/swiper-react';
import { Image, StyledSwiper, Wrapper } from './Slider.styled';

/**
 *
 * @param slides {array} array of object, which include content prop
 * @param props {object} style_props for slider and image
 * @returns {JSX.Element}
 * @constructor
 */

export const Slider = ({ slides, ...props }) => {
  return (
    <StyledSwiper
      modules={[Navigation, Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      {...props}
    >
      {slides.map(({ content }) => (
        <SwiperSlide>
          <Wrapper>
            <Image src={content} alt="slide" {...props} />
          </Wrapper>
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
};
