import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';

export const {
  createLinkRequest,
  updateLinkRequest,
  getLinksRequest,
  deleteLinkRequest,
  getLinkViewRequest,
  getLinkViewSuccess,
  getLinkViewFailure,
  getFilesOfLinkRequest,
  getFilesOfLinkSuccess,
  getFilesOfLinkFailure,
  updateFileOfLinkRequest,
  updateFileOfLinkSuccess,
  updateFileOfLinkFailure,
  setLink,
  setLinks,
  deleteLink,
} = createActions({
  [types.CREATE_LINK_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/video-links',
      selectorName: 'createLinkRequest',
      method: 'POST',
      params,
      actions: {
        success: setLink,
      },
      ...meta,
    }),
  ],
  [types.UPDATE_LINK_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/api/video-links/${id}`,
      selectorName: 'updateLinkRequest',
      method: 'PUT',
      params,
      actions: {
        success: setLink,
      },
      ...meta,
    }),
  ],
  [types.GET_LINKS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/api/video-links',
      selectorName: 'getLinksRequest',
      method: 'GET',
      params,
      actions: {
        success: setLinks,
      },
      ...meta,
    }),
  ],
  [types.DELETE_LINK_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/api/video-links/${id}`,
      selectorName: 'deleteLinkRequest',
      method: 'DELETE',
      params,
      ...meta,
    }),
  ],
},
types.SET_LINKS,
types.SET_LINK,
types.GET_LINK_VIEW_REQUEST,
types.GET_LINK_VIEW_SUCCESS,
types.GET_LINK_VIEW_FAILURE,
types.GET_FILES_OF_LINK_REQUEST,
types.GET_FILES_OF_LINK_SUCCESS,
types.GET_FILES_OF_LINK_FAILURE,
types.UPDATE_FILE_OF_LINK_REQUEST,
types.UPDATE_FILE_OF_LINK_SUCCESS,
types.UPDATE_FILE_OF_LINK_FAILURE);
