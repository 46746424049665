export const animationInstance = (() => {
  let tempData;
  let listOfHandlers = [];

  return {
    get: () => tempData,
    set: (data) => {
      tempData = { ...data };
      listOfHandlers.map((handler) => handler(data));
    },
    subscribe: (handler) => {
      listOfHandlers.push(handler);
    },
    killAllSubscribers: () => {
      listOfHandlers = [];
    },
  };
})();
