import React, { memo } from 'react';
import { BadgeFormat } from 'ui-kit';
import { Item } from './TypesItem.styled';

/**
 *
 * @param label {string}
 * @param type {number} render_type
 * @param isDefaultBackground {boolean}
 * @param onChangeFilter {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const TypesItem = memo(({
  label = '', type, isDefaultBackground = false, onChangeFilter,
}) => (
  <Item onClick={onChangeFilter}>
    <BadgeFormat label={label} type={type} isDefaultBackground={isDefaultBackground} />
  </Item>
));
