import styled from 'styled-components';
import { layout, position, color } from 'styled-system';
import { space } from '@styled-system/space';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';

export const Root = styled.div`
  max-width: 100%;
  ${({ type }) => type === PRELOADER_TYPES.CONTAINER && `
    position: relative;
  `}
  ${({ type }) => type === PRELOADER_TYPES.CONTAINER && `
    position: relative;
  `}
  ${layout}
  ${position}
  ${space}
  ${color}
`;

export const PreloaderItem = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.EXTRA_LIGHT};
  ${({ type }) => type === PRELOADER_TYPES.OVERLAY && `
    position: fixed;
  `}
  ${({ type }) => type === PRELOADER_TYPES.CONTAINER && `
    position: absolute;
  `}
  ${({ type }) => type === PRELOADER_TYPES.CERTAIN && `
    position: static;
  `}
  ${color}
`;

export const PreloaderImage = styled.img`
  ${({ size }) => size === PRELOADER_SIZES.SMALL && `
    max-width: 80px !important;
  `}
  ${({ size }) => size === PRELOADER_SIZES.MIDDLE && `
    max-width: 160px !important;
  `}
  ${({ size }) => size === PRELOADER_SIZES.LARGE && `
    max-width: 320px !important;
  `}
`;
