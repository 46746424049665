import styled from 'styled-components';
import DatePicker from 'react-datepicker-styled-components';
import TimePicker from 'rc-time-picker';

export const Title = styled.div`
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-left: 80px;
  font-size: 14px;
  font-weight: 300;
`;

export const FieldGroup = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  ${({ isDate }) => (isDate && 'align-items: center')};
`;

export const IconsGroup = styled.div`
  position: absolute;
  right: 100%;
  margin-right: 32px;
  svg {
    margin-bottom: 8px;
  }
`;

export const Label = styled.label`
  width: 100%;
  margin-right: 8px;
  ${({ isClickable }) => isClickable && 'cursor: pointer;'};
  ${({ isDate }) => (isDate && `
    width: initial;
    svg {
      margin-left: 8px;
    }
  `)};
`;

export const SubTitle = styled.div`
  margin-bottom: 16px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  margin-top: 12px;
  padding: 8px 16px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 300;
  border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  resize: none;
`;

export const Input = styled.input`
  width: 80px;
  padding: 12px 16px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 300;
  border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  resize: none;
`;

export const DatePickerInput = styled(DatePicker)`
  width: 80px;
  padding: 12px 16px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 300;
  border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  resize: none;
`;

export const Error = styled.small`
  width: 100%;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.ERROR};
  font-size: 12px;
  font-weight: 300;
`;

export const SocialList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 10px 0 0;
`;

export const SocialItem = styled.li`
  position: relative;
  margin-right: 10px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  a {
    min-width: 102px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.CANCEL};
    border: 1px solid ${({ theme }) => theme.colors.CANCEL};
    color: ${({ theme }) => theme.colors.WHITE};
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    text-decoration: none;
    :hover{
      opacity: 0.7;
    }
  }
`;

export const TimePickerContainer = styled(TimePicker)`
  width: 60%;
  height: 40px;
  .rc-time-picker-input {
    height: 40px;
    color: #000;
    border-radius: 24px;
    font-size: 14px;
    padding-left: 15px;
    outline: none;
  }
  .rc-time-picker-panel-input-wrap {
    height: 40px;
    color: #000;
    border-radius: 24px;
    font-size: 14px;
    padding-left: 15px;
  }
  .rc-time-picker-clear {
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 20px;
    top: 3px;
    margin: 0;
  }
  .rc-time-picker-clear-icon::after {
    margin-top: 5px;
    font-size: 20px;
    color: #000;
  }
`;
