import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'ui-kit';
import { COLORS } from 'constants/styles';
import { useDispatch } from 'react-redux';
import { changeVideosIdsForDelete } from 'state/videos/actions';
import { Row, Text } from './ConfirmRenderModal.styled';

const BUTTON_SIZES = {
  HEIGHT: 34,
  WIDTH: 102,
};

/**
 *
 * @param isOpen {boolean}
 * @param setIsOpen {function}
 * @param confirmRendering {function}
 * @param idsForDelete {array<number>}
 * @returns {JSX.Element}
 * @constructor
 */

export const ConfirmRenderModal = ({
  isOpen, setIsOpen, confirmRendering, idsForDelete,
}) => {
  const { t } = useTranslation(['account', 'common', 'forms']);
  const dispatch = useDispatch();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    confirmRendering(true);
    handleClose(false);
  };

  const handleDelete = () => {
    dispatch(changeVideosIdsForDelete(idsForDelete));
    handleConfirm();
  };

  return (
    <Modal isOpen={isOpen}>
      <Text>
        {t('An export with these settings and filename already exists. What would you like to do?')}
      </Text>
      <Row>
        <Button
          width={BUTTON_SIZES.WIDTH}
          height={BUTTON_SIZES.HEIGHT}
          label={t('Overwrite')}
          color={COLORS.MAIN}
          onClick={handleDelete}
        />
        <Button
          width={BUTTON_SIZES.WIDTH}
          height={BUTTON_SIZES.HEIGHT}
          label={t('Keep both')}
          color={COLORS.MAIN}
          onClick={handleConfirm}
        />
        <Button
          width={BUTTON_SIZES.WIDTH}
          height={BUTTON_SIZES.HEIGHT}
          label={t('Cancel')}
          color={COLORS.RED}
          onClick={handleClose}
        />
      </Row>
    </Modal>
  );
};
