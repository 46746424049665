import styled from 'styled-components';

export const Container = styled.div`
  height: 70%;
  padding: 50px 0 50px 50px;
  color: ${({ theme }) => theme.colors.BLACK};
  background-color: ${({ theme }) => theme.colors.WHITE};
  font-family: ${({ theme }) => theme.fontFamily};
  * {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  h3 {
    font-size: 16px;
    padding-bottom: 10px;
    display: inline;
  }
    h3 + p {
      display: inline;
      margin-left: 10px;
      color:  ${({ theme }) => theme.colors.GREY};
    }
  ul {
    padding: 10px 0;
    list-style-type: none;
  }
  li {
    margin-bottom: 10px;
  }
`;
