import Ws from '@adonisjs/websocket-client';

import { getWsUrl } from 'utils/helpers/requestHelpers';
import { identity, propOr } from 'ramda';

const WsConnection = ({ reconnection = false, reconnectionAttempts = 0 }) => {
  let ws;
  let subscribers = {};
  const [ON_OPEN, ON_CLOSE] = ['onOpen', 'onClose']; // events

  const handleOpenConnection = (e) => propOr(identity, ON_OPEN, subscribers)(e);
  const handleCloseConnection = (e) => propOr(identity, ON_CLOSE, subscribers)(e);

  const initConnection = (token) => {
    ws = Ws(getWsUrl(), {
      reconnection,
      reconnectionAttempts,
    });

    ws.withJwtToken(token).connect();

    ws.on('open', handleOpenConnection);
    ws.on('close', handleCloseConnection);
    return ws;
  };

  const getSubscription = (topic) => {
    const subscription = ws.getSubscription(topic);
    return !subscription ? ws.subscribe(topic) : subscription;
  };

  return {
    get: () => ws,
    subscribe: (eventName, handler) => {
      subscribers[eventName] = handler;
    },
    unsubscribe: (eventName) => {
      delete subscribers[eventName];
    },
    unsubscribeAll: () => {
      subscribers = {};
    },
    initSocketConnection({ token }) {
      return !ws ? initConnection(token) : false;
    },
    closeSocketConnection: () => {
      subscribers = {};
      if (ws) {
        ws.close();
        ws = null;
      }
    },
    getChannel: (topic) => getSubscription(topic),
  };
};

export default WsConnection({ reconnection: true, reconnectionAttempts: 8 });
