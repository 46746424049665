import React from 'react';
import classnames from 'classnames';

import {
  CheckboxItem, Label, CheckedIcon,
} from './Checkbox.styled';

/**
 *
 * @param id
 * @param name {string}
 * @param value {string}
 * @param className {string}
 * @param onChange {function}
 * @param checked {boolean}
 * @param title {string}
 * @param type {string}
 * @param disabled {boolean}
 * @param size {string}
 * @param hasLabel {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const Checkbox = ({
  id,
  name = '',
  value = '',
  className = '',
  onChange = (a) => a,
  checked = false,
  title = '',
  type = 'checkbox',
  disabled = false,
  size = '',
  hasLabel = true,
}) => {
  return (
    <>
      <CheckboxItem
        type={type}
        id={id}
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <Label
        htmlFor={id}
        onChange={onChange}
        title={title}
        checked={checked}
        className={classnames(className)}
        size={size}
      >
        {checked && hasLabel ? <CheckedIcon width="100%" height="100%" name="checked-icon" /> : ''}
      </Label>
    </>
  );
};
