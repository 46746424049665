import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Icon, Input } from 'ui-kit';
import { COLORS } from 'constants/styles';
import { getBase64TempFileUrl, notify } from 'utils/helpers';
import { tempFileService } from 'api';
import {
  Button, InputWrapper, RootStyle,
} from './UrlUploading.styled';
import { rules } from './rules';

/**
 *
 * @param setFile {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const UrlUploading = ({ setFile }) => {
  const { t } = useTranslation('common');

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      url: '',
    },
    resolver: yupResolver(rules),
  });

  const onSubmit = useCallback(async (values) => {
    try {
      const result = await tempFileService.downloadFileUsingUrl(values);
      const url = getBase64TempFileUrl(result.fileName);
      const file = await fetch(url);
      const blob = await file.blob();
      setFile(new File([blob], result.fileName, { type: blob.type }));
      reset();
    } catch (e) {
      notify.error(t('Issue with downloading file using url'));
    }
  }, []);

  return (
    <RootStyle onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <Controller
          control={control}
          name="url"
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <Input
              type="text"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              inputStyleProps={{
                height: '46px',
                width: '250px',
                paddingLeft: '10px',
                borderRadius: '3px',
                fontSize: '13px',
              }}
              id="url"
              error={t(error?.message)}
            />
          )}
        />
        <Button type="submit">
          <Icon
            name="link-line"
            height={20}
            width={20}
            fill={COLORS.WHITE}
            stroke={COLORS.WHITE}
          />
        </Button>
      </InputWrapper>
    </RootStyle>
  );
};
