import styled from 'styled-components';
import { layout } from 'styled-system';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
`;

export const Frame = styled.div`
  background-color: #F9FAFC;
  border-radius: 4px;
  padding: 12px 12px 8px 12px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.02), 0 4px 6px 1px rgba(0, 0, 0, 0.06);
  ${layout}
`;

export const Video = styled.video`
  max-width: 60vw;
  max-height: 60vh;
  border: 1px solid #333;
  ${layout}
`;

export const TimeLine = styled.div`
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 43px);
  margin-right: 43px;
`;
