import * as yup from 'yup';

export const rules = {
  first_name: yup
    .string()
    .min(1)
    .max(30)
    .required('First name is required'),
  last_name: yup
    .string()
    .min(1)
    .max(30)
    .required('Last name is required'),
  company_name: yup
    .string()
    .min(2)
    .max(30)
    .required('Company name is required'),
  password: yup
    .string()
    .min(6, 'Min length 6 chars')
    .max(16, 'Max length 16 chars'),
  repeat: yup
    .string()
    .min(6, 'Min length 6 chars')
    .max(16, 'Max length 16 chars')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .when('password', {
      is: (val) => !!val,
      then: yup.string().required('Confirm password is required'),
    }),
};
