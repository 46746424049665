import styled from 'styled-components';

export const Picker = styled.div`
  position: relative;
  width: 29px;
  min-width: 29px;
  height: 29px;
  margin-right: 6px;
  border-radius: 100%;
  background-color: ${({ currentColor }) => currentColor};
  border: 1px solid ${({ theme }) => theme.colors.EXTRA_LIGHT_GREY};
  cursor: pointer;
`;

export const Lock = styled.img`
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 15px;
  height: 15px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 17px;
`;

export const MainColor = styled.div`
  width: 43px;
  height: 43px;
  margin: 0 20px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.EXTRA_LIGHT_GREY};
  cursor: pointer;
  transition: .3s;
`;

export const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 37px;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  :last-child {
    width: 46%;
  }
`;
