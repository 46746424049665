export const USER_ROLE = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  USER: 3,
};

export const MODULES = {
  BILLINGS: 'BILLINGS',
  USERS: 'USERS',
  CSV: 'CSV',
  INTEGRATIONS: 'INTEGRATIONS',
  FULL_CONTROL_ANIMATION: 'FULL_CONTROL_ANIMATION',
  TEAMS: 'TEAMS',
  SWITCHERS_MODIFYING: 'SWITCHERS_MODIFYING',
  TOP_DRAFTS: 'TOP_DRAFTS',
  DESIGN_PRESETS: 'DESIGN_PRESETS',
  ANALYTICS: 'ANALYTICS',
  VIDEO_EXPORTS: 'VIDEO_EXPORTS',
};

export const ACTIONS = {
  READ: 'READ',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
};

const SUPER_ADMIN_PERMISSIONS = {
  [MODULES.BILLINGS]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.USERS]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.CSV]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.INTEGRATIONS]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.FULL_CONTROL_ANIMATION]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.TEAMS]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.SWITCHERS_MODIFYING]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.TOP_DRAFTS]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.DESIGN_PRESETS]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.ANALYTICS]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.VIDEO_EXPORTS]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
};

const ADMIN_PERMISSIONS = {
  [MODULES.BILLINGS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.USERS]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.CSV]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.INTEGRATIONS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.FULL_CONTROL_ANIMATION]: {
    READ: 1,
    DELETE: 1,
    UPDATE: 1,
    CREATE: 1,
  },
  [MODULES.TEAMS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.SWITCHERS_MODIFYING]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.TOP_DRAFTS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.DESIGN_PRESETS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.ANALYTICS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.VIDEO_EXPORTS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
};

const USER_PERMISSIONS = {
  [MODULES.BILLINGS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.USERS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.CSV]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.INTEGRATIONS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.FULL_CONTROL_ANIMATION]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.TEAMS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.SWITCHERS_MODIFYING]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.TOP_DRAFTS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.DESIGN_PRESETS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.ANALYTICS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
  [MODULES.VIDEO_EXPORTS]: {
    READ: 0,
    DELETE: 0,
    UPDATE: 0,
    CREATE: 0,
  },
};

export const PERMISSIONS = {
  [USER_ROLE.SUPER_ADMIN]: SUPER_ADMIN_PERMISSIONS,
  [USER_ROLE.ADMIN]: ADMIN_PERMISSIONS,
  [USER_ROLE.USER]: USER_PERMISSIONS,
};
