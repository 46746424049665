/* eslint-disable */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ScrollBars from 'react-custom-scrollbars';

import './style.sass';
import { preloaderAnim } from '../../assets/images';

class LazyScroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: props.limit,
      offset: props.offset,
      isLoading: true,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleAction() {
    const {
      action,
    } = this.props;
    const {
      limit,
      offset,
    } = this.state;
    action({ limit, offset }, {
      callbacks: {
        finally: () => {
          this.setState({ isLoading: false });
        },
      },
    });
    this.setState({ isLoading: true });
  }

  componentDidMount() {
    this.handleAction();
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.limit !== this.state.limit) {
      return {
        isRunLoading: true,
      };
    }
    return { isRunLoading: false };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.isRunLoading) {
      this.handleAction();
    }
  }

  handleScroll(e) {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - (scrollTop + clientHeight) < 40) {
      if (this.props.hasMore && !this.state.isLoading) {
        this.setState({ limit: this.state.limit + this.props.limit });
      }
    }
  }

  render() {
    const {
      action, hasMore, count, children, result, itemRender, className, ...props
    } = this.props;
    const { isLoading } = this.state;
    return (
      <ScrollBars
        onScroll={this.handleScroll}
        className={`lazy-scroll ${className}`}
        {...props}
      >
        {
              result.map(itemRender)
          }
        <div className="lazy-scroll__preloader">
          {
              isLoading && (<img src={preloaderAnim} alt="Pending" />)
            }
        </div>
      </ScrollBars>
    );
  }
}

LazyScroll.propTypes = {
  limit: PropTypes.number,
  offset: PropTypes.number,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
};

LazyScroll.defaultProps = {
  limit: 10,
  offset: 0,
};

export default memo(LazyScroll);
