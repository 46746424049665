import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

const SEARCH_TIMEOUT = 400;
const FIRST_PAGE = 1;

/**
 *
 * @param setPage {function}
 * @returns {{debouncedSearchValue: string, setSearchValue: (value: (((prevState: string) => string) | string)) => void, searchValue: string}}
 */

export const useListSearch = (setPage) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_TIMEOUT);
  const previousDebouncedSearch = useRef('');

  useEffect(() => {
    previousDebouncedSearch.current = debouncedSearchValue;
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (previousDebouncedSearch !== debouncedSearchValue) {
      setPage(FIRST_PAGE);
    }
  }, [previousDebouncedSearch.current]);

  return {
    debouncedSearchValue,
    searchValue,
    setSearchValue,
  };
};
