export const CUSTOM_NAMES_REGEXP = new RegExp('UIname = \'(.*)\'');
export const HIDE_REGEXP = /HIDE=1/;
export const FONTS_SWITCHER_HIDE_REGEXP = /HIDE=2/;
export const HIDE_WITH_SPACES_REGEXP = /HIDE = 1/;
export const FONTS_SWITCHER_HIDE_WITH_SPACES_REGEXP = /HIDE = 2/;
export const HIDE_EVERYWHERE_REGEXP = /HIDE=3/;
export const HIDE_EVERYWHERE_WITH_SPACES_REGEXP = /HIDE = 3/;
export const SPLIT_WORDS_REGEXP = new RegExp(/\s\d\s/);
export const IMAGE_REF_ID_REGEXP = new RegExp(/\D[^0-9]*/gm);
export const GIF_VIDEO_REGEXP = new RegExp(/gif|video/g);
export const REGEX_OF_ARRAY_SWITCHERS = /\[(.|\n|\t|\r)*\]/g;
export const MEDIA_URL_REGEXP = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|mp4|mov))/i;
export const EDIT_FRAME_REGEXP = /EDITFRAME\s=\s\d*/gm;
export const SINGLE_WHITE_SPACE_CHAR_REG_EXP = /\s/;
export const DIGIT_REGEXP = /\d*/g;
export const BM_RT_REGEXP = /\$bm_rt = value$/gim;
export const ALL_SYMBOLS_END_REGEXP = /\s*$/;
