import React, { useState, useCallback, useMemo } from 'react';
import { formatSeconds } from 'utils/helpers/videoTrimmerHelpers';
import { customPlayIcon } from 'assets/images';
import { Player } from './Player';
import {
  Header,
  Description,
  Container,
  ButtonContainer,
  ToggleButton,
  PauseImg,
  VideoDuration,
  TrimmerEdge,
  TrimmerPointContainer,
  TrimmerPoint,
  PauseIcon,
} from './TrimmerCore.styled';
import { TrimmerLine } from './TrimmerLine';

const SLIDER_STEP = 0.01;
const SLIDER_MIN = 0;
const MAX_LENGTH = 500;

export const TrimmerCore = ({
  webVideoRef,
  timeLimit,
  timeRange,
  onTrim,
  onChangePlayback,
  playedSeconds,
  trimmerImages,
  videoDataURL,
  children,
}) => {
  const [isPlaying, setIsPlaying] = useState();
  const [minutes, seconds] = formatSeconds(webVideoRef.videoData.duration);
  const formattedTime = `${minutes}:${seconds}`;
  const duration = timeRange.end - timeRange.start;

  const isWarning = useMemo(() => duration.toFixed(2) > timeLimit || duration < 0, [duration, timeLimit]);

  const handlePlayerToggle = useCallback((value) => () => {
    setIsPlaying(value);
  }, []);

  const handlePlayerProgress = useCallback((progressSeconds) => {
    if (isPlaying) {
      onChangePlayback(progressSeconds);
    }
  }, [isPlaying]);

  const handleChangeLimit = (type) => (e) => {
    let newEndPoint = e.target.value;

    if (newEndPoint > webVideoRef.videoData.duration || newEndPoint < 0) {
      return;
    }

    if (newEndPoint) {
      newEndPoint = +newEndPoint;
      newEndPoint = Number(newEndPoint.toFixed(2));
    }

    onTrim({
      ...timeRange,
      [type]: newEndPoint,
    });
  };

  return (
    <>
      <Header>
        Trim video
      </Header>
      <Description>
        Choose the part of the video you would like to use.
      </Description>
      <Description>
        Maximum length is determined by the currently selected animation length.
      </Description>
      {!!videoDataURL && (
      <Player
        src={videoDataURL}
        timeRange={timeRange}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        onPlayerPlay={handlePlayerToggle(true)}
        onPlayerProgress={handlePlayerProgress}
        currentTime={playedSeconds}
      />
      )}
      <Container>
        <ButtonContainer>
          <ToggleButton
            onClick={handlePlayerToggle(!isPlaying)}
            type="button"
          >
            {isPlaying
              ? <PauseIcon />
              : <PauseImg src={customPlayIcon} alt="Pause" />}
          </ToggleButton>
        </ButtonContainer>
        <TrimmerLine
          onPausePlayer={handlePlayerToggle(false)}
          duration={webVideoRef.videoData.duration}
          onTrim={onTrim}
          timeLimit={timeLimit}
          timeRange={timeRange}
          currentTime={playedSeconds}
          trimmerImages={trimmerImages}
          moveCurrentTime={onChangePlayback}
        />
        <VideoDuration>{formattedTime}</VideoDuration>
      </Container>
      <TrimmerEdge>
        <TrimmerPointContainer>
          Start Point
          <TrimmerPoint
            warning={isWarning}
            type="number"
            value={timeRange.start}
            onChange={handleChangeLimit('start')}
            step={SLIDER_STEP}
            min={SLIDER_MIN}
          />
        </TrimmerPointContainer>
        <TrimmerPointContainer>
          Out Point
          <TrimmerPoint
            warning={isWarning}
            type="number"
            value={timeRange.end}
            onChange={handleChangeLimit('end')}
            step={SLIDER_STEP}
            min={SLIDER_MIN}
          />
        </TrimmerPointContainer>
        <TrimmerPointContainer>
          Length
          <TrimmerPoint
            type="number"
            value={(duration).toFixed(2)}
            disabled
            step={SLIDER_STEP}
            min={SLIDER_MIN}
            max={MAX_LENGTH}
          />
        </TrimmerPointContainer>
      </TrimmerEdge>
      {children()}
    </>
  );
};
