import { handleActions } from 'redux-actions';
import types from './types';

const DEFAULT_STATE = {
  isError: false,
  isLoading: false,
  data: [],
  count: 0,
  params: [],
};

const reducer = handleActions({
  [types.WEBHOOKS_GET_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [types.WEBHOOKS_GET_SUCCESS]: (state, action) => ({
    ...state,
    data: [...action.payload],
    isLoading: false,
  }),
  [types.WEBHOOK_CREATE_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [types.WEBHOOK_CREATE_SUCCESS]: (state, action) => ({
    ...state,
    isLoading: false,
    count: state.count + 1,
    data: [action.payload, ...state.data],
  }),
  [types.WEBHOOK_DELETE_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [types.WEBHOOK_DELETE_SUCCESS]: (state, action) => ({
    ...state,
    isLoading: false,
    count: state.count - 1,
    data: [...action.payload],
  }),
  [types.WEBHOOK_UPDATE_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [types.WEBHOOK_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    isLoading: false,
    data: [...action.payload],
  }),
  [types.WEBHOOK_REQUEST_FAILURE]: (state) => ({
    ...state,
    isError: true,
    isLoading: false,
  }),
  [types.GET_WEBHOOK_PARAMS_SUCCESS]: (state, action) => ({
    ...state,
    params: [...action.payload],
  }),
}, DEFAULT_STATE);

export default reducer;
