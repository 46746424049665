import React from 'react';
import { Wrapper } from './Badge.styled';

/**
 *
 * @param label {string}
 * @param props {object} style props
 * @returns {JSX.Element}
 * @constructor
 */

export const Badge = ({ label, ...props }) => (
  <Wrapper {...props}>{label}</Wrapper>
);
