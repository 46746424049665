import styled from 'styled-components';
import { layout, typography } from 'styled-system';
import { space } from '@styled-system/space';

export const RootStyle = styled.div`
  display: flex;
  flex-direction: column;
  ${space}
`;

export const Label = styled.label`
  margin-bottom: 10px;
  white-space: nowrap;
  font-size: 12px;
  ${typography}
`;

export const Field = styled.textarea`
  box-sizing: border-box;
  height: 60px;
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 5px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  font-size: 10px;
  line-height: 12px;
  resize: none;
  ${space}
  ${layout}
  ${typography}
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.ERROR};
  font-size: 10px;
  ${typography}
`;
