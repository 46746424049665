import React, { useCallback } from 'react';
import { path } from 'ramda';
import { v4 as uuidV4 } from 'uuid';
import { useSelector } from 'react-redux';
import {
  ColorPicker, Icon, Toggle, Select,
} from 'ui-kit';
import { getImageRef } from 'state/editor/selectors';
import { FILE_TYPES } from 'constants/files';
import { IMAGE_PATH } from 'constants/animationsPath';
import {
  HIDE_EVERYWHERE_REGEXP,
  HIDE_EVERYWHERE_WITH_SPACES_REGEXP,
  HIDE_REGEXP,
  HIDE_WITH_SPACES_REGEXP,
} from 'constants/regexp';
import {
  IconButton, RootStyle, SelectLabel, SelectText,
} from './Controls.styled';

/**
 *
 * @param isDisabled {boolean}
 * @param refId {string}
 * @param onCrop {function}
 * @param onRotate {function}
 * @param tintColor {string}
 * @param setTintColor {function}
 * @param tintAmount {string}
 * @param tint {number}
 * @param tintOptions {array}
 * @param onTintChange {function}
 * @param data {object}
 * @param setDisabled {function}
 * @param onOpenFileModal {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const Controls = ({
  isDisabled, refId, onCrop, onRotate,
  tintColor, setTintColor, tintAmount,
  tint, tintOptions, onTintChange,
  data, setDisabled, onOpenFileModal,
}) => {
  const imageRef = useSelector((store) => getImageRef(store)(refId));

  const isGif = imageRef?.fileType === FILE_TYPES.GIF;
  const tintValues = path(IMAGE_PATH.TINT_OPTIONS_ARRAY, data);

  const handleToggleDisabled = useCallback(({ target: { checked } }) => {
    setDisabled(!checked);
    if (checked && isGif) {
      onCrop(false, true, true);
    }
  }, [onCrop, isGif]);

  const isShowedTintsSelector = !(new RegExp(HIDE_REGEXP).test(tintValues))
    && !(new RegExp(HIDE_WITH_SPACES_REGEXP).test(tintValues))
    && !(new RegExp(HIDE_EVERYWHERE_REGEXP).test(tintValues))
    && !(new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(tintValues));

  const chosenColorTints = path(IMAGE_PATH.TINT_CHOSEN_COLOR_PATH, data);

  const isShowedColorTintsSelector = !(new RegExp(HIDE_REGEXP).test(chosenColorTints))
    && !(new RegExp(HIDE_WITH_SPACES_REGEXP).test(chosenColorTints))
    && !(new RegExp(HIDE_EVERYWHERE_REGEXP).test(chosenColorTints))
    && !(new RegExp(HIDE_EVERYWHERE_WITH_SPACES_REGEXP).test(chosenColorTints));

  return (
    <RootStyle>
      <Toggle
        value={!isDisabled}
        onChange={handleToggleDisabled}
      />
      <IconButton type="button" disabled={isDisabled && !isGif} onClick={onOpenFileModal}>
        <Icon name="camera" height={24} width={24} />
      </IconButton>
      {!isGif && (
        <IconButton
          type="button"
          onClick={onRotate}
          disabled={isDisabled}
        >
          <Icon name="rotate" height={30} width={30} />
        </IconButton>
      )}
      {tintValues && isShowedColorTintsSelector && (
      <ColorPicker
        id={`colorPicker-${uuidV4()}`}
        value={tintColor}
        onChange={setTintColor}
        name={`imageColorPicker${uuidV4()}`}
        globalColorIsDisabled
      />
      )}
      {tintValues && isShowedTintsSelector && (
      <Select
        id={`tint-select-${refId}`}
        label={(
          <SelectLabel>
            <Icon name="star" height={10} width={10} />
            <SelectText>{tintAmount}</SelectText>
            <Icon name="dropdown-icon" height={10} width={10} />
          </SelectLabel>
          )}
        value={tint}
        options={tintOptions}
        onChange={onTintChange}
      />
      )}
    </RootStyle>
  );
};
