import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowWidth } from '@react-hook/window-size';
import SelectFontsModal from 'components/SelectFontsModal/SelectFontsModal';
import './style.sass';
import { fontsActions, fontsSelectors } from 'state/fonts';
import { useDispatch, useSelector } from 'react-redux';
import { PRELOADER_SIZES, PRELOADER_TYPES } from 'constants/ui';
import { Preloader } from 'ui-kit';
import Portal from '../Portal/Portal';

const FontsSelector = ({ children }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const {
    isPending, fonts, selectedFonts, isSelectingPending,
  } = useSelector((store) => ({
    fonts: fontsSelectors.getFonts(store),
    selectedFonts: fontsSelectors.getSelectedFonts(store),
    isPending: fontsSelectors.getFontsRequestPending(store),
    isSelectingPending: fontsSelectors.getChangeFontsPending(store),
  }));

  const [isOpenSelectFonts, setIsOpenSelectFonts] = useState(false);

  const handleChange = (e) => {
    const { target: { checked, name } } = e;
    const tumplateFonts = fonts.find((font) => font.title === 'New Official Tumplate Fonts');
    if (name) {
      const action = checked ? fontsActions.selectFontRequest : fontsActions.unselectFontRequest;
      dispatch(action({ id: name }));
      if (!checked && tumplateFonts && selectedFonts.length === 1) {
        dispatch(fontsActions.selectFontRequest({ id: tumplateFonts.id }));
      }
      if (checked && tumplateFonts && selectedFonts.length === 1) {
        if (selectedFonts[0].title === 'New Official Tumplate Fonts') {
          dispatch(fontsActions.unselectFontRequest({ id: tumplateFonts.id }));
        }
      }
    } else {
      e.stopPropagation();
      setIsOpenSelectFonts(false);
    }
  };

  useEffect(() => {
    dispatch(fontsActions.getFontsRequest());
  }, []);

  return (
    <div className="select-font__row">
      <Preloader
        type={PRELOADER_TYPES.CONTAINER}
        size={PRELOADER_SIZES.SMALL}
        isLoading={isPending}
      >
        <div className="select-font__container">
          <div className="select-font__row-button-open">
            <ul className="select-font__list-selected">
              {
                selectedFonts.map(({ id, title }) => (
                  <li key={id}>{title}</li>
                ))
              }
            </ul>
            <button
              title={`${isOpenSelectFonts ? '' : 'Select font'}`}
              onClick={() => setIsOpenSelectFonts(true)}
              className="select-font__button"
              type="button"
            >
              {
                isOpenSelectFonts && (
                <>
                  {
                        screenWidth < 1200 ? (
                          <Portal>
                            <SelectFontsModal
                              isPortal
                              fonts={fonts}
                              isSelectingPending={isSelectingPending}
                              onChange={handleChange}
                            />
                          </Portal>
                        ) : (
                          <SelectFontsModal
                            isSelectingPending={isSelectingPending}
                            fonts={fonts}
                            onChange={handleChange}
                          />
                        )
                      }
                </>
                )
              }
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.815"
                height="39.488"
                viewBox="0 0 30.815 39.488"
                className="svg-button"
              >
                <path
                  className="a"
                  d="M35.5,51.861H24.161a2.711,2.711,0,0,1-2.711-2.706V19.646a2.711,2.711,0,0,1,2.711-2.706H36.035a2.691,2.691,0,0,1,1.916.79l8.843,8.828a2.676,2.676,0,0,1,.8,1.916V39.732a1.35,1.35,0,0,1-2.7,0V28.474L36.05,19.621H24.161v29.54H35.515a1.345,1.345,0,1,1,0,2.691Z"
                  transform="translate(-21.45 -16.94)"
                />
                <path
                  className="a"
                  d="M59.123,29.979H51.475a1.345,1.345,0,0,1-1.345-1.35v-8.2a1.345,1.345,0,1,1,2.691,0v6.857h6.3a1.35,1.35,0,0,1,0,2.7Z"
                  transform="translate(-35.785 -18.01)"
                />
                <path
                  className="a"
                  d="M70.444,76.957a6.312,6.312,0,1,1-6.307-6.307,6.312,6.312,0,0,1,6.307,6.307Zm-3.4-.735H64.872V74.046a.735.735,0,0,0-1.47,0v2.176H61.226a.735.735,0,0,0,0,1.47h2.186v2.176a.735.735,0,0,0,1.47,0V77.692h2.176a.735.735,0,1,0,0-1.47Z"
                  transform="translate(-39.629 -43.786)"
                />
              </svg>
            </button>
          </div>
          {children}
        </div>
      </Preloader>
    </div>
  );
};

FontsSelector.propTypes = {
  children: PropTypes.element,
};
FontsSelector.defaultProps = {
  children: <></>,
};

export default FontsSelector;
