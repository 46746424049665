import styled from 'styled-components';

export const Root = styled.div`
  @keyframes shownUp {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255, .4);
  animation: .5s shownUp;
  @media(${({ theme }) => theme.devices.SM}) {
    top: 50px;
    height: calc(100vh - 50px);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  max-height: 900px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: 0 4px 15px -2px ${({ theme }) => theme.colors.BORDER_COLOR};
  font-weight: 300;
  font-size: 13px;
  border-radius: 8px;
  @media(${({ theme }) => theme.devices.LGX}) {
    height: 630px !important;
  }
  @media(${({ theme }) => theme.devices.LG}) {
    max-width: calc(100% - 32px);
  }
  @media(${({ theme }) => theme.devices.SM}) {
    width: 100%;
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0;
    border: none;
  }
`;
