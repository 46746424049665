import * as yup from 'yup';

export const rules = {
  billing_name: yup
    .string()
    .min(3)
    .max(16)
    .required('Billing name is required'),
  billing_company_name: yup
    .string()
    .min(3)
    .max(16)
    .required('Billing company name is required'),
  billing_address_1: yup
    .string()
    .min(3)
    .max(16)
    .required('Billing address is required'),
  billing_address_2: yup
    .string()
    .min(3)
    .max(16)
    .required('Billing address 2 is required'),
  billing_city: yup
    .string()
    .min(3)
    .max(16)
    .required('Billing  city is required'),
  billing_zip: yup
    .string()
    .min(3)
    .max(16)
    .required('Billing  zip code is required'),
  billing_country: yup
    .string()
    .min(2)
    .max(16)
    .required('Billing  country is required'),
  billing_tax_id: yup
    .string()
    .min(2)
    .max(16),
};
