import React from 'react';
import { Select } from 'ui-kit';
import { RootStyle, Label } from './SwitchersItem.styled';

/**
 *
 * @param name {string}
 * @param values {array}
 * @param selectedControlValue {number | string}
 * @param index {number}
 * @param handleChange {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const SwitchersItem = ({
  name, values, selectedControlValue, index, handleChange,
}) => {
  return (
    <RootStyle>
      <Select
        id={name}
        label={(
          <Label>
            {values[selectedControlValue]}
          </Label>
        )}
        name={index}
        value={selectedControlValue}
        options={values.map((val, indexValue) => ({ value: indexValue, label: val }))}
        onChange={handleChange}
      />
    </RootStyle>
  );
};
