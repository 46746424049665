import React from 'react';
import { downloadFile, getFileUrl } from 'utils/helpers';
import { Icon } from '../Icon';
import { Button } from './Buttons.styled';

/**
 *
 * @param title {string}
 * @param videoFileId {number}
 * @param type {number}
 * @param status {number}
 * @param iconName {string}
 * @param props {object} style props
 * @returns {JSX.Element}
 * @constructor
 */

export const DownloadButton = ({
  title, videoFileId, type, status, iconName = 'load', ...props
}) => {
  return (
    <Button
      {...props}
      type="button"
      title={`Download ${title}`}
      onClick={downloadFile(
        getFileUrl({
          fileUrl: `files/${videoFileId}`, isUseSalt: true, isUseBase64: true, isDownloading: true,
        }),
        title,
        type,
        status,
      )}
    >
      <Icon name={iconName} />
    </Button>
  );
};
