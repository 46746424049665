import styled from 'styled-components';

export const Button = styled.button`
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Comment = styled.span`
  display: inline-block;
  padding-right: 10px;
`;
