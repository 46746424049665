import { last } from 'ramda';

export const normalizedTime = (time) => {
  if (!time || time === '') return '';
  const [hours, minutes] = time.split(/\D/g);
  const timeVector = last(time.split(/\d/));
  let timeZone = -(new Date().getTimezoneOffset() / 60) * 100;
  timeZone = timeZone > 1000 ? timeZone : `0${timeZone}`;
  const charTimeZone = timeZone > 0 ? '+' : '-';

  return `${timeVector === 'AM' ? hours : Number(hours) + 12}:${minutes}:00 ${charTimeZone}${timeZone}`;
};

const formattedDate = (date) => (date ? `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}` : '');

const formatSocials = (socials) => Object.keys(socials).filter((key) => socials[key]);

export const normalizedCommand = ({
  text, fullDate, time, socials,
}) => `${text}__${formattedDate(fullDate)} ${normalizedTime(time)}__[${formatSocials(socials)}]`;
