import styled from 'styled-components';

export const HeaderContainer = styled.header`
  position: relative;
  width: 100%;
  height: 99px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.WHITE};
  @media(${({ theme }) => theme.devices.SM}) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    height: 51px;
    background-color: ${({ theme }) => theme.colors.WHITE};
  };
`;

export const Logo = styled.img`
  max-width: 162px;
  margin-left: 34px;
  max-height: 60px;
  @media(${({ theme }) => theme.devices.XS_X}) {
    max-width: 100px;
    margin-left: 65px;
  };
  
  @media(${({ theme }) => theme.devices.SM}) {
    margin-left: 65px;
    max-height: 30px;
  };
  
  @media(${({ theme }) => theme.devices.MD}) {
    margin-left: 65px;
  }
`;

export const Welcome = styled.b`
  margin-top: 10px;
  margin-right: auto;
  margin-left: 26px;
  font-size: 12px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.BLACK};
  @media(${({ theme }) => theme.devices.XS}) {
    margin-top: 5px;
    margin-left: 16px;
  };
`;

export const Navigation = styled.nav`
  margin-right: 56px;
  @media(${({ theme }) => theme.devices.XS}) {
    margin-right: 16px;
  };
  @media(${({ theme }) => theme.devices.SM}) {
    margin-right: 26px;
  };
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  @media(${({ theme }) => theme.devices.SM}) {
    display: none;
  };
`;

export const ListItem = styled.li`
  display: grid;
  position: relative;
  margin-left: 64px;
  margin-top: 24px;
  &:hover {
    filter: 
      invert(48%) 
      sepia(72%) 
      saturate(3290%) 
      hue-rotate(178deg) 
      brightness(101%) 
      contrast(108%);
    color: ${({ theme }) => theme.colors.MAIN};
  }
  a, button {
    text-decoration: none;
    display: flex;
    justify-content: center;
    letter-spacing: 0;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.BLACK};
    background-color: transparent;
    border: 0;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.MAIN};
      filter: 
        invert(48%) 
        sepia(72%) 
        saturate(3290%) 
        hue-rotate(178deg) 
        brightness(101%) 
        contrast(108%);
    }
    svg {
      margin-bottom: 6px;
      width: 26px;
      height: 26px;
    }
  }
`;

export const PerformingRenders = styled.div`
  position: absolute;
  top: 100%;
  left: 15%;
  width: 30px;
  img {
    width: 100%;
  }
`;

export const BurgerButton = styled.button`
  width: 33px;
  height: 18px;
  display: none;
  flex-wrap: wrap;
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: none;
  @media(${({ theme }) => theme.devices.SM}) {
    display: flex;
  };
  span {
    width: 100%;
    height: 3px;
    display: inline-block;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.BORDER_COLOR};
    &:nth-child(2) {
      margin: 5px 0;
    }
  }
  ${({ isAppearNavigation }) => isAppearNavigation && `
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    span:nth-child(2) {
      display: none;
    }
    span:first-child {
      margin-top: 8px;
      margin-bottom: 1px;
      margin-left: -1px;
      transform: rotate(45deg);
    }
    span:last-child {
      margin-top: -6px;
      transform: rotate(-45deg);
    }
  `}
`;
