import { handleActions } from 'redux-actions';
import {
  compose, objOf, path, pathOr,
} from 'ramda';
import { normalize } from 'normalizr';
import { mergeDeep, mergeIn } from 'utils/helpers/ramdaStateHelpers';
import { videosSchema } from './schema';
import types from './types';

const reducer = handleActions(
  {
    [types.SET_VIDEOS]: mergeDeep(({ payload: { data } }) => {
      const { entities, result } = normalize(data.data, videosSchema);
      return {
        count: data.count,
        result,
        entities: {
          ...entities,
        },
      };
    }),
    [types.SET_PREPARE_TO_RENDER]: mergeDeep(compose(objOf('isPreparingToRender'), path(['payload']))),
    [types.SET_COUNT_OF_RENDERING_FILES]: mergeDeep(
      compose(
        objOf('renderingFilesCount'),
        ({ payload }) => pathOr(payload, ['data', 'count'], payload),
      ),
    ),
    [types.GET_VIDEOS_REQUEST]: mergeIn(({ payload }) => ({ params: payload })),
    [types.UPDATE_VIDEO_CLIENT_STATUS_SUCCESS]: mergeDeep(({ payload: { data: { model } } }) => ({
      entities: {
        videos: {
          [model.id]: model,
        },
      },
    })),
    [types.TOGGLE_TOP_VIDEO]: (state, { payload }) => ({
      ...state,
      entities: {
        ...state.entities,
        videos: {
          ...state.entities.videos,
          [payload]: {
            ...state.entities.videos[payload],
            draft: {
              ...state.entities.videos[payload].draft,
              is_top: Number(!state.entities.videos[payload].draft.is_top),
            },
          },
        },
      },
    }),
    [types.CHANGE_VIDEOS_IDS_FOR_DELETE]: (state, { payload }) => ({
      ...state,
      idsForDelete: [...payload],
    }),
    [types.CHANGE_IS_MAKING_EXPORT]: (state, { payload }) => ({
      ...state,
      isMadeExport: payload,
    }),
  },
  {
    idsForDelete: [],
    isMadeExport: false,
  },
);

export default reducer;
