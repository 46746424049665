import { ReactComponent as ArrowDown } from 'assets/images/arrow-down.svg';
import { ReactComponent as Camera } from 'assets/images/camera-icon.svg';
import { ReactComponent as Cancel } from 'assets/images/cancel.svg';
import { ReactComponent as CancelOnly } from 'assets/images/cancel-only.svg';
import { ReactComponent as Crop } from 'assets/images/crop.svg';
import { ReactComponent as CustomFullView } from 'assets/images/custom-full-view.svg';
import { ReactComponent as CustomLogo } from 'assets/images/custom-logo.svg';
import { ReactComponent as CustomUpload } from 'assets/images/custom-upload.svg';
import { ReactComponent as Checked } from 'assets/images/checked-icon.svg';
import { ReactComponent as CustomCheck } from 'assets/images/customCheck-icon.svg';
import { ReactComponent as CustomPlay } from 'assets/images/customPlay.svg';
import { ReactComponent as CustomReset } from 'assets/images/customReset.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/delete-icon-red.svg';
import { ReactComponent as Download } from 'assets/images/download.svg';
import { ReactComponent as DownloadNew } from 'assets/images/download-new.svg';
import { ReactComponent as Email } from 'assets/images/email.svg';
import { ReactComponent as LargeCustomCheck } from 'assets/images/largeCustomCheck.svg';
import { ReactComponent as Link } from 'assets/images/link.svg';
import { ReactComponent as LoadIcon } from 'assets/images/load-icon.svg';
import { ReactComponent as PlayerArrow } from 'assets/images/player-arrow.svg';
import { ReactComponent as Rotate } from 'assets/images/rotate.svg';
import { ReactComponent as ShareIcon } from 'assets/images/share-icon.svg';
import { ReactComponent as Sidebar } from 'assets/images/sidebar.svg';
import { ReactComponent as Controls } from 'assets/images/controls-icon.svg';
import { ReactComponent as HeadPhones } from 'assets/images/headphones-icon.svg';
import { ReactComponent as Image } from 'assets/images/image-icon.svg';
import { ReactComponent as Text } from 'assets/images/text-icon.svg';
import { ReactComponent as Video } from 'assets/images/video-icon.svg';
import { ReactComponent as Message } from 'assets/images/message.svg';
import { ReactComponent as Zapier } from 'assets/images/zapier.svg';
import { ReactComponent as Calendar } from 'assets/images/calendar.svg';
import { ReactComponent as Cloud } from 'assets/images/cloud.svg';
import { ReactComponent as Facebook } from 'assets/images/facebook.svg';
import { ReactComponent as Instagram } from 'assets/images/instagram.svg';
import { ReactComponent as Linkedin } from 'assets/images/linkedin.svg';
import { ReactComponent as Twitter } from 'assets/images/twitter.svg';
import { ReactComponent as UploadPicture } from 'assets/images/upload-picture.svg';
import { ReactComponent as DropDownIcon } from 'assets/images/small-arrow-down.svg';
import { ReactComponent as PauseIcon } from 'assets/images/pause.svg';
import { ReactComponent as Star } from 'assets/images/small-star.svg';
import { ReactComponent as SharedLinksIcon } from 'assets/images/shared-links.svg';
import { ReactComponent as MyFilesIcon } from 'assets/images/my-files.svg';
import { ReactComponent as AccountIcon } from 'assets/images/account.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/logout-icon.svg';
import { ReactComponent as FilesIcon } from 'assets/images/files-icon.svg';
import { ReactComponent as AccountIconMobile } from 'assets/images/account-icon.svg';
import { ReactComponent as DesignPresetsIcon } from 'assets/images/design-presets.svg';
import { ReactComponent as SharedIcon } from 'assets/images/shared-icon.svg';
import { ReactComponent as LogoutIconMobile } from 'assets/images/logout-icon-mobile.svg';
import { ReactComponent as LinkLine } from 'assets/images/link-line.svg';
import { ReactComponent as PaperClip } from 'assets/images/paperclip.svg';
import { ReactComponent as AddFile } from 'assets/images/add-file.svg';
import { ReactComponent as Close } from 'assets/images/close.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/remove.svg';
import { ReactComponent as Eye } from 'assets/images/eye.svg';
import { ReactComponent as CrossedEye } from 'assets/images/crossed-eye.svg';
import { ReactComponent as Gear } from 'assets/images/gear.svg';

export const icons = {
  star: Star,
  'checked-icon': Checked,
  'logout-mobile': LogoutIconMobile,
  'shared-icon': SharedIcon,
  'design-presets': DesignPresetsIcon,
  'account-icon': AccountIconMobile,
  'files-icon': FilesIcon,
  'shared-links': SharedLinksIcon,
  'my-files': MyFilesIcon,
  account: AccountIcon,
  'logout-icon': LogoutIcon,
  'arrow-down': ArrowDown,
  camera: Camera,
  cancel: Cancel,
  'cancel-only': CancelOnly,
  crop: Crop,
  'full-view': CustomFullView,
  logo: CustomLogo,
  upload: CustomUpload,
  check: CustomCheck,
  play: CustomPlay,
  reset: CustomReset,
  delete: DeleteIcon,
  download: Download,
  'download-new': DownloadNew,
  email: Email,
  'large-check': LargeCustomCheck,
  link: Link,
  load: LoadIcon,
  'player-arrow': PlayerArrow,
  rotate: Rotate,
  share: ShareIcon,
  sidebar: Sidebar,
  controls: Controls,
  video: Video,
  headphones: HeadPhones,
  text: Text,
  image: Image,
  message: Message,
  zapier: Zapier,
  calendar: Calendar,
  cloud: Cloud,
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  linkedin: Linkedin,
  'upload-picture': UploadPicture,
  'dropdown-icon': DropDownIcon,
  pause: PauseIcon,
  'link-line': LinkLine,
  paperclip: PaperClip,
  'add-file': AddFile,
  close: Close,
  'edit-icon': EditIcon,
  remove: RemoveIcon,
  eye: Eye,
  'crossed-eye': CrossedEye,
  gear: Gear,
};
