import React from 'react';
import { Container } from './Option.styled';

/**
 *
 * @param optionColor {color}
 * @param isDisabled {boolean}
 * @param isActive {boolean}
 * @param onClick {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const Option = ({
  optionColor, isDisabled, isActive, onClick,
}) => {
  return (
    <Container
      role="presentation"
      isActive={isActive}
      isDisabled={isDisabled}
      currentColor={optionColor}
      onClick={onClick}
    />
  );
};
