import React from 'react';
import PropTypes from 'prop-types';
import { identity, is } from 'ramda';

import './style.sass';

const Input = ({
  id, name, label, error, type, value, className, placeholder, onChange, autoComplete, onBlur,
}) => (
  <div className={`field-group ${className}`}>
    {label && (<label htmlFor={id} className="field-group__label">{label}</label>)}
    <input
      onBlur={onBlur}
      type={type}
      id={id}
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      autoComplete={autoComplete}
      className="field-group__input"
    />
    {error && (<small className="field-group__error">{ is(Object, error) ? error.message : error}</small>)}
  </div>
);

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'password']),
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  name: '',
  error: '',
  label: '',
  placeholder: '',
  value: '',
  className: '',
  id: '',
  onChange: identity,
  autoComplete: 'off',
};

export default Input;
