import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { getTimeStringBySeconds } from 'utils/helpers/commonHelpers';
import { PlayerTrack } from '../PlayerTrack';

const TIMEOUT = 100;

/**
 *
 * @param playerRef {object}
 * @param isPaused {boolean}
 * @param onTogglePlaying {function}
 * @param setProgress {function}
 * @param progress {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const Track = ({
  playerRef, isPaused, onTogglePlaying, setProgress, progress,
}) => {
  const [duration, setDuration] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);

  const handleChangeProgress = useCallback((value) => {
    playerRef.current.pause();
    playerRef.current.currentTime = value;
    setCurrentProgress(value);
    setProgress(value);
  }, [playerRef, progress, currentProgress]);

  const makeInterval = useMemo(() => setInterval(() => {
    if (!isPaused) {
      setCurrentProgress(playerRef.current.currentTime);
      setProgress(playerRef.current.currentTime);
    }
    if (playerRef.current.paused !== isPaused) {
      onTogglePlaying(!isPaused);
    }
  },
  TIMEOUT), [isPaused, playerRef]);

  const makeSpecialLabel = useCallback((value) => getTimeStringBySeconds(Math.ceil(value)) || 0, []);

  useEffect(() => {
    let interval;
    if (playerRef.current) {
      interval = makeInterval;
    }
    return () => {
      clearInterval(interval);
    };
  }, [isPaused, playerRef]);

  // set duration for player track
  useEffect(() => {
    const handleVideoReady = (e) => {
      setDuration(e.target.duration);
    };

    if (playerRef.current) {
      playerRef.current.addEventListener('canplay', handleVideoReady);
    }
    return () => {
      // I need to check ref, because sometimes video removed faster
      if (playerRef.current) {
        playerRef.current.removeEventListener('canplay', handleVideoReady);
      }
    };
  }, [playerRef.current]);

  return (
    <PlayerTrack
      maxValue={duration}
      minValue={0}
      formatLabel={makeSpecialLabel}
      value={currentProgress}
      onChange={handleChangeProgress}
    />
  );
};
