import React, { memo } from 'react';
import { Icon } from 'ui-kit';
import { Button } from './LinkItem.styled';

/**
 *
 * @param title {string}
 * @param onClick {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const RemoveButton = memo(({ title, onClick }) => {
  return (
    <Button
      type="button"
      onClick={onClick}
      title={`Delete ${title}`}
    >
      <Icon name="delete" height={20} width={17} />
    </Button>
  );
});
