import { API_METHODS } from 'constants/common';
import { ROUTES } from 'constants/api.routes';
import { catchError } from 'utils/helpers';
import { apiService } from './api.service';

export const tempFileService = {
  downloadFileUsingUrl: async (data = {}) => {
    return catchError(apiService({
      method: API_METHODS.POST,
      subUrl: ROUTES.TEMP_FILES.URL,
      data,
    }));
  },
};
