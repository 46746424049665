import React from 'react';
import { Head, HeadCell, Row } from 'ui-kit';

const DEFAULT_PADDING = '16px 0';

const DEFAULT_STYLES = {
  textAlign: 'left',
  padding: DEFAULT_PADDING,
};

const HEAD_LIST = [
  {
    id: 'title',
    label: 'Title',
    width: 176,
    ...DEFAULT_STYLES,
    paddingLeft: '10px',
  },
  {
    id: 'recipients',
    label: 'Recipients',
    width: 250,
    ...DEFAULT_STYLES,
  },
  {
    id: 'comment',
    label: 'Comment',
    width: 176,
    ...DEFAULT_STYLES,
  },
  {
    id: 'created_at',
    label: 'Created',
    name: 'created_at',
    width: 176,
    ...DEFAULT_STYLES,
  },
  {
    id: 'copy_link',
    label: 'Copy link',
    width: 176,
    ...DEFAULT_STYLES,
  },
  {
    id: 'remove',
    label: '',
    width: 106,
    ...DEFAULT_STYLES,
  },
];

/**
 *
 * @param orderBy {"desc" | "asc"}
 * @param handleSort {function}
 * @param sortBy {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const HeadTable = ({ orderBy, handleSort, sortBy }) => {
  return (
    <Head>
      <Row isHead isBoxShadow>
        {HEAD_LIST.map(({
          id, label, name, render, ...props
        }) => (
          <HeadCell
            key={id}
            order={orderBy}
            onSort={handleSort}
            name={name}
            isLabel={name === sortBy}
            {...props}
          >{render ? render() : label}
          </HeadCell>
        ))}
      </Row>
    </Head>
  );
};
