export const STATUSES = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const WEBHOOK_METHODS = {
  GET: 'get',
  POST: 'post',
};

export const WEBHOOK_METHODS_OPTIONS = [{
  label: 'GET',
  value: WEBHOOK_METHODS.GET,
},
{
  label: 'POST',
  value: WEBHOOK_METHODS.POST,
}];

export const BODY_TYPES = {
  JSON: 1,
  FORM_DATA: 2,
};

export const BODY_TYPES_OPTIONS = [{
  label: 'JSON',
  value: BODY_TYPES.JSON,
},
{
  label: 'Form-data',
  value: BODY_TYPES.FORM_DATA,
}];
