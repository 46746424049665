import React, { useCallback, useMemo } from 'react';
import { is } from 'ramda';
import { Container, Label } from './Button.styled';

/**
 *
 * @param children {JSX.Element}
 * @param isDisabled {boolean}
 * @param onClick {function}
 * @param buttonTheme {string}
 * @param type {string}
 * @param label {string | number}
 * @param title {string | number}
 * @param props {object}
 * @returns {JSX.Element}
 * @constructor
 */

export const Button = ({
  children,
  isDisabled = false,
  onClick,
  buttonTheme = 'primary',
  type = 'button',
  label,
  title,
  ...props
}) => {
  const handleClick = useCallback(() => {
    if (!isDisabled && onClick) {
      onClick();
    }
  }, [isDisabled, onClick]);
  const labelNode = useMemo(() => children || <Label>{label}</Label>, [
    label,
    children,
  ]);

  const content = is(Function, children) ? children(labelNode) : labelNode;

  return (
    <Container
      buttonTheme={buttonTheme}
      isDisabled={isDisabled}
      onClick={handleClick}
      type={type}
      title={title}
      {...props}
    >
      {content}
    </Container>
  );
};
