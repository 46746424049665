import React from 'react';
import { Container, Text } from './SelectGroup.styled';
import { Select } from '../Select';

/**
 *
 * @param id {string | number}
 * @param value {string | number}
 * @param title {string}
 * @param label {string | JSX.Element}
 * @param name {string}
 * @param options {Array<{label: string, value: number | string}>}
 * @param onChange {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const SelectGroup = ({
  id,
  value,
  title,
  label,
  name,
  options,
  onChange,
}) => {
  return (
    <Container>
      <Text>{title}</Text>
      <Select
        id={id}
        value={value}
        onChange={onChange}
        name={name}
        label={label}
        options={options}
      />
    </Container>
  );
};
