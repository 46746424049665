export default {
  CREATE_VIDEO: 'CREATE_VIDEO',
  CREATE_VIDEO_REQUEST: 'CREATE_VIDEO_REQUEST',

  DELETE_VIDEO_REQUEST: 'DELETE_VIDEO_REQUEST',
  DELETE_VIDEO_REQUEST_SUCCESS: 'DELETE_VIDEO_REQUEST_SUCCESS',

  DELETE_VIDEOS_REQUEST: 'DELETE_VIDEOS_REQUEST',
  DELETE_VIDEOS_REQUEST_SUCCESS: 'DELETE_VIDEOS_REQUEST_SUCCESS',

  SEND_DATA_FILE_REQUEST: 'SEND_DATA_FILE_REQUEST',
  SEND_DATA_FILE_SUCCESS: 'SEND_DATA_FILE_SUCCESS',

  RUN_RENDER_VIDEO_REQUEST: 'RUN_RENDER_VIDEO_REQUEST',
  RUN_RENDER_VIDEO_SUCCESS: 'RUN_RENDER_VIDEO_SUCCESS',

  GET_VIDEOS_REQUEST: 'GET_VIDEOS_REQUEST',
  SET_VIDEOS: 'SET_VIDEOS',

  SEND_AUDIO_FILE_REQUEST: 'SEND_AUDIO_FILE_REQUEST',
  SEND_AUDIO_FILE_SUCCESS: 'SEND_AUDIO_FILE_SUCCESS',

  SET_PREPARE_TO_RENDER: 'SET_PREPARE_TO_RENDER',

  UPDATE_VIDEO_CLIENT_STATUS_REQUEST: 'UPDATE_VIDEO_CLIENT_STATUS_REQUEST',
  UPDATE_VIDEO_CLIENT_STATUS_SUCCESS: 'UPDATE_VIDEO_CLIENT_STATUS_SUCCESS',

  GET_COUNT_OF_RENDERING_FILES_REQUEST: 'GET_COUNT_OF_RENDERING_FILES_REQUEST',
  GET_COUNT_OF_RENDERING_FILES_SUCCESS: 'GET_COUNT_OF_RENDERING_FILES_SUCCESS',
  SET_COUNT_OF_RENDERING_FILES: 'SET_COUNT_OF_RENDERING_FILES',

  SEND_VIDEOS_TO_ZAPIER_REQUEST: 'SEND_VIDEOS_TO_ZAPIER_REQUEST',

  FFMPEG_VALIDATION_REQUEST: 'FFMPEG_VALIDATION_REQUEST',

  TOGGLE_TOP_VIDEO: 'TOGGLE_TOP_VIDEO',

  CHANGE_VIDEOS_IDS_FOR_DELETE: 'CHANGE_VIDEOS_IDS_FOR_DELETE',

  CHANGE_IS_MAKING_EXPORT: 'CHANGE_IS_MAKING_EXPORT',
};
