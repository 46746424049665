import styled from 'styled-components';
import Cropper from 'react-cropper';

export const RootStyle = styled.div`
  box-sizing: border-box;
  text-align: center;
`;

export const PreloaderText = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  font-weight: 300;
`;

export const StyledCropper = styled(Cropper)`
  .cropper-container {
    box-sizing: border-box;
    max-width: 100% !important;
    max-height: 400px;
  }
  .cropper-crop-box {
    position: relative;
    max-height: calc(100% - 3px);
    max-width: calc(100% - 3px);
    margin-left: 1px;
  }
  .pseudo-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .cropper-face {
    z-index: 1
  }
  .cropper-line {
    background-color: transparent !important
  }
  .cropper-view-box {
    outline: 1px dashed ${({ theme }) => theme.colors.BORDER_COLOR};
  }
  .cropper-view-box {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.CROPPER};
    outline: 0;
  }
  .cropper-point {
    background-color: ${({ theme }) => theme.colors.CROPPER};
    height: 5px;
    opacity: 0.75;
    width: 5px;
    z-index: 9;
  }
  .cropper-line {
    z-index: 9;
  }
  .line-s, .line-n {
    height: 8px;
  }
  .line-w, .line-e {
    width: 8px;
  }
`;

export const GifImage = styled.img`
  border: 1px solid ${({ theme }) => theme.colors.BLACK};
`;

export const CropperContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
