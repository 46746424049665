import styled from 'styled-components';

export const BackDropOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
`;

export const MainContainer = styled.div`
  position: fixed;
  top: 70px;
  left:  50%;
  width: 846px;
  height: 548px;
  z-index: 1000;
  margin-left: -423px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 20px;
  font-family: ${({ theme }) => theme.fontFamily};
    .rvt-icon-spin {
      animation: spin infinite 1s linear;
    }
  @keyframes spin{
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @media (${({ theme }) => theme.devices.LG}){
    top: 40px;
  }

  @media (${({ theme }) => theme.devices.MD}){
    width: 700px;
    margin-left: -350px;
  }

  @media (${({ theme }) => theme.devices.SM}){
    width: 460px;
    margin-left: -230px;
  }

  @media (${({ theme }) => theme.devices.XS}){
    width: 340px;
    height: 500px;
    margin-left: -170px;
  }
`;

export const TrimmerControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  padding-right: 20px;
  padding-bottom: 20px;
    `;

const TrimmerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 32px;
  border-radius: 8px;
  border: none;
  color: ${({ theme }) => theme.colors.WHITE};
  font-weight: 700;
  font-size: 14px;
  line-height: 43px;
  cursor: pointer;
`;

export const CancelButton = styled(TrimmerButton)`
  margin-right: 11px;
  background-color: ${({ theme }) => theme.colors.RED};
`;

export const TrimButton = styled(TrimmerButton)`
  background-color: ${({ theme }) => theme.colors.SUCCESS};
`;

export const LabelSelect = styled.div`
  min-width: 55px;
  width: 160px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  border-radius: 7px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.MAIN};
  color: ${({ theme }) => theme.colors.WHITE};
  white-space: nowrap;
  .dropdown-icon {
    margin-bottom: -3px;
    margin-left: 5px;
  }
  .dropdown-icon-img {
    margin-bottom: 9px;
    margin-left: 5px;
  }
  @media(${({ theme }) => theme.devices.XS}) {
    padding: 0 13px;
  }
`;

export const MaxLengthWrapper = styled.div`
  display: flex;
  width: 300px;
  justify-content: center;
  align-items: center;
`;

export const MaxLengthLabel = styled.div`
  font-size: 12px;
  color: rgba(0,0,0, 0.5);
  margin-right: 10px;
`;
