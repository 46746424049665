import { createSelector } from 'reselect';

export const selectWebhookList = createSelector(
  (state) => state.webhooks.data || [],
  (a) => a,
);

export const selectWebhookById = createSelector(
  selectWebhookList,
  (_, id) => id,
  (list, id) => list.find((item) => item.id === id),
);

export const selectWebhookParams = createSelector(
  (state) => state.webhooks.params || [],
  (a) => a,
);
