import styled from 'styled-components';

export const Container = styled.div`
  max-width: 400px;
  margin: 160px auto 0;
  text-align: center;
`;

export const Status = styled.div`
  color: ${({ theme }) => theme.colors.MAIN}
`;

export const Loader = styled.img`
  width: 80px;
  height: 80px;
  margin: 50px auto;
`;

export const Filename = styled.div`
  color: ${({ theme }) => theme.colors.MAIN};
  opacity: 0.5;
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  bottom: 20px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 32px;
  border-radius: 8px;
  border: none;
  color: ${({ theme }) => theme.colors.WHITE};
  font-weight: 700;
  font-size: 14px;
  line-height: 43px;
  cursor: pointer;
  :focus, :hover{
    outline:none;
  }
`;

export const CancelButton = styled(Button)`
  margin-right: 11px;
  background-color: ${({ theme }) => theme.colors.RED};
`;

export const TrimButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.SUCCESS};
`;
