export const MIN_DURATION_PRELOADER = 800;

export const PRELOADER_TYPES = {
  OVERLAY: 'overlay',
  CONTAINER: 'container',
  CERTAIN: 'certain',
};

export const PRELOADER_SIZES = {
  LARGE: 'large',
  MIDDLE: 'middle',
  SMALL: 'small',
};
