import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { getColorByType } from 'utils/helpers';
import { Badge } from '../Badge';

/**
 *
 * @param label {string}
 * @param type {number} render_type
 * @param isDefaultBackground {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const BadgeFormat = ({ label, type, isDefaultBackground = false }) => {
  const theme = useTheme();
  const bgColor = useMemo(() => {
    return getColorByType({ theme, type, isDefaultBackground });
  }, [type, isDefaultBackground]);
  return (
    <Badge bg={bgColor} label={label} />
  );
};
