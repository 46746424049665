import styled from 'styled-components';
import {
  typography, color, system, layout,
} from 'styled-system';

export const Wrapper = styled.div`
  position: relative;
  ${layout};
`;

export const Label = styled.div`
  display: inline-block;
  width: 100%;
`;

export const LabelText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  ${typography}
  ${color}
  ${system({
    textTransform: true,
    letterSpacing: true,
  })}
`;

export const StyledSelect = styled.select`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 7px;
  cursor: pointer;
`;

export const Option = styled.option`
  font-size: 14px;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.ERROR};
  font-size: 12px;
  :first-letter{
    text-transform: capitalize
  }
  ${typography}
`;
