import styled from 'styled-components';
import { Modal } from 'ui-kit';
import ScrollBars from 'react-custom-scrollbars';

export const RootStyle = styled(Modal)`
  .modal-window__container {
    margin-top: 100px;
    width: 90%;
    max-width: 800px;
    overflow: hidden;
  }
`;

export const ButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 10px;
`;

export const CustomScrollbars = styled(ScrollBars)`
  //set important because inline styles are used by default in this component
  height: 620px !important; 
  width: 746px !important;
  @media(${({ theme }) => theme.devices.LGX}) {
    height: 330px !important;
  }
`;
