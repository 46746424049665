import { API_METHODS } from 'constants/common';
import { ROUTES } from 'constants/api.routes';
import { catchError } from 'utils/helpers';
import { apiService } from './api.service';

export const animationService = {
  fetchAllAnimations: async () => {
    return catchError(apiService({
      method: API_METHODS.GET,
      subUrl: ROUTES.ANIMATIONS.ALL,
    }));
  },
};
