import sagasManager from 'utils/sagasManager';
import {
  fork, select, take, put,
} from 'redux-saga/effects';

import {
  runRenderVideoRequest,
  sendAudioFileRequest,
  sendDataFileRequest,
} from 'state/videos/actions';
import { editorSelectors } from 'state/editor';
import { animationsSelectors } from 'state/animations';
import {
  find, forEach, pathOr, prop, propEq, propOr, test,
} from 'ramda';
import { notify } from 'utils/helpers/notificationHelpers';
import { AUDIO_FADE_DURATION, VIDEO_BITRATE, RENDER_IMAGE_FORMATS } from 'constants/rendering';
import { createFileByUrl } from 'utils/helpers/filesHelper';
import { getApiUrl } from 'utils/helpers/requestHelpers';
import { LottieInstance } from 'utils/helpers/lottieHelpers';
import { makeFileNameEnd } from 'utils/helpers/renderHelpers';
import { animationSelectors } from '../animation';
import { draftsActions } from '../drafts';
import { companySelectors } from '../company';
import videosTypes from './types';
import * as videosActions from './actions';

// ### Render video workflow ###
/*
    Introduce: Before rendering video we must prepared draft file of render version.
    We save all asset ( Images, videos, audio) and variables to draft.
    Then we can render file by draftId  and rendering params that was selected by User.
 */

function* senDataFileSuccessFlow() {
  while (true) {
    try {
      const {
        payload: {
          data: { id },
          meta: {
            name, type, gif_fps, audioFileId, createVideoEndCb, ffmpeg_settings,
            draftId, outputCommand, version, length, outputUrl,
          },
        },
      } = yield take(videosTypes.SEND_DATA_FILE_SUCCESS);
      const {
        audioStart,
        audioFade,
        selectedAnimation,
        company: { image_type: imagesFormat, video_max_bitrate: bitrate },
      } = yield select((store) => ({
        audioStart: editorSelectors.getAudioStartPoint(store),
        audioFade: editorSelectors.getAudioFade(store),
        selectedAnimation: animationsSelectors.getSelectedAnimation(store),
        company: companySelectors.getCompany(store),
      }));

      const data = yield find(
        propEq('aspectratio', version),
        propOr([], 'template', selectedAnimation),
      );

      const fileNameEnd = makeFileNameEnd(version, length);

      yield put(runRenderVideoRequest({
        data_file_id: id,
        title: `${name}_${fileNameEnd.replace(/\..*$/g, '')}`,
        type,
        max_bitrate: pathOr(VIDEO_BITRATE[1].valueForApi, [bitrate, 'valueForApi'], VIDEO_BITRATE),
        gif_fps,
        draft_id: draftId,
        image_format: pathOr(1, [imagesFormat, 'value'], RENDER_IMAGE_FORMATS),
        animation_ratio: version,
        animation_name: prop('name', data),
        original_audio_file_id: audioFileId,
        audio_fade: audioFade ? 1 : 0,
        audio_fade_in_time: AUDIO_FADE_DURATION.IN,
        audio_fade_out_time: AUDIO_FADE_DURATION.OUT,
        audio_start_at: audioStart,
        selected_frame: LottieInstance.getCurrentFrame(),
        output_command: outputCommand,
        output_url: outputUrl,
        ffmpeg_settings,
      }, {
        callbacks: {
          success: createVideoEndCb,
          error: () => {
            createVideoEndCb();
            notify.error('Rendering error: unknown error');
          },
        },
      }));
    } catch (e) {
      console.log(e);
    }
  }
}

function* sendDataFileRequestBuilder({
  audioFileId, createVideoEndCb, draftId, ...params
}) {
  let data = yield select((store) => animationSelectors.animationDataSelector(store));

  data = {
    ...data,
    assets: data.assets.map((asset) => {
      const url = asset.u;
      const regexApiUrl = new RegExp(`${getApiUrl()}/dist/`, 'g');
      if (test(regexApiUrl, url)) {
        return { ...asset, u: url.replace(regexApiUrl, '') };
      }
      return asset;
    }),
  };
  const formData = yield new FormData();
  const blob = yield new Blob([JSON.stringify(data)], { type: 'application/json' });

  yield formData.append('file', new File([blob], 'data.json'));
  yield put(sendDataFileRequest(formData, {
    audioFileId, createVideoEndCb, draftId, ...params,
  }));
}

function* sendAudioFileSuccessFlow() {
  while (true) {
    const {
      payload: {
        data: { id: audioFileId },
        meta: { createVideoEndCb, draftId, renderConfigsList },
      },
    } = yield take(videosTypes.SEND_AUDIO_FILE_SUCCESS);
    forEach((params) => {
      sagasManager.addSagaToRoot(function* watcher() {
        yield sendDataFileRequestBuilder({
          audioFileId, createVideoEndCb, draftId, ...params,
        });
      });
    }, renderConfigsList);
  }
}

function* createVideoFlow() {
  while (true) {
    const {
      payload: {
        createVideoEndCb, renderConfigsList,
        name, length, version, outputCommand, outputUrl,
      },
    } = yield take(videosTypes.CREATE_VIDEO);
    yield put(videosActions.setPrepareToRender(true));
    yield put(draftsActions.createVideoDraft({
      title: name,
      length,
      version,
      outputCommand,
      outputUrl,
      createDraftEndCb: ({ data }) => {
        if (data.model) {
          const { model } = data;
          sagasManager.addSagaToRoot(function* watcher() {
            const { source, info } = yield select(editorSelectors.getAudioFile);
            const formData = yield new FormData();
            if (source) {
              const file = yield createFileByUrl(source, info.name, info.type);
              formData.append('file', file);
              yield put(sendAudioFileRequest(formData, {
                createVideoEndCb, draftId: model.id, renderConfigsList,
              }));
            } else {
              forEach((params) => {
                sagasManager.addSagaToRoot(function* watcherData() {
                  yield sendDataFileRequestBuilder({
                    audioFileId: null, name, createVideoEndCb, draftId: model.id, ...params,
                  });
                });
              }, renderConfigsList);
            }
          });
        } else {
          createVideoEndCb();
        }
      },
    }));
  }
}

function* getRenderingFilesSuccessFlow() {
  const { payload: { data: { count } } } = yield take(videosTypes.GET_COUNT_OF_RENDERING_FILES_SUCCESS);
  yield put(videosActions.setCountOfRenderingFiles(count));
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(createVideoFlow);
  yield fork(senDataFileSuccessFlow);
  yield fork(sendAudioFileSuccessFlow);
  yield fork(getRenderingFilesSuccessFlow);
});
